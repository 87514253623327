import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import OverlayLoader from '../../Components/OverlayLoader';
import {alpha, withStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {getIdbySegment, getToken, registerForm} from "../../redux/authActions";
import SanitizedHTML from 'react-sanitized-html';
import {withSnackbar} from "notistack";
import API from "../../Util/API";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const GroupSelector = (process.env.REACT_APP_TACLIENT_DIR === 'tam') ?
    require('../../Components/GroupSelector').default :
    '';

class Login extends Component {

    constructor(p) {
        super(p);
        this.state = {email: '', gids: [], coupon: '', loading: false, showPassword:false} // browser pre-populating makes state her difficult. we just always rely on the DOM version  - document.getElementById("inp-login-email").value
        this.signUp = this.signUp.bind(this);
        this.handleEmailInp = this.handleEmailInp.bind(this);
        this.sendOtpLink = this.sendOtpLink.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.me && this.props.auth.me.profile) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.me && this.props.auth.me.profile) {
            this.props.history.push('/');
        }
    }

    handleEmailInp(e) {
        this.setState({email: e.currentTarget.value});
    }

    handleGids(val) {
        this.setState({gids: val});
    }

    validateEmail(e) {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const email = document.getElementById("inp-login-email").value;
        if (!email || email === '') {
            this.props.enqueueSnackbar('First type in your Email Address', {variant: 'info'});
            return false;
        }
        if (!re.test(String(email).toLowerCase())) {
            this.props.enqueueSnackbar('Invalid Email Address', {variant: 'info'});
            return false;
        }
        return email;
    }

    sendOtpLink(e) {
        const tdata = getIdbySegment(document.location.pathname);
        tdata.target_id = 'otplink-click';

        const email = this.validateEmail(e);
        if (!email) return;

        if (this.state.showPassword && document.getElementById("inp-login-password")) {
            tdata.target_id = 'passcode-login';
            const password = document.getElementById("inp-login-password").value;
            return this.props.dispatch(getToken(email, password));
        }

        window.logUse.logEvent('login', tdata);

        const req = {
            method: 'POST', url: '/otp/account_otp/send',
            data: {mail: [{value: email}]}
        };
        if (this.props.auth.curGroup > 0) {
            req.data.gid = [{target_id: this.props.auth.curGroup}];
        }

        const noticeParams = {
            variant: 'error', persist: true, action: key => (
                <IconButton variant="outline-light"
                            onClick={() => {
                                this.props.closeSnackbar(key);
                            }}
                >
                    <Close/>
                </IconButton>
            )
        }

        this.setState({loading: true});
        API.Request(req).then((res) => {
            this.setState({loading: false});
            if (res.data.error) {
                this.props.enqueueSnackbar(res.data.error, noticeParams);
            } else {
                noticeParams.variant = 'success';
                this.props.enqueueSnackbar(res.data.success || 'Welcome.', noticeParams);
            }
        })
            .catch((err) => {
                this.setState({loading: false});
                this.props.enqueueSnackbar(API.getErrorMsg(err), noticeParams);
                tdata.verb = "otplink-failed";
                window.logUse.logEvent("init", tdata);
                return err;
            });
    }


    signUp(e) {
        const email = this.validateEmail(e);
        if (!email) return;
        window.logUse.logEvent('view', {page: 'register'});
        return this.props.dispatch(registerForm(email, this.state.gids, this.state.coupon));
    }


    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                {(this.state.loading || this.props.auth.loading === true) ? <OverlayLoader/> : null}
                {this.props.auth.me.brandId === 59 &&
                    <div className={classes.panBG}>
                        <div className={'img'}></div>
                    </div>
                }
                <div className={classes.innerContainer}>
                    <Grid container justifyContent={"space-between"} alignContent={"space-between"}
                          alignItems={'space-between'} spacing={2} className={classes.paperBg}>
                        {(this.props.auth.signUpError) && <Grid item xs={12}><SanitizedHTML style={{color: 'red', textAlign: 'center', width:'100%', fontSize:16}}
                                                                                   html={this.props.auth.signUpError}/></Grid>}

                        <Grid item xs={12}>
                            <TextField
                                label={'Sign In or Up with your Email'}
                                variant={'outlined'}
                                defaultValue={""}
                                autoFocus={true}
                                fullWidth={true}
                                id='inp-login-email'
                                autoComplete={'username email name'}
                                name='email'
                                type='email'
                            />
                        </Grid>

                        {(this.state.showPassword) && <Grid item xs={12}>
                            <TextField
                                label={'PassCode'}
                                variant={'outlined'}
                                defaultValue={""}
                                autoFocus={true}
                                fullWidth={true}
                                id='inp-login-password'
                                autoComplete={'password'}
                                name='password'
                                type='password'
                            />
                        </Grid>}


                        {this.props.auth.curGroup > 0 || this.props.auth.me.brandId > 0 ?
                            <input type='hidden' name='GroupSelector'
                                   value={this.props.auth.curGroup || this.props.auth.me.brandId}/>
                            :
                            <Grid item xs={12}><GroupSelector onChange={gids => this.handleGids(gids)}/></Grid>
                        }

                        <Grid item>
                            <ButtonGroup>
                                <Button variant="contained" color="primary" aria-label='Login Button' type='submit'
                                        onClick={this.sendOtpLink}>
                                    Sign In
                                </Button>
                                {this.state.showPassword === false &&
                                    <Button variant="contained" color="secondary" type='submit' onClick={this.signUp}>
                                        Sign Up
                                    </Button>
                                }
                            </ButtonGroup>
                        </Grid>


                        <Grid item style={{paddingTop:20, paddingBottom:0, textAlign:'right', fontSize:'80%', opacity:.7}}>
                            {this.state.showPassword === false ?
                                <span id={'showPasswordButton'} style={{cursor:'pointer'}} onClick={() => this.setState({showPassword: true})}>Use your Passcode</span>
                                :
                                <span id={'showPasswordButton'} style={{cursor:'pointer'}} onClick={() => this.setState({showPassword: false})}>Close Account Management Password</span>
                            }
                        </Grid>



                    </Grid>
                </div>
            </React.Fragment>

        );
    }
}

const styles = theme => ({
    panBG: {
        zIndex: -1,
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        height: '80%',
        opacity: .17,
        '& .img': {
            animation: 'panBG 1 100s linear',
            backgroundImage: `url(/assets/allathletes-bg.png)`,
            backgroundRepeat: 'repeat-x',
            backgroundSize: 'cover',
            minWidth: '100%',
            height: '100%',
            position: 'absolute'
        }
    },
    paperBg: {
        padding: 10,
        backgroundColor: alpha(theme.palette.background.paper, .75),
    },
    innerContainer: {
        position: 'relative',
        padding: 10,
        width: '100%',
        margin: '35vh 3%'
    },
    tabBtn: {
        borderRight: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
        borderTop: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`
    }
});


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(withSnackbar(Login))));
