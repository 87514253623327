import React, {useEffect, useRef, useState} from 'react';
import API from "../../Util/API";
import PropTypes from "prop-types";
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import {useParamListener} from '../../Util/WindowUtils';

const AuthenticatedVideo = (props) => {
    const [videoUrl, setVideoUrl] = useState(props.apiurl);
    const [clicked, setClicked] = useState(false);
    const videoRef = useRef(null);

    function handleSeek(seekto) {
        const videoElement = videoRef.current;
        if (videoElement && seekto) {
            if (seekto.split(':').length > 2) {
                const [hours, minutes, seconds] = seekto.split(':');
                seekto = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
            } else {
                seekto = parseInt(seekto)
            }
            if (seekto > 0) {
                videoElement.currentTime = seekto;
            }
        }
    }

    const handleLoadedMetadata = () => {
        handleSeek(props.start_time)
    };

    useParamListener('start_time', (seekto) => {
        handleSeek(seekto);
    });

    useEffect(() => {
        const fetchVideoUrl = async () => {

            if (props.apiurl.indexOf('/media/stream') > -1 || props.apiurl.indexOf('/sites/default/private') -1) {
                const tokens = API.getLocalTokens();
                if (!tokens || !tokens.access_token) {
                    return false
                }

                return API.Blob(props.apiurl).then(async res => {
                    const blob = await res.data;
                    const url = URL.createObjectURL(blob);
                    setVideoUrl(url);
                }).catch(e => {
                    console.error('stream error:', e);
                })
            } else {
                setVideoUrl(props.apiurl);
            }

        };

        fetchVideoUrl();
    }, []);

    // if there's a poster show simpler click to play opti
    if (clicked === false && props.poster) {
        return <div onClick={() => setClicked(true)} style={{position:'relative'}}>
            <img src={props.poster} width={'100%'} />
            <span style={{opacity:0.5, position:"absolute", top:'50%', left:'50%', transform: 'translate(-50%, -50%)'}}>
                <PlayIcon fontSize={"large"} />
            </span>
        </div>
    }

    return (
        <div>
            <video poster={props.poster} controls
                   onLoadedMetadata={handleLoadedMetadata}
                   ref={videoRef} autoPlay={props.poster && clicked === true}
                   preload="metadata" src={videoUrl} width={'100%'}/>
        </div>
    );
};


PropTypes.propTypes = {
    apiurl: PropTypes.string.isRequired,
    poster: PropTypes.string
};

export default AuthenticatedVideo;
