import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ProgressLoading from '../../Components/ProgressLoading';
import Config from '../../Config';
import {withStyles} from '@material-ui/core/styles';
import {updateImageField} from "../../redux/formsReducer";
import InputLabel from "@material-ui/core/InputLabel";
import ImageIcon from "@material-ui/icons/Camera";
import Button from "@material-ui/core/Button";

class ImageUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {canvasdata: false};
        this.fileEl = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.startBrowse = this.startBrowse.bind(this);
    }

    startBrowse(e) {
        if (typeof this.props.onFocused === 'function') {
            this.props.onFocused();
        }
        e.stopPropagation();
        this.fileEl.current.click();
    }

    componentWillUnmount() {
        if (this.state.canvasdata) {
            URL.revokeObjectURL(this.state.canvasdata);
        }
    }

    handleChange(event) {
        const data64 = URL.createObjectURL(event.target.files[0]);
        this.setState({canvasdata: data64});
        this.props.dispatch(updateImageField(event.target, this.props.field, this.props.index, this.props.sourceName));
    }

    makeAbsolute(url) { // because current rest endpoint doesn't handle normalization properly
        if (url.indexOf('http') === 0) return url;
        return Config.api.base + url;
    }

    render() {
        const {classes, entry, field, index} = this.props;
        let preview = null;

        if (this.state.canvasdata) {
            preview = <div className={classes.previewStyle} style={{backgroundImage: 'url(' + this.state.canvasdata + ')'}}>&nbsp;</div>
        } else if (entry[index] && entry[index].url){
            preview = <div className={classes.previewStyle} style={{backgroundImage: 'url(' + this.makeAbsolute(entry[index].url) + ')'}}>&nbsp;</div>;
        }

        let progress = entry[index] && entry[index].loading === true ? <ProgressLoading  /> : <ImageIcon />;

        const input = <input
            style={{display: 'none'}}
            name={field.field_name}
            accept="image/*,.heic"
            onChange={this.handleChange}
            ref={this.fileEl}
            type="file"
        />

        return <div className={classes.root} >
                    <Typography component={'div'} style={{minHeight: 60}}>
                        <InputLabel>{field.label}</InputLabel>
                    </Typography>
                    <Button onClick={this.startBrowse} fullWidth={true}  style={{zIndex:1, justifyContent:(preview) ?  'flex-start':'center'}}  >
                        {progress}
                    </Button>
                    {input}
                    {preview}
        </div>
    }
}


const styles = theme => ({
    root: {
        width: '100%', height: '100%',
        margin:'auto'
    },
    previewStyle: {
        textAlign:'center',
        position: 'absolute',
        zIndex: 0,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
        width: '100%', height: '100%', left: 0, top: 0,
        margin:'auto'
    }
});

ImageUpload.propTypes = {
    index: PropTypes.number.isRequired,
    label: PropTypes.string,
    field: PropTypes.object.isRequired,
    entry: PropTypes.array.isRequired,
    onFocused: PropTypes.func,
};

export default withStyles(styles)(ImageUpload);
