import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {raterStyles} from "../../Util/ThemeUtils";
import CloseIcon from "@material-ui/icons/Close";

class VoteNotice extends Component {
    render() {

        return (
            <Grid container className={this.props.classes.onVoteNotice} justifyContent={'space-between'} spacing={1} >
                <Grid item style={{flexGrow:1}}>
                    <span style={{float:'right'}} aria-label={'Close vote response'} onClick={this.props.onClose}><CloseIcon color={'secondary'} /></span>
                    {this.props.posted.message}
                </Grid>
                <Grid item style={{textAlign:'right'}} container direction={'column'} justifyContent={'space-between'}>
                    {this.props.posted.action}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(raterStyles)(VoteNotice);
