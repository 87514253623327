import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import {getIdbySegment} from "../../redux/authActions";

const howTos = {
 'Group Demo': 'custom/ftb.ts.mp4',
 'Add Group': 'forms/forms_group_add-game_type=83-tauser.json.ts.mp4',
 'Edit Group': 'forms/forms_group_edit-group_owner.json.ts.mp4',
 'Edit Membership Profile': 'forms/forms_group_members_edit-member.json.ts.mp4',
 'Edit Playlist': 'forms/forms_group_playlists_edit-curator.json.ts.mp4',
 'Add Playlist': 'forms/forms_group_playlists_add-curator.json.ts.mp4',
 'Add A Track': 'forms/forms_group_playlists_tracks_add-track_owner.json.ts.mp4',
 'Curate/Edit A Track': 'forms/forms_group_playlists_tracks_edit-track_owner.json.ts.mp4',
 'Rate A Track': 'custom/rate-track.ts.mp4',
 'Edit Account Profile': 'forms/forms_users_edit-track_owner.json.ts.mp4',
};

const screenSizes = {"mobile": {w: 360, h: 640}, "tablet": {w: 768, h: 1024}, "desktop": {w: 1366, h: 800}};

const hostname = 'https://trackauthoritymusic.com/videos/ftb';

/*
const hostname = (process.env.REACT_APP_TACLIENT_DIR === 'tam' || process.env.REACT_APP_TACLIENT_DIR === 'ftb') ? // currently on these have videos
    `https://trackauthoritymusic.com/videos/${process.env.REACT_APP_TACLIENT_DIR}`
    :
    `https://trackauthoritymusic.com/videos/ftb`;
*/

class DemoVideos extends Component {
 constructor(props) {
  super(props);
  this.state = {playrate: .8, filename: (props.filename) ? (props.filename) : 'custom/ftb.ts.mp4'};
  this.vidEl = React.createRef();

  this.onVidChange = this.onVidChange.bind(this);
 }

 setPlayRate(val) {
  this.setState({playrate: val});
  this.vidEl.current.playbackRate = val;
 }

 onVidChange(e) {
  this.setState({filename: e.target.value});
  const tdata = getIdbySegment(document.location.pathname);
  tdata.page = 'demos';
  tdata.value = e.target.value;
  window.logUse.logEvent('view', tdata);
 }

 render() {
  // console.log('demovideos: ', this.props); // not sure why props.windowWidth is not updating here
  const size = 'mobile'; // window.innerWidth > 1000 ? 'desktop' : ((window.innerWidth > 768) ? 'tablet' : 'mobile');
  let vidPath = `${hostname}/${size}/${this.state.filename}`;

  if (this.props.displaymode === 'background') {
   return <video
    width='100%' height='100%'
    ref={this.vidEl}
    autoPlay muted controls playsInline loop
    playbackrate={this.state.playrate}
    onCanPlay={() => this.setPlayRate(.8)}
    src={vidPath} type="video/mp4"
   />;
  }

  var options = [];
  for (var t in howTos) {
   options.push(<MenuItem key={t} value={howTos[t]}>{t}</MenuItem>);
  }

  let ratio = 1;
  const dims = screenSizes[size];
  const winSize = {w: window.innerWidth - 40, h: window.innerHeight - 65};
  if (dims.w > winSize.w) {
   ratio = winSize.w / dims.w;
  }
  if (dims.h > winSize.h) {
   ratio = Math.min(ratio, winSize.h / dims.h);
  }

  dims.newW = Math.floor(ratio * dims.w);
  dims.newH = Math.floor(ratio * dims.h);

  return (
   <div className={this.props.classes.vidContainer} >
    <Select
     className={this.props.classes.vidSelector}
     value={this.state.filename}
     onChange={this.onVidChange}>
     {options}
    </Select>
    <div className={size}>
     <div className="deviceMask" >
      <video
       width='100%' height='100%'
       ref={this.vidEl}
       autoPlay muted controls playsInline
       playbackrate={this.state.playrate}
       onCanPlay={() => this.setPlayRate(.8)}
       src={vidPath} type="video/mp4"
      />
     </div>
    </div>
   </div>
  );
 }
}

const styles = theme => ({
 vidSelector: {
  margin:'0',
  textAlign:'center',
  fontWeight:600,
  width:'100%',
  padding:10,
  '&::before' : {
   right:30,
   left:30
  }
 },
 vidContainer: {
  position: 'relative',
  padding:10,
  margin: 'auto'
 }
});

DemoVideos.defaultProps = {
 windowWidth:window.innerWidth,
 windowHeight:window.innerHeight,
 displaymode:'block',
};

DemoVideos.propTypes = {
 displaymode:PropTypes.string,
 filename: PropTypes.string,
 onChange: PropTypes.func,
 windowHeight:PropTypes.number,
 windowWidth:PropTypes.number
};

export default withStyles(styles)(DemoVideos);
