import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Cancel';

class DialogView extends Component {

 render() {

  const Wrapper = this.props.component;

  return (
   <Dialog maxWidth='xl' fullWidth={true} open={this.props.open} >
    <DialogTitle>
     {this.props.title}
     <Button onClick={() => this.props.closeDialog()} style={{float:'right'}}
             variant="outlined" color="secondary" size='small' aria-label={'Close Dialog'} startIcon={<CloseIcon />}>Close</Button>
    </DialogTitle>
    <DialogContent>
     <Wrapper {...this.props.content} />
    </DialogContent>
    <DialogActions>
     {this.props.action}
    </DialogActions>
   </Dialog>
  );
 }
}

export default DialogView;
