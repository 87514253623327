import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


class CharitySelector extends Component {
    state = {charityId:'0'};

    render() {
     const item = {...this.props.data};
     const { classes } = this.props;
     const productId = item.product_id[0].value;

     var charities = [];

     if (item.variations && item.variations.length > 0 && item.variations[0].field_charity && item.variations[0].field_charity.length > 0) {
      if (item.variations.length === 1) {
       charities = <p>{item.variations[0].field_charity_percent[0]['value'] + '% to ' + item.variations[0].field_charity[0]['target_label']}</p>;
      } else {
       for(var t=0; t < item.variations.length; t++) {
        var obj = item.variations[t].field_charity[0]; // entity reference with target_xxx properties,
        charities.push(
         <MenuItem key={obj.target_id} value={obj.target_id}>{item.variations[t].field_charity_percent[0].value + '% to ' + obj.target_label}</MenuItem>
        );
       }
       charities = <Select id={'charityselector-'+productId}
        value={this.state.charityId}
        fullWidth={true}
        className={this.state.charityId === '0' ? classes.placeholder : classes.select}
        onChange={(e) => this.setState({'charityId':e.target.value})}
       >
        <MenuItem value='0' >Choose a cause</MenuItem>
        {charities}
       </Select>;
      }
     } else if (document.location.pathname && document.location.pathname.indexOf('/group/') > -1) {
      var gid = document.location.pathname.split('/')[2];
      var store = this.props.me.groups[gid].field_store;
      if (store.length > 0 && store[0].entity && store[0].entity.field_my_charities) {
       var preferred = store[0].entity.field_my_charities;
       if (preferred.length > 0) {
        charities = <div className={classes.inlineFields}>
         <FormControl style={{flexGrow:1}}>
          <Select id={'charityselector-'+productId} name='charity'
           value={this.state.charityId}
           className={this.state.charityId === '0' ? classes.placeholder : classes.select}
           onChange={(e) => this.setState({'charityId':e.target.value})}
          >
           <MenuItem value='0' >Give a % of this donation to a cause</MenuItem>
           {preferred.map((o, i)=> (
            <MenuItem key={i} value={o.value}>{o.value.split('|')[1]}</MenuItem>
           ))}
          </Select>
         </FormControl>
         <FormControl style={{maxWidth:70}}>
          <Input
           defaultValue='10' name='charity_percent'
           disabled={this.state.charityId === '0'}
           type='number'
           startAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
         </FormControl>
        </div>;
       } else {
        console.log('store has no preferred charities. render full autocomplete', preferred);
       }
      } else {
       console.log(gid + ' has no store');
      }
     }

     return <div className={classes.actions} >{charities}</div>;
    }
}

export default CharitySelector;
