import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import API from "../../Util/API";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import {Clear, Edit, Publish} from "@material-ui/icons";

class EditableTitle extends Component {

    constructor(p) {
        super(p);
        this.state = {editting: false, title: this.props.title}
    }

    handleSave() {
        API.Put(`/forms${this.props.entityLink}/edit`, {label: this.state.title, field_name:this.state.title}).then((res) => {
            if (res.data.error) {
                alert('update failed');
            } else {
                this.setState({editting:false})
                this.props.enqueueSnackbar('Title updated', {variant: 'info'});
            }
        }).catch((err) => {
            alert(API.getErrorMsg(err));
        });
    }

    render() {

        if (!this.state.editting) {
            return <React.Fragment><IconButton size={'small'} onClick={() => this.setState({editting:true})}><Edit fontSize={'small'} /></IconButton>  {this.state.title} </React.Fragment>
        }

        return <TextField
            label="Edit Title"
            fullWidth={true}
            value={this.state.title}
            variant="standard"
            InputProps={{
                startAdornment: (
                    <IconButton size={'small'} style={{cursor:'pointer'}} onClick={e => this.setState({title: this.props.title, editting:false})}
                                    position="start"><Clear fontSize={'small'} /></IconButton>
                ),
                endAdornment: (
                    <IconButton size={'small'}  style={{cursor:'pointer'}} position="end" onClick={e => this.handleSave()}><Publish color={'secondary'} fontSize={'small'}  /></IconButton>
                )
            }}
            onChange={e => this.setState({title: e.target.value})}
        />

    }
}

EditableTitle.propTypes = {
    title: PropTypes.string.isRequired,
    entityLink: PropTypes.string.isRequired,
    me: PropTypes.object.isRequired
};

export default withSnackbar(EditableTitle);
