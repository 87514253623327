import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import {Link} from 'react-router-dom';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Cancel";
import PropTypes from 'prop-types';
import API from '../../Util/API';
import VoteNotice from '../VoteNotice';
import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as HeartMonitor} from '../../images/heartrate.svg';
import Tooltip from '@material-ui/core/Tooltip';
import MediaItem from "../MediaItem";
import {raterStyles, sliderStyle} from "../../Util/ThemeUtils";
import confetti from "canvas-confetti";
import {addPendingPayout} from "../../redux/walletReducer";

export const StyledSlider = withStyles(sliderStyle)(Slider);
export const HeartbeatSlider = withStyles(theme => ({
    root: {
        color: theme.palette.secondary.main,
        padding: '24px 0 13px 0',
        overflowX: 'hidden',
    },
    active: {
        backgroundColor: 'transparent'
    },
    valueLabel: {
        left: '-50%',
    },
    rail: { /* behind thumb */
        color: theme.palette.secondary.light,
        background: 'linear-gradient(90deg, ' + theme.palette.secondary.dark + ' 0%, ' + theme.palette.secondary.light + ' 100%)',
        opacity: 1,
        height: 1
    },
    thumb: {
        backgroundColor: theme.palette.background.paper,
        marginTop: -7,
        marginLeft: 0,
        '& svg': {
            width: 90, height: 35,
            stroke: theme.palette.secondary.main,
            '& polyfill ': {
                fill: theme.palette.background.paper
            }
        },
        '& .heartRateIn': {
            position: 'absolute',
            width: 2,
            height: '100%',
            backgroundColor: theme.palette.background.paper,
            top: 0,
            right: 0,
            animation: 'heartRateIn 5s linear infinite'
        },
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    track: { /* ahead of thuimb */
        height: 1,
        backgroundColor: theme.palette.secondary.dark
    },
    mark: {
        backgroundColor: theme.palette.background.paper,
        height: 5,
        width: 2,
        marginTop: -2,
        transform:'rotate(45deg)'
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
}))(Slider);
export const MyValueLabelComponent = React.forwardRef((props, ref) => {
    const {children, open, value} = props;
    return <Tooltip {...props} ref={ref} open={open} enterTouchDelay={0} placement="top" arrow={true} title={value}>{children}</Tooltip>;
})
export const MyHeartBeatThumb = React.forwardRef((props, ref) => {
    return (<span {...props} ref={ref} ><span style={{position: 'relative'}} id={props['aria-labelledby']}>
                <SvgIcon component={HeartMonitor}
                         color={'primary'}
                         viewBox='0 0 150 73'/>
                    <span className={'heartRateIn'}></span>
                    </span>
                </span>);
})
export const MyThumbComponent = React.forwardRef((props, ref) => {
    return (<span {...props} ref={ref} ></span>);
})

class RatingSliderPlaylist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {disabled: false, countType: props.countType};
        this.postRating = this.postRating.bind(this);
    }

    logRater(verb, extra) {
        const tdata = Object.assign({
            pid: this.props.pjson.get("id", "value"),
            gid: this.props.pjson.get("gid", "target_id"),
            verb: verb
        }, extra);
        if (this.props.isLoggedIn > 0) {
            tdata.uid = this.props.isLoggedIn;
        }
        window.logUse.logEvent('rate', tdata);
    }

    componentDidMount() {
        this.logRater('view');
    }

    closeRater() {
        if (this.props.ratingComplete)
            return this.props.ratingComplete(false);
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            x: (rect.left) / window.innerWidth,
            y: (rect.top) / window.innerHeight
        };
    }

    postRating = (value, voteType) => {
        if (this.props.pjson.get("gid", "target_id") === 54) {
            value = value * 20;
        }
        if (this.props.isLoggedIn === false) {
            return this.props.enqueueSnackbar('You must login or register to vote', {variant: 'error'});
        }
        if (!this.props.verified_email) {
            return this.props.enqueueSnackbar('Please verify your email to vote', {variant: 'error'});
        }

        this.setState({disabled: true});
        API.Post('/group/' + this.props.pjson.get('gid', 'target_id') + '/playlists/' + this.props.pjson.get('id', 'value') + '/rate',
            {'vote': value, 'vote_type': voteType.target_id})
            .then((res) => {
                if (typeof res.data.error === 'string') {
                    this.logRater('failed');
                    return this.props.enqueueSnackbar(res.data.error);
                } else if (!res.data.success) {
                    return this.props.enqueueSnackbar('Something went wrong');
                }

                const conOpts = {
                    particleCount: 150,
                    spread: 120,
                    zIndex: 999999,
                    colors: [this.props.theme.palette.primary.main, this.props.theme.palette.primary.light, this.props.theme.palette.primary.dark,
                        this.props.theme.palette.secondary.main, this.props.theme.palette.secondary.light, this.props.theme.palette.secondary.dark]
                }

                const keyId = ['prate', this.props.pjson.get("id", "value"), voteType.target_id].join('-')
                if (document.getElementById(keyId)) {
                    conOpts.origin = this.getOffset(document.getElementById(keyId))
                    console.warn(conOpts.origin)
                }

                confetti(conOpts);

                this.setState({defaultValue: value});

                const obj = res.data;

                if (obj.payout > 0) {
                    this.props.dispatch(addPendingPayout(obj.payout))
                    this.logRater('payout', {payout: obj.payout, vote: voteType.target_id, value: value});
                    obj.action = <Typography variant={'body1'}><em>Payout Pending Approval</em>. <Link
                        onClick={e => this.closeRater(e)}
                        to={`/group/${this.props.pjson.get('gid', 'target_id')}/wallet/activity`}>View
                        Earnings</Link></Typography>;
                } else {
                    this.logRater('voted', {vote: voteType.target_id, value: value});
                    obj.action = <Link onClick={e => this.closeRater(e)}
                                       to={`/group/${this.props.pjson.get('gid', 'target_id')}/wallet/activity`}>View
                        Earnings</Link>;
                }


                obj.message = <div>
                    <Typography variant={'body1'}>{(this.props.vote_types.length > 1) &&
                        <b>{voteType.target_label} - </b>}<em>{this.props.pjson.get('field_name')}</em></Typography>
                    <Typography variant={'h2'} align={'center'}>You rated <b>{value}%</b> {(obj.payout > 0) &&
                        <span> and earned <b><sup>₮</sup>{obj.payout.toFixed(2)}</b></span>}</Typography>
                </div>;
                this.props.enqueueSnackbar(obj.success, {
                    variant: 'success',
                    content: <VoteNotice onClose={() => this.props.closeSnackbar(keyId)} posted={obj}/>,
                    action: obj.action,
                    key: keyId,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                    autoHideDuration: 7000
                });
                if (this.props.ratingComplete && this.props.vote_types.length < 2) {
                    this.props.ratingComplete(false);
                }
                this.setState({disabled: false}); // enabled
            }).catch((err) => {
            this.setState({disabled: false});
            this.props.enqueueSnackbar('Rating failed. Are you a member of this group?', {variant: 'error'});
            console.log("VOTE FAILED", err);
            return err;
        });
    }

    renderVoteType(voteType, i) {
        const {classes} = this.props;
        let curVal = voteType[this.state.countType];
        if (!voteType[this.state.countType]) {
            curVal = 0;
        }
        let media = false;
        if (voteType.thumbnail) {
            media = <Grid item><MediaItem media={voteType}
                                          height={120}
                                          style={{height: 120, width: 213}}/></Grid>
        }
        ;

        const keyId = ['prate', this.props.pjson.get("id", "value"), voteType.target_id].join('-')

        return (<Grid item
                      xs={12} md={6} lg={4}
                      className={classes.raterRoot}
                      key={keyId}>

            <div className={this.props.classes.voteTypeBlock}>
                <Grid container style={{marginBottom: 25, flexWrap: 'nowrap'}} spacing={1}>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant='h3' className={'ctaLabel'} gutterBottom={true}>
                            {voteType.target_label}
                        </Typography>
                        {voteType.target_desc &&
                            <Typography variant='h4' className={'ctaLabel'}>
                                {voteType.target_desc}
                            </Typography>
                        }
                        {curVal > 0 &&
                            <Typography variant='h4'
                                        className={classes.valueNumber}>{voteType.count === 0 ? 'unrated' : <>{curVal}<sup>%</sup> from {voteType.count} {voteType.count === 1 ? 'vote' : 'votes'}</>}
                            </Typography>
                        }
                    </Grid>
                    {media}
                </Grid>

                {this.props.pjson.get("gid", "target_id") === 54 ?
                    <HeartbeatSlider
                        defaultValue={(this.props.pjson.get("gid", "target_id") === 54) ? Math.round(curVal / 20) : curVal}
                        aria-labelledby={keyId}
                        ThumbComponent={MyHeartBeatThumb}
                        ValueLabelComponent={MyValueLabelComponent}
                        aria-label='Playlist Rating Slider'
                        step={1} max={5} min={0}
                        marks={true}
                        disabled={this.state.disabled}
                        onChangeCommitted={(e, value) => this.postRating(value, voteType)}
                        valueLabelFormat={(val) => `${val} ${val === 1 ? 'Heartbeat' : 'Heartbeats'}`}
                        valueLabelDisplay="auto"
                    />
                    :
                    <StyledSlider
                        defaultValue={(this.props.pjson.get("gid", "target_id") === 54) ? Math.round(curVal / 20) : curVal}
                        aria-labelledby={keyId}
                        ThumbComponent={MyThumbComponent}
                        aria-label='Playlist Rating Slider'
                        step={1} max={100} min={0}
                        disabled={this.state.disabled}
                        onChangeCommitted={(e, value) => this.postRating(value, voteType)}
                        valueLabelDisplay="auto"
                    />
                }
            </div>

        </Grid>)
    }

    render() {
        let tally = 0, count = 0;
        if (this.props.vote_types.length > 0) {
            this.props.vote_types.forEach(voteType => {
                if (voteType[this.state.countType]) {
                    count += voteType.count;
                    tally += voteType[this.state.countType] * voteType.count;
                }
            });
            if (count > 0) {
                tally = Math.round(tally / count);
            }
        }

        const tooltip = tally === 0 ? 'vote' : `${tally}% ${this.props.countType} from ${count} ${count === 1 ? 'vote' : 'votes'}`

        const html = <React.Fragment>
            {tally > 0 && <Typography variant='h4' color={'primary'}>{tooltip}</Typography>}
            <Divider style={{marginBottom: 30}}/>
            <Grid container spacing={8}>
                {this.props.vote_types.map((voteType, i) => this.renderVoteType(voteType, i))}
            </Grid>
        </React.Fragment>

        if (this.props.layout === 'block') {
            return html;
        }

        return (<Dialog maxWidth='xl' fullWidth={true} open={true}>
                <DialogTitle>
                    Rate. &nbsp;
                    <IconButton onClick={() => this.closeRater()} style={{float: 'right'}}
                                color="secondary" size='small' aria-label='Close Window'><CloseIcon/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h3'>{this.props.pjson.get('field_name')}</Typography>
                    {html}
                </DialogContent>
            </Dialog>
        );
    }
}

RatingSliderPlaylist.defaultProps = {
    countType: 'avg'
}

RatingSliderPlaylist.propTypes = {
    pjson: PropTypes.object.isRequired,
    ratingComplete: PropTypes.func,
    countType: PropTypes.string.isRequired,
    vote_types: PropTypes.array.isRequired
};


export default withSnackbar(withStyles(raterStyles, {withTheme: true})(RatingSliderPlaylist));
