import React, {Component} from 'react';
import {lighten, withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Drupal2Json from '../../Util/Drupal2Json';
import UserAvatar from '../../Components/UserAvatar';
import PaginationBlock from '../../Components/PaginationBlock';
import ProgressLoading from '../../Components/ProgressLoading';

import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


import {Link} from 'react-router-dom';
import Sport12Stats from "../Sport12/Sport12Stats";

const styles = theme => ({
 highlight: theme.palette.type === 'light'
  ? {
   color: theme.palette.secondary.main,
   backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  }
  : {
   color: theme.palette.text.primary,
   backgroundColor: theme.palette.secondary.dark,
  },
 root: {
  width: '100%',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
 },
 table: {
  minWidth:'100%',
 },
 profilePic : {
  width:50,
  maxWidth:'100%',
 },
 spacer: {
  flex: '1 1 100%',
 },
 actions: {
  color: theme.palette.text.secondary,
 },
 title: {
  flex: '0 0 auto',
 },
 inlineFlex : {
  display:'inline-flex'
 }
});

class MembersTable extends Component {

 componentDidUpdate(prevProps) {
  if (this.props.location.pathname !== prevProps.location.pathname) {
   // this.refresh();
  } else if (!prevProps.apiData || !this.props.apiData) {
   // first page
  } else if (prevProps.apiData.metadata.request_time !== this.props.apiData.metadata.request_time) {
   var elIndex = Math.max(this.props.apiData.metadata.end_index - this.props.apiData.metadata.perpage, 0);
   if (elIndex === 0) {
    // do nothing
   } else if (elIndex > 0) {
    var el = document.querySelector('.memberRow:nth-child('+elIndex+')');
    if (el) {
     el.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
   } else {
    console.log("WHAT HAPPENED?", prevProps, this.props);
   }
  }
 }

 render() {
  if (this.props.apiData.metadata.url.indexOf(this.props.location.pathname) === -1) return <ProgressLoading />;

  const {classes} = this.props;

  const gid = parseInt(this.props.apiData.metadata.url.split('/')[2]);
  const gjson = new Drupal2Json(this.props.me.groups[gid]);
  const isAdmin = gjson.isGroupAdmin(this.props.me);

  return (
   <Paper className={classes.root}>
    {this.props.apiData.metadata.total_items > this.props.apiData.data.length ?
     <PaginationBlock meta={this.props.apiData.metadata} dispatch={this.props.dispatch} />
     :
     <Toolbar><Typography variant="h6" display="inline" gutterBottom color='secondary'>
      {this.props.apiData.metadata.page_title}
     </Typography></Toolbar>
    }

    <Table className={classes.table}>
     <TableHead>
      <TableRow className='memberRow'>
       <TableCell>Name</TableCell>
       <TableCell>Last Login</TableCell>
       {gid === 56 ?
           <TableCell>Stats</TableCell>
           :
           <TableCell>Credibility</TableCell>
       }
       {isAdmin === true && <TableCell>Actions</TableCell>}
      </TableRow>
     </TableHead>
     <TableBody>
      {this.props.apiData.data.map(item => {
       var ujson = new Drupal2Json(item);
       return <TableRow key={'user-'+ujson.get('id')}>
        <TableCell component="th" scope="row">
         <CardHeader
          avatar={
           <Link to={"/group/" + ujson.get('gid', 'target_id') + '/members/' + ujson.get('id')}>
            <UserAvatar data={item} />
           </Link>
          }
          title={
           <Link to={"/group/" + ujson.get('gid', 'target_id') + '/members/' + ujson.get('id')}>
            {ujson.get('field_name')}
           </Link>
          }
          subheader={'Joined '+ ujson.getTime('created', 'MMM Do \'YY')}
         />
        </TableCell>
        <TableCell>{ujson.getTime('field_last_login', 'MMM Do \'YY')}</TableCell>
        {gid === 56 ?
            <TableCell><Sport12Stats data={ujson.json.field_json[0]} showKeys={{PreferredSport:1, Won:1, Lost:1}} /></TableCell>
            :
            <TableCell>{ujson.get('field_ta_cred') || '0.00'}</TableCell>
        }
        {isAdmin === true &&
         <TableCell>
          {this.props.me.profile.uid[0].value !== gjson.get('uid', 'target_id') &&
          <IconButton data-aria={'remove membership'}><DeleteIcon /></IconButton>
          }
         </TableCell>
        }
       </TableRow>;
      })}
     </TableBody>
    </Table>
   </Paper>
  );
 }

}

export default withRouter(withStyles(styles)(MembersTable));
