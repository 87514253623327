import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import {getIdbySegment, initApp, otpLogin} from "./redux/authActions";
import {loadFaq} from "./redux/helpReducer";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import {createTheme, MuiThemeProvider, withStyles,} from "@material-ui/core/styles";
import {lightOrDark} from "./Util/ThemeUtils";
import {getParam, getStartupParam} from "./Util/WindowUtils";
import CssBaseline from "@material-ui/core/CssBaseline";
import ResponsiveDrawer from "./Components/ResponsiveDrawer";
import Header from "./Components/Header";
import Main from "./Views/Main";
import Footer from "./Components/Footer";
import UnbrandedFooter from "./Components/Footer/UnbrandedFooter";
import Drupal2Json from "./Util/Drupal2Json";
import {SnackbarProvider} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Config from "./Config";
import "./scss/main.scss";
import EnabledFeature from "./Util/EnabledFeature";
import GroupEditDrawer from "./Views/NewGroupPlans/GroupEditDrawer";
import DrawerForm from "./Views/DialogForm/DrawerForm";
import {changeCurrency} from "./redux/walletReducer";
import SanitizedHTML from "react-sanitized-html";
// import "./scss/brackets.scss";

const initialPalette = {
  primary: { main: Config.theme_color },
  secondary: { main: Config.secondary_color },
  background: {
    paper: Config.paper_color,
    default: Config.background_color,
  },
};

const initialFonts = {
  fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
  button: { textTransform: "none" },
  h1: { fontFamily: "Roboto", fontSize: "1.75rem" },
  h2: { fontFamily: "Roboto", fontSize: "1.5rem" },
  h3: { fontFamily: "Roboto", fontSize: "1.25rem" },
  h4: { fontFamily: "Roboto", fontSize: "1rem" },
  h5: { fontFamily: "Roboto", fontSize: ".9rem" },
  h6: { fontFamily: "Roboto", fontSize: ".8rem" },
};

const setGroupColors = (group) => {
  const palette = JSON.parse(JSON.stringify(initialPalette));
  // hex such as 003893 are return as 3893 by the API's json parser
  let color = group.get("field_color_background", "color");
  if (color && color.toString().length > 1) {
    while (color.toString().length < 6) color = "0" + color.toString();
    palette.background.default = "#" + color;
  }

  color = group.get("field_color_paper", "color");
  if (color && color.toString().length > 1) {
    while (color.toString().length < 6) color = "0" + color.toString();
    palette.background.paper = "#" + color;
  } else {
    palette.background.paper = alpha(palette.background.default, 0.8);
  }

  color = group.get("field_color_primary", "color");
  if (color && color.toString().length > 1) {
    while (color.toString().length < 6) color = "0" + color.toString();
    palette.primary.main = "#" + color;
  }

  color = group.get("field_color_secondary", "color");
  if (color && color.toString().length > 1) {
    while (color.toString().length < 6) color = "0" + color.toString();
    palette.secondary.main = "#" + color;
  }

  return palette;
};

class App extends Component {
  constructor(props) {
    super(props);
    if (document.location.pathname.indexOf("/otp/") === 0) {
      props.otpLogin();
    } else {
      props.initApp(document.location.pathname, "onload");
    }

    // faster checks on non-form colors on non form pages ex. /group/9/playlists?primary=ffffff&secondary=cccccc&background=000000
    if (document.location.search.length > 3) {
      let obj = {
        primary: "main",
        secondary: "main",
        background: "default",
        paper: "paper",
      };
      for (let p in obj) {
        let color = getParam(p);
        if (color.length === 6) {
          if (p === "paper") initialPalette.background.paper = "#" + color;
          else initialPalette[p][obj[p]] = "#" + color;
        }
      }
    }

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      theme: this.buildPalette(initialPalette),
      themeId:
        initialPalette.primary.main +
        initialPalette.secondary.main +
        initialPalette.background.default +
        initialPalette.background.paper,
    };

    this.reportWindowSize = this.reportWindowSize.bind(this);
  }

  buildPalette(palette) {
    const themeObj = {};

    /* themeObj.overrides = {
            MuiIconButton: {
                root: {
                    background: `radial-gradient(circle, ${palette.background.default} 0%, ${alpha(palette.background.default, 0)} 50%)`,
                },
            }
        } */

    if (palette) {
      palette.type = lightOrDark(palette.background.default);
      palette.primary.type = lightOrDark(palette.primary.main);
      palette.secondary.type = lightOrDark(palette.secondary.main);
      if (palette.background.default === palette.background.paper) {
        palette.background.paper = alpha(palette.background.paper, 0.9); // TODO: make only popovers faded
      }
      palette.background.type = lightOrDark(palette.background.paper);
      /* this doesn't seem necessary, but documents our intended layers
             palette.zIndex = {CircularProgress:900000,  DrawerMenu:890000, Header:870000, ConfirmWager:860000, AddFunds:860000, Share:850000, RatingSlider:840000, SnackBar:830000, TrackContextMenu:820000, Player:810000}
            * */
      themeObj.palette = palette;
    }

    themeObj.typography = { ...initialFonts };

    /* {"default":{"name":"'Big Shoulders Stencil Display', Serif","ref":"Big+Shoulders+Stencil+Display:ital,wght@0,400;0,700;1,400"}}
     */
    if (this.props.fonts) {
      const refNames = [];
      for (let f in this.props.fonts) {
        let font = this.props.fonts[f];
        if (font.ref.indexOf("/styles.css") > 0) {
          /* const link = document.createElement("LINK"); // not worth securing
          link.setAttribute("href", Config.api.base + font.ref);
          document.getElementsByTagName("HEAD")[0].appendChild(link);
           */
        } else {
          refNames.push(font.ref);
        }
        if (f === "default") {
          themeObj.typography.fontFamily = font.name;
          if (font.weight) themeObj.typography.fontWeight = font.weight;
        } else if (f === "h") {
          for (let h = 1; h <= 6; h++) {
            themeObj.typography[`h${h}`].fontFamily = font.name;
            if (font.weight)
              themeObj.typography[`h${h}`].fontWeight = font.weight;
          }
        } else {
          if (typeof themeObj.typography[f] === "undefined") {
            console.log("INVALID FONT KEY" + f);
          } else {
            themeObj.typography[f].fontFamily = font.name;
            if (font.weight) themeObj.typography[f].fontWeight = font.weight;
          }
        }
      }
      if (refNames.length > 0) {
        document
          .getElementById("googleFontPath")
          .setAttribute(
            "href",
            "https://fonts.googleapis.com/css2?display=swap&family=" +
              refNames.join("&family=")
          );
      }
    }

    const theme = createTheme(themeObj);
    if (process.env.NODE_ENV !== "production") console.log(theme);
    return theme;
  }

  componentDidUpdate() {
    if (this.props.palette) {
      let newTheme =
        this.props.palette.primary.main +
        this.props.palette.secondary.main +
        this.props.palette.background.default +
        this.props.palette.background.paper;
      if (this.state.themeId !== newTheme) {
        this.setState({
          theme: this.buildPalette(this.props.palette),
          themeId: newTheme,
        });
      }
    }
  }

  reportWindowSize(e) {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    if (this.props.fonts && this.props.fonts.length > 0) {
      this.buildPalette(false);
    }
    window.addEventListener("resize", this.reportWindowSize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
    console.log("APP UNMOUNTING!!!!!?");
  }

  render() {
    const appStyle = {};
    let topPad = 0;
    getStartupParam('appOS'); // just save it for later
    if (getStartupParam('paddingTop').length > 0) {
      topPad += parseInt(getStartupParam('paddingTop'));
    }
    if (topPad > 0) {
      appStyle.paddingTop = topPad;
    }

    return (
      <MuiThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <SnackbarProvider>
          {this.props.auth.me === false ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection:'column',
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {(this.props.auth.logInError) ? <SanitizedHTML style={{color:'red', marginBottom:20}} html={this.props.auth.logInError} /> : ''}
              <CircularProgress />
            </div>
          ) : (
            <div
              id="App"
              className={this.props.classes.container}
              style={appStyle}
            >
              <ResponsiveDrawer
                windowWidth={this.state.windowWidth}
                auth={this.props.auth}
                dispatch={this.props.dispatch}
                location={this.props.location}
              />
              {this.props.auth.formDrawerOpen > 0 &&
              <GroupEditDrawer
                  windowWidth={this.state.windowWidth}
                  auth={this.props.auth}
                  dispatch={this.props.dispatch}
                  location={this.props.location}
              />
              }

              {this.props.forms && <DrawerForm forms={this.props.forms} dispatch={this.props.dispatch} />}

              <Header dispatch={this.props.dispatch} me={this.props.auth.me} windowWidth={this.state.windowWidth}
                      formDrawerOpen={this.props.auth.formDrawerOpen}
                      isGroupAdmin={this.props.isGroupAdmin}
                      curGroup={this.props.auth.curGroup}
                      cartTotal={this.props.cartTotal}
              />
              <Main
                windowWidth={this.state.windowWidth}
                windowHeight={this.state.windowHeight}
                history={this.props.history}
              />
              <EnabledFeature
                feature={"groups:field_remove_tam"}
                value={true}
                bypass={false}
                placeholder={
                  <Footer
                    me={this.props.auth.me}
                    loadFaq={this.props.loadFaq}
                  />
                }
              >
                <UnbrandedFooter
                  me={this.props.auth.me}
                  loadFaq={this.props.loadFaq}
                />
              </EnabledFeature>
            </div>
          )}
          {this.props.background && <div className={this.props.classes.appBg} style={{backgroundImage:`url(${this.props.background})`}}></div>}
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {};
  props.auth = state.auth;
  props.location = state.router.location;
  props.cartTotal = -1;
  if (state.wallet.cartItems && state.wallet.cartItems.length > 0) {
    props.cartTotal = 0; // could be free items
    state.wallet.cartItems.forEach(c => {
      props.cartTotal += changeCurrency(c.variation.price[0].number * c.count, c.variation.price[0].currency_code);
    })
  }
  props.cartTotal = props.cartTotal.toFixed(2)
  if (state.forms.ctx === 'drawer') {
    if (state.forms.api && state.forms.api.bundle !== 'groups') {
      props.forms = state.forms;
    }
  }
  props.isGroupAdmin = false;

  const gid = getIdbySegment(document.location.pathname).gid;
  if (
    props.auth.me &&
    props.auth.me.groups &&
    props.auth.me.groups[gid]
  ) {
    const gjson = new Drupal2Json(props.auth.me.groups[gid]);
    props.isGroupAdmin = gjson.isGroupAdmin(props.auth.me);
    const group = new Drupal2Json(props.auth.me.groups[gid]);
    props.palette = setGroupColors(group);
    if (group.get("field_background", 'url')) {
      props.background = group.get("field_background", 'url');
      if (props.background.indexOf('/') === 0) {
        props.background = Config.api.base + props.background
      }
    }
    if (group.json.field_fonts && group.json.field_fonts.length > 0) {
      props.fonts = JSON.parse(group.get("field_fonts"));
    }
  }
  return props;
};

const mapDispatchToProps = (dispatch) => {
  return {
    initApp: (apiurl, verb) => dispatch(initApp(apiurl, verb)),
    otpLogin: () => dispatch(otpLogin()),
    loadFaq: (nid, ctx) => dispatch(loadFaq(nid, ctx)),
  };
};

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    alignContent: "stretch",
    height: "100%",
    width: "100%",
  },
  appBg : {
    backgroundSize: "cover",
    backgroundColor:'transparent',
    backgroundPosition:'center center',
    backgroundRepeat:'norepeat',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: -1,
  }
};

// withRouter (https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md#blocked-updates)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App))
);
