import FaqItem from './FaqItem';
import {connect} from 'react-redux';

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(null, mapDispatchToProps)(FaqItem);
