import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import FieldList from '../FieldList';
import Drupal2Json from '../../Util/Drupal2Json';
import TrackActions from '../TrackActions';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from '@material-ui/core/Avatar';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {isEnabled} from "../../Util/EnabledFeature";
import API from "../../Util/API";
import RatingSliderPlaylist from "../RatingSliderPlaylist";
import RatingSlider from "../RatingSlider";
import Grammer from "../../Util/Grammer";
import {withStyles} from '@material-ui/core/styles';
import {pStyles} from "../../Util/ThemeUtils";

class TrackDetails extends Component {
    constructor(props) {
        super(props);

        var actions = {
            'rate': true,
            'comments': true,
            'youtube': true,
            'mp3': false,
            'info': false,
            'edit': false,
            'delete': false,
            'bet': true,
            'share': true
        };

        if (this.props.me.profile === false) {
            actions = {
                'rate': false,
                'youtube': true,
                'mp3': false,
                'info': true,
                'edit': false,
                'delete': false,
                'bet': false,
                'share': true
            };
        } else {
            if (this.props.me.profile && typeof this.props.me.profile.roles['access_mp3'] === 'number') {
                actions.mp3 = true;
            }
        }

        if (isEnabled('playlists:cta', 'bet') || isEnabled('playlists:field_judge', 'streamgains-yt')) {
            actions.bet = true; // WARN: essential since i don't check 83 this further down
        }

        this.state = {'actions': actions, showPlaylist:false};
    }

    componentDidMount() {
        if (isEnabled('playlists:rating', true)) {
            const json = new Drupal2Json(this.props.item);
            API.Get("/group/" + json.get('gid', 'target_id') + '/playlists/' + json.get('field_playlist_gc', 'target_id')).then((res) => {
                this.setState({showPlaylist: new Drupal2Json(res.data)});
            });
        }
    }

    render() {
        const json = new Drupal2Json(this.props.item);
        const node = new Drupal2Json(this.props.item.node);

        return (
            <div style={{padding:"20px 1%", width:'100%'}} className={this.props.classes.paperBg}>

                <Grid container style={{marginBottom: 20, justifyItems:'center'}} alignItems={'center'} justifyContent={'space-between'} >
                    <Grid item xs={12} sm={6}>
                        {(node.get('field_cover', 'url')) ?
                            <img src={node.get('field_cover', 'url')} alt="Track Cover" style={{maxWidth:'100%'}}/>
                            :
                            (node.get('field_yt_id', 'value')) ?
                                <img src={`https://img.youtube.com/vi/${node.get('field_yt_id', 'value')}/default.jpg`}
                                     alt="Track Cover" style={{maxWidth: 300}}/>
                                : ''
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TrackActions key={'tactions-' + json.get('id')} ctas={this.state.actions}
                                      profile={this.props.me.profile} track={this.props.item}
                                      dispatch={this.props.dispatch}/>
                    </Grid>
                </Grid>

                <Typography variant={'h1'}>{node.get('title')}</Typography>
                {node.get('field_artists') ?
                    <Typography variant={'h2'}>{node.get('field_artists')}</Typography> : ''}
                <Typography
                    variant={'h4'}>on <Link
                    to={"/group/" + json.get('gid', 'target_id') + '/playlists/' + json.get('field_playlist_gc', 'target_id')}>
                    {json.get('field_playlist_gc', 'target_label')}
                </Link></Typography>

                {isEnabled('tracks:rating', true) &&
                <div style={{marginTop: 30}}>
                    <Typography variant='overline'>Rate this {Grammer.g('tracks', 's')}.</Typography>
                    <RatingSlider tjson={json} layout={'block'} dispatch={this.props.dispatch} />
                </div>
                }

                {this.state.showPlaylist &&
                <div style={{marginTop: 30}}>
                    <Typography variant='overline'>Rate the {Grammer.g('playlists', 's')}.</Typography>
                    <RatingSliderPlaylist
                        layout={'block'}
                        dispatch={this.props.dispatch}
                        pjson={this.state.showPlaylist} />
                </div>
                }

                <div style={{marginTop: 20}}>
                    <Typography variant='overline'>Genre Tags</Typography>
                    {this.props.item.node.field_genres.map(o => (
                        <ListItem key={o.target_id}>
                            <ListItemAvatar>
                                <Avatar color={'primary'}><img src={o.url} alt={o.target_label}
                                                               style={{width: '100%'}}/></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={o.target_label}/>
                        </ListItem>
                    ))}
                </div>

                <div style={{marginTop: 20}}>
                    <Typography variant='overline'>Meta Tags</Typography>
                    <FieldList entity={this.props.item.node}
                               map={{
                                   'field_album': 'Album',
                                   'field_year': 'Released',
                                   'field_itunes_url': 'iTunes',
                                   'field_amazon_url': 'Amazon',
                                   'body': 'Editorial',
                                   'field_lyrics': 'Lyrics'
                               }}/>
                </div>

            </div>

        );
    }
}

TrackDetails.propTypes = {
    item: PropTypes.object,
    dispatch: PropTypes.func,
};

export default withStyles(pStyles, {withTheme:true})(TrackDetails)
