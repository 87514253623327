import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import {findAndPlay} from '../../redux/playerReducer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ProgressLoading from '../../Components/ProgressLoading';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";

class TransactionGrid extends Component {

 render() {
  if (this.props.apiData.metadata.url.indexOf(this.props.location.pathname) === -1) return <ProgressLoading />;
  if (!this.props.apiData.data || this.props.apiData.data.length === 0) return <h2>You have no matching transactions</h2>;

  const {classes} = this.props;
  const body = [];

  for(var f in this.props.apiData.data) {
   const item = this.props.apiData.data[f];
   body.push(<Grid item xs={12} md={6} className={classes.row} key={'f-'+body.length} >
    <Grid container justifyContent='space-between' >
     <Grid item><small>{moment(item.created * 1000).format('MMM Do \'YY, h:mma')}</small></Grid>
     <Grid item><small>{ item.issuer['target_id'] === 1 ? item.method + ' payout' : item.type }</small></Grid>
    </Grid>
    <Grid container justifyContent='space-between' wrap='nowrap' >
     <Grid item>
      <Typography variant='body1' className={classes.line}>
       {item.track_gc > 0 &&
       <IconButton aria-label="MP3" onClick={(e) => this.props.dispatch(findAndPlay(item.track_gc, item.playlist_gc, item.gid))} size="small"><PlayIcon color="primary" /></IconButton>
       }
       {item.notes__value}
      </Typography>
     </Grid>
     <Grid item>
      <Typography variant='body1' className={classes.line}><sup>{(item.currency === 'USD') ? '$' : Grammer.tac('₮')}</sup>{item.net_amount.toFixed(2) + ' ' +item.status}</Typography>
     </Grid>
    </Grid>
   </Grid>);
  }
  return (
   <Paper elevation={2} style={{paddingBottom:10}}>
    <div style={{padding:'2%'}}  >
     <Typography variant='h3' className={classes.headline}>
      {this.props.apiData.metadata.page_title}
     </Typography>
     <Typography variant='h5' className={classes.headline}>
      {Grammer.tac('TAC')} Payouts may take up to 24 hours to be approved
     </Typography>
    </div>

    <Grid container justifyContent={'space-around'}>
     {body}
    </Grid>
   </Paper>
  );
 }

}

const styles = theme => ({
 headline : {
  color:theme.palette.secondary.main,
  margin:0
 },
 row : {
  padding:theme.spacing(1),
  marginBottom:theme.spacing(1),
  borderTop:'1px solid ' + theme.palette.primary.main
 },
 line : {
  display:'flex',
  alignItems:'center',
  margin:'0 0 2px 0'
 }
});

export default withRouter(withStyles(styles)(TransactionGrid));
