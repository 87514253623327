import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import TamTrackSearch from '../TamTrackSearch';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import {testPlay} from '../../redux/playerReducer';
import {pStyles} from "../../Util/ThemeUtils";
import {addAndCheckout} from "../../redux/walletReducer";
import FormattedText from "../FormattedText/FormattedText";
import MediaSlider from "../MediaSlider/MediaSlider";
import {Link} from "react-router-dom";
import UserAvatar from "../UserAvatar";
import VariationSelector from "./VariationSelector";

class ProductBase extends React.Component {

    constructor(p) {
        super(p);
        this.state = {cartItem: new Commerce2Json(this.props.product)}
    }

    addAndCheckout() {
        this.props.dispatch(addAndCheckout(this.state.cartItem.toJson()));
    }

    onVariationChange(variant_id) {
        const json = new Commerce2Json(this.props.product, variant_id);
        this.setState({cartItem:json}); // getAvatarImage
    }

    render() {
        const {classes} = this.props;
        const bundle = this.state.cartItem.get('type', 'target_bundle');

        let timestamp = this.state.cartItem.getTimestamp('field_date_on_sale', 'value');
        if (bundle !== 'default') {
            timestamp = new Date().getTime() - 100; // fake it until we add field_date_on_sale to every type / bundle
        }

        const headerAttrs = {
            title : this.state.cartItem.get('title', 'value')
        }
        if (this.state.cartItem.get('uid', 'target_id') > 1) {
            let authorLink = "/users/" + this.state.cartItem.get('uid', 'target_id');
            if (this.props.curGroup > 0) {
                authorLink += '/group/' + this.props.curGroup;
            }
            headerAttrs.avatar = <Link to={authorLink}><UserAvatar data={this.state.cartItem.getField('uid')} /></Link>
            headerAttrs.subheader = <Link to={authorLink}>{this.state.cartItem.get('uid', 'target_label')}</Link>
        }
        if (this.props.product.variations.length > 1) {
            headerAttrs.title = this.state.cartItem.product.get('title', 'value')
            headerAttrs.subheader = this.state.cartItem.variation.get('title', 'value')
        }

        const toLink = this.props.curGroup > 0 ? `/group/${this.props.curGroup}/rewards` : '/marketplace';
        headerAttrs.title = <Link to={`${toLink}/${this.state.cartItem.get('product_id')}`}>{headerAttrs.title}</Link>;

        const allMedia = this.state.cartItem.getAllMedia();

        return (
            <Card className={['pBlock', classes.card].join(' ')}>

                <CardHeader {...headerAttrs} />

                {allMedia.length > 0 && <MediaSlider images={allMedia} />}

                <CardContent className={classes.content}>
                    {this.state.cartItem.getField('body') && <FormattedText {...this.state.cartItem.getField('body')} displayField={'auto'} />}

                    {this.state.cartItem.get('type', 'target_bundle') === 'download' && this.state.cartItem.findPropByVal('field_partner', '4956', 'target_id') ?
                        <TamTrackSearch field={{'#id': 'download-' + this.state.cartItem.get('product_id')}}/> :
                        ''
                    }
                </CardContent>

                <CardActions className={classes.actions}>
                    <Badge
                        badgeContent={this.state.cartItem.getSymbol() + this.state.cartItem.getPrice()} color="primary"
                        classes={{badge: classes.badge}}>
                        <VariationSelector variations={this.props.product.variations} cta={timestamp === 0 ? 'Pre-Order' : 'Buy'}
                                           onOrder={variant_id => this.addAndCheckout(variant_id)}
                                           onVariationChange={variant_id => this.onVariationChange(variant_id)}
                        />
                    </Badge>
                    {this.state.cartItem.getField('field_media') &&
                    <Button onClick={() => this.props.dispatch(testPlay(this.state.cartItem.getField('field_media')))}>Play Sample</Button>
                    }
                    {timestamp > new Date().getTime() &&
                        <p><strong>On Sale</strong> <u>{this.state.cartItem.getTime('field_date_on_sale')}</u></p>
                    }
                </CardActions>

            </Card>
        );
    }
}

ProductBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles)(ProductBase);
