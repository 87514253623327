import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SanitizedHTML from 'react-sanitized-html';
import Config from '../../Config';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FAQplaylists from "../../Components/FAQplaylists/FAQplaylists";
import Typography from "@material-ui/core/Typography";
import DemoVideos from "../../Components/DemoVideos";

class FaqView extends Component {
    state = { topicId: this.props.topicId };
    ctas = {};

    componentDidMount() {
     this.props.helpList('faqs/topics/all');
    }

    showRelatedFaqs(cta) {
     var msgs = '<h4><u>'+cta.meta.target_label+'</u></h4>';
     for(var f in cta.faqs) {
      var item = cta.faqs[f];
      msgs += '<div><b>'+item.topic+': <u>'+item.title+'</u></b><br />' + (item.permissions ? item.permissions : '') + ' ' + item.definition + '</div>';
     }
     this.props.showNotice(msgs, 'warning');
    }

    render() {
     const { classes } = this.props;
     if (!this.props.help.faqs || !this.props.help.faqs.topics) return <Typography variant='h3'>loading...</Typography>;
     var helpers = [], item = null, f = null;
     for(f in this.props.help.faqs.data) {
      item = this.props.help.faqs.data[f];
      if (this.state.topicId === 0) {
       for(var c in item.ctas) {
        if (!item.ctas[c].target_id) continue;
        if (typeof this.ctas[item.ctas[c].target_id] === 'undefined') {
         this.ctas[item.ctas[c].target_id] = {
          'meta' : item.ctas[c], // target_id + target_label + icon eventually
          'faqs' : []
         };
        }
        this.ctas[item.ctas[c].target_id].faqs.push(item);
       }
      } else if (item.topicId !== this.state.topicId) {
       continue;
      } else {
       helpers.push(<Accordion key={'faqpanel-' + f}  >
        <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />} >
         <Typography variant='h4'>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root:classes.detailsRoot}}>
         <div>
          {item.permissions ? <SanitizedHTML allowedTags={Config.allowedTags} html={item.permissions} /> : '' }
          <Typography variant='h5' style={{marginBottom:0, fontStyle:'italic', textDecoration:'underline'}}>LEGAL DEFINTION</Typography>
          <SanitizedHTML allowedTags={Config.allowedTags.concat('a')} allowedAttributes={Config.allowedAttributes} html={item.definition} />
          {item.ctas ?
           <div>
            <Typography variant='h5' style={{marginBottom:2, fontStyle:'italic', textDecoration:'underline'}}>UI ELEMENTS</Typography>
            <div>
             {item.ctas.map( o => {
              return o.target_id ? <Button key={o.target_id} style={{margin:'0 5px 5px 0'}} disabled variant='outlined'>{o.target_label}</Button> : '';
             })}
            </div>
           </div>
           : ''
          }
         </div>
        </AccordionDetails>
       </Accordion>);
      }
     }

     if (this.state.topicId === 0) {
      Object.entries(this.ctas).map( (a, tid) => (
       helpers.push(<Button key={a[1].meta.target_id}
        onClick={e => this.showRelatedFaqs(a[1])}
        style={{margin:'0 15px 15px 0'}} variant='outlined' >
        {a[1].meta.target_label}</Button>)
      )
      );
     }

     if (this.props.help.faqs.topics.length === 0) return helpers;
     const curTopic = this.props.help.faqs.topics.find(e => e.tid === this.state.topicId);

     return (
      <div style={{padding:"0 5px", maxWidth:1000, margin:'0 auto'}} className={this.props.classes.paperBg} >
       <FAQplaylists expanded='howtoplay' gid={parseInt(Config.api.gid)} />
       <Tabs
        className={classes.tabPanel}
        value={this.state.topicId}
        variant="fullWidth"
        indicatorColor="primary" >
        { this.props.help.faqs.topics.map( a => (
         <Tab key={a.tid} label={<Typography variant='h5'>{a.name}</Typography>} value={a.tid} onClick={(e) => this.setState({topicId:a.tid})} />
        )
        )}
       </Tabs>
       <SanitizedHTML allowedTags={Config.allowedTags} html={curTopic.body} />
       {helpers}

       <DemoVideos {...this.props}  />
      </div>
     );
    }
}

const styles = theme => ({
 tabPanel: {
  color:theme.palette.secondary.main,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width:'100%',
 },
 detailsRoot : {
  paddingTop:0
 },
 paperBg : {
  backgroundColor:theme.palette.background.paper,
 },
});


export default withStyles(styles)(FaqView);
