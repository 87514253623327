import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import RateIcon from '@material-ui/icons/Stars';
import CardHeader from "@material-ui/core/CardHeader";

const styles = theme => ({
    chipWide: {
        width: '100%',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        fontSize: 14,
        '& .MuiChip-label': {
            width: '100%'
        }
    },
    ratingBadge: {
        display: 'flex',
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        position: 'relative',
        '& svg' : {
            opacity:0.3,
            height:36,
            width:36,
        }
    },
    badgeLabel: {
      gap: 8,
      display:'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    ratingLabel: {
        fontSize: 13,
    },
    ratingType : {
        fontWeight: 900,
        fontSize: 11
    },
    ratingValue: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 900,
        '& small': {
            fontWeight: 400
        }
    }
});

class RatingItem extends Component {

    onClick() {
        this.props.history.push(this.props.item.link_url);
    }

    render() {
        const {item, classes} = this.props;
        const title = item.title[0].value;

        const badge = <span className={classes.ratingBadge}>
            <RateIcon color={'primary'} />
            <span className={classes.ratingValue}>{this.props.item.rating}<small>%</small></span>
        </span>

        const toPass = {};
        if (item.field_cover) {
            toPass.avatar = <img src={item.field_cover[0].url} alt={item.field_vote_type[0].target_label}/>;
        }

        return <CardHeader {...toPass}
                           title={<span className={classes.ratingType}>{item.field_vote_type[0].target_label}</span>}
                           subheader={<span className={classes.ratingLabel}>{title}</span>}
                           action={badge} />

    }
}

export default withStyles(styles)(withRouter(RatingItem));
