import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import {pStyles} from "../../Util/ThemeUtils";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {withSnackbar} from "notistack";
import ProductActions from "../Products/ProductActions";
import Grid from "@material-ui/core/Grid";

class PickupMatchHeader extends React.Component {

    render() {
        const cartItem = new Commerce2Json(this.props.product);
        let timezone = cartItem.get('field_json', 'timezone');
        const address = cartItem.getAddress('short');
        const linkTo = `/group/${cartItem.get('stores', 'gid')}/rewards/${cartItem.get('product_id')}`;
        const vote_types = cartItem.product.get('field_json', 'vote_types');
        const dateRange = cartItem.product.getTimeRange('field_date_range', 'ddd MMM Do \'YY hh:mm a', timezone);

        const headerAttrs = {
            title: <Link to={linkTo}>{cartItem.product.get('title')}</Link>,
            subheader: <Grid container flexDirection={'column'}>
                {dateRange ?
                    <Grid item xs={12} component={Typography}
                          variant={'subtitle1'}>{dateRange}
                    </Grid>
                    :
                    <Grid item xs={12} component={Typography}
                          variant={'h6'}>Date & Time TBA
                    </Grid>
                }
                <Grid item xs={12} component={Typography} variant={'subtitle2'}>
                    {cartItem.getPriceRange()} &nbsp;
                    {address &&
                        <a target={"_blank"} className={'address'}
                           href={`https://maps.google.com?q=${encodeURI(cartItem.getAddress('long'))}`}>@ {cartItem.get("field_address", "given_name") || address}</a>}
                </Grid>
            </Grid>
        }

        if (cartItem.get('field_sport', 'url')) {
            headerAttrs.avatar = <img src={cartItem.get('field_sport', 'url')}/>
        }
        headerAttrs.action =
            <ProductActions me={this.props.me} gid={cartItem.get('stores', 'gid')} cartItem={cartItem}
                            vote_types={vote_types}
                            dispatch={this.props.dispatch}/>

        return <CardHeader {...headerAttrs} />
    }
}

PickupMatchHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
};

export default withStyles(pStyles, {withTheme: true})(withSnackbar(PickupMatchHeader));
