import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import CommentButton from '../../Components/CommentButton';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import MoreVert from '@material-ui/icons/MoreVert';
import {shareUrl} from '../../redux/helpReducer';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Grammer from '../../Util/Grammer';
import {loadForm} from "../../redux/formsReducer";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {isEnabled} from "../../Util/EnabledFeature";
import Button from "@material-ui/core/Button";
import Videocam from "@material-ui/icons/Videocam";
import BetButton from "../../Components/BetButton";
import DeleteIcon from "@material-ui/icons/Delete";
import API from "../../Util/API";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";

class ReplayActions extends Component {
    constructor(props) {
        super(props);
        this.state = {expanded: false};
    }

    openMenu(e) {
        this.setState({expanded: true, anchorEl: e.currentTarget});
    }

    closeMenu(e) {
        this.setState({expanded: false});
    }


    promptDelete(apiurl) {
        if (window.confirm('Are you sure you want remove this Replay Reel. The containing clips will not be deleted but public access will be')) {
            API.Delete(apiurl)
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        this.props.enqueueSnackbar(msg, {variant: 'error'});
                    } else {
                        this.props.enqueueSnackbar(res.data.success, {variant: 'info'});
                        this.props.history.go(0)
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.enqueueSnackbar(msg, {variant: 'error'});
                    return err;
                });
        }
    }


    render() {
        const {classes, jreplay} = this.props;
        const pUrl = `/group/${jreplay.get('gid', 'target_id')}/replays/${jreplay.get('id', 'value')}`;

        const inContext = [];
        const inPage = [];

        const canEdit = jreplay.can_edit(this.props.me) === true;
        // Tag users

        /*
        if (jreplay.get('field_listening_starts') !== null && new Date(jreplay.get('field_listening_starts')).getTime() < new Date().getTime()) {
            inContext.push(<ListItem alignItems="center" key={`playbtn-ctx-${pUrl}`} className={classes.actionLine}
                                     style={{cursor: 'pointer'}}
                                     onClick={(e) => this.props.dispatch(findAndPlay(false, jreplay.get('id'), jreplay.get('gid', 'target_id')))} >
                <IconButton>
                    <Videocam color={this.props.color} />
                </IconButton>
                <ListItemText primary={'Watch Reel'}/>
            </ListItem>);
            if (this.props.ctas['play'] === true) {
                inPage.push(<Button
                    key={`playbtn-${pUrl}`}
                    onClick={(e) => this.props.dispatch(findAndPlay(false, jreplay.get('id'), jreplay.get('gid', 'target_id')))}
                    aria-label="Listen"
                    variant="outlined"
                    startIcon={<Videocam />}
                    color={this.props.color} >
                    {this.props.location.pathname === pUrl ? 'Watch Replays' : 'Reel'}
                </Button>)
            }
        }
         */


        if (isEnabled('playlists:cta', 'upgrade')) {
            inContext.push(<BetButton showlabel={true} color={this.props.color} json={jreplay} profile={this.props.me.profile}
                                      dispatch={this.props.dispatch} key={`betbutton-ctx-${pUrl}`} className={classes.actionLine}/>);

            // if (this.props.ctas['upgrade'] === true) {
            inPage.push(<BetButton color={this.props.color} json={jreplay} profile={this.props.me.profile}
                                   dispatch={this.props.dispatch} key={`betbutton-ctx-${pUrl}`} />);
            // }
        }

        inContext.push(<ListItem alignItems="center" className={classes.actionLine} key={`sharebtn-ctx-${pUrl}`}
                                 aria-label={`Share Playlist ${jreplay.get('id', 'value')}`}
                                 onClick={e => this.props.dispatch(shareUrl(pUrl))}>
            <IconButton>
                <ShareIcon color={this.props.color}/>
            </IconButton>
            <ListItemText primary='Share'/>
        </ListItem>);
        if (this.props.ctas['share'] === true) {
            inPage.push(<IconButton key={`sharebtn-${pUrl}`}   aria-label={`Share Playlist ${jreplay.get('id', 'value')}`}
                                    onClick={e => this.props.dispatch(shareUrl(pUrl))}>
                <ShareIcon color={this.props.color}/>
            </IconButton>);
        }

        if (isEnabled('playlists:field_comments', true)) {
            inContext.push(<ListItem alignItems="center" key={`commentbtn-ctx-${pUrl}`} className={classes.actionLine}>
                <CommentButton profile={this.props.profile} showComments={this.state.showComments}
                               color={this.props.color} json={jreplay} dispatch={this.props.dispatch}/>
                <ListItemText onClick={e => this.setState({showComments: !this.state.showComments})}
                              primary={'Comments'}/></ListItem>);

            if (this.props.ctas['comments'] === true) {
                inPage.push(<CommentButton profile={this.props.profile} color={this.props.color} json={jreplay}
                                           key={`commentbtn-${pUrl}`} dispatch={this.props.dispatch}/>);
            }
        }

        if (this.props.location.pathname !== pUrl) {
            inContext.push(<ListItem alignItems="center" key={`metabtn-ctx-${pUrl}`} className={classes.actionLine}>
                <IconButton>
                    <Link to={pUrl}>
                        <InfoIcon color={this.props.color}/>
                    </Link>
                </IconButton>
                <ListItemText primary={<Link style={{textDecoration: 'none'}} to={pUrl}>{Grammer.g('Playlists', 's')} Homepage</Link>}/></ListItem>);

            if (this.props.ctas['info'] === true) {
                inPage.push(<Button
                        key={`metabtn-${pUrl}`}
                        to={pUrl}
                        component={Link}
                        aria-label="Listen"
                        variant="outlined"
                        startIcon={<Videocam />}
                        color={this.props.color} >
                        {this.props.location.pathname === pUrl ? 'Watch Replays' : 'Reel'}
                    </Button>);
            }
        }

        if (canEdit) {
            inContext.push(<ListItem alignItems="center" key={`editbtn-ctx-${pUrl}`} className={classes.actionLine}
                                     href={"/forms" + pUrl + '/edit'}
                                     onClick={e => this.props.dispatch(loadForm("/forms" + pUrl + '/edit', 'drawer'))}>
                <IconButton>
                    <EditIcon color={this.props.color}/>
                </IconButton>
                <ListItemText primary={'Edit Playlist'}/>
            </ListItem>);
            if (this.props.ctas['edit'] === true) {
                inPage.push(<IconButton key={`editbtn-${pUrl}`}
                                        href={"/forms" + pUrl + '/edit'}
                                        onClick={e => this.props.dispatch(loadForm("/forms" + pUrl + '/edit', 'drawer'))}>
                    <EditIcon color={this.props.color}/>
                </IconButton>);
            }
        }

        if (jreplay.can_delete(this.props.me)) {
            inContext.push(<ListItem item key='delete clip'
                                     onClick={() => this.promptDelete(`/forms${pUrl}/delete`)}
                                     className={classes.actionLine}>
                <IconButton>
                    <DeleteIcon color={this.props.color}/>
                </IconButton>
                <ListItemText primary={'Delete Replay Reel'}/>
            </ListItem>);
        }

        if (jreplay.can_edit(this.props.me)) {
            inContext.push(<ListItem alignItems="center" key={`addclips-ctx-${pUrl}`} className={classes.actionLine}>
                <IconButton>
                    <Link to={`/group/${jreplay.get('gid', 'target_id')}/rewards/${jreplay.get('field_product', 'target_id')}/clips`}>
                        <InfoIcon color={this.props.color}/>
                    </Link>
                </IconButton>
                <ListItemText primary={<Link style={{textDecoration: 'none'}} to={pUrl}>Add Clips from Footage</Link>}/>
            </ListItem>);
        }

        return (
            <Grid container justifyContent={inPage.length === 1 ? 'space-between' : 'space-around'} alignItems='center'
                  wrap='nowrap'>
                {inPage}
                <IconButton size='small' aria-haspopup="true" aria-label={`Playlist Menu ${jreplay.get('id', 'value')}`}
                            onClick={e => this.openMenu(e)}>
                    <MoreVert size='small'/>
                </IconButton>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={e => this.closeMenu(e)}
                    open={this.state.expanded}>
                    <Card>
                        <CardContent>
                            <List dense={true}>
                                {inContext}
                            </List>
                        </CardContent>
                    </Card>
                </Popover>
            </Grid>
        );
    }
}

ReplayActions.defaultProps = {
    color: 'primary',
    ctas: {
        'rate': false,
        'info': true,
        'upgrade': false,
        'edit': false,
        'tag':false,
        'play':false,
        'comments': false,
        'delete': false,
        'share': false
    }
};

ReplayActions.propTypes = {
    color: PropTypes.string,
    ctas: PropTypes.object.isRequired,
    jreplay: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    me: PropTypes.any
};

const styles = theme => ({
    actionLine: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    }
});

const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    newState.forms = state.forms;
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(withRouter(withSnackbar(ReplayActions))));
