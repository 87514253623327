import React, {Component} from 'react';
import {withSnackbar} from 'notistack';
import TextField from '@material-ui/core/TextField';
import {getIdbySegment} from "../../redux/authActions";

class ShareDialog extends Component {

 constructor(props) {
  super(props);

  var domain = document.location.hostname;
  domain = domain.replace('localhost.', '');
  this.state = {url: 'https://' + domain + props.help.url};
  if (props.brandId && props.help.url.indexOf('/group/') < 0)  {
   this.state.url += '?gid=' + props.brandId;
  }
 }

 componentDidMount() {
  const tdata = getIdbySegment(this.state.url);
  tdata.verb = 'fb';
  tdata.url = this.state.url;
  window.logUse.logEvent('share', tdata);
 }

 handleCopy(e) {
  console.log(e);
  var el = e.target;
  el.select();
  el.setSelectionRange(0, 99999);
  document.execCommand("copy");
  this.props.enqueueSnackbar('URL copied to clipboard');
 }

 shareFb(e, method) {
  e.stopPropagation();
  let fbUrl = `https://www.facebook.com/dialog/${method}?app_id=267212653413336&display=popup&redirect_uri=${encodeURIComponent(this.state.url)}&redirect_uri=${encodeURIComponent(this.state.url)}`;
  if (method === 'send') {
   fbUrl += '&link=' + encodeURIComponent(this.state.url);
  }
  console.log('sharing ' + method + ' ' + fbUrl)
  var options = 'toolbar=0,status=0,resizable=1,width=' + Math.round(window.innerWidth / 1.5) + ',height=' + Math.round(window.innerHeight / 1.5);
  window.open(fbUrl, 'sharer', options);
  return false;
 }

 render() {
  return (
   <div>
    <TextField
     id="share-url"
     label="URL"
     fullWidth
     value={this.state.url}
     onClick={e => this.handleCopy(e)}
     InputProps={{
      readOnly: true,
      type: 'url'
     }}
     variant="outlined"
    />
    {/*
    <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
     <a onClick={e => this.shareFb(e, 'share')}
      href={`https://www.facebook.com/dialog/share?app_id=267212653413336&display=popup&redirect_uri=${encodeURIComponent(this.state.url)}&href=${encodeURIComponent(this.state.url)}`}
      target="_blank" rel="noopener noreferrer"
      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', marginRight: 20}}>
      <img src="/assets/fb-share-icon.png" alt='fb sharer'/>
                        &nbsp;<span>Post</span>
     </a>
     <a onClick={e => this.shareFb(e, 'send')}
      href={`https://www.facebook.com/dialog/share?app_id=267212653413336&display=popup&redirect_uri=${encodeURIComponent(this.state.url)}&href=${encodeURIComponent(this.state.url)}&link=${encodeURIComponent(this.state.url)}`}
      target="_blank" rel="noopener noreferrer"
      style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
      <img src="/assets/fb-share-icon.png" alt='fb sharer'/>
                        &nbsp;<span>Messenger</span>
     </a>
    </div>
    */}
   </div>
  );
 }
}

export default withSnackbar(ShareDialog);
