import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import OverlayLoader from '../../Components/OverlayLoader';
import Typography from "@material-ui/core/Typography";
import FormWrapper from "./FormWrapper";
import {loadForm} from '../../redux/formsReducer';
import {getIdbySegment, joinGroup} from "../../redux/authActions";

class FormLoader extends Component {


    componentDidMount() { // direct load: http://localhost:1337/forms/tracks/32434
        let ctx = this.props.ctx || this.props.forms.ctx || 'page';
        let apiurl = false;
        if (this.props.apiurl) {
            apiurl = this.props.apiurl;
            // this.props.dispatch(loadForm(this.props.apiurl, ctx));
        } else if (this.props.location.pathname.indexOf('/forms/') === 0) {
            apiurl = this.props.location.pathname + this.props.location.search;
        }
        if (apiurl && apiurl !== this.props.forms.apiurl) {
            if (apiurl.indexOf('/members/add') > -1) {
                const parts = getIdbySegment(apiurl);
                if (typeof parts.gid === 'number') {
                    return this.props.dispatch(joinGroup(parts.gid)); // only works for page, not dialog
                }
            }
            this.props.dispatch(loadForm(apiurl, ctx)); // only works for page, not dialog
            /* if (this.props.forms.apiurl !== '/otp/account_otp/send') {
                console.log('update form page load from link', this.props);
            } */
        }
    }

    render() {
        const {forms} = this.props;
        if (forms.loading === true) return <OverlayLoader />;
        if (forms.error) return <Typography variant='h2'>{forms.error}</Typography>;
        if (!forms.api) {
            return 'error';
        }
        return <FormWrapper key={this.props.apiurl + ' - forms'} {...this.props} />
    }
}


const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    newState.forms = state.forms;
    if (!props.gFeatures) {
        newState.gFeatures = state.auth.gFeatures;
    }
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FormLoader));
