import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";
import {raterStyles} from "../../Util/ThemeUtils";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";

class AddedToCart extends Component {

    render() {
        const {classes} = this.props;

        return <Dialog
            scroll="paper"
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody
            }}
            onClose={this.props.onContinue} open={true} >
            <div style={{padding:16}}>
                <Typography variant={'caption'}>{this.props.cartItem.getTitle()}</Typography>
                <Typography variant={'subtitle1'}>Added to Cart</Typography>
                {this.props.cartItem.get('type', 'target_type') === 'pickup_match' && <Typography variant={'subtitle1'}>Scheduled games require purchase to confirm you spot</Typography>}
                <Divider style={{margin:'4px 0 8px 0'}} />
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item><Button onClick={this.props.onCheckout} variant={'contained'} color={'secondary'}>Checkout
                            Now</Button></Grid>
                        <Grid item><Button onClick={this.props.onContinue} variant={'outlined'} color={'secondary'}>Continue
                            Browsing</Button></Grid>
                    </Grid>

            </div>
        </Dialog>
    }
}


AddedToCart.defaultProps = {
    countType: 'avg'
}

AddedToCart.propTypes = {
    cartItem: PropTypes.object.isRequired,
    onCheckout: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
};


export default withStyles(raterStyles, {withTheme:true})(AddedToCart);
