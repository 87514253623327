import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Drupal2Json from '../../Util/Drupal2Json';
import UserAvatar from "../UserAvatar";
import CardHeader from "@material-ui/core/CardHeader";

const styles = () => ({
 avatar : {
  width:150,
  height:150,
  borderRadius:8,
  textAlign:'center'
 }
});


class MemberBlock extends Component {

 render() {
  const ujson = new Drupal2Json(this.props.data);

  return (
      <CardHeader classes={{avatar:this.props.classes.avatar}}
          avatar={
           <Link to={"/group/" + ujson.get('gid', 'target_id') + '/members/' + ujson.get('id')}>
            <UserAvatar data={this.props.data} size={150} />
           </Link>
          }
          title={
           <Link to={"/group/" + ujson.get('gid', 'target_id') + '/members/' + ujson.get('id')}>
            {ujson.get('field_name')}
           </Link>
          }
          subheader={`Joined ${ujson.get('gid', 'target_label')} ${ujson.getTime('created', 'MMM Do \'YY')}`}
      />
  );
 }
}

MemberBlock.propTypes = {
 classes: PropTypes.object.isRequired,
 data: PropTypes.object.isRequired,
};

export default withStyles(styles)(MemberBlock);
