import React, {Component} from 'react';
import {withRouter} from 'react-router';
import ProgressLoading from '../../Components/ProgressLoading';
import API from "../../Util/API";
import ClipBlock from "./ClipBlock";
import PropTypes from "prop-types";
import ListMeta from "../../Components/PaginationBlock/ListMeta";
import Grid from "@material-ui/core/Grid";
import PaginationBlock from "../../Components/PaginationBlock";

class ClipsLoader extends Component {

    constructor(p) {
        super(p);
        this.state = {clips: false, loading: true}
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname + this.props.location.search !== prevProps.location.pathname + prevProps.location.search) {
            this.refresh();
        } else if (!prevState.clips.data || !this.state.clips.data) {
            // first page
        } else if (prevState.clips.metadata.request_time !== this.state.clips.metadata.request_time) {
            var elIndex = Math.max(this.state.clips.metadata.end_index - this.state.clips.metadata.perpage, 0);
            if (elIndex === 0) {
                // do nothing
            } else if (elIndex > 0) {
                var el = document.querySelector('.pBlock:nth-child('+elIndex+')');
                if (el) {
                    el.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                }
            } else {
                console.log("WHAT HAPPENED?", prevProps, this.props);
            }
        }
    }

    refresh() {
        let apiurl = `/group/${this.props.match.params.gid}`
        if (this.props.match.params.rid) {
            apiurl += `/rewards/${this.props.match.params.rid}/clips`;
        } else if (this.props.match.params.fid) {
            apiurl += `/footage/${this.props.match.params.fid}/clips`;
        } else {
            console.error('invalid path')
            this.setState({clips: [], loading:false});
        }
        if (this.props.match.params.hid) {
            apiurl += `/${this.props.match.params.hid}`
        }
        if (this.props.location.search && this.props.location.search !== '?') {
            apiurl += apiurl.indexOf("?") > -1 ? `&${this.props.location.search.substring(1)}` : `?${this.props.location.search.substring(1)}`;
        }
        API.Get(apiurl).then((res) => {
            this.setState({clips: res.data, loading:false});
        }).catch((err) => {
            alert(API.getErrorMsg(err));
            this.setState({loading: false});
        });
    }

    render() {
        if (!this.state.clips || this.state.loading) return <ProgressLoading/>;

        if (this.state.clips.data.length === 0) return null; // <p style={{textAlign: 'center'}}>No clips</p>; there's already a Create a clip button

        if (this.props.match.params.hid && this.state.clips.data.length === 1) return <div><ClipBlock clip={this.state.clips.data[0]} gid={this.props.gid} {...this.props.match.params} /></div>;

        return (<div><Grid container style={{paddingTop: 10}} spacing={4} justifyContent={'space-between'}>
                <ListMeta meta={this.state.clips.metadata}/>
                {this.state.clips.data.map((item, f) => <Grid xs={12} sm={6} md={4} item key={`${item.id[0].value}clipblock`}><ClipBlock clip={item} gid={this.props.gid} {...this.props.match.params} /></Grid>)}
            </Grid>
                {this.state.clips.metadata.total_items > this.state.clips.data.length
                    &&
                    <PaginationBlock hideAddButton={true} meta={this.state.clips.metadata}/>
                }
            </div>
        );
    }
}

ClipsLoader.propTypes = {
    apiurl: PropTypes.string.isRequired,
    gid: PropTypes.number.isRequired,
    rid: PropTypes.number.isRequired,
};

export default withRouter(ClipsLoader);
