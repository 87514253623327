import axios from 'axios';
import Config from '../Config';
import {getIdbySegment} from "../redux/authActions";
import {setQueryParam} from "./WindowUtils";

class API {

    constructor() {
        this.requester = axios.create({
            baseURL: Config.api.base,
            timeout: process.env.NODE_ENV === 'production' ? 30000 : 0,
            params: {
                '_format': 'json',
                //              '_xhprof':1
                //            ,'XDEBUG_SESSION_START': 'xdebug-atom'
            }
        });
        // WARN: https://github.com/axios/axios/issues/385!
        // this.requester.defaults.headers.common['crossDomain'] = true;
        // this.requester.defaults.headers.common['async'] = true;
        this.requester.defaults.headers.common['Accept'] = 'application/json, audio/mpeg, video/mp4';
        //this.requester.defaults.headers.common['Access-Control-Max-Age'] = 6000;
        this.requester.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        //this.requester.defaults.headers.post['Content-Type'] = 'multipart/form-data';


        this.requester.interceptors.request.use((config) => {
            if (!config.url) return config; // API.Blob

            var tokens = this.getLocalTokens();
            if (config.url.indexOf('/oauth/token') > -1 || config.url.indexOf('/otp/') === 0) {
                // config.headers.common['Authorization'] = 'Basic ' + Config.api.base64d;
            } else if (tokens && tokens.access_token) {
                if (config.data && config.data.grant_type === 'refresh_token') {
                    config.headers.common['Authorization'] = 'Bearer ' + tokens.refresh_token;
                } else {
                    /* if (tokens.created_time + tokens.expires_in < new Date().getTime() - 30) {
                        setTimeout(() => this.refreshAccessToken(tokens), 1000); // preemptive after this request completes
                    } */
                    if (tokens.created_time + tokens.expires_in > new Date().getTime()) {
                        // token is expired but this request might not need authorization
                    } else {
                        config.headers.common['Authorization'] = 'Bearer ' + tokens.access_token;
                    }

                }

            }
            return config;
        }, (error) => {
            console.log('ERROR ON INTERCEPTOR REQUEST', error);
            return Promise.reject(error);
        });

        this.requester.interceptors.response.use(res => {
            if (res.data.apiversion) {
                var appversion = localStorage.getItem(Config.api.tokName + '_apiversion');
                if (appversion && parseInt(appversion) < parseInt(res.data.apiversion)) {
                    if (res.config.url.indexOf(Config.api.base + '/appstartup') === 0) { // on initial load and any form submissions that require menu updates
                        // localStorage.setItem(Config.api.tokName + '_apiversion', (res.data.apiversion) ? res.data.apiversion : Math.floor(new Date().getTime()/1000));
                    } else {
                        localStorage.setItem(Config.api.tokName + '_apiversion', res.data.apiversion);
                        localStorage.removeItem("cliptypes")// or else this will repeat every request
                        if (window.confirm("We've launched some breaking changes. Do you want to reload the latest page?")) {
                            document.location.reload();
                        }
                    }
                }
            }
            return res;
        }, async (error) => { // error might be o
            const originalRequest = error.config;// ld access_token
            const statusCodes = {401: false, 403: false}; // 418 is used by Tam for business logic rejections
            if (error.response && typeof statusCodes[error.response.status] !== 'undefined') {
                if (originalRequest.url.indexOf('/oauth/token') > -1) {
                    if (!originalRequest.url.indexOf('refreshing=true') < 0) {
                        return this.refreshAccessToken(null, originalRequest);
                    } else {
                        console.error("the refresh token expired!!?!?");
                        localStorage.removeItem(Config.api.tokName);
                        const tdata = getIdbySegment(document.location.pathname);
                        document.location.href = tdata.gid > 0 ? `/group/${tdata.gid}/signin` : '/signin';
                    }
                } else if (originalRequest.url.indexOf('refreshing=true') < 0) {
                    return this.refreshAccessToken(null, originalRequest);
                }
                return Promise.reject(error);
            }
            return Promise.reject(error); // remove and run next
        });
    }

    async refreshAccessToken(tokens, callback) {
        if (!tokens) tokens = this.getLocalTokens();
        var formData = new FormData();
        formData.append("grant_type", "refresh_token");
        formData.append("client_id", Config.api.client_id);
        formData.append("client_secret", Config.api.client_secret);
        formData.append("refresh_token", tokens.refresh_token);

        callback.url = setQueryParam(callback.url, {"refreshing":true});

        var req = {
            url: "/oauth/token?refreshing=true",
            method: "POST",
            headers: {"Content-Type": `multipart/form-data; boundary=` + formData._boundary},
            data: formData,
            body: {
                "refreshing":true
            }
            /* data : {
                grant_type: "refresh_token",
                client_id:Config.api.client_id,
                client_secret:Config.api.client_secret,
                refresh_token:tokens.refresh_token
            }

             */
        };

        return this.Request(req)
            .then((res) => {
                console.log("TOKEN REFRESHED!", res.data);
                res.data.created_time = res.data.apiversion
                    ? res.data.apiversion
                    : Math.floor(new Date().getTime() / 1000);
                localStorage.setItem(Config.api.tokName, JSON.stringify(res.data));
                localStorage.setItem(Config.api.tokName + "_apiversion", res.data.created_time);
                if (callback) {
                    callback.headers.Authorization = `Bearer ${res.data.access_token}`;
                    return this.requester(callback);
                }
            })
    }


    getLocalTokens() {
        let tokens = localStorage.getItem(Config.api.tokName);
        if (!tokens || tokens === '') return false;
        try {
            tokens = JSON.parse(tokens);
            if (tokens === null || typeof tokens.access_token !== 'string') tokens = false;
        } catch (e) {
            tokens = false;
        }
        return tokens;
    }

    Blob(path) {
        // console.log('Get to '+path);
        return this.requester.get(path, {
            responseType: 'blob'
        });
    }

    Get(path) {
        // console.log('Get to '+path);
        return this.requester.get(path);
    }

    Put(path, data) {
        // console.log('Put to '+path+' with', data);
        return this.requester.put(path, data);
    }

    Delete(path, data) {
        // console.log('Delete to '+path+' with', data);
        return this.requester.delete(path, data);
    }

    Post(path, data) {
        // console.log('POST to "' + path + '" with ', data);
        return this.requester.post(path, data);
    }

    Request(req) {
        // console.log('API', req.method + ' to "' + req.url + '"');
        return this.requester.request(req);
    }

    Html(path) {
        axios.defaults.headers.common['Accept'] = 'text/html';
        var html = this.requester.get(path);
        axios.defaults.headers.common['Accept'] = 'application/json';
        return html;
    }

    checkError(res) {
        if (typeof res.message !== 'undefined') {
            return res.message;
        } else if (res.error) {
            if (typeof res.error === 'object') return res.error.join('. ');
            return res.error;
        }
        return '';
    }

    getErrorMsg(err) {
        var msg = [];
        if (err.response && err.response.data) {
            console.log(err.response.status, err.response.data);
            if (typeof err.response.data.message === 'string') {
                msg.push(err.response.data.message === '' ? 'Unauthorized' : err.response.data.message);
            } else if (err.response.data.error) {
                if (typeof err.response.data.error === 'object') msg.push(err.response.data.error.join(', '));
                else msg.push(err.response.data.error);
            } else if (err.response.data.indexOf('<!DOCTYPE html>') !== 0) {
                // msg.push(err.response.data);
            }
        }

        if (msg.length === 0) {
            if (typeof err.message !== 'undefined' && err.message !== '') {
                console.log('Error', err.message);
                msg.push(err.message);
            } else if (err.request) {
                console.log(err.request);
                msg.push('no server response from server: ' + Config.api.base);
            } else {
                msg.push(JSON.stringify(err));
                console.log('Default Server Error', err);
            }
        }

        return msg.join('. ');
    }

}

export default new API(); // singleton
