import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {setGrammer} from "../../redux/authReducer";
import NOUNS from "../../apps/tam/nouns.json";

class GrammerFields extends Component {

    updateGrammer(key, cardinality, val) {
        const grammer = JSON.parse(JSON.stringify(this.props.gGrammer))
        if (!grammer[key]) grammer[key] = {...NOUNS[key]}
        if (key === 'TAC') {
            grammer[key][cardinality] = val.trim();
            // if (grammer[key][cardinality].length > 3) return false;
        } else {
            grammer[key][cardinality] = val.trim();
        }
        this.props.dispatch(setGrammer(grammer));
    }

    render() {

        return (
            <React.Fragment>
                {Object.entries(NOUNS).map(arr => {
                    let key = arr[0];
                    let gram = this.props.gGrammer[key];
                    if (!gram) gram = arr[1];

                    return <Grid container key={'grammerblock-'+key}
                                 justifyContent={'space-between'} alignItems={'flex-end'} alignContent={'center'}
                                 wrap={'nowrap'} spacing={0} style={{marginBottom:10, padding:'1%'}}>
                        {/* <Grid item>
                            <TamIcon noavatar={true} iconName={key} size={'small'} />
                        </Grid> */}
                        <Grid item>
                            <TextField value={gram.s}
                                       onChange={e => this.updateGrammer(key, 's', e.currentTarget.value)}
                                       name={'singular:' + key}
                                       label={'singular: ' + key}
                                       disabled={this.props.isEnabled === false}
                            />
                        </Grid>
                        <Grid item>
                            <TextField value={gram.p}
                                       onChange={e => this.updateGrammer(key, 'p', e.currentTarget.value)}
                                       name={'plural:' + key}
                                       label={'plural: ' + key}
                                       disabled={this.props.isEnabled === false}
                            />
                        </Grid>
                    </Grid>
                })}
                <Typography variant={'body2'}>* Your currency name cannot match any existing currency code or symbol</Typography>
            </React.Fragment>
        );


    }
}

export default GrammerFields;
