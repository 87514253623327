import React from 'react';
import {withStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';
import Drupal2Json from '../../Util/Drupal2Json';
import UserAvatar from '../UserAvatar';
import PropTypes from 'prop-types';
import SanitizedHTML from 'react-sanitized-html';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Config from '../../Config';
import {pStyles} from '../../Util/ThemeUtils';
import TrackList from "../../Views/TrackList";
import VisibilitySensor from "react-visibility-sensor";
import PlaylistActions from "../PlaylistActions";
import EnabledFeature from "../../Util/EnabledFeature";
import CircularProgress from "@material-ui/core/CircularProgress";

class PlaylistBlock extends React.Component {
    constructor(p) {
        super(p);
        this.classes = this.props.classes;
        this.state = {
            layout: 'grid',
            rendered: false
        };
    }

    renderTrackList(isVisible) {
        if (isVisible === true) {
            this.setState({rendered: true});
        }
    }

    render() {
        const {classes} = this.props;
        const item = {...this.props.data};
        const json = new Drupal2Json(item);
        const gid = json.get('gid', 'target_id');

        let desc = json.get('field_description', 'summary');
        if (!desc || desc === '') desc = json.get('field_description', 'value');

        return (
            <Card className={['pBlock', this.classes.card].join(' ')}>
                <CardHeader
                    avatar={gid == 54 ? null :
                        <Link to={"/users/" + json.get('uid', 'target_id') + '/group/' + gid}>
                            <UserAvatar data={item.uid[0]}/>
                        </Link>
                    }
                    title={<Link className={classes.noUnderline}
                                 to={'/group/' + gid + '/playlists/' + json.get('id')}
                                 title='Group Playlist'>
                        {json.getLabel()}
                    </Link>}
                    subheader={<React.Fragment>
                        <span>{json.getTime('created', 'MMMM Do YYYY')} {item.changed && item.changed - item.created > 8600 ? `, updated ${json.getTime('changed', 'MMMM Do YYYY')}` : ''}</span>
                        {gid !== 54 && <React.Fragment>
                        <span style={{margin:'0 5px'}}>•</span>
                        <Link to={'/users/' + json.get('uid', 'target_id') + '/group/' + gid}>{json.get('uid', 'target_label')}</Link>
                        </React.Fragment>}
                </React.Fragment>}
                />

                {json.get('field_cover', 'url') ?
                    <Link to={"/group/" + gid + "/playlists/" + json.get('id', 'value')}>
                        <img
                            className={classes.masonryImg}
                            src={json.get('field_cover', 'url')}
                            alt="Playlist Cover"
                        />
                    </Link> : ''
                }

                {desc && desc !== '' ?
                    <CardContent className={classes.content}><SanitizedHTML allowedTags={Config.allowedTags}
                                                                            html={desc}/></CardContent>
                    : ''
                }

                <EnabledFeature feature={'playlists:rating'} value={true}>
                    <div style={{padding: '0 1% 10px 1%'}}>
                        <PlaylistActions playlistgc={item} color={'secondary'}
                                         ctas={54 ? {'rate': true, 'add': false, 'play': true} : {'rate': true, 'add': true, 'play': false}}
                                         dispatch={this.props.dispatch}
                                         me={this.props.me}/>
                    </div>
                </EnabledFeature>


                {json.get('field_track_count', 'value') > 0 &&
                    <EnabledFeature feature={'playlists:preview_count'} value={0} condition={'greaterOrEqual'}>
                        <VisibilitySensor offset={{bottom: -50}} partialVisibility='bottom'
                                          onChange={e => this.renderTrackList(e)}>
                            {({isVisible}) =>
                                <div>{isVisible || this.state.rendered === true ?
                                    <TrackList key={'pBlocKList' + item.id[0].value} onEmpty={false} blockType={'list'}
                                               showTabLabels={false} playlist={item}
                                               perpage={gid === 54 ? 2 : 3}/> : <CircularProgress />}</div>
                            }
                        </VisibilitySensor>
                    </EnabledFeature>
                }
            </Card>
        );
    }
}

PlaylistBlock.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles)(PlaylistBlock);
