import React, {Component} from 'react';
import {lighten, withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Paper from '@material-ui/core/Paper';
import {deleteEmail, resendEmail} from '../../redux/emailReducer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Drupal2Json from '../../Util/Drupal2Json';
import UserAvatar from '../../Components/UserAvatar';
import PaginationBlock from '../../Components/PaginationBlock';
import ProgressLoading from '../../Components/ProgressLoading';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';


import {Link} from 'react-router-dom';

const styles = theme => ({
 highlight: theme.palette.type === 'light'
  ? {
   color: theme.palette.secondary.main,
   backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  }
  : {
   color: theme.palette.text.primary,
   backgroundColor: theme.palette.secondary.dark,
  },
 root: {
  width: '100%',
  marginTop: theme.spacing(3),
  overflowX: 'auto',
 },
 table: {
  minWidth:'100%',
 },
 profilePic : {
  width:50,
  maxWidth:'100%',
 },
 spacer: {
  flex: '1 1 100%',
 },
 actions: {
  color: theme.palette.text.secondary,
 },
 title: {
  flex: '0 0 auto',
 },
 inlineFlex : {
  display:'inline-flex'
 },
 badge: {
  fontSize: '70%',
  padding: '0px 3px'
 }
});


class EmailsTable extends Component {

 componentDidUpdate(prevProps) {
  if (this.props.location.pathname !== prevProps.location.pathname) {
   // this.refresh();
  } else if (!prevProps.apiData || !this.props.apiData) {
   // first page
  } else if (prevProps.apiData.metadata.request_time !== this.props.apiData.metadata.request_time) {
   var elIndex = Math.max(this.props.apiData.metadata.end_index - this.props.apiData.metadata.perpage, 0);
   if (elIndex === 0) {
    // do nothing
   } else if (elIndex > 0) {
    var el = document.querySelector('.memberRow:nth-child('+elIndex+')');
    if (el) {
     el.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
   } else {
    console.log("WHAT HAPPENED?", prevProps, this.props);
   }
  }
 }

 tryDelete(email) {
  if (window.confirm('This will not unsend the email, only invalidate the auto-login links sent. Is this ok?')) {
   this.props.dispatch(deleteEmail(email.eid));
  }
 }

 tryResend(email) {
  if (window.confirm('Are you sure you want to resend this email to ' + email.email)) {
   this.props.dispatch(resendEmail(email.eid));
  }
 }

 render() {
  if (this.props.apiData.metadata.url.indexOf(this.props.location.pathname) === -1) return <ProgressLoading />;

  const {classes} = this.props;
  const body = [];

  for(var f in this.props.apiData.data) {
   const item = this.props.apiData.data[f];
   const ujson = new Drupal2Json(item);
   const taCred = parseFloat(ujson.get('field_ta_cred') > 0) ? '$' + ujson.get('field_ta_cred') : null;

   body.push(<TableRow key={'user-'+body.length}>
    <TableCell component="th" scope="row">
     <Badge badgeContent={taCred} color="primary" classes={{ badge: classes.badge }} showZero={true} anchorOrigin={{horizontal:'left', vertical:'top'}} >
      {ujson.get('entity_id', 'target_id') ?
       <Link to={"/users/" + ujson.get('entity_id', 'target_id')}>
        <UserAvatar data={item} />
       </Link>
       : <UserAvatar data={item} />
      }
     </Badge>
     <div>
      {ujson.get('entity_id', 'target_id') ?
       <Link to={"/users/" + ujson.get('entity_id', 'target_id')}>
        {ujson.get('field_name')}
       </Link>
       :
       ujson.get('field_name')}
     </div>
     <div>{item.email.email}</div>
    </TableCell>
    <TableCell>
     <List component="nav" dense={true} >
      <ListItem key={'status'}>
       <ListItemText primary={item.email.status + (parseInt(item.email.seen, 0) > 0 ? ', seen' : '')} secondary={'status'} />
      </ListItem>
      {Object.entries({subject:'Subject', message:'Message', cta_url:'CTA'}).map(arr => {
       var entries = [];
       if (item.email[arr[0]] !== null) {
        entries.push(
         <ListItem key={arr[0]}>
          <ListItemText primary={item.email[arr[0]]} secondary={arr[1]} />
         </ListItem>
        );
       }
       return entries;
      })}
     </List>
    </TableCell>
    <TableCell>
     <IconButton onClick={e => this.tryResend(item.email)} data-aria={'Resend email'}><EmailIcon /></IconButton>
     <IconButton onClick={e => this.tryDelete(item.email)} data-aria={'invalidate email'}><DeleteIcon /></IconButton>
    </TableCell>
   </TableRow>
   );
  }
  return (
   <Paper className={classes.root}>

    {this.props.apiData.metadata.total_items > this.props.apiData.data.length ?
     <PaginationBlock meta={this.props.apiData.metadata} dispatch={this.props.dispatch} />
     :
     <Toolbar><Typography variant="h6" display="inline" gutterBottom color='secondary'>
      {this.props.apiData.metadata.page_title}
     </Typography></Toolbar>
    }

    <Table className={classes.table}>
     <TableHead>
      <TableRow className='memberRow'>
       <TableCell>Recipient</TableCell>
       <TableCell>Email</TableCell>
       <TableCell>Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {body}
     </TableBody>
    </Table>
   </Paper>
  );
 }

}

export default withRouter(withStyles(styles)(EmailsTable));
