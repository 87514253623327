import React, {Component} from 'react';
import {withRouter} from 'react-router';
import Drupal2Json from '../../Util/Drupal2Json';
import ProductDetails from '../../Components/ProductDetails';
import GroupDetails from '../GroupDetails';
import PlaylistGC from '../PlaylistGC';
import MemberProfile from '../../Components/MemberProfile';
import TrackDetails from '../../Components/TrackDetails';
import ProgressLoading from '../../Components/ProgressLoading';
import FaqItem from '../../Components/FaqItem';
import Typography from "@material-ui/core/Typography";
import EventTicketsDetail from "../../Components/ProductEvents/EventTicketsDetail";
import {getParam} from "../../Util/WindowUtils";
import {entityData} from '../../redux/entityDataReducer';
import {listData} from '../../redux/listDataReducer';
import PickupMatchDetail from "../../Components/ProductEvents/PickupMatchDetail";
import FootageBlock from "../../core/clips/FootageBlock";
import HighlightBlock from "../../core/clips/HighlightBlock";
import Replay from "../../core/playlists/Replay";

class EntityView extends Component {

    componentDidMount() {
        this.refresh(true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname || this.props.location.search !== prevProps.location.search) {
            this.refresh();
        }
    }

    refresh(isFirst) {
        var url = (this.props.me.profile && this.props.location.pathname === '/my-profile') ?
            '/users/' + this.props.me.profile.uid[0].value
            :
            this.props.location.pathname;

        if (url.indexOf('group/') > -1) {
            let segs = this.props.location.pathname.split('/');
            if (segs.length === 3) { // ex. /group/#
                url += '/details'; // api thinks /group/# is the Group module
            } else if (this.props.match.params.aid && this.props.match.params.gid && this.props.match.params.bundle) {
                url += this.props.location.search;
                this.props.dispatch(listData(url));
                if (!isFirst && this.entityPath() !== this.props.location.pathname) {
                    return;
                }
                segs.pop();
                url = segs.join('/');
            }
        }

        let cachebuster = getParam('cache', document.location.search);
        if (cachebuster) {
            url += '?cache=' + new Date().getTime();
        }

        return this.props.dispatch(entityData(url));
    }

    entityPath() {
        let segs = this.props.location.pathname.split('/');
        if (this.props.match.params.aid && this.props.match.params.gid && this.props.match.params.bundle) {
            segs.pop();
        }
        return segs.join('/');
    }

    render() {
        /* if (this.props.entity.loading === true) {
         return <div style={{width:'100%', textAlign:'center', margin:'20px auto'}}><ProgressLoading /><Typography variant='h5'>Loading</Typography></div>;
        } */
        const entityPath = this.entityPath();

        if (this.props.entity.error) return <div style={{width: '100%', textAlign: 'center', margin: '20px auto'}}>
            <Typography variant='h3'>{this.props.entity.error}</Typography></div>;
        if (this.props.entity.url.indexOf(entityPath) !== 0 && this.props.location.pathname !== '/my-profile') {
            return <div style={{width: '100%', textAlign: 'center', margin: '20px auto'}}><ProgressLoading/><Typography
                variant='h5'>Searching {this.props.location.pathname}</Typography></div>;
        }
        if (!this.props.entity.apiData && this.props.entity.loading === false) return 'no results';

        var item = this.props.entity.apiData;
        var json = new Drupal2Json(item);
        var type = json.get('type', 'target_id');
        const canEdit = json.can_edit(this.props.me) === true;
        var view = '';

        if (type === "groups-group_node-playlists") {
            if (json.get('field_category', 'target_id') === 868) {
                view = <Replay me={this.props.me} jreplay={json} dispatch={this.props.dispatch} />;
            } else {
                view = <PlaylistGC me={this.props.me} playlist={item} dispatch={this.props.dispatch} />;
            }
        } else if (type === "groups-group_membership") {
            view = <MemberProfile me={this.props.me} dispatch={this.props.dispatch} data={item} lists={this.props.lists}
                                  formDrawerOpen={this.props.formDrawerOpen}/>;
        } else if (type === "user") {
            view = <MemberProfile me={this.props.me} dispatch={this.props.dispatch} data={item} lists={this.props.lists}
                                  formDrawerOpen={this.props.formDrawerOpen}/>;
        } else if (type === "groups-group_node-tracks") {
            view = <TrackDetails me={this.props.me} dispatch={this.props.dispatch} item={item} canEdit={canEdit}/>;
        } else if (type === "groups-group_node-clips") {
            view = <HighlightBlock me={this.props.me} dispatch={this.props.dispatch} clip={item} canEdit={canEdit}/>;
        } else if (type === "groups") {
            view = <GroupDetails me={this.props.me} dispatch={this.props.dispatch}
                                 data={item}
                                 formDrawerOpen={this.props.formDrawerOpen}
                                 lists={this.props.lists}/>;
        } else if (type === "faq") {
            view = <FaqItem faq={item} dispatch={this.props.dispatch}/>;
        } else if (type === 'commerce_product' || json.get('type', 'target_type') === 'commerce_product') {
            if (type === 'event_tickets') {
                view = <EventTicketsDetail product={item} me={this.props.me} canEdit={canEdit}
                                           dispatch={this.props.dispatch}
                                           curGroup={this.props.curGroup}/>;
            } else if (type === 'pickup_match') {
                view = <PickupMatchDetail product={item} me={this.props.me} canEdit={canEdit}
                                          dispatch={this.props.dispatch} curGroup={this.props.curGroup}/>;
            } else {
                view =
                    <ProductDetails data={item} me={this.props.me} canEdit={canEdit} dispatch={this.props.dispatch}/>;
            }

        } else if (type == 'clips') {
            view  = <FootageBlock clip={item} dispatch={this.props.dispatch} />;
        }

        return <div style={{
            width: '100%',
            minHeight: '90vh',
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column'
        }} id="EntityView">
            {this.props.entity.loading === true && <ProgressLoading/>}
            {view}
        </div>;
    }
}

export default withRouter(EntityView);
