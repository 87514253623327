import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TamFeaturesList from '../../Components/TamFeatures/TamFeaturesList';
import TamFeaturesPrice from "../../Components/TamFeatures/TamFeaturesPrice";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withRouter} from "react-router";
import API from "../../Util/API";
import {connect} from "react-redux";
import Drupal2Json from "../../Util/Drupal2Json";
import featuresSchema from "../../apps/tam/features-schema.json";
import {withSnackbar} from "notistack";
import GroupTitle from "./GroupTitle";
import {changeGroup, editGroupMode, updateMyGroup} from "../../redux/authReducer";
import {updateEntity} from "../../redux/entityDataReducer";
import GrammerFields from "./GrammerFields";
import FontSelector from "./FontSelector";
import SettingsIcon from "@material-ui/icons/Settings";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import ColorLens from "@material-ui/icons/ColorLens";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import Accordion from "@material-ui/core/Accordion";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PolicyIcon from '@material-ui/icons/Policy';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FeatureItem from "../../Components/TamFeatures/FeatureItem";
import {withStyles} from "@material-ui/core/styles";
import {pStyles} from "../../Util/ThemeUtils";
import AddContentMenu from "./AddContentMenu";
import {getParam} from "../../Util/WindowUtils";
import FormLoader from "../FormWrapper/FormLoader";
import Grammer from "../../Util/Grammer";

const AllTabs = [
    {label:"Brand",  id:"branding", icon:LoyaltyIcon, apiform:true, featurefields:true},
    {label:"Theme",  id:"theming", icon:ColorLens, apiform:true, featurefields:true}, // merged into branding form via api
    {label:"Vocabulary", id:"grammer", icon:SortByAlpha},
    // {label:"Menus & Links",  id:"menu", icon:MenuIcon},
    {label:"Access",  id:"features", icon:PolicyIcon, featurefields:true},
//    {label:"Fonts & Typography",  id:"fonts", icon:FontDownload},
    {label:"Tools",  id:"allfeatures", icon:SettingsIcon, featurefields:true},
    {label:"Add Content",  id:"addcontent", icon:PlaylistAdd}
]

class NewGroupPlans extends Component {

    constructor(p) {
        super(p);
        this.state = {
            step: false,
            savingChanges:false,
            promptSave:false,
            groupPrice : 0,
            tab: getParam('tab', this.props.location.search, (this.props.curGroup > 0 ? 'all' : 'bytype')),
            game_type:getParam('type', this.props.location.search, null),
        }

        this.tdata = {
            bundle: 'groups',
            // gid:this.props.myGroup.id[0].value,
            verb: "add",
            // uid: this.props.profile.uid[0].value
        };
    }

    componentDidMount() {
        if (!this.props.profile) {
            this.props.enqueueSnackbar('Login or register first', {variant: 'info'});
            return this.props.history.push('/login');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.promptSave === false &&
            (JSON.stringify(this.props.myGroup) !== JSON.stringify(prevProps.myGroup) ||
             JSON.stringify(this.props.gFeatures) !== JSON.stringify(prevProps.gFeatures) ||
             JSON.stringify(this.props.gGrammer) !== JSON.stringify(prevProps.gGrammer))
        ) {
            this.setState({promptSave:true, groupPrice: this.sumTotalPrice()})
        }
    }

    startGroup(label) {
        if (this.state.savingChanges === true) return false;
        this.setState({savingChanges:true});

        const parent_id = getParam('parent_id', this.props.location.search, this.props.curGroup)
        const payload = {
            label: [{value: label}],
            field_parent_id: [{target_id:parent_id}]
        };

        this.tdata.verb = 'add';
        API.Post(`/forms/group/add`, payload).then((res) => {
            if (res.data.newgroup) {
                this.updateGroup(res.data.newgroup);
                // TODO: redirect somewhere?
                window.logUse.logEvent('group_add', this.tdata);
                    this.setState({savingChanges: false});
            } else {
                this.setState({savingChanges: false});
                window.logUse.logEvent('group_add_failed', this.tdata);
            }
        }).catch((err) => {
            window.logUse.logEvent('group_add_failed', this.tdata);
            var msg = API.getErrorMsg(err);
            console.error(msg);
            this.setState({savingChanges: false});
        });
    }

    updateTitle(label) {
        const payload = {label: [{value: label}]};
        this.props.dispatch(updateMyGroup(payload))
        const entity = {...this.props.myGroup};
        entity['label'] = [{value: label}];
        this.props.dispatch(updateEntity(entity, 'label'))
    }

    uploadGroup() {
        if (this.state.savingChanges === true) return false;
        this.setState({savingChanges:true});

        let updated = Object.assign({}, this.props.myGroup, {
            field_features:this.props.gFeatures,
            field_grammer:this.props.gGrammer,
        });

        let apiurl = `/forms/group/${this.props.myGroup.id[0].value}/edit`
        API.Put(apiurl, updated).then((res) => {
            if (res.data.success) {
                this.props.enqueueSnackbar(res.data.success, {variant: 'warning'});
                window.logUse.logEvent('group_edit', this.tdata);
            } else {
                this.props.enqueueSnackbar(res.data.error || 'Unknown error', {variant: 'error'});
            }
            this.setState({promptSave:false, savingChanges: false});
        }).catch((err) => {
            window.logUse.logEvent('group_edit_failed', this.tdata);
            var msg = API.getErrorMsg(err);
            this.setState({savingChanges: false});
            this.props.enqueueSnackbar(msg, {variant: 'error'});
        });

    }

    updateGroup(group) {
        const gid = group.id[0].value;
        this.props.dispatch(changeGroup(gid, [group]));
        this.props.dispatch(updateEntity(group));
        setTimeout(() => {
            this.props.history.push(`/group/${gid}`);
            this.props.dispatch(editGroupMode(true));
            console.log("UPDATING GROUP AND REDIRECTING THERE");
        }, 300)
    }

    publishGroup() {
        if (this.state.savingChanges === true) return false;
        this.setState({savingChanges:true});

        let apiurl = `/forms/group/${this.props.myGroup.id[0].value}/publish`;
        const status = this.props.myGroup.status[0].value === 0 ? 1 : 0;
        API.Put(apiurl, {status:status}).then((res) => {
            if (res.data.success) {
                this.props.enqueueSnackbar(res.data.success, {variant: 'warning'});
                window.logUse.logEvent('group_published', this.tdata);
            } else {
                this.props.enqueueSnackbar(res.data.error || 'Unknown error', {variant: 'error'});
            }
            this.setState({savingChanges: false});
        }).catch((err) => {
            window.logUse.logEvent('group_published_failed', this.tdata);
            var msg = API.getErrorMsg(err);
            this.setState({savingChanges: false});
            this.props.enqueueSnackbar(msg, {variant: 'error'});
        });
    }

    changeStep(tab) {
        if (this.state.step === tab.id) {
            this.setState({step:false});
        } else {
            this.setState({step: tab.id});
        }
    }

    sumTotalPrice() {
        let totalPrice = 0;
        Object.entries(featuresSchema).forEach((arr) => {
            arr[1].options.forEach((opt) => {
                let selected = this.props.gFeatures[arr[0]];
                if (selected) {
                    if (arr[1].multiple) {
                        if (selected.indexOf(opt.value) > -1) {
                            totalPrice += parseFloat(opt.price);
                        }
                    } else {
                        if (opt.value === selected) {
                            totalPrice += parseFloat(opt.price);
                        }
                    }
                }
            });
        });
        return totalPrice.toFixed(2)
    }

    readyToPublish() {
        if (this.props.balance.usd.net < this.state.groupPrice) {
            return false;
        }
        let required = {'field_body':'A description is required', 'field_cover':'A cover image is required', 'label':'A title is required'};
        for(let f in required) {
            if (!this.props.myGroup[f] || this.props.myGroup[f].length === 0) {
                return false;
            }
        }
        return true;
    }

    renderStepFeatures() {
        return <React.Fragment>
            <Tabs
                value={this.state.tab}
                variant="fullWidth"
                centered={true}
                indicatorColor="primary"
                textColor="inherit">
                <Tab value={'bytype'} label="Templates" onClick={() => this.setState({tab:'bytype'})} />
                <Tab value={'all'} label="Customize" onClick={() => this.setState({tab:'all'})} />
            </Tabs>
            {this.state.tab === 'bytype' ?
                <TamFeaturesList xs={this.props.layout === 'menu' ? 12 : 6} onSelect={type => this.setState({game_type:type, tab:'all'})} />
                :
                <TamFeaturesPrice game_type={this.state.game_type}/>
            }

        </React.Fragment>
    }

    renderFeatureFields() {
        let featFields = [];
        for (let f in featuresSchema) {
            let feat = featuresSchema[f];
            if (feat.form === this.state.step) {
                let section = feat.section || f.split(':')[0];
                featFields.push(<FeatureItem key={`feattools-${section}-${f}`} feature={feat} feature_id={f} gFeatures={this.props.gFeatures} />)
            }
        }
        return featFields;
    }

    renderTabContent(tab) {
        let formItem = null;
        if (this.state.step === 'allfeatures') {
            formItem = this.renderStepFeatures();
        } else if (this.state.step === 'fonts') {
            formItem = <FontSelector gFonts={this.props.myGroup.field_fonts} dispatch={this.props.dispatch}/>
        } else if (this.state.step === 'addcontent') {
            formItem = <AddContentMenu gid={this.props.myGroup.id[0].value} classes={this.props.classes} />
        } else if (this.state.step === 'grammer') {
            formItem = <div style={{padding: '1%'}}><GrammerFields isEnabled={this.props.gFeatures['groups:field_grammer'] === true} gGrammer={this.props.gGrammer} dispatch={this.props.dispatch}/></div>
        } else if (tab.apiform === true) {
            formItem = <FormLoader apiurl={`/forms/group/${this.props.myGroup.id[0].value}/edit?step=${tab.id}`}
                                   key={`/forms/group/${this.props.myGroup.id[0].value}/edit?step=${tab.id}`}
                                   ctx={'drawer'}
                                   hideActions={true}
                                   gFeatures={this.props.gFeatures} />
        }
        return formItem;
    }

    render() {
        if (!this.props.profile) return 'please login first';
        if (!this.props.myGroup) {
            return <GroupTitle dispatch={this.props.dispatch} onSelected={label => this.startGroup(label)}/>
        }

        const {classes} = this.props;
        return (
            <Grid
                container
                spacing={0}
                style={{maxWidth: 1000, padding: '5px 0'}}
                direction='column'
                justifyContent='flex-start'>

                <Grid container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'} style={{padding: '2%', alignSelf:'flex-start'}} >
                    <Grid item xs={3}>
                        <ButtonGroup variant={'contained'}
                                     color={'secondary'}
                                    size={'small'}>
                        <Button
                            disabled={this.state.promptSave === false || this.state.savingChanges === true}
                            onClick={() => this.uploadGroup()}>
                            Save
                        </Button>
                        <Button
                            disabled={this.readyToPublish() === false || this.state.savingChanges === true}
                            onClick={() => this.publishGroup()}>
                            {this.props.myGroup.status[0].value === 0 ? "Publish" : "Unpublish"}
                        </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={6} >
                        <Typography color={'primary'} style={{textAlign:'right', fontWeight:700}}><sup>$</sup>{this.state.groupPrice} / month</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {/* padding for docking mover in GroupEditDrawer */}
                    </Grid>

                </Grid>

                <Grid item >
                    <GroupTitle dispatch={this.props.dispatch}
                                gid={this.props.curGroup} label={this.props.myGroup.label[0].value}
                                onSelected={label => this.updateTitle(label)}/>
                </Grid>


                <Grid item style={{margin:'20px 0'}}>
                {AllTabs.map(tab => {
                        const IconWrap = tab.icon;
                        const featFields = this.renderFeatureFields();

                        return <Accordion key={`groupedit-${tab.id}`}
                                          style={{width:'100%'}}
                                          aria-label={`group ${tab.id}`}
                                          elevation={5}
                                          expanded={this.state.step === tab.id}
                                          onChange={() => this.changeStep(tab)} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={tab.id+'-content'}
                                id={tab.id + '-header'}
                                style={{paddingTop:3, paddingBottom:3}} >
                                    <Grid item><IconWrap style={{marginRight:5}} color={this.state.step === tab.id ? 'secondary' : 'inherit'} /></Grid>
                                    <Grid item>{tab.label}</Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{margin:0, padding:0, flexDirection:'column'}} >
                                {this.state.step === tab.id &&
                                    <React.Fragment>
                                        {this.renderTabContent(tab)}
                                        {featFields.length > 0 && <div className={classes.altBlocks}>{featFields}</div>}
                                    </React.Fragment>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }
                )}
                </Grid>

                <Grid item style={{padding:'2%'}}>
                    <Typography variant={'body2'}>We always reward members for their contributions to the
                        content catalogue with {Grammer.tac('TAC')} ({Grammer.tac('₮')}). <b style={{fontWeight: 500}}> How users
                        engage, earn, and spend {Grammer.tac('TAC')} is up to you.</b></Typography>
                </Grid>

            </Grid>
        );
    }
}


const mapStateToProps = (state, props) => {
    if (!state.auth.me || !state.auth.me.groups || !state.auth.me.profile) return false;
    const st = {
        profile: state.auth.me.profile,
        curGroup: state.auth.curGroup,
        editMenuOpen: state.auth.editMenuOpen,
        formDrawerOpen: state.auth.formDrawerOpen,
        gFeatures: state.auth.gFeatures,
        gGrammer: state.auth.gGrammer,
        balance:state.wallet.balance
    };
    const parent_id = getParam('parent_id', state.router.location.search, state.auth.curGroup)

    if (state.auth.formDrawerOpen > 0 && parent_id > 0) {
        if (!state.auth.me.groups[parent_id]) {
            // maybe hits on race condition just after creating a new group, before menu is loaded
        } else {
            const gjson = new Drupal2Json(state.auth.me.groups[parent_id]);
            if (gjson.isGroupAdmin(state.auth.me) === true) {
                st.myGroup = state.auth.me.groups[parent_id]
            }
        }

    }

    return st;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(withStyles(pStyles, {withTheme:true})(NewGroupPlans))));
