import React from 'react';
import Drupal2Json from '../../Util/Drupal2Json';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
 card: {
  width:'100%',
  padding:0,
  maxWidth:960,
  justifyContent:'center',
  marginBottom:15,
  position:'relative',
  background:theme.palette.background.paper
 },
 header : {
  margin:0,
  paddingTop: theme.spacing(2),
  fontSize:'80%',
  fontWeight:'normal',
  textAlign:'center',
  color: theme.palette.text.secondary,
  width:'100%',
  '& a' : {
   textDecoration:'none'
  }
 },
 content : {
  padding:theme.spacing(1),
  textAlign:'center',
  '& img' : {
   width:'100%'
  }
 },
 media : {
  backgroundSize:'contain',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center center',
  width:'100%', height:'100%',
  display: 'block',
  minHeight: 200
 },
 actions: {
  padding:theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  position:'relative',
  display: 'flex',
  alignItems:'center',
  alignContent:'center',
  justifyContent: 'space-between'
 },
 stepper : {
  background:theme.palette.background.default
 },
 chipRoot : {
  backgroundColor:'transparent',
  textDecoration:'none',
  color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
  '& a' : {
   textDecoration:'none'
  }
 },
 chipLabel : {
  border:'1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]),
  borderWidth:'1px 1px 2px 2px',
  borderRadius:'0 8px 8px 0',
  paddingTop:theme.spacing(.5),
  paddingBottom:theme.spacing(.5),
  marginLeft:theme.spacing(1) * -1,
  paddingLeft:theme.spacing(2),
 },
 chipAvatar : {
  color: theme.palette.primary.main + '!important',
  backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  fontWeight: 900,
  padding: theme.spacing(3),
  fontSize:'90%',
  width: 40,
  height: 40,
 },
});

class ProductReferral extends React.Component {
  state = { imgIndex: 0 };

  handleNext = (e) => {
   this.setState({imgIndex: (this.state.imgIndex === this.props.data.field_remote_images.length - 1 ? 0 : this.state.imgIndex + 1 )});
  }

  handleBack = (e) => {
   this.setState({imgIndex: (this.state.imgIndex === 0 ? this.props.data.field_remote_images.length - 1 : this.state.imgIndex - 1 )});
  }

  render() {
   const { classes } = this.props;
   const item = {...this.props.data};
   const json = new Drupal2Json(item);
   const refLink = json.get('field_referral_link', 'uri');

   return (
    <div className={['pBlock', classes.card].join(' ')} >

     <Typography variant='h3' className={classes.header}>
      <a href={refLink} target='_blank' rel="noopener noreferrer" title='Group Reward'>
       {json.get('title', 'value')}
      </a>
     </Typography>

     <div className={classes.content}>
      <a href={refLink} target='_blank' rel="noopener noreferrer" title='Group Reward'>
       <span alt="Product Cover"
        className={classes.media}
        style={{backgroundImage:"url(" + json.get('field_remote_images', 'uri', this.state.imgIndex) + ")"}}>&nbsp;</span>
      </a>
     </div>


     <div className={classes.actions} >
      <a href={refLink} style={{textDecoration:'none'}} target='_blank' rel="noopener noreferrer" title='Group Reward'>
       <Chip
        avatar={<Avatar>{'$' + json.get('field_list_price', 'value')}</Avatar>}
        label='Buy on Amazon'
        classes={{
         root:classes.chipRoot,
         label:classes.chipLabel,
         avatar:classes.chipAvatar
        }}
       />
      </a>
      {item.field_remote_images.length > 1 ?
       <MobileStepper
        variant="dots"
        steps={item.field_remote_images.length}
        position="static"
        className={classes.stepper}
        activeStep={this.state.imgIndex}
        nextButton={
         <IconButton size="small" onClick={this.handleNext} disabled={this.state.imgIndex === item.field_remote_images.length}>
          <KeyboardArrowRight />
         </IconButton>
        }
        backButton={
         <IconButton size="small" onClick={this.handleBack} disabled={this.state.imgIndex === 0}>
          <KeyboardArrowLeft />
         </IconButton>
        }
       /> : ''}
     </div>

    </div>
   );
  }
}

ProductReferral.propTypes = {
 classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductReferral);
