import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import App from './App';
import configureStore, {history} from './redux/configureStore';

// import {unregister} from './registerServiceWorker';
const store = configureStore({});

/*
store.subscribe(() => {
  const test = store.getState().forms;
  console.log(test.palette);
});
*/

if ((process.env.NODE_ENV !== 'production' && typeof window['Cypress'] === 'object') || document.location.search.indexOf("debug") > -1) {
    const img = document.createElement("IMG");
    img.src = '/assets/my_location-white-18dp.svg';
    img.width = 18;
    img.height = 18;
    img.id = 'cypressHand';
    document.body.appendChild(img);
}

ReactDOM.render(
 <Provider store={store}>
  <ConnectedRouter history={history} >
   <App history={history} dispatch={store.dispatch} />
  </ConnectedRouter>
 </Provider>,
 document.getElementById('root')
);

// unregister();
