import React, {Component} from 'react';
import API from "../../Util/API";
import {withRouter} from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import {Close, Edit} from "@material-ui/icons";
import {isEnabled} from "../../Util/EnabledFeature";
import RatingStar from "../../Components/RatingStar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Popover from "@material-ui/core/Popover";
import MoreVert from "@material-ui/icons/MoreVert";
import UserAvatar from "../../Components/UserAvatar";
import UserTagForm from "../users/UserTagForm";
import {shareUrl} from "../../redux/helpReducer";
import ShareIcon from "@material-ui/icons/Share";
import {loadForm} from "../../redux/formsReducer";

class HighlightActions extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false, alltags: this.props.clip.json.field_user_tags || [], showTagForms: false};
        this.handleUserTag = this.handleUserTag.bind(this)
        this.toggleTagger = this.toggleTagger.bind(this);
    }

    openMenu(e) {
        this.setState({expanded: true, anchorEl: e.currentTarget});
    }

    closeMenu(e) {
        this.setState({expanded: false});
    }


    handleUserTag(index, user_tag) {
        console.log("ADD TAG TO CLIP ", user_tag);
        if (this.state.savingChanges === true) return false;
        this.setState({savingChanges: true});

        const tags = [...this.state.alltags];
        tags[index] = user_tag;

        let {editLink, entityLink} = this.props;
        if (!editLink) editLink = entityLink;

        let apiurl = `/forms/${editLink}/edit`
        API.Put(apiurl, {field_user_tags:tags}).then((res) => {
            if (res.data.success) {
                this.props.enqueueSnackbar(res.data.success, {variant: 'warning'});
                window.logUse.logEvent('clip_edit', this.tdata);
            } else {
                this.props.enqueueSnackbar(res.data.error || 'Unknown error', {variant: 'error'});
            }
            this.setState({promptSave: false, savingChanges: false});
        }).catch((err) => {
            window.logUse.logEvent('clip_edit_failed', this.tdata);
            var msg = API.getErrorMsg(err);
            this.setState({savingChanges: false});
            this.props.enqueueSnackbar(msg, {variant: 'error'});
        });
    }

    promptDelete(apiurl) {
        if (window.confirm('Are you sure you want remove this highlight from the reel. Any existing votes will be lost')) {
            API.Delete(apiurl)
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        this.props.enqueueSnackbar(msg, {variant: 'error'});
                    } else {
                        this.props.enqueueSnackbar(res.data.success, {variant: 'info'});
                        this.props.history.go(0)
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.enqueueSnackbar(msg, {variant: 'error'});
                    return err;
                });
        }
    }

    notice(variant) {
        return {
            variant: 'variant', persist: true, action: key => (
                <IconButton variant="outline-light"
                            onClick={() => {
                                this.props.closeSnackbar(key);
                            }}
                >
                    <Close/>
                </IconButton>
            )
        }
    }

    toggleTagger() {
        this.setState({showTagForms: !this.state.showTagForms});
    }

    render() {
        const {classes} = this.props;
        let {editLink, entityLink} = this.props;
        if (!editLink) editLink = entityLink;
        /// /group/{group}/rewards/{product}/footage/{node}/clips/{group_content}/edit
        editLink = editLink.replace('highlights', 'clips')
        const {clip} = this.props;
        const inPage = [];
        const inContext = [];


        if (isEnabled('tracks:rating', true)) {
            if (this.props.ctas['rate'] === true) {
                inPage.push(<RatingStar color={this.props.color} tjson={clip} key='ratebtn'/>);
            } else {
                inContext.push(<Grid item xs={12} key='ratebtn' className={classes.actionLine}>
                    <RatingStar color={this.props.color} tjson={clip}/></Grid>);
            }
        }


        inPage.push(<Grid item key={`usertagbtn${editLink}`} ><Button onClick={this.toggleTagger} variant={'outlined'}
                                                                      color={'secondary'} size={'small'}>Tag  Athletes</Button></Grid>);


        if (clip.get('field_user_tags', 'target_id')) {
            inPage.push(<AvatarGroup max={9}>
                {this.props.clip.json.field_user_tags.map(o => <UserAvatar key={o.target_id} data={o}/>)}
            </AvatarGroup>)
        }



        inContext.push(<Grid item xs={12} key='share highlight'><Button
            size={'small'}
            startIcon={<ShareIcon />}
            onClick={e => this.props.dispatch(shareUrl(entityLink))}>Share</Button>
        </Grid>);



        if (clip.can_edit(this.props.me) === true) {
            inContext.push(<Grid item xs={12} key='edit highlight'><Button size={'small'}
                                                                             startIcon={<Edit color={this.props.color}/>}
                                                                             onClick={() => this.props.dispatch(loadForm(`/forms${editLink}/edit`, 'dialog'))}
                                                                             className={classes.actionLine}>
                Edit
            </Button></Grid>);
        }

        if (clip.can_delete(this.props.me) === true) {
            inContext.push(<Grid item xs={12} key='delete highlight'><Button size={'small'}
                                                                startIcon={<DeleteIcon color={this.props.color}/>}
                                                                onClick={() => this.promptDelete(`/forms${editLink}/delete`)}
                                                                className={classes.actionLine}>
                Delete
            </Button></Grid>);
        }

        return (
            <Grid container justifyContent={inPage.length === 1 ? 'space-between' : 'space-around'} alignItems='center'>
                {inPage.length === 1 ? <div className={classes.primeCta}>{inPage}</div> : inPage}
                <IconButton size='small' aria-haspopup="true" aria-label={`Clip Menu ${clip.get('id', 'value')}`}
                            onClick={e => this.openMenu(e)}>
                    <MoreVert size='small'/>
                </IconButton>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={e => this.closeMenu(e)}
                    open={this.state.expanded}>
                    <Grid container direction={'column'} spacing={2} style={{padding:5, gap:4}}>
                        {inContext}
                    </Grid>
                </Popover>
                {this.state.showTagForms &&
                    <React.Fragment>
                        {this.state.alltags.map((tag, i) => <Grid key={`tagform${editLink}-${i}`} item xs={12} style={{margin:'25px auto'}}>
                            <UserTagForm clip={clip}
                                         user_tag={tag}
                                         entityLink={editLink}
                                         onSubmit={(payload) => this.handleUserTag(i, payload)} />
                        </Grid>)}
                        <Grid item xs={12} >
                        <UserTagForm clip={clip}
                                     user_tag={null}
                                     entityLink={editLink}
                                     onSubmit={(payload) => this.handleUserTag(this.state.alltags.length, payload)} />
                        </Grid>
                    </React.Fragment>

                }
            </Grid>
        );

    }
}

HighlightActions.defaultProps = {
    color: 'primary',
    ctas: {
        'rate': true,
        'info': false,
        'edit': false,
        'tag': true,
        'delete': false,
        'upgrade': true,
        'share': true
    }
};

HighlightActions.propTypes = {
    color: PropTypes.string,
    ctas: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    me: PropTypes.any,
    editLink: PropTypes.string,
    entityLink: PropTypes.string.isRequired,
};

const styles = theme => ({
    actionLine: {
        display: 'flex',
        alignItems: 'center'
    },
    actionItem: {
        marginBottom: 15
    }
});


const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    newState.forms = state.forms;
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(withRouter(withSnackbar(HighlightActions))));
