import React, {Component} from 'react';
import Grow from '@material-ui/core/Grow';
import {withStyles} from "@material-ui/core/styles";

class GrowControls extends Component {

 render() {
  const grower = { transformOrigin: '100% 0 0' };
  grower.display = (this.props.open === true) ? 'block' : 'none';
  return (
   <Grow
    className='bubbleArrow'
    in={this.props.open}
    style={grower}
    {...(this.props.open ? { timeout: 1000 } : {})}
   >
    <div>
     {this.props.open === true ? this.props.children : false}
    </div>
   </Grow>
  );
 }
}

const styles = {
 arrowBG : {
  '&::before' : {
   content: '',
   position: 'absolute',
   top: 0,
   left:'auto',
   right: 40,
   width: 0,
   height: 0,
   border: '10px solid transparent',
   borderRightColor: '#FF0000',
   borderBottomColor: '#FF0000'
  }
 }
};

export default withStyles(styles)(GrowControls);
