import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import {Link} from "react-router-dom";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {findAndPlay} from "../../redux/playerReducer";
import PersonPin from "@material-ui/icons/PersonPin";
import {loadForm} from "../../redux/formsReducer";

class AddedPickupMatchToCart extends Component {

    loadForm(e, url) {
        e.preventDefault();
        this.props.onContinue();
        this.props.dispatch(loadForm(url, 'drawer'));
    }

    listenToPlaylist(e, gid, pid) {
        e.preventDefault();
        this.props.onContinue();
        this.props.dispatch(findAndPlay(null, pid, gid, null));
    }

    render() {
        const {classes, cartItem} = this.props;

        const buttonProps = {fullWidth: true, variant: 'outlined', color: 'secondary', className:classes.wideButton, style: {marginBottom: 10}}
        let gid = cartItem.get('stores', 'gid');
        const pid = cartItem.get('field_playlist_gc', 'target_id');
        const els = [<Button {...buttonProps} onClick={this.props.onContinue}>Continue Browsing</Button>];

        if (this.props.me.profile && !this.props.me.profile.field_cover) {
            els.push(<Button {...buttonProps} component={Link}
                             to={`/forms/users/${this.props.me.profile.uid[0].value}/group/${gid}/edit`}
                             key={'promptprofilepic'} startIcon={<PersonPin/>}>Add a profile pic</Button>)
        }
        if (!pid) {
            els.push(<Button {...buttonProps} startIcon={<PlaylistAdd/>}
                             component={Link} to={`/forms/group/${gid}/playlists/add`}
                             key={'promptcreateplaylist'}>Start game playlist</Button>)
        } else {
            gid = cartItem.get('field_playlist_gc', 'gid');
            els.push(<Button key={'promptaddtoplaylist'}
                             component={Link} to={`/forms/group/${gid}/playlists/${pid}/tracks/add`}
                             onClick={e => this.loadForm(e, `/forms/group/${gid}/playlists/${pid}/tracks/add`)}
                             {...buttonProps} startIcon={<PlaylistAdd/>}>Add songs to the game playlist</Button>)
            // link to playlist if in same group!
            els.push(<Button key={'promptlistentoplaylist'}
                             component={Link} to={`/group/${gid}/playlists/${pid}?autoplay=true#playlist`}
                             {...buttonProps} startIcon={<PlayIcon/>}
                             onClick={e => this.listenToPlaylist(e, gid, pid)}>Listen to game
                playlist</Button>)

        }

        return <Dialog onClose={this.props.onContinue} open={true}>
            <div style={{padding: '2%', maxWidth: 500, margin: 'auto'}}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant={'caption'}>Added to Cart</Typography>
                        <Typography variant={'subtitle1'}>{cartItem.getTitle()}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={this.props.onContinue} size='small'
                                    aria-label={'Close Dialog'}><CloseIcon/></IconButton>
                    </Grid>
                </Grid>

                <div style={{padding: 16}}>

                    <Button fullWidth={true} onClick={this.props.onCheckout}
                            className={classes.twoLineButton}
                            color='secondary' variant='contained' size={"large"}>
                                    <span>
                                        <strong>Checkout Now</strong>
                                        <span className={'caption'}>to confirm your spot</span>
                                    </span>
                    </Button>

                    <Divider style={{margin: '4px 0 8px 0'}}/>
                    <Typography variant={'h6'} style={{textAlign: 'center', marginBottom: 10}}>or</Typography>


                    {els}
                </div>
            </div>
        </Dialog>
    }
}


AddedPickupMatchToCart.defaultProps = {
    countType: 'avg'
}

AddedPickupMatchToCart.propTypes = {
    classes: PropTypes.object.isRequired,
    cartItem: PropTypes.object.isRequired,
    onCheckout: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
};


export default AddedPickupMatchToCart;
