import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {pStyles} from "../../Util/ThemeUtils";
import FormattedText from "../FormattedText/FormattedText";
import MediaSlider from "../MediaSlider/MediaSlider";
import {Link, withRouter} from "react-router-dom";
import UserAvatar from "../UserAvatar";
import Grid from "@material-ui/core/Grid";
import PurchaseWithContractAction from "../../core/checkout/PurchaseWithContractAction";
import VariationOrders from "../../core/rewards/VariationOrders";
import Grammer from "../../Util/Grammer";
import RatingCategories from "../RatingItem/RatingCategories";
import PlaylistCardHeader from "../PlaylistActions/PlaylistCardHeader";
import Button from "@material-ui/core/Button";
import PickupMatchHeader from "./PickupMatchHeader";

class PickupMatchDetail extends React.Component {

    constructor(p) {
        super(p);
        this.state = {cacheBust : new Date().getTime()}
    }

    getAuthorLink(cartItem) {
        let authorLink = "/users/" + cartItem.get('uid', 'target_id');
        if (this.props.curGroup > 0) {
            authorLink += '/group/' + this.props.curGroup;
        }
        return <CardHeader
            title={Grammer.g('Authors', 's')}
            subheader={<Link to={authorLink}>{cartItem.get('uid', 'target_label')}</Link>}
            avatar={<Link to={authorLink}><UserAvatar data={cartItem.getField('uid')}/></Link>}
        />
    }

    reRender() {
        this.setState({cacheBust: new Date().getTime()});
    }

    render() {
        const {classes} = this.props;
        const cartItem = new Commerce2Json(this.props.product);
        const linkTo = `/group/${this.props.curGroup}/rewards/${cartItem.get('product_id')}`;
        const vote_types = cartItem.product.get('field_json', 'vote_types');
        let end_date = cartItem.product.getTimestamp('field_date_range', 'end_value');
        end_date = end_date === 0 || end_date > new Date().getTime() ? false : true; // 0 means it has not even been scheduled yet

        const allMedia = cartItem.getAllMedia();
        const authorLink = this.getAuthorLink(cartItem);

        let actions = null;
        if (end_date === false) {
            actions = this.props.product.variations.map((variation, i) => {
                    const orderItem = (variation.target_id === cartItem.id()) ? cartItem : new Commerce2Json(this.props.product, variation.target_id);
                    let btn = <PurchaseWithContractAction dispatch={this.props.dispatch} cartItem={orderItem}
                                                          showOrderCountAs={'badge'} index={i}
                                                          reload={() => this.reRender()}/>;

                    return <Grid item xs={6} container={true} key={'event_ticket' + variation['target_id']}
                                 flexDirection={'column'} spacing={2} justifyContent={'center'}
                                 alignItems={'center'} alignContent={'center'}
                                 style={{textAlign: 'center'}}>
                        <Grid item xs={12}>
                            {btn}
                        </Grid>
                        <VariationOrders
                            dispatch={this.props.dispatch}
                            cacheBust={this.state.cacheBust}
                            onDelete={() => this.reRender()} me={this.props.me} cartItem={orderItem}
                            curGroup={this.props.curGroup} gjson/>
                    </Grid>
                })
        } else if (this.props.product.stats) {
            actions = []
            if (this.props.product.stats.footage > 0) {
                actions.push(<Button key={`${linkTo}footagelink`} component={Link} to={`${linkTo}/footage`} endIcon={this.props.product.stats.footage}>View Footage</Button>)
            }
            if (this.props.product.stats.clips > 0) {
                actions.push(<Button key={`${linkTo}cliplinks`} component={Link} to={`${linkTo}/clips`} endIcon={this.props.product.stats.clips}>View Clips</Button>)
            }
        }

        return (
            <Card className={classes.card}>

                <PickupMatchHeader product={this.props.product} />

                {allMedia.length > 0 && <MediaSlider linkTo={linkTo} images={allMedia}/>}

                <CardContent className={classes.content}>
                    {cartItem.getField('body') &&
                        <FormattedText {...cartItem.getField('body')} displayField={'auto'}/>}
                    {vote_types &&
                        <div style={{margin: '5px auto'}}><RatingCategories vote_types={vote_types}
                                                                            cartItem={cartItem}
                                                                            dispatch={this.props.dispatch}/></div>}
                    {cartItem.get('type', 'target_id') === 'pickup_match' && authorLink}
                </CardContent>

                {actions &&
                    <Grid style={{margin: '20px auto 40px auto', gap: 25}} container alignContent={'center'}
                          alignItems={'flex-start'} justifyContent={'center'}>
                        {actions}
                    </Grid>
                }

                <PlaylistCardHeader key={`backgroundmusic${cartItem.product.get('field_playlist_music', 'target_id')}`} productj={cartItem.product} dispatch={this.props.dispatch} />

            </Card>
        );
    }
}

PickupMatchDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles, {withTheme: true})(withRouter(PickupMatchDetail));
