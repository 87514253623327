import React, {Component} from 'react';
import UserAvatar from "./index";
import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

export const StyledBadge = withStyles((theme) => ({
    badge: {
        fontSize: '70%',
        right:'50%',
        width:'100%',
        backgroundColor:theme.palette.secondary.dark
    }
}), {withTheme:true})(Badge);

class AvatarBadge extends Component {

    render() {

        let avatar = <UserAvatar {...this.props} />;
        if (!this.props.badgeContent) return avatar;

        return (<StyledBadge
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            color={'secondary'}
            badgeContent={this.props.badgeContent} >
            {avatar}
        </StyledBadge>)
    }
}

AvatarBadge.defaultProps = {
    badgeContent: null
};

AvatarBadge.propTypes = {
    data: PropTypes.object.isRequired,
    badgeContent:PropTypes.string
};

export default AvatarBadge;
