import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Config from '../../Config';
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

class Homepage extends Component {

 render() {
  return (
   <Grid
    container
    spacing={0}
    style={{padding:8}}
    direction='column'
    justifyContent='space-evenly'
    alignContent='space-between'
    alignItems='center'
   >
    <div style={{width:'100%'}}>
     <Typography variant='h1'>{Config.name}</Typography>
     <Typography variant='h3'>Song Dedications and Shout-Outs</Typography>
    </div>
    <div style={{width:'100%'}}>
     <Link to={'/group/1/playlists'} style={{textDecoration:'none'}} >
      <Button color='primary' variant='contained' style={{margin:5}}>Dedicate a Song</Button>
     </Link>
     <Link to={'/group/1/playlists'} style={{textDecoration:'none'}} >
      <Button color='primary' variant='contained' style={{margin:5}}>Find My Dedication</Button>
     </Link>
    </div>
   </Grid>
  );
 }
}

export default Homepage;
