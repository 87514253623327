import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Drupal2Json from '../../Util/Drupal2Json';
import {withRouter} from 'react-router';
import CommentButton from '../CommentButton';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import MoreVert from '@material-ui/icons/MoreVert';
import {shareUrl} from '../../redux/helpReducer';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Grammer from '../../Util/Grammer';
import Copier from "../Copier";
import {loadForm} from "../../redux/formsReducer";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {isEnabled} from "../../Util/EnabledFeature";
import RatingStarPlaylist from "../RatingStarPlaylist/RatingStarPlaylist";
import Button from "@material-ui/core/Button";
import {findAndPlay} from "../../redux/playerReducer";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import BetButton from "../BetButton";

class PlaylistActions extends Component {
    constructor(props) {
        super(props);
        this.state = {expanded: false};
    }

    openMenu(e) {
        this.setState({expanded: true, anchorEl: e.currentTarget});
    }

    closeMenu(e) {
        this.setState({expanded: false});
    }

    render() {
        const {classes} = this.props;
        const json = new Drupal2Json(this.props.playlistgc);
        const pUrl = `/group/${json.get('gid', 'target_id')}/playlists/${json.get('id', 'value')}`;

        const inContext = [];
        const inPage = [];

        const canEdit = json.can_edit(this.props.me) === true;

        if (json.get('field_listening_starts') !== null && json.getTimestamp('field_listening_starts') < new Date().getTime()) {
            inContext.push(<ListItem alignItems="center" key={`playbtn-ctx-${pUrl}`} className={classes.actionLine}
                                     style={{cursor: 'pointer'}}
                                     onClick={(e) => this.props.dispatch(findAndPlay(false, json.get('id'), json.get('gid', 'target_id')))}>
                <IconButton>
                    <PlayIcon color={this.props.color}/>
                </IconButton>
                <ListItemText primary={Grammer.g('Listen', 's')}/>
            </ListItem>);
            if (this.props.ctas['play'] === true) {
                const atts = {
                    key: `playbtn-${pUrl}`,
                    onClick: () => this.props.dispatch(findAndPlay(false, json.get('id'), json.get('gid', 'target_id'))),
                    'aria-label': "Listen",
                    size: 'small',
                    color: this.props.color
                }
                if (this.props.location.pathname !== pUrl) {
                    inPage.push(<IconButton {...atts} >
                        <PlayIcon/>
                    </IconButton>)
                } else {
                    inPage.push(<Button
                        {...atts}
                        variant="outlined"
                        startIcon={<PlayIcon/>}>
                        {Grammer.g('Listen', 's')}
                    </Button>)
                }
            }
        }

        if (canEdit || isEnabled('tracks:create', true)) {

            inContext.push(
                <ListItem alignItems="center" key={`addbtn-ctx-${pUrl}`} className={classes.actionLine}
                          style={{cursor: 'pointer'}}
                          component={Link}
                          to={"/forms" + pUrl + '/tracks/add'}>
                    <IconButton>
                        <PlaylistAdd color={this.props.color}/>
                    </IconButton>
                    <ListItemText primary={`Add a ${Grammer.g('tracks', 's')}`}/>
                </ListItem>
            );

            if (isEnabled('tracks:create', true)) {
                inPage.push(<IconButton
                    component={Link}
                    to={"/forms" + pUrl + '/tracks/add'}
                    aria-label={`Add a Track`}
                    key={`addbtn-${pUrl}`}
                    variant="outlined"
                    color="secondary">
                    <PlaylistAdd/>
                </IconButton>)
            }
        }

        if (isEnabled('playlists:cta', 'upgrade')) {
            inContext.push(<BetButton showlabel={true} color={this.props.color} json={json}
                                      profile={this.props.me.profile}
                                      dispatch={this.props.dispatch} key={`betbutton-ctx-${pUrl}`}
                                      className={classes.actionLine}/>);

            // if (this.props.ctas['upgrade'] === true) {
            inPage.push(<BetButton color={this.props.color} json={json} profile={this.props.me.profile}
                                   dispatch={this.props.dispatch} key={`betbutton-ctx-${pUrl}`}/>);
            // }
        }

        if (isEnabled('playlists:rating', true)) {
            // console.warn(" PLAYLISTACTION: " + this.props.location.pathname); // Cypress is getting the wrong location here
            inContext.push(
                <ListItem alignItems="center" key={`ratebtn-ctx-${pUrl}`} className={classes.actionLine}>
                    <RatingStarPlaylist showSlider={this.state.showSlider} color={this.props.color} pjson={json}
                                        displayAs={'star'}/>
                    <ListItemText onClick={e => this.setState({showSlider: !this.state.showSlider})}
                                  primary={'Vote!'}/></ListItem>);

            if (this.props.ctas['rate'] === true) {
                const displayAs = json.get("gid", "target_id") === 54 ? 'slider' : 'star';
                inPage.push(<RatingStarPlaylist displayAs={displayAs} showSlider={false}
                                                color={this.props.color} pjson={json} key={`ratebtn-page-${pUrl}`}/>);
            }
        }

        inContext.push(<ListItem alignItems="center" className={classes.actionLine} key={`sharebtn-ctx-${pUrl}`}
                                 aria-label={`Share Playlist ${json.get('id', 'value')}`}
                                 onClick={e => this.props.dispatch(shareUrl(pUrl))}>
            <IconButton>
                <ShareIcon color={this.props.color}/>
            </IconButton>
            <ListItemText primary='Share'/>
        </ListItem>);
        if (this.props.ctas['share'] === true) {
            inPage.push(<IconButton key={`sharebtn-${pUrl}`} aria-label={`Share Playlist ${json.get('id', 'value')}`}
                                    onClick={e => this.props.dispatch(shareUrl(pUrl))}>
                <ShareIcon color={this.props.color}/>
            </IconButton>);
        }

        if (isEnabled('playlists:field_comments', true)) {
            inContext.push(<ListItem alignItems="center" key={`commentbtn-ctx-${pUrl}`} className={classes.actionLine}>
                <CommentButton profile={this.props.profile} showComments={this.state.showComments}
                               color={this.props.color} json={json} dispatch={this.props.dispatch}/>
                <ListItemText onClick={e => this.setState({showComments: !this.state.showComments})}
                              primary={'Comments'}/></ListItem>);

            if (this.props.ctas['comments'] === true) {
                inPage.push(<CommentButton profile={this.props.profile} color={this.props.color} json={json}
                                           key={`commentbtn-${pUrl}`} dispatch={this.props.dispatch}/>);
            }
        }

        if (canEdit || isEnabled('playlists:create', true)) {
            // if (json.get('gid', 'parent') > 0) {
            inContext.push(<ListItem alignItems="center" key={`copybtn-ctx-${pUrl}`} className={classes.actionLine}
                                     style={{cursor: 'pointer'}}>
                <Copier source={json}/>
                <ListItemText primary={'Copy Playlist'}/>
            </ListItem>);
            // }
        }

        if (this.props.location.pathname !== pUrl) {
            inContext.push(<ListItem alignItems="center" key={`metabtn-ctx-${pUrl}`} className={classes.actionLine}>
                <IconButton>
                    <Link to={pUrl}>
                        <InfoIcon color={this.props.color}/>
                    </Link>
                </IconButton>
                <ListItemText primary={<Link style={{textDecoration: 'none'}}
                                             to={pUrl}>{Grammer.g('Playlists', 's')} Homepage</Link>}/></ListItem>);

            if (this.props.ctas['info'] === true) {
                inPage.push(<Link key={`metabtn-${pUrl}`} to={pUrl}>
                    <IconButton aria-label="Playlist Homepage">
                        <InfoIcon color={this.props.color}/>
                    </IconButton></Link>);
            }
        }

        if (canEdit) {
            inContext.push(<ListItem alignItems="center" key={`editbtn-ctx-${pUrl}`} className={classes.actionLine}
                                     style={{cursor: 'pointer'}}
                                     href={"/forms" + pUrl + '/edit'}
                                     onClick={e => this.props.dispatch(loadForm("/forms" + pUrl + '/edit', 'drawer'))}>
                <IconButton>
                    <EditIcon color={this.props.color}/>
                </IconButton>
                <ListItemText primary={'Edit Playlist'}/>
            </ListItem>);
            if (this.props.ctas['edit'] === true) {
                inPage.push(<IconButton key={`editbtn-${pUrl}`}
                                        href={"/forms" + pUrl + '/edit'}
                                        onClick={e => this.props.dispatch(loadForm("/forms" + pUrl + '/edit', 'drawer'))}>
                    <EditIcon color={this.props.color}/>
                </IconButton>);
            }
        }

        return (
            <Grid container justifyContent={inPage.length === 1 ? 'space-between' : 'space-around'} alignItems='center'
                  wrap='nowrap' style={{gap: '3%'}}>
                {inPage}
                <IconButton size='small' aria-haspopup="true" aria-label={`Playlist Menu ${json.get('id', 'value')}`}
                            onClick={e => this.openMenu(e)}>
                    <MoreVert size='small'/>
                </IconButton>
                <Popover
                    anchorEl={this.state.anchorEl}
                    onClose={e => this.closeMenu(e)}
                    open={this.state.expanded}>
                    <Card>
                        <CardContent>
                            <List dense={true}>
                                {inContext}
                            </List>
                        </CardContent>
                    </Card>
                </Popover>
            </Grid>
        );
    }
}

PlaylistActions.defaultProps = {
    color: 'primary',
    ctas: {
        'rate': false,
        'info': false,
        'upgrade': false,
        'edit': false,
        'add': false,
        'play': true,
        'copy': false,
        'comments': false,
        'delete': false,
        'share': false
    }
};

PlaylistActions.propTypes = {
    color: PropTypes.string,
    ctas: PropTypes.object.isRequired,
    playlistgc: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    me: PropTypes.any
};

const styles = theme => ({
    actionLine: {
        display: 'flex',
        alignItems: 'center'
    }
});


export default withStyles(styles, {withTheme: true})(withRouter(PlaylistActions));
