import React, {Component} from 'react';
import {getIdbySegment} from '../../redux/authActions';
import {loadFaq, shareUrl} from '../../redux/helpReducer';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from "@material-ui/icons/Edit";
import MoreVert from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import InfoIcon from "@material-ui/icons/Info";
import API from "../../Util/API";
import {Link, withRouter} from 'react-router-dom';
import {_showNotice} from "../../redux/formsReducer";
import DeleteIcon from "@material-ui/icons/Delete";
import Grammer from "../../Util/Grammer";
import TamIcon from "../TamIcon/TamIcon";
import ImageIcon from "@material-ui/icons/Camera";

class ProductActions extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false};
    }

    openMenu(e) {
        this.setState({expanded: true, anchorEl: e.currentTarget});
    }

    closeMenu(e) {
        this.setState({expanded: false});
    }

    promptDelete(apiurl) {
        if (window.confirm('Are you sure you want to delete your product?')) {
            API.Delete(apiurl)
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        if (res.data.success) this.props.dispatch(_showNotice(msg, 'error'));
                    } else {
                        if (res.data.success) this.props.dispatch(_showNotice(res.data.success));
                        this.props.history.go(0)
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.dispatch(_showNotice(msg, 'error'));
                    return err;
                });
        }
    }

    handleFaq(evt, nid) {
        evt.preventDefault();
        this.props.dispatch(loadFaq(nid, 'dialog'));
        return false;
    }

    render() {
        const {gid, me, cartItem} = this.props;
        const product_id = cartItem.get('id', 'value');
        const tdata = getIdbySegment(document.location.pathname);
        const baseUrl = `/group/${gid}/rewards/${cartItem.get('product_id')}`;

        // share (public)
        // view details (if Block)
        // edit (if Admin)
        // View All Orders (if PickupMVP)
        // View Group Store (if TAM Marketplace === window.REACT_APP_GID === 0 && !tdata.gid)
        const noun = Grammer.g('Rewards', 's');

        const inContext = [];
        inContext.push(<ListItem style={{cursor:'pointer'}} onClick={e => this.props.dispatch(shareUrl(baseUrl))} key='share product button' >
            <ListItemAvatar><ShareIcon/></ListItemAvatar>
            <ListItemText primary={'Share'}/></ListItem>);


        if (document.location.pathname !== baseUrl) {
            inContext.push(<ListItem component={Link} to={baseUrl}  key='view product store' >
                <ListItemAvatar><InfoIcon color={this.props.color}/></ListItemAvatar>
                <ListItemText primary={`${noun} Details`} />
            </ListItem>)
        }

        if (cartItem.product.json.stats) {
            if (cartItem.product.json.stats.clips > 0) {
                inContext.push(<ListItem component={Link} to={`/group/${gid}/replays/${cartItem.get('field_playlist_gc', 'target_id')}`} key='View Highlights' >
                    <ListItemAvatar><TamIcon  noavatar={true} iconName={'miniGoldCD'} color={this.props.color}/></ListItemAvatar>
                    <ListItemText primary={`View Highlights`} />
                </ListItem>)
            }
            if (cartItem.product.json.stats.footage > 0) {
                inContext.push(<ListItem component={Link} to={`${baseUrl}/footage`} key='View Footage' >
                    <ListItemAvatar><ImageIcon noavatar={true} iconName={'playlists'} color={this.props.color}/></ListItemAvatar>
                    <ListItemText primary={`View Footage`} />
                </ListItem>)
            }
        }

        inContext.push(<ListItem component={Link} to={`/forms${baseUrl}/footage/add`} key='Upload Footage' >
            <ListItemAvatar><ImageIcon noavatar={true} iconName={'playlists'} color={this.props.color}/></ListItemAvatar>
            <ListItemText primary={(document.location.search.indexOf("date=ended") > -1) ? `Upload Footage` : `Stream Footage`} />
        </ListItem>)

        if (cartItem.product.get('type', 'target_id') === 'pickup_match' && this.props.vote_types) {
            inContext.push(<ListItem  key='Rules & Rewards'
                                      style={{cursor:'pointer'}}
                                      href='/faqs/102679' onClick={e => this.handleFaq(e, 102679)} >
                <ListItemAvatar><TamIcon noavatar={true} iconName={'helpIcon'} color={this.props.color}/></ListItemAvatar>
                <ListItemText primary={`Rules & Rewards`} />
            </ListItem>)
        }

        if (cartItem.product.can_edit(me) === true) {
            inContext.push(<ListItem component={Link} to={`/forms${baseUrl}/edit`}  key='edit product' >
                <ListItemAvatar><EditIcon color={this.props.color}/></ListItemAvatar>
                <ListItemText primary={`Edit ${noun}`} />
            </ListItem>)
        }

        if (cartItem.product.can_delete(me) === true) {
            inContext.push(<ListItem style={{cursor:'pointer'}} onClick={() => this.promptDelete(`/forms${baseUrl}/delete`)}  key='delete product' >
                <ListItemAvatar><DeleteIcon color={this.props.color}/></ListItemAvatar>
                <ListItemText primary={`Delete ${noun}`} />
            </ListItem>)
        }


        return <React.Fragment>
            <IconButton size='small' aria-haspopup="true" aria-label={`Product Menu ${product_id}`} onClick={e => this.openMenu(e)}>
                <MoreVert size='small' />
            </IconButton>
            <Popover
                anchorEl={this.state.anchorEl}
                onClose={e => this.closeMenu(e)}
                open={this.state.expanded}>
                <List dense={true}>
                    {inContext}
                </List>
            </Popover>
        </React.Fragment>
    }
}

export default withRouter(ProductActions);
