import Drupal2Json from "./Drupal2Json";

class Commerce2Json {

    // product:Drupal2Json;
    // variation:Drupal2Json;

    constructor (product, variation_id, count) {
        this.product = new Drupal2Json(product);
        const variation = (!variation_id) ? product.variations[0] : this.getVariationById(variation_id);
        this.variation = new Drupal2Json(variation || {});
        this.count = count || 1;
    }

    getVariationById(id) {
        let has = this.product.json.variations.find(o => o.target_id === id)
        if (has) return has;
        return null;
    }

    getProductId() {
        return this.product.get('product_id', 'value');
    }

    id() {
        return this.variation.get('variation_id', 'value') || this.variation.get('target_id'); // unique
    }

    getVariationIds() {
        return this.product.json.variations.map(v => v.target_id);
    }

    countVariations() {
        return this.product.json.variations.length
    }

    getVariationTitle() {
        return this.variation.get('title', 'value', 0) || this.variation.get('target_label') ;
    }

    getTitle() {
        let val = this.product.get('title', 'value', 0);
        let suffix = this.variation.get('title', 'value', 0);
        if (!suffix) suffix = this.variation.json.target_label;
        if (suffix && val !== suffix) {
            val += ' - ' + suffix;
        }
        return val;
    }

    getMyUrl() {
        return `/group/${this.product.get('stores', 'gid')}/rewards/${this.product.get("product_id")}/variations/${this.id()}`;
    }

    get (field, prop, delta) {
        let val = this.variation.get(field, prop, delta);
        if (!val) val = this.product.get(field, prop, delta);
        return val;
    }

    getField(field) {
        return this.product.json[field] && this.product.json[field].length > 0 ? this.product.json[field][0] : null;
    }

    getAddress(fmt) {
        const props = ['given_name',  'additional_name' ,'address_line1', 'address_line2', 'locality', 'dependent_locality', 'administrative_area', 'postal_code', 'country_code'];
        let name = [];
        for (let i = 0; i < props.length; i++) {
            let part = this.get("field_address", props[i]);
            if (part) {
                if (fmt === 'short') return part;
                else name.push(part);
            }
        }
        return name.join(' ');
    }

    getTimestamp (field, prop, delta) {
        let val = this.variation.getTimestamp(field, prop, delta);
        if (!val) val = this.product.getTimestamp(field, prop, delta);
        return val;
    }

    getTime(field, format, prop, timezone) {
        let val = this.variation.getTime(field, format, prop, timezone);
        if (!val) val = this.product.getTime(field, format, prop, timezone);
        return val;
    }

    findPropByVal(field, value, prop) {
        let val = this.variation.findPropByVal(field, value, prop);
        if (!val) val = this.product.findPropByVal(field, value, prop);
        return val;
    }

    getAvatarImg() {
        if (this.product.json.field_images && this.product.json.field_images.length > 0) {
            return this.product.json.field_images[0].url
        }
        if (this.product.json.field_remote_images && this.product.json.field_remote_images.length > 0) {
            return this.product.json.field_remote_images[0].url
        }
        if (this.variation.json.target_bundle === 'pickup_match')  {
            let url = this.product.get('field_sport', 'url')
            if (!url) url = '/assets/pmvp-futbol.png';
            return url;
        }
    }

    getAllMedia() {
        let allMedia = this.product.json.field_images;
        if (!allMedia) allMedia = [];
        if (this.product.json.field_media && this.product.json.field_media.length > 0) {
            allMedia = allMedia.concat(this.product.json.field_media);
        }
        if (this.product.json.field_remote_images && this.product.json.field_remote_images.length > 0) {
            allMedia = allMedia.concat(this.product.json.field_remote_images);
        }
        if (this.product.json.field_address && this.product.json.field_address.length > 0 && this.product.json.field_address[0].family_name) {
            allMedia = allMedia.concat(this.product.json.field_address[0].family_name);
        }
        return allMedia
    }

    getRevenueTotal() {
        if (this.product.json.variations.length === 0) return 0;
        let total = 0;
        for (var t = 0; t < this.product.json.variations.length; t++) {
            // code = this.product.json.variations[t].price[0]['currency_code'];
            let price = parseFloat(this.product.json.variations[t].price[0]['number'], 2);
            let stock = 1;
            if (this.product.json.variations[t].field_stock) {
                stock = parseFloat(this.product.json.variations[t].field_stock[0]['value'], 2);
            }
            total += price * stock
        }
        return total;
    }

    getPriceRange() {
        if (this.product.json.variations.length === 0) return 0;
        var lowestPrice = Infinity, highestPrice = 0, code = 'USD';
        for (var t = 0; t < this.product.json.variations.length; t++) {
            code = this.product.json.variations[t].price[0]['currency_code'];
            let price = parseFloat(this.product.json.variations[t].price[0]['number'], 2);
            lowestPrice = Math.min(price, lowestPrice);
            highestPrice = Math.max(price, highestPrice);
        }
        if (highestPrice != lowestPrice) return `$${lowestPrice} - $${highestPrice} ${code}`;
        return `$${lowestPrice} ${code}`;
    }

    getSymbol() {
        const code = this.variation.get('price', 'currency_code')
        if (code == 'MXN') return '$';
        if (code == 'TAC') return '₮';
        else return '$';
    }

    getPrice(exchange) {
        return parseFloat(this.variation.get('price', 'number'), 2);
    }

    getFullPrice() {
        return this.getPrice() * this.count;
    }

    toJson() {
        return { product: this.product.json, variation: this.variation.json, count: this.count }
    }


}

export default Commerce2Json;
