import React from 'react';
import {Link} from 'react-router-dom';
import {loadFaq} from '../../redux/helpReducer';

import Drupal2Json from '../../Util/Drupal2Json';
import Grammer from '../../Util/Grammer';
import EntityList from '../../Components/EntityList';
import TrackList from '../TrackList';
import PlaylistTimeline from '../../Components/PlaylistTimeline';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {pStyles} from '../../Util/ThemeUtils';
import CommentsList from "../../Components/CommentsList";
import SanitizedHTML from "react-sanitized-html";
import CardHeader from "@material-ui/core/CardHeader";
import UserAvatar from "../../Components/UserAvatar";
import Config from "../../Config";
import PlaylistActions from "../../Components/PlaylistActions/PlaylistActions";
import EnabledFeature, {hasEnabled, isEnabled} from "../../Util/EnabledFeature";
import PlaylistPayouts from "../../Components/PlaylistPayouts";

class PlaylistGC extends React.Component {

    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        this.scrollIntoView = this.scrollIntoView.bind(this);
        this.loadFaq = this.loadFaq.bind(this);
    }

    scrollIntoView() {
        if (document.location.hash && document.location.hash.indexOf('playlist') > -1) {
            // this.anchorEl.current.style.marginBottom = '45px';
            window.scrollTo({left: 0, top: this.anchorEl.current.offsetTop, behavior: 'smooth'});
            // this.anchorEl.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
    }

    loadFaq(e, nid) {
        e.preventDefault();
        this.props.dispatch(loadFaq(nid, 'dialog'));
    }

    render() {
        const {classes, playlist} = this.props;
        const json = new Drupal2Json(playlist);

        return (
            <div style={{width: '100%', position: 'relative'}}>
                <Grid container>
                    <Grid item xs={12} style={{padding: 0, textAlign: 'center'}}>
                        <img style={{width: '100%', maxWidth: '800px'}} src={json.get('field_cover', 'url')}
                             alt={json.get('field_cover', 'alt')}/>
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="stretch"
                          className={classes.paperBg}
                          style={{padding: '1%'}}>
                        <Typography variant='h2' style={{
                            margin: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            {json.getLabel()}
                        </Typography>

                        {json.get('field_description', 'value') &&
                        <React.Fragment>
                            {isEnabled('playlists:field_description', 'plaintext')
                                ?
                                json.get('field_description', 'value')
                                :
                                isEnabled('playlists:field_description', 'basicHTML')
                                    ?
                                    <SanitizedHTML allowedTags={Config.allowedTags}
                                                   html={json.get('field_description', 'value')}/>
                                    :
                                    <SanitizedHTML allowedTags={Config.allowedTags}
                                                   html={json.get('field_description', 'value')}/>
                            }
                        </React.Fragment>
                        }

                        <CardHeader
                            avatar={
                                <Link
                                    to={"/users/" + json.get('uid', 'target_id') + '/group/' + json.get('gid', 'target_id')}>
                                    <UserAvatar data={json.json.uid[0]}/>
                                </Link>
                            }
                            title={<Link
                                to={'/users/' + json.get('uid', 'target_id') + '/group/' + json.get('gid', 'target_id')}>{json.get('uid', 'target_label')}</Link>}
                            subheader={<em
                                style={{fontSize: '85%'}}>{json.getTime('created', 'MMMM Do YYYY')} {playlist.changed && playlist.changed - playlist.created > 8600 ? `, updated ${json.getTime('changed', 'MMMM Do YYYY')}` : ''}</em>}
                        />

                        <EnabledFeature feature={'playlists:field_editors'} value={true}>
                            <EntityList entity={playlist} map={{'field_editors': Grammer.g('authors', 's')}}/>
                        </EnabledFeature>

                        <PlaylistActions playlistgc={playlist} ctas={{'rate': true, 'edit': true, 'add':true, 'play':true}}
                                         me={this.props.me}
                                         dispatch={this.props.dispatch} />

                        {this.props.me.profile &&
                        <EnabledFeature value={true} feature={'playlists:field_comments'}>
                            <div style={{marginTop:10}}>
                            <CommentsList json={json} dispatch={this.props.dispatch}/>
                            </div>
                        </EnabledFeature>
                        }

                        {json.get('field_tracks_per_user', 'value') ?
                            <ListItem>
                                <ListItemText
                                    primary={Grammer.g('tracks', 'p') + ' per ' + Grammer.g('members', 's')}
                                    secondary={json.get('field_tracks_per_user', 'value')}/>
                            </ListItem> : ''
                        }

                        <div style={{textAlign: 'center'}}>
                            <PlaylistPayouts json={json} voteTypes={playlist.field_vote_type} classes={classes} />

                            {(json.get('field_judge') === 'curator')
                                ?
                                <Link to={'/faqs/100792'} onClick={e => this.loadFaq(e, 100792)} className={classes.miniText}>How to Play - Curator's Choice</Link>
                                :
                                (json.get('field_judge') === 'closestaverage')
                                ?
                                <Link to={'/faqs/100943'} onClick={e => this.loadFaq(e, 100943)} className={classes.miniText}>How to Play - Closest Average</Link>
                                :
                                (json.get('field_judge') === 'community')
                                ?
                                <Link to={'/faqs/100791'} onClick={e => this.loadFaq(e, 100791)}className={classes.miniText}>How to Play - Community Ratings</Link>
                                :
                                ''
                            }

                            {playlist.field_vote_type.length > 1 ?
                                <Typography variant='subtitle2' style={{textAlign: 'center', marginTop: 10}}>
                                    Rating Scales: {playlist.field_vote_type.map((o) => o.target_label).join(', ')}
                                </Typography> : ''}
                        </div>

                        {hasEnabled('playlists:timeline', 'field_listening_starts') || hasEnabled('playlists:timeline', 'field_rating_starts') || hasEnabled('playlists:timeline', 'field_awarding_starts') &&
                            <PlaylistTimeline json={json}/>
                        }

                        <div ref={this.anchorEl}>&nbsp;</div>

                        <EnabledFeature feature={'playlists:field_leaders'} value={true}>
                            <EntityList entity={playlist} map={{'field_leaders': `Leading ${Grammer.g('members', 's')}`}}/>
                        </EnabledFeature>
                    </Grid>
                </Grid>
                <TrackList perpage={15} showEmpty={true} playlist={playlist} blockType={'pid'}  onLoaded={this.scrollIntoView}/>
            </div>
        );
    }
}

export default withStyles(pStyles)(PlaylistGC);
