import React, {Component} from 'react';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import {withRouter} from 'react-router-dom';
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

class RatingCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false};
    }

    openMenu(e) {
        this.setState({expanded: true, anchorEl: e.currentTarget});
    }

    closeMenu(e) {
        this.setState({expanded: false});
    }

    render() {
        const {cartItem, vote_types} = this.props;
        // const judge = cartItem.product.get('field_json', 'field_judge');

        const rows = [];

        for(let label in vote_types) {
            const pert = vote_types[label];
            rows.push(<Chip
                color={'primary'}
                key={`rcat${label}`}
                style={{margin:'0 8px 8px 0'}}
                avatar={<Avatar>{pert}<sup>%</sup></Avatar>}
                label={label}
            />)
        }

        if (this.props.layout === 'inpage') return <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>{rows}</div>;

        return <React.Fragment>
            <Button fullWidth={true}  startIcon={<WalletIcon color={'primary'} />}  aria-haspopup="true" aria-label={`Reward Categries Menu ${cartItem.get('product_id')}`} onClick={e => this.openMenu(e)}>
                {cartItem.getSymbol()}{cartItem.getRevenueTotal()} Cash Purse
            </Button>
            <Popover
                anchorEl={this.state.anchorEl}
                onClose={e => this.closeMenu(e)}
                open={this.state.expanded}>
                <div style={{padding:'5%'}}>
                <Typography variant={'subtitle2'}>Prizes</Typography>

                <Divider style={{margin:'5px 0'}} />
                {rows}

                </div>

            </Popover>
        </React.Fragment>
    }
}

export default withRouter(RatingCategories);
