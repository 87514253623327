import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {_clearNotes} from '../../redux/formsReducer';
import {transferFailure} from '../../redux/walletReducer';
import {clearHelp} from '../../redux/helpReducer';

import Login from '../Login';
import Dashboard from '../Dashboard';
import StoreView from '../StoreView';
import LegalView from '../LegalView';
import EntityView from '../EntityView';
import ManageFunds from "../ManageFunds";
import FormLoader from '../FormWrapper/FormLoader';
import NewGroupPlans from '../NewGroupPlans/NewGroupPlans';
import NewPlaylist from '../NewPlaylist';
import PlayerBar from '../PlayerBar';
import DialogForm from '../DialogForm';
import DemoVideos from '../../Components/DemoVideos';
import MemberList from '../MemberList';
import EmailsView from '../EmailsView';
import FaqView from '../FaqView';
import DialogView from '../DialogView';
import Youtube from '../Youtube';
import {withSnackbar} from 'notistack';
import SanitizedHTML from 'react-sanitized-html';
import Transactions from '../Transactions';
import FaqItem from '../../Components/FaqItem';
import ShareDialog from '../../Components/ShareDialog';
import {changeApp, getIdbySegment} from "../../redux/authActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MetaBuilder from "../MetaBuilder";
import TamFeaturesPrice from "../../Components/TamFeatures/TamFeaturesPrice";
import Collage from "../../Components/Collage";
import SearchView from "../Search/SearchView";
import StyleGuide from "../StyleGuide/StyleGuide";
import FootageLoader from "../../core/clips/FootageLoader";
import ClipsLoader from "../../core/clips/ClipsLoader";
import HighlightsLoader from "../../core/clips/HighlightsLoader";

const Homepage = require('../../apps/' + process.env.REACT_APP_TACLIENT_DIR + '/Homepage').default;

const formMap = {
    'group_content': {
        'clips': {
            'add': '/group/:gid/rewards/:rid/footage/:nid/clips/add',
            'edit': '/group/:gid/rewards/:rid/footage/:nid/clips/:clip/edit',
            'delete': '/group/:gid/rewards/:rid/footage/:nid/clips/:clip/delete',
        },
        'members': {
            'delete': '/group/:gid/members/:gcid/delete',
            'edit': '/group/:gid/members/:gcid/edit',
            'add': '/group/:gid/members/add',

            'delete-byaccount': '/users/:uid/group/:gid/delete',
            'edit-byaccount': '/users/:uid/group/:gid/edit',
            'add-byaccount': '/users/:uid/group/:gid',
        },
        'playlists': {
            'delete': '/group/:gid/playlists/:gcid/delete',
            'edit': '/group/:gid/playlists/:gcid/edit',
            'add': '/group/:gid/playlists/add',
        },
        'tracks': {
            'delete': '/group/:gid/playlists/:pid/tracks/:gcid/delete',
            'edit': '/group/:gid/playlists/:pid/tracks/:gcid/edit',
            'add': '/group/:gid/playlists/:pid/tracks/add'
        },
    },
    'node': {
        'clip': {
            'add': '/group/:gid/rewards/:rid/footage/add',
            'edit': '/group/:gid/rewards/:rid/footage/:nid/edit',
            'delete': '/group/:gid/rewards/:rid/footage/:nid/delete'
        }
    },
    'group': {
        'groups': {
            'delete': '/group/:gid/delete',
            'edit': '/group/:gid/edit',
            'add': '/group/add'
        }
    },
    'user': {
        'account': {
            'delete': '/users/:uid/delete',
            'edit': '/users/:uid/edit'
            // ,'add':'/users/add'
        }
    },
    'commerce_product': {
        'product': {
            'add': '/group/:gid/rewards/add',
            'delete': '/group/:gid/rewards/:rid/delete',
            'edit': '/group/:gid/rewards/:rid/edit',
        },
    }
};

class Main extends Component {

    componentDidMount() {
        console.log("MAIN MOUNTED "+ document.location.href);
        document.title = this.props.docTitle;
        var segs = this.props.location.pathname.split('/');
        if (segs.length > 1 && segs[1] === 'sharer') {
            if (typeof window['Cypress'] === 'object') {
                console.log("cypress can read the virtual dom: ");
            } else {
                const goto = '/' + segs.slice(2).join('/'); // must be absolute (api) for cors
                document.location.href = goto;
                // return this.props.history.push(goto)
            }
        }
        if (!this.props.me || !this.props.me.profile) {
            if (segs.length > 1) {
                if (segs[1] === 'forms' || segs[1] === 'my-profile' || segs[1] === 'group/start') {
                    this.props.enqueueSnackbar('Sign-In or Sign-Up', {variant: 'info'});
                    this.props.history.push(this.props.curGroup > 0 ? `/group/${this.props.curGroup}/signin` : '/signin');
                }
            }
        }
        this.displayNotices();
    }

    componentWillUnmount() {
        console.log("MAIN UNMOUNTING!?");
    }

    componentDidUpdate(prevProps) {
        this.displayNotices();
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const tdata = getIdbySegment(this.props.location.pathname);
            if (tdata.gid > 0 && tdata.gid !== this.props.curGroup) {
                this.props.dispatch(changeApp(tdata.gid));
            }
        }
    }

    displayNotices() {
        document.title = this.props.docTitle;
        if (this.props.notifications) {
            for (let variant in this.props.notifications) {
                for (let e in this.props.notifications[variant]) {
                    let notice = this.props.notifications[variant][e];
                    let params = {'variant': variant, preventDuplicate: true};
                    if (variant === 'success-persist') params.variant = 'success';
                    if (params.variant === 'warning' || params.variant === 'error' || variant === 'success-persist') {
                        if (params.variant === 'warning') {
                            params.autoHideDuration = 10000;
                        } else {
                            params.persist = true;
                        }
                        params.action = key => (
                            <IconButton style={{float: 'right'}}
                                        onClick={(e) => this.props.closeSnackbar(key)}><CloseIcon/></IconButton>
                        );
                    }
                    if (notice.children) {
                        params.children = notice.children;
                        this.props.enqueueSnackbar('', params);
                    } else {
                        this.props.enqueueSnackbar(<SanitizedHTML style={{}} html={notice}/>, params);
                    }
                }
            }
            if (this.props.forceClear === true) {
                this.props.dispatch(_clearNotes());
                this.props.dispatch(transferFailure(false));
            }
        }
    }

    render() {

        var forms = [];
        for (var entity_type in formMap) {
            for (var bundle in formMap[entity_type]) {
                for (var verb in formMap[entity_type][bundle]) {
                    var path = formMap[entity_type][bundle][verb];
                    forms.push(
                        <Route key={path} path={'/forms' + path} component={FormLoader}/>
                    );
                }
            }
        }

        const classes = {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            width: '100%',
            paddingLeft: 0,
            paddingBottom: 0,
            minHeight: '89vh',
            paddingTop: document.getElementById('appHeader') ? document.getElementById('appHeader').offsetHeight : 64,
        };

        return (
            <main style={classes}>
                <Switch>
                    <Route path='/help' component={FaqView}/>
                    <Route path='/faqs/:nid' component={EntityView}/>
                    <Route path='/terms' component={LegalView}/>
                    <Route path='/demos' component={(props) => <DemoVideos {...props}  />}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/register' component={Login}/>

                    <Route exact path='/collage' component={Collage}/>

                    <Route exact path='/marketplace/:rid' component={EntityView}/>
                    <Route exact path='/marketplace' component={StoreView}/>
                    <Route exact path='/group/start' component={NewGroupPlans}/>

                    <Route exact path='/group/:gid' render={({match}) => {
                        return match.params.gid === process.env.REACT_APP_GID ? <Homepage/> : <EntityView/>;
                    }}/>
                    <Route exact path='/group/:gid/styleguide' component={StyleGuide}/>
                    <Route exact path='/group/:gid/signin' component={Login}/>
                    <Route exact path='/group/:gid/signup' component={Login}/>
                    <Route exact path='/group/:gid/info' component={EntityView}/>
                    <Route exact path='/group/:gid/demos' component={DemoVideos}/>
                    <Route exact path='/group/:gid/features' component={TamFeaturesPrice}/>
                    <Route exact path='/group/:gid/wallet/activity' component={Transactions}/>
                    <Route exact path='/groups' component={Dashboard}/>
                    <Route exact path='/group/:gid/groups' component={Dashboard}/>

                    <Route exact path='/otp/group_invite/:eid/:code/:gid' component={Login}/>
                    <Route exact path='/otp/account_otp/:eid/:code' component={Login}/>
                    <Route exact path='/signin' component={Login}/>
                    <Route exact path='/signup' component={Login}/>
                    <Route exact path='/my-profile' component={EntityView}/>

                    <Route exact path='/wallet/activity' component={Transactions}/>
                    <Route exact path='/wallet' component={ManageFunds}/>
                    <Route exact path='/group/:gid/wallet' component={ManageFunds}/>

                    <Route exact path='/group/:gid/rewards/:rid' component={EntityView}/>
                    <Route exact path='/group/:gid/rewards/:rid/variations/:vid' component={EntityView}/>

                    <Route exact path='/group/:gid/rewards/:rid/footage' component={FootageLoader} />
                    <Route exact path='/group/:gid/rewards/:rid/clips' component={ClipsLoader} />
                    <Route exact path='/group/:gid/rewards/:rid/footage/:fid' component={FootageLoader} />
                    <Route exact path='/group/:gid/rewards/:rid/footage/:fid/clips' component={ClipsLoader} />
                    <Route exact path='/group/:gid/rewards/:rid/footage/:fid/clips/:hid' component={ClipsLoader} />

                    <Route exact path='/group/:gid/rewards/:rid/highlights' component={HighlightsLoader} />
                    <Route exact path='/group/:gid/rewards/:rid/highlights/:hid' component={HighlightsLoader} />
                    <Route exact path='/group/:gid/replays/:pid/highlights' component={HighlightsLoader}/>
                    <Route exact path='/group/:gid/replays/:pid/highlights/:hid' component={HighlightsLoader}/>
                    <Route exact path='/group/:gid/rewards/:rid/footage/:fid/clips/:hid' component={HighlightsLoader} />

                    <Route exact path='/group/:gid/highlights' component={HighlightsLoader} />

                    <Route exact path='/group/:gid/rewards' component={StoreView}/>
                    <Route exact path='/group/:gid/episodes' component={Dashboard}/>
                    <Route exact path='/group/:gid/challenges' component={Dashboard}/>
                    <Route exact path='/group/:gid/compilations' component={Dashboard}/>
                    <Route exact path='/group/:gid/playlists' component={Dashboard}/>
                    <Route exact path='/group/:gid/replays' component={Dashboard}/>
                    <Route exact path='/group/:gid/bets' component={Transactions}/>

                    <Route exact path='/group/:gid/replays/:pid' component={EntityView}/>
                    <Route exact path='/group/:gid/playlists/:pid' component={EntityView}/>
                    <Route exact path='/group/:gid/playlists/:pid/metabuilder' component={MetaBuilder}/>
                    <Route exact path='/group/:gid/playlists/:pid/tracks/:tid' component={EntityView}/>
                    <Route exact path='/playlist/:pid' component={EntityView}/>

                    <Route exact path='/users/:aid' component={EntityView}/>
                    <Route exact path='/users/:aid/group/:gid' component={EntityView}/>
                    <Route exact path='/users/:aid/group/:gid/:bundle' component={EntityView}/>
                    <Route exact path='/group/:gid/members/:uid' component={EntityView}/>
                    <Route exact path='/group/:gid/members' component={MemberList}/>
                    <Route exact path='/group/:gid/emails' component={EmailsView}/>
                    <Route exact path='/group/:gid/search' component={SearchView}/>

                    <Route exact path='/group/:gid/youtube' component={Youtube}/>

                    <Route exact path='/forms/group/:gid/playlists/:pid/rewards/:rid' component={FormLoader}/>
                    <Route exact path='/forms/group/:gid/playlists/start' component={NewPlaylist}/>

                    <Route path='/kauai' component={Homepage} />
                    <Route path='/bayarea' component={Homepage} />
                    <Route path='/oahu' component={Homepage} />

                    {forms}

                    <Route path='/' component={Homepage}/>
                </Switch>

                {this.props.includePlayer ?
                    <PlayerBar windowWidth={this.props.windowWidth} windowHeight={this.props.windowHeight}/> : ''}

                {this.props.formDialog ?
                    <DialogForm windowWidth={this.props.windowWidth}/>
                    : this.props.wallet.ctx === 'managefunds' ?
                        <ManageFunds asDialog={true} method={'payment'}/>
                        :
                        ''
                }

                {(this.props.help && this.props.help.ctx === 'dialog') ?
                    <DialogView
                        title={this.props.help.faqs.data[0].title}
                        closeDialog={this.props.clearHelp}
                        component={FaqItem}
                        content={{faq: this.props.help.faqs.data[0]}}
                        open={this.props.help.ctx === 'dialog'}/>
                    : (this.props.help && this.props.help.ctx === 'share') ?
                        <DialogView
                            title='Share this page'
                            closeDialog={this.props.clearHelp}
                            component={ShareDialog}
                            content={{help: this.props.help, brandId: this.props.me.brandId}}
                            open={this.props.help.ctx === 'share'}/>
                        :
                        ''}
            </main>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const props = {
        notifications: {error: [], info: []}, // , awards:[], info:[]
        me: state.auth.me,
        curGroup: state.auth.curGroup,
        wallet: state.wallet,
        formDialog: state.forms.ctx === 'dialog',
    };
    if (state.help.ctx === 'dialog' && state.help.faqs.data.length === 1) {
        props.help = state.help;
    }
    if (state.help.ctx === 'share' && state.help.url) {
        props.help = state.help;
    }
    props.forceClear = false;
    if (typeof state.forms.error === 'string') {
        props.notifications.error.push(state.forms.error);
        props.forceClear = true;
    }
    if (typeof state.forms.notice !== 'undefined') {
        if (!props.notifications[state.forms.notice.variant]) props.notifications[state.forms.notice.variant] = [];
        props.notifications[state.forms.notice.variant].push(state.forms.notice.msg);
        props.forceClear = true;
    }
    /* if (typeof state.lists.error === 'string') {
           props.notifications.error.push(state.lists.error);
           props.forceClear = true; // doesn't clear from list
       } */
    if (typeof state.wallet.error === 'string') {
        props.notifications.error.push(state.wallet.error);
        props.forceClear = true;
    }
    if (typeof state.player.notice === 'string') {
        props.notifications.info.push(state.player.notice);
        props.forceClear = true;
    }
    props.includePlayer = false;
    if (state.player.testPlay || state.player.curTrack) {
        props.includePlayer = true;
    }

    if (state.router.location.pathname.length < 3) {
        props.docTitle = (state.auth.me.brandId && state.auth.me.groups[state.auth.me.brandId]) ? state.auth.me.groups[state.auth.me.brandId].label[0]['value'] : 'TAM :: Home';
    } else {
        let url = state.router.location.pathname.split('/');
        if (parseInt(url[url.length - 1]) > 0 && state.entity.apiData && state.entity.apiData.label) {
            props.docTitle = state.entity.apiData.label[0].value;
        } else {
            var title = [];
            for (var u in url) {
                if (url[u].length > 1) {
                    var seg = url[u][0].toUpperCase() + url[u].slice(1);
                    title.push(seg);
                }
            }
            props.docTitle = title.join(' ');
        }
    }
    //console.log('Main props', props);
    return props;
};

const mapDispatchToProps = dispatch => {
    return {
        clearHelp: () => {
            dispatch(clearHelp());
        },
        dispatch
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)((withSnackbar(Main))));
