import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Drupal2Json from "../../Util/Drupal2Json";
import Grid from "@material-ui/core/Grid";
import TrackActions from "../../Components/TrackActions";

import Config from "../../Config";

import styles from "./PlayerStyles";
import {getIdbySegment} from "../../redux/authActions";
import ReactPlayer from "react-player";
import API from "../../Util/API";
import TrackTile from "../../Components/TrackTile";
import GrowIcon from "@material-ui/icons/ArrowDropUp";
import ShrinkIcon from "@material-ui/icons/ArrowDropDown";
import {nextPage} from "../../redux/listDataReducer";
import NavigateNext from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {Link} from "react-router-dom";

class PlayerBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posIndex: 1, handleUsed: false,
            partHeights: {actions: 30, player: 300, tracks: 150},
            pHeight: 15
        };
        if (props.curTrack) {
            this.tjson = new Drupal2Json(props.curTrack); // my assumption is putting this in state will cause unnecessary renders since it's already maintained by props
            this.tnode = new Drupal2Json(props.curTrack.node);
            if (props.media !== 'audio') {
                this.state.posIndex = 3;
            }
        }
        this.onStart = this.onStart.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.onError = this.onError.bind(this);

        this.pScrollRef = React.createRef();
        this.state.actions = this.upCtas();
        this.playCount = 0;
    }

    ref = player => {
        this.playerRef = player;
    }

    upCtas() {
        let actions = {
            next: true,
            youtube: true,
            title: true,
            rate: false,
            comments: false,
            mp3: false,
            info: false,
            edit: false,
            delete: false,
            bet: false,
            share: false,
        };
        if (this.props.me.profile && typeof this.props.me.profile.roles["access_mp3"] === "number") {
            actions.mp3 = true;
            // actions.youtube = false;
        }
        return actions;
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillUnmount() {
        this.pScrollRef = null;
        console.log("PLAYER UNMOUNTS!!");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.curTrack && (!prevProps.curTrack || this.props.curTrack.id[0].value !== prevProps.curTrack.id[0].value)) {
            this.refresh()
        }
    }

    refresh() {
        // console.log("PLAYER UPDATING ", this.props.curTrack.id)
        if (!this.props.curTrack) {
            this.tjson = false;
            this.tnode = false;
            if (document.location.hash.indexOf("autoplay") > -1) {
                const seg = getIdbySegment(document.location.href);
                return this.props.setActivePlaylist(seg.pid, seg.gid, true);
            }
            return false;
        }
        this.tjson = new Drupal2Json(this.props.curTrack);
        this.tnode = new Drupal2Json(this.props.curTrack.node);
        const gid = this.tjson.get("gid", "target_id");
        const pid = this.tjson.get("field_playlist_gc", "target_id");
        if (!this.props.playlist || this.props.playlist.id[0].value !== pid) {
            this.props.setActivePlaylist(pid, gid, document.location.hash.indexOf("autoplay") > -1);
        }

        this.setState({actions: this.upCtas()});

        if (this.props.curTrack.node.field_media.length === 0) {
            const url = `/forms/group/${this.tjson.get("gid", "target_id")}/playlists/${this.tjson.get("field_playlist_gc", "target_id")}/tracks/${this.tjson.get("id")}/edit`;
            let prompt = <p>There are no media sources attached to <em>{this.tjson.get('label')}</em>.
                Earn <em>Credibility</em> by <a href={url}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.loadForm(url, 'dialog');
                                                    return false;
                                                }}>adding it from youtube</a></p>;
            this.props.enqueueSnackbar(prompt, {variant: 'error', preventDuplicate: true});
        }
        this.measurePartHeights();
    }

    logPlayer(verb) {
        const tdata = {
            tid: this.tjson.get("id"),
            pid: this.tjson.get("field_playlist_gc", "target_id"),
            gid: this.tjson.get("gid", "target_id"),
            nid: this.props.curTrack.node ? this.props.curTrack.node.nid[0].value : null,
            source: this.props.media,
            seconds: this.playCount,
            currenttime: Math.round(this.playerRef.getCurrentTime()),
            duration: Math.ceil(this.playerRef.getDuration()),
            verb: verb
        };
        if (this.props.me.profile) {
            tdata.uid = this.props.me.profile.uid[0].value;
        }

        window.logUse.logEvent('stream', tdata);
    }

    onStart(e) {
        console.log("onStart", this.props.curTrack);
        this.playCount = 0;
        this.measurePartHeights();
    }

    onSeek(e) {
        // element.currentTime = e.currentTarget.value;
        this.logPlayer('seek');
    }

    onProgress(progress) {
        if (this.props.status === "playing") {
            if (this.playCount === 0) {
                this.logPlayer('play');
            }
            // TODO: implement and use this.state.isplaying
            if (progress.playedSeconds > 29 && (this.props.media === 'audio' || this.props.media === 'video')) {
                if (!this.props.me.profile || typeof this.props.me.profile.roles["access_mp3"] !== "number") {
                    this.logPlayer('ppp-prompted');
                    let media = this.props.curTrack.node.field_media.find(e => e.target_bundle === this.props.media);
                    if (media.ppp > 0) {
                        this.props.pausePlayer();
                        let prompt = <p>This {this.props.media} requires a TAMp4 Subscription. <Link
                            to={`/group/${this.tjson.get("gid", "target_id")}/rewards/20`} >Click here to add it your Cart, then Checkout</Link> to continue listening.</p>;
                        this.props.enqueueSnackbar(prompt, {variant: 'warning', preventDuplicate: true, persist:true, action: key => (
                                <IconButton variant="outline-light"
                                            onClick={() => { this.props.closeSnackbar(key); }}
                                >
                                    <Close />
                                </IconButton>
                            ) });
                    }
                } else {
                    this.logPlayer('ppp');
                }
            }
            this.playCount++;
        }
        if (this.playCount === 2) this.measurePartHeights(); // just once it gets going
    }

    onPause(e) { // WARN: we cannot distinguish onPause event between manual click and youtube event making continuous play more difficult
        console.log("onPause ", e, this.props.curTrack);
        this.props.pausePlayer();
        this.logPlayer('pause');
    }

    onError(e) {
        console.log('PLAYER LOAD ERROR', e);
        this.measurePartHeights();
        this.logPlayer('error');
        if (this.tjson) {
            const url = `/forms/group/${this.tjson.get("gid", "target_id")}/playlists/${this.tjson.get("field_playlist_gc", "target_id")}/tracks/${this.tjson.get("id")}/edit`;
            let prompt = <p>The {this.props.media} source for <em>{this.tjson.get('label')}</em> is broken. <a
                href={url} onClick={e => {
                e.preventDefault();
                this.props.loadForm(url, 'dialog');
                return false;
            }}>Fix it</a> to earn Credibility</p>;
            this.props.enqueueSnackbar(prompt, {variant: 'error', preventDuplicate: true});
        }
        // this.props.playNext(); // WARN: infinite loop if all tracks are broken
    }

    onEnded(e) {
        console.log("PLAYER ENDED ", this.props.curTrack);
        this.measurePartHeights();
        this.playCount = 0;
        this.logPlayer('ended');
        this.props.playNext();
        setTimeout(e => { // WARN: his means manual pauses may get ignored in 1500 ms
            if (this.props.status !== "playing" && this.props.curTrack) {
                // TODO: !this.props.curTrack happened once. maybe previous track had no media?
                console.log("restarting from background after youtube auto paused");
                this.props.playTrack(this.props.curTrack, this.props.media);
            }
        }, 1500); // WARN: possible race condition if playNext requires an ajax call

    }

    measurePartHeights() {
        const resized = {...this.state.partHeights};
        const parts = {'activeTrackListEl': 'tracks', 'activePlayerEl': 'player', 'trackActionsEl': 'actions'};
        for (let q in parts) {
            let el = document.getElementById(q);
            if (el && el.offsetHeight > 0) {
                resized[parts[q]] = Math.min(el.offsetHeight, window.innerHeight - 70);
            }
        }
        this.setState({partHeights: resized}, e => this.calcHeights());
    }

    calcHeights() {
        let height = 0;
        if (this.state.posIndex === 1) height = this.state.partHeights.actions;
        else if (this.state.posIndex === 2) height = this.state.partHeights.actions + this.state.partHeights.player;
        else if (this.state.posIndex > 2) height = this.state.partHeights.actions + this.state.partHeights.player + this.state.partHeights.tracks;
        //console.log('new height ' + height, this.state.partHeights);
        height = Math.min(window.innerHeight - 70, height);
        this.setState({pHeight: height});
    }

    nextPlayerPosition(dir) {
        this.measurePartHeights();

        let nextPos = this.state.posIndex + dir;
        if (nextPos > 4) nextPos = 4;
        else if (nextPos < 0) nextPos = 0;

        //console.log('cur pos ' + this.state.posIndex + ' next: ' + nextPos, this.state.partHeights);
        this.setState({posIndex: nextPos, handleUsed: true}, e => this.measurePartHeights());
    }

    buildPlayerConfig(media) {
        if (media === 'youtube') return {};
        const tokens = API.getLocalTokens();
        const config = {file: {}}
        if (tokens.access_token) {
            // config.file.forceHLS = true; // TODO: fix this to force
            config.file.hlsOptions = {
                debug: true,
                xhrSetup: function (xhr, url) {
                    if (tokens.access_token) {
                        xhr.setRequestHeader('Authorization', 'Authorization: Bearer ' + tokens.access_token);
                    }
                }
            }
        }
        if (media === 'audio') {
            config.file.forceAudio = true;
        } else {
            config.file.forceVideo = true;
        }
        return config;

    }

    makeStreamUrl(mid, ext) {

    }

    render() {
        const {classes} = this.props;

        let actionsCtx, carouselList = null;
        const ext = this.props.media === 'audio' ? '.mp3' : '.mp4'
        let playerProps = {};
        if (this.props.testPlay) {
            if (this.props.testPlay.target_bundle === 'youtube') {
                playerProps.url = this.props.testPlay.target_youtube;
            } else {
                playerProps.height = (this.props.testPlay.target_bundle === 'audio' ? 40 : 'auto');
                playerProps.url = Config.api.base + '/media/stream/' + this.props.testPlay.target_id + '/sample' + ext;
                playerProps.config = this.buildPlayerConfig(this.props.testPlay.target_bundle);
            }
            actionsCtx = '';
        } else if (!this.props.curTrack || this.props.curTrack.node.field_media.length === 0) {
            return '';
        } else {
            let mid = this.props.curTrack.node.field_media.find(e => e.target_bundle === this.props.media);
            if (this.props.media === "audio" || this.props.media === "video") {
                playerProps = {
                    height: (this.props.media === 'audio' ? 40 : 'auto')
                }
                playerProps.config = this.buildPlayerConfig(this.props.media);
                if (mid) {
                    playerProps.url = `${Config.api.base}/media/stream/${mid.target_id}/sample${ext}`
                } else {
                    playerProps.url = `${Config.api.base}/group/${this.tjson.get("gid", "target_id")}/stream/${this.tjson.get("id")}/media${ext}?_format=json`;
                }
            } else if (this.props.media === "youtube") {
                playerProps.url = this.tnode.get("field_media", "target_youtube");
            } else {
                console.log("UNKNOWN FILE TYPE", this.props);
                return "";
            }

            /*
            const tokens = API.getLocalTokens();
            if (tokens.access_token) {
                const headers = { Authorization: 'Authorization: Bearer ' + tokens.access_token };
                playerProps.url = {src:playerProps.url, headers:headers}
            }
             */

            actionsCtx = (
                <TrackActions ctas={this.state.actions}
                              key={'tactions-' + this.props.curTrack.id[0].value}
                              color="secondary"
                              profile={this.props.me.profile}
                              track={this.props.curTrack}
                              dispatch={this.props.dispatch}
                />
            );


            if (this.props.playlist && this.props.tracklist[this.props.playlist.id[0].value]) {
                const purchased = this.props.tracklist[this.props.playlist.id[0].value].metadata.p_roles && typeof this.props.tracklist[this.props.playlist.id[0].value].metadata.p_roles['purchased'] === 'number';
                carouselList = (<Grid container className={classes.gridList} justifyContent='space-evenly'>
                    {this.props.tracklist[this.props.playlist.id[0].value].data.map((item, i) => {
                        return (
                            <TrackTile containerWidth='player' key={"tile-" + i} dispatch={this.props.dispatch}
                                       track={item}/>
                        );
                    })}
                    {purchased === true ?
                        <IconButton size={'small'} aria-label="Next page"
                                    onClick={e => this.props.dispatch(nextPage(this.props.tracklist[this.props.playlist.id[0].value].metadata, false))}><NavigateNext/></IconButton>
                        : ''
                    }
                </Grid>);
            }
        }

        return (
            <AppBar id="playerBar" position="fixed" className={classes.appBar}
                    style={{height: this.state.pHeight + "px"}}>
                <div className={classes.dragHandlers}>
                    {this.state.posIndex !== 3 ?
                        <div
                            edge="start"
                            color="secondary"
                            aria-label="resize player"
                            id='playerGrowEl'
                            className={classes.growHandler + ' ' + classes.dragHandler}
                            onClick={e => this.nextPlayerPosition(1)}
                        >
                            <GrowIcon/>
                        </div> : ''}
                    {this.state.posIndex !== 0 ?
                        <div
                            edge="start"
                            color="secondary"
                            aria-label="resize player"
                            id='playerShrinkEl'
                            className={classes.shrinkHandler + ' ' + classes.dragHandler}
                            onClick={e => this.nextPlayerPosition(-1)}
                        >
                            <ShrinkIcon/>
                        </div> : ''}
                </div>
                <Grid container direction="column" justifyContent="space-between" alignItems="center"
                      ref={this.pScrollRef}>
                    <div id='trackActionsEl'
                         style={{width: '100%', backgroundColor: this.props.theme.palette.background.paper}}>
                        {actionsCtx}
                    </div>
                    <div id='activePlayerEl' className={classes.activePlayerEl}>
                        <ReactPlayer
                            ref={this.ref}
                            onStart={this.onStart}
                            onSeek={this.onSeek}
                            onProgress={this.onProgress}
                            onPause={this.onPause}
                            onEnded={this.onEnded}
                            onError={this.onError}
                            progressInterval={1000}
                            {...playerProps}
                            playing={this.props.status === "playing"}
                            playsInline
                            controls
                            volume={1}
                            style={{maxWidth: "100%!important", width: '100%!important'}}
                        />
                    </div>
                    <Grid id='activeTrackListEl' container wrap='nowrap'>
                        {carouselList}
                    </Grid>
                </Grid>
            </AppBar>
        );
    }
}

export default withStyles(styles, {withTheme: true})(PlayerBar);
