import MediaForm from './MediaForm';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
 return {
  forms: state.forms,
  balance: state.wallet.balance,
  curGroup : state.auth.curGroup
 }
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(MediaForm);
