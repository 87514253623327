import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import UserAvatar from '../UserAvatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListSubheader from '@material-ui/core/ListSubheader';

class EntityList extends Component {
 render() {
  const {entity, header, map} = this.props;
  let gidSeg = null;
  if (entity.gid && entity.gid[0].target_id) {
   gidSeg = '/group/' + entity.gid[0].target_id
  } else if (entity.type && entity.type[0].target_id === 'groups') {
   gidSeg = '/group/' + entity.id[0].value;
  }

  return (
   <List
    component="nav"
    dense={true}
    subheader={(header) ? <ListSubheader component="div">{header}</ListSubheader> : ''}
   >
    {Object.entries(map).map(arr => {
     return entity[arr[0]].map( (o, delta) => {
      var toUrl = '#';
      if (arr[0] === 'uid' || arr[0] === 'field_leaders' || arr[0] === 'field_editors') {
         toUrl = "/users/" + o.target_id + gidSeg;
      }
      if (toUrl === '#') {
       toUrl = '/' + (o.target_type || 'users') + '/' +  o.target_id;
      }
      return (<ListItem key={arr[0] + '-' + delta} style={{paddingLeft:0, paddingRight:0}}>
       <ListItemAvatar>
        <Link to={toUrl} >
         <UserAvatar data={o} />
        </Link>
       </ListItemAvatar>
       <ListItemText primary={o.target_label} secondary={arr[1] ? <em style={{fontSize:'85%'}}>{arr[1]}</em> : null} />
      </ListItem>);
     });
    })}
   </List>
  );
 }
}

export default EntityList;
