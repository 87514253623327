import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormattedText from "../FormattedText/FormattedText";

class StoreHeader extends Component {
 render() {

  // let body = ''; // <p className={this.props.classes.helpText}>You can pay for part of anything below with earnings in your <a href='/wallet'>wallet</a>.</p>
  if (this.props.gstore.get('field_body', 'value')) {
   return <FormattedText {...this.props.gstore.json.field_body[0]} displayField={'auto'} />
  }
  return null;

  /*
  return (
   <div className={this.props.classes.header}>
    <Typography variant='h2' ><strong>{this.props.gstore.get('name', 'value')}</strong></Typography>
    {body}
   </div>
  );
   */
 }
}


const styles = theme => ({
 header: {
  width:'100%',
  padding:theme.spacing(1),
  marginTop:theme.spacing(3),
  marginBottom:theme.spacing(3),
  justifyContent:'center',
  textAlign:'center'
 },
 primary : {
  color:theme.palette.primary.main
 },
 headerTitle : {
  fontWeight:'normal',
  margin:0
 },
 ctaText: {
  marginTop:theme.spacing(1),
  marginBottom:theme.spacing(1),
 },
 helpText : {
  color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
  fontSize:'95%',
  margin:0
 }
});


export default withStyles(styles)(StoreHeader);
