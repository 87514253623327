import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {lighten, withStyles} from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as Logo} from '../../images/LogoAngel.svg';
import {ReactComponent as LogoSlogan} from '../../images/LogoSlogan.svg';
import {getStartupParam} from "../../Util/WindowUtils";

class Footer extends Component {

 handleFaq(evt, nid) {
  evt.preventDefault();
  this.props.loadFaq(nid, 'dialog');
  return false;
 }

 render () {
  const {classes} = this.props;

  const appStyle = {marginTop:200};
  if (getStartupParam('paddingBottom').length > 0) {
   const bottomPad = parseInt(getStartupParam('paddingBottom'));
   if (bottomPad > 0) {
    appStyle.paddingBottom = bottomPad;
   }
  }

  return (
   <footer className={classes.appBar}  style={appStyle} id="appFooter" >
    <Grid
     container
     spacing={1}
     direction='row'
     justifyContent='space-between'
     alignContent='center'
     alignItems='center'
    >
     <Grid item xs={12} sm={6} >
      <Grid container className={classes.brandLogo} >
       <div className={classes.logoBg}>
        <a href={"https://trackauthoritymusic.com"} target={"_blank"} rel="noopener">
        <SvgIcon component={Logo} htmlColor={this.props.theme.palette.text.primary} style={{height:'100%', width:'100%'}} viewBox='0 0 48 48' />
        </a>
       </div>
       <div>
        <a href={"https://trackauthoritymusic.com"} target={"_blank"} rel="noopener">
        <SvgIcon component={LogoSlogan} htmlColor={this.props.theme.palette.text.primary} style={{height:46, width:240}} viewBox='0 0 240 46'  />
        </a>
       </div>
      </Grid>
     </Grid>
     <Grid item xs={12} sm={6} className={classes.contactInfo} >
      <p className={classes.line}><a href={"https://trackauthoritymusic.com"} target={"_blank"} rel="noopener">Track Authority Music <strong>BETA</strong></a> &copy; { (new Date()).getFullYear() }</p>
      <p className={classes.line}><img src="/assets/creditcards.png" alt='credit card icons' /></p>
      <p className={classes.line}>1130 3rd Avenue. Oakland, California 94606 USA</p>
      <p className={classes.line}> +1 415-300-0834 <span>&#x25CF;</span> support@trackauthoritymusic.com</p>
     </Grid>
    </Grid>
    <Grid
     container
     spacing={1}
     direction='row-reverse'
     justifyContent='space-between'
     alignContent='center'
     alignItems='center'
     className={classes.inlineBulletList}
    >
     <a  href='/faqs/100664' onClick={e => this.handleFaq(e, 100664)}>User Privacy Policy</a>
     <span>&nbsp; &#x25CF; &nbsp;</span>
     <a  href='/faqs/100724' onClick={e => this.handleFaq(e, 100724)}>Copyrights Policy</a>
     <span>&nbsp; &#x25CF; &nbsp;</span>
     <a  href='/faqs/100665' onClick={e => this.handleFaq(e, 100665)}>Wallet Withdrawal Policy</a>
     <span>&nbsp; &#x25CF; &nbsp;</span>
     <a  href='/faqs/100667' onClick={e => this.handleFaq(e, 100667)}>Subscription Refund Policy</a>
     <span>&nbsp; &#x25CF; &nbsp;</span>
     <a  href='/faqs/100668' onClick={e => this.handleFaq(e, 100668)}>Awarding Dispute Policy</a>
    </Grid>
   </footer>
  );
 }
}

const useStyles = theme => ({
 appBar: {
  color: theme.palette.background.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[300],
  background: 'linear-gradient(90deg, ' + theme.palette.background.paper + ' 0%, ' + lighten(theme.palette.background.paper, .35) + ' 20%, ' + theme.palette.background.paper + ' 60%)',
  padding:theme.spacing(1),
  fontWeight:500,
  fontSize:11
 },
 brandLogo : {
  justifyContent:'center',
  alignContent:'center',
  textAlign:'center',
  [theme.breakpoints.up('sm')]: {
   textAlign:'left',
   justifyContent:'flex-start',
   margin:'4px 0 8px 0'
  },
 },
 contactInfo : {
  textAlign:'center',
  [theme.breakpoints.up('sm')]: {
   textAlign:'right'
  },
 },
 inlineBulletList : {
  flexDirection:'row-reverse',
  textAlign:'center', justifyContent:'center', width:'100%', marginTop:20,
  letterSpacing: 1,
  fontWeight: 600,
  lineHeight: '26px',
  '& a' : {
   textDecoration:'none'
  }
 },
 line : {
  marginTop: 3,
  marginBottom: 3
 },
 logoBg : {
  backgroundColor: theme.palette.secondary.main,
  borderRadius:45,
  width:45,
  height:45,
  marginRight:5,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  boxShadow: '0px 1px 3px black',
 }
});

export default withStyles(useStyles, {withTheme:true})(Footer);
