import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MediaItem from "../MediaItem";
import CardActions from '@material-ui/core/CardActions';

const styles = theme => ({
    stepper : {
        background:theme.palette.background.default
    }
});

class MediaSlider extends React.Component {
    constructor(p) {
        super(p);
        this.state = {imgIndex: p.imgIndex || 0}
    }

    handleNext = (e) => {
        this.setState({imgIndex: (this.state.imgIndex === this.props.images.length - 1 ? 0 : this.state.imgIndex + 1 )});
    }

    handleBack = (e) => {
        this.setState({imgIndex: (this.state.imgIndex === 0 ? this.props.images.length - 1 : this.state.imgIndex - 1 )});
    }

    render() {
        const { classes, linkTo } = this.props;

        return (
            <div className={'mediaSlider'} style={{textAlign:'center'}}>
                <MediaItem media={this.props.images[this.state.imgIndex]} linkTo={linkTo} />
                {this.props.images.length > 1 &&
                <CardActions style={{justifyContent:'center'}}>
                    <MobileStepper
                        variant="dots"
                        steps={this.props.images.length}
                        position="static"
                        className={classes.stepper}
                        activeStep={this.state.imgIndex}
                        nextButton={
                            <IconButton size="small" onClick={this.handleNext} disabled={this.state.imgIndex === this.props.images.length}>
                                <KeyboardArrowRight />
                            </IconButton>
                        }
                        backButton={
                            <IconButton size="small" onClick={this.handleBack} disabled={this.state.imgIndex === 0}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        }
                    />
                </CardActions>
                }
            </div>
        );
    }
}

MediaSlider.defaultProps = {
    imgIndex:0
}

MediaSlider.propTypes = {
    linkTo: PropTypes.string, // only passed to img and document
    imgIndex:PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    images : PropTypes.array.isRequired
};

export default withStyles(styles)(MediaSlider);
