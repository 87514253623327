import React from "react";
import {toggleMenu} from "../../redux/authReducer";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import NewGroupPlans from "./NewGroupPlans";
import ListIcon from '@material-ui/icons/PictureInPicture';
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = Math.min(window.innerWidth, Math.max(360, Math.round(window.innerWidth / 3)));
const drawerHeight = Math.min(300, Math.round(window.innerHeight / 3));
const useStyles = (theme) => ({
    movableDrawer: {
      transition: theme.transitions.create(['width', 'height']),
    },
    drawertop: {
        width: '100%',
        height:drawerHeight,
    },
    drawerleft: {
        width: drawerWidth
    },
    drawerright: {
        width: drawerWidth
    },
    drawerbottom: {
        width: '100%',
        height:drawerHeight
    }
})


class GroupEditDrawer extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            anchor: this.props.theme.direction === "rtl" ? "left" : "right"
        }
    }

    moveDocking() {
        let next = {};
        if (this.state.anchor === 'left') {
            next.anchor = 'top';
        } else if (this.state.anchor === 'top') {
            next.anchor = 'right';
        } else if (this.state.anchor === 'right') {
            next.anchor = 'bottom';
        } else if (this.state.anchor === 'bottom') {
            next.anchor = 'left';
        }
        this.setState(next);
    }

    render() {
        const { classes } = this.props;

        return (
                <Drawer
                    id={'GroupEditDrawer'}
                    variant="persistent"
                    anchor={this.state.anchor}
                    open={this.props.auth.editMenuOpen}
                    classes={{ paper: classes.movableDrawer + ' ' + classes['drawer'+this.state.anchor] }}
                >

                    <IconButton onClick={() => this.moveDocking()} style={{position:'absolute', right:2, top:4}}>
                        <ListIcon  />
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="Close Group Builder"
                        style={{position:'absolute', right:39, top:6}}
                        onClick={(e) => this.props.dispatch(toggleMenu(false, 'editMenuOpen'))} >
                        <CloseIcon fontSize={'small'} />
                    </IconButton>

                    <NewGroupPlans layout={'menu'} />
                </Drawer>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(GroupEditDrawer);
