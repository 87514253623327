import {connect} from 'react-redux';
import MetaBuilder from './MetaBuilder';

const mapStateToProps = (state, ownProps) => {
 const newProps = {...ownProps};
 newProps.me = state.auth.me;

 const pid = newProps.match.params.pid;

 newProps.ptlist = {loading:true,data:false,error:false};
 if (state.player.tracklist[pid]) {
  newProps.ptlist = state.player.tracklist[pid];
 }

 return newProps;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(MetaBuilder);
