import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import SportsSelector from "../../core/taxonomy/SportsSelector";
import {listData} from "../../redux/listDataReducer";

class PmvpFilters extends Component {

    constructor(p) {
        super(p);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    onFilterChange(tid, allSelected){
        const {metadata} = this.props;
        let url = `${metadata.url}?perpage=${metadata.perpage}`;
        if (Array.isArray(allSelected) && allSelected.length > 1) {
            allSelected.forEach(t => {
                url += `&sport[]=${t}`;
            })
        } else {
            url += `&sport=${allSelected}`;
        }
        let extras = ['date', 'seed', 'sort', 'status', 'sort_dir'];
        extras.forEach(e => {
            if (metadata[e]) {
                url += `&${e}=${metadata[e]}`;
            }
        })
        this.props.dispatch(listData(url));
    }

    render() {

        const gid = this.props.gid;
        const dateBtn = (document.location.search.indexOf("date=ended") > -1)  ?
            <Button component={Link} variant={'outlined'} color={'secondary'} to={`/group/${gid}/rewards`}>View Upcoming Matches</Button>
                :
            <Button component={Link} variant={'outlined'} color={'secondary'} to={`/group/${gid}/rewards?date=ended`}>View Past Matches</Button>

        return <AppBar position={'sticky'} style={{marginBottom:30, paddingBottom:10}} color={"transparent"}>
            <Toolbar>
                <Grid container={'nowrap'} justifyContent={'space-between'} alignItems={'flex-end'} spacing={8} >
                    <Grid item style={{flexGrow:1}}><SportsSelector onChange={this.onFilterChange} /></Grid>
                    <Grid item>{dateBtn}</Grid>
                </Grid>
            </Toolbar>
        </AppBar>


    }
}


const styles = theme => ({
    header: {
        width:'100%',
        padding:theme.spacing(1),
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        justifyContent:'center',
        textAlign:'center'
    }
});


export default withStyles(styles)(PmvpFilters);
