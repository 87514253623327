import {darken, lighten} from '@material-ui/core/styles';
import {alpha} from "@material-ui/core/styles/colorManipulator";

export function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)\s*\)/);
        r = color[1];
        g = color[2];
        b = color[3];
    } else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        // eslint-disable-next-line
        g = color >> 8 & 255;
        // eslint-disable-next-line
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

        return 'light';
    } else {

        return 'dark';
    }
}


export const headFootStyles = (theme) => ({
    appBar: {
        color: "inherit",
        background:
            "linear-gradient(90deg, " +
            theme.palette.background.paper +
            " 0%, " +
            lighten(theme.palette.background.paper, 0.35) +
            " 20%, " +
            theme.palette.background.paper +
            " 60%)",
    },
    appBarHome: {
        background: "transparent",
    },
    imgContain: {
        objectFit: 'contain',
        backgroundSize: 'contain'
    },
    softAvatar: {
        backgroundColor: theme.palette.background.default,
        opacity: .75,
        '& img': {
            maxWidth: '80%',
            objectFit: 'contain',
            backgroundSize: 'contain'
        }
    },
    navLink: {
        textDecoration: 'none',
    },
    navButtons: {
        marginLeft: theme.spacing(2)
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    summary: {
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    w100: {
        alignItems: 'center',
        width: '82%'
    },
    title: {
        marginLeft: theme.spacing(2),
        fontSize: 18,
        flexGrow: 1,
        fontWeight: 900,
        "& a": {
            textDecoration: "none",
        },
    },
    subheader: {
        fontSize: 12, fontWeight: 600, lineHeight: 'normal', margin: '20px auto 10px auto', display: 'block'
    },
    container: {
        '& a': {
            textDecoration: 'none'
        }
    },
    menuItem: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    badge: {
        fontSize: '70%',
        padding: '0px 3px'
    },
    menuIcon: {
        height: 24, width: 24
    }
});

export const sliderStyle = theme => ({
    root: {
        color: theme.palette.secondary.main,
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 13,
        width: 13,
        //    backgroundColor: 'rgba(100,173,33,.75)',
        background: `radial-gradient(circle at 100% 0%, #ffffff, ${theme.palette.primary.main})`,
        marginTop: -5,
        marginLeft: -6,
        transform: 'rotate(45deg)',
        borderRadius: 0,
        boxShadow: `${theme.palette.primary.main} -1px 1px 3px 1px`,
        '&:focus,&:hover,&:active': {
            boxShadow: `${theme.palette.primary.main} -2px 2px 5px 1px`,
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 9px)',
    },
    track: {
        height: 3,
    },
    rail: {
        color: theme.palette.secondary.main,
        opacity: 1,
        height: 3,
    },
})

export const raterStyles = theme => ({
    dialogContent: {
        position: 'relative',
        width: '100%',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8
    },
    raterRoot: {
        position: 'relative',
        overflow: 'visible',
        minWidth: 100,
        maxWidth: 640,
        flexGrow: 1,
        margin: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8
    },
    valueWrap: {
        position: 'absolute',
        right: 0,
        top: '9px',
        width: '27px',
        height: '27px',
        display: 'flex',
        transform: 'rotate(45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.main
    },
    valueNumber: {
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontSize: 12,
    },
    badge: {},
    voteTypeBlock: {
        padding: '5%',
        boxShadow: `${theme.palette.type === 'dark' ? lighten(theme.palette.background.paper, .2) : darken(theme.palette.background.paper, .2)} -1px 1px 6px 1px`
    },
    onVoteNotice: {
        backgroundColor: alpha(theme.palette.background.paper, .95),
        color: theme.palette.primary.main,
        padding: 8,
        borderRadius: '10px 0px 10px 10px',
        textShadow: `-1px 1px 1px ${theme.palette.type === 'dark' ? darken(theme.palette.background.paper, .8) : darken(theme.palette.background.paper, .2)}`,
        boxShadow: `#202020 -1px 1px 3px 1px`,
        minWidth: '60%'
    },
    topScrollPaper: {
        alignItems: "flex-start"
    },
    topPaperScrollBody: {
        verticalAlign: "top"
    }
});

export const pStyles = theme => ({
    card: {
        width: '100%',
        maxWidth: 960,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 15,
        justifyContent: 'center'
    },
    list: {
        marginTop: 10,
        marginBottom: 10,
    },
    paperBg: {
        backgroundColor: alpha(theme.palette.background.paper, .75),
    },
    paper: {
        padding: 8
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: theme.spacing(1)
    },
    centeredHeader: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: 'auto'
    },
    actions: {
        gap: 8,
        padding: '3px 6px 3px 6px',
        margin: '10px auto 10px auto',
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between'
    },
    earnerLabel: {
        fontSize: '90%',
        fontWeight: 700,
        marginBottom: 8,
    },
    tagline: {
        fontWeight: 300,
        marginTop: 0,
        marginBottom: 18,
        fontStyle: 'italic',
        textAlign: 'center'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    gridList: {
        marginTop: theme.spacing(2),
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    addSquareBtn: {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'inset 0px 0px 5px 0px ' + theme.palette.secondary.main
    },
    shadowBorder: {
        backgroundColor: 'transparent',
        boxShadow: `1px 1px 5px 2px ${theme.palette.type === 'dark' ? darken(theme.palette.background.paper, .8) : darken(theme.palette.background.paper, .2)}`
    },
    addIcon: {
        position: 'absolute',
        opacity: .5,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    chipRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        borderRadius: '30px 13px 15px 30px',
        width: '100%',
        flexGrow: 1,
        height: 30,
        cursor: 'pointer',
        padding: '2px 5px 2px 0',
        border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
        borderWidth: '1px 1px 2px 2px',
        textDecoration: 'none',
        margin: '12px 0',
        '&.half': {
            flexGrow: 0,
            borderWidth: '1px 1px 1px 1px',
            width: '48%',
            border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
            opacity: .6,
        },
        '&.primary': {
            backgroundColor: theme.palette.primary.main,
            border: '1px solid ' + theme.palette.primary.main,
        },
        '&.secondary': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        }
    },
    chipAvatar: {
        color: (theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
        backgroundColor: (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
        borderRadius: 48,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 1,
        marginLeft: '-2px!important',
        height: '36px!important',
        width: '36px!important',
        '&.small': {
            padding: 3,
            width: '30px!important',
            height: 'auto!important',
            '& svg': {
                height: '100%',
                width: '100%'
            }
        },
        '&.primary': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '&.secondary': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        }
    },
    chipLabel: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
        fontWeight: 600,
        '&.earns::after': {
            content: '"earns"',
            color: (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
            fontSize: 9,
            fontWeight: 100,
            marginTop: -3,
            display: 'block',
            verticalAlign: 'super',
        }
    },
    chipValue: {
        fontWeight: 900,
        '&.dollar::before': {
            content: '"$"',
            fontSize: 9,
            color: (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
            verticalAlign: 'super',
        }
    },
    playlistCardHeader: {
        '& .MuiListItemText-primary': {
            fontSize: '85%',
            color: theme.palette.text.disabled
        },
        '& .MuiListItemText-secondary': {
            fontSize: '120%',
            color: theme.palette.primary.main,
            textDecoration:'underline',
            cursor:'pointer'
        }
    },
    pickupMatchCard: {
        '& .MuiCardHeader-avatar': {
            alignSelf: 'flex-start',
            marginTop: 4,
            marginRight: theme.spacing(1),
            '& img': {
                maxWidth: 40, maxHeight: 70,
            }
        },
        '& .MuiCardHeader-title': {
            fontWeight: 800,
            textDecoration: 'none',
            fontSize: '125%',
            '& a': {
                textDecoration: 'none',
            }
        },
        '& .MuiTypography-subtitle1': {
            fontSize: '110%',
            color: theme.palette.secondary.main,
            fontWeight: 700
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '100%',
        },
        '& .address': {
            color: theme.palette.secondary.main,
            fontWeight: 700
        }
    },
    statCount: {
        padding: theme.spacing(2),
        fontSize: '90%',
        width: '40px!important',
        height: '40px!important',
    },
    subHeading: {
        margin: 0,
        fontSize: '80%',
        fontWeight: 'normal',
        fontStyle: 'italic'
    },
    subheadTopBorder: {
        fontWeight: 700, textAlign: 'right', width: '100%',
        margin: '1px 0 5px 0',
        borderTop: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
        paddingTop: 2
    },
    helpTopBorder: {
        fontWeight: 200, fontSize: 12, margin: 0,
        borderTop: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
    },
    strongTopBorder: {
        margin: '30px 0 5px 0', padding: '6px 0 10px 0',
        fontWeight: 200, fontSize: 12,
        borderTop: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[900] : theme.palette.grey[100]),
    },
    noUnderline: {
        textDecoration: 'none',
        '& a': {
            textDecoration: 'none'
        }
    },
    miniText: {
        fontWeight: 500, fontSize: '.75rem'
    },
    tabIconBtn: {
        minWidth: 20
    },
    fullButton: {
        textAlign: 'center',
        height: '100%',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-around',
        border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300]),
        padding: 4,
        margin: 4,
        borderRadius: 4,
        display: 'flex',
        textDecoration: 'none'

    },
    badge: {
        fontSize: '70%',
        padding: '0px 3px'
    },
    badgeDark: {
        backgroundColor: theme.palette.primary.dark,
        fontSize: '70%',
        padding: '0px 3px'
    },
    max100 : {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    masonryImg: {
        width: '100%'
    },
    taForm: {
        '& .MuiGrid-item:nth-child(odd)': {
            backgroundColor: darken(theme.palette.background.paper, .05),
            paddingTop: '3%', paddingBottom: '3%',
        },
        '& .MuiGrid-item:nth-child(even)': {
            backgroundColor: lighten(theme.palette.background.paper, .05),
            paddingTop: '3%', paddingBottom: '3%',
        }
    },
    altBlocks: {
        '& .altBlock:nth-child(odd)': {
            backgroundColor: darken(theme.palette.background.paper, .05),
            paddingTop: '3%', paddingBottom: '3%',
        },
        '& .altBlock:nth-child(even)': {
            backgroundColor: lighten(theme.palette.background.paper, .05),
            paddingTop: '3%', paddingBottom: '3%',
        }
    }
});
