import React, {Component} from 'react';
import PropTypes from "prop-types";
import GenreButton from "../GenreButton/GenreButton";
import GenreList from '../../GenreList';
import Grid from "@material-ui/core/Grid";

class GenreSelector extends Component {

 handleClick(e) {
  let selected = [...this.props.selected];
  let isSelected = selected.findIndex(tid => tid.target_id === e.target_id);

  if (isSelected > -1) {
   selected.splice(isSelected, 1);
   // console.log("removed ", e, selected)
  } else {
   selected.push(e);
   // console.log("added ", e, selected)
  }

  this.props.onChange(selected);
 }

 getAllGenres() {
  let ordered = [];
  for(let tid in GenreList) {
   ordered[GenreList[tid].weight] = GenreList[tid];
  }
  return ordered;
 }

 render() {
  const options = this.props.options === 'all' ? this.getAllGenres() : this.props.options;
  return (
   <Grid container wrap='wrap' >
    {options.map(e => {
     let term = GenreList[e.target_id]; // passed options are not normalized
     if (!term) {
      console.log('genres missing!!! ' + e.target_id, options);
      return null;
     }
     let isSelected = this.props.selected.findIndex(tid => tid.target_id === e.target_id);
     return (<GenreButton
      onClick={a => this.handleClick(e)}
      count={e.count}
      key={'genre-' + e.target_id}
      isSelected={isSelected > -1}
      label={term.label}
      target_id={e.target_id}
      uri={term.icon} />);
    })}
   </Grid>
  );
 }
}

GenreSelector.defaultProps = {
 selected : [],
 options : 'all'
};

GenreSelector.propTypes = {
 onChange: PropTypes.func.isRequired,
 selected: PropTypes.array.isRequired,
 options: PropTypes.any.isRequired
};

export default GenreSelector;