import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";
import {isEnabled} from "../../Util/EnabledFeature";

class PlaylistPayouts extends Component {
    render() {
        if (isEnabled('playlists:field_hide_payouts', 'hideall')) return '';
        if (isEnabled('playlists:cta', 'bet') === false && isEnabled('playlists:cta', 'upgrade') === false) return '';

        const {json, voteTypes, classes} = this.props;

        const buyin = json.get('field_buy_in') || .99; // should never happen;
        const minpool = json.get('field_pool_min') || 0;
        var earnings = {'song_count' : Math.floor(minpool / buyin)};
        earnings['field_author_takes'] = Math.round(minpool * (json.get('field_author_takes') / 100));
        earnings['field_house_takes'] = Math.round(minpool * (json.get('field_house_takes') / 100));
        earnings['winnable'] = Math.floor(minpool - earnings['field_house_takes'] - earnings['field_author_takes']);
        earnings['first_takes'] = Math.floor(earnings['winnable'] / voteTypes.length);

        return (
            <div style={{marginTop: 10}}>

                <Typography variant='body2' className={classes.miniText}>
                    ${json.get('field_buy_in')} upgrade
                    * {earnings['song_count']} {Grammer.g('tracks', 'p')} -
                    ${earnings.field_author_takes} to curator - ${earnings.field_house_takes} to
                    house = ${earnings.winnable} purse
                </Typography>

            </div>
        );
    }
}

export default PlaylistPayouts;
