import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import {
    HeartbeatSlider,
    MyHeartBeatThumb,
    MyThumbComponent,
    MyValueLabelComponent,
    StyledSlider
} from '../RatingSliderPlaylist/RatingSliderPlaylist';

class RatingTally extends React.Component {

    render() {
        let tally = 0, count = 0;
        if (this.props.vote_types.length > 0) {
            this.props.vote_types.forEach(voteType => {
                if (voteType[this.props.countType]) {
                    count += voteType.count;
                    tally += voteType[this.props.countType] * voteType.count;
                }
            });
            if (count > 0) {
                tally = Math.round(tally / count);
            }
        }

        if (tally === 0 && this.props.showZero === false) return false;
        const tooltip = tally === 0 ? 'vote' : `${tally}% ${this.props.countType} from ${count} ${count === 1 ? 'vote' : 'votes'}`

        if (this.props.displayAs === 'slider') {
            return this.props.gid=== 54 ?
                <HeartbeatSlider
                    title={tooltip}
                    value={Math.round(tally / 20)}
                    ThumbComponent={MyHeartBeatThumb}
                    ValueLabelComponent={MyValueLabelComponent}
                    aria-label='Playlist Rating Slider'
                    step={1} max={5} min={0}
                    track={false}
                    marks={true}
                    onClick={() => this.props.handleClick()}
                    onChange={() => this.props.handleClick()}
                    valueLabelFormat={val => `${val} ${val === 1 ? 'Heartbeat' : 'Heartbeats'}`}
                    valueLabelDisplay="auto"
                />
                :
                <StyledSlider
                    title={tooltip}
                    value={tally}
                    ThumbComponent={MyThumbComponent}
                    aria-label='Playlist Rating Slider'
                    step={1} max={100} min={0}
                    onClick={() => this.props.handleClick()}
                    valueLabelDisplay="auto"
                />

        }

        return <Typography variant='h4' color={'primary'}>{tooltip}</Typography>
    }
}

RatingTally.defaultProps = {
    countType: 'avg',
    showZero: true
}

RatingTally.propTypes = {
    displayAs:PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    showZero: PropTypes.bool,
    gid: PropTypes.number,
    countType: PropTypes.string.isRequired,
    vote_types: PropTypes.array.isRequired
};


export default withRouter(RatingTally);
