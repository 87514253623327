import CasinoIcon from "@material-ui/icons/Casino";
import EventIcon from "@material-ui/icons/EventSeat";
import CommentIcon from "@material-ui/icons/Comment";
import StoreIcon from "@material-ui/icons/Redeem";
import Apartment from "@material-ui/icons/Apartment";

export const typeMap = {
    "81": {p: "Blogs", s: "Blog", icon: CommentIcon, desc: "For apps focused on sharing content with engagement around comments."},
    "82": {p: "Stores", s: "Store", icon: StoreIcon, desc: "For apps focused on selling products by offerings credits for ratings and reviews."},
    "83": {p: "Casinos", s: "Casino", icon: CasinoIcon, desc: "For apps focused on gambling on the best matches between categories and content."},
    "84": {p: "Events", s: "Event", icon: EventIcon, desc: "For apps focused on promoting a single or series of events with party favors for curating content."},
    "825": {p: "Forums", s: "Forum", icon: Apartment, desc: "For apps focused on engaging group conversations and rewarding top contributors."}
};

/*
export const featureList = [
    {label: "Rich HTML templating for playlists and posts", types: {81: true, 84: true}},
    {label: "RSVP / Guest List controls", types: {84: true}},
    {label: "Gift Registry importers", types: {84: true, 82: true}},
    {label: "Upload your own music / videos", types: {82: true, 825: true}},
    {label: "Live Record your own music / videos", types: {82: true, 825: true}},
    {label: "Sell or License your music", types: {82: true, 83: true}},
    {label: "Schedule contributor deadlines &amp; gift promotions", types: {84: true, 82: true, 83: true}},
];
 */
