import NewPlaylist from './NewPlaylist';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
 var newState = {me:state.auth.me};
 return newState;
};

export default connect(
 mapStateToProps,
 null
)(NewPlaylist);
