import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {typeMap} from '../../apps/tam/appData';
import {pStyles} from "../../Util/ThemeUtils";
import TamIcon from "../TamIcon";
import Avatar from '@material-ui/core/Avatar';

class TamFeaturesList extends Component {

    render() {
        const {classes} = this.props;

        const toPass = {xs:this.props.xs || 6};
        if (toPass.xs < 12) {
            toPass.sm = this.props.sm || 4
        }

        return (
            <div style={{width: '100%'}}>
                <Grid container justifyContent={'space-between'} >
                    {Object.entries(typeMap).map((gtype, i) => {
                        let rowStyle = {
                            padding: '4%',
                            textDecoration:'none',
                            backgroundColor: (i % 2 > 0) ? this.props.theme.palette.background.paper : this.props.theme.palette.background.default
                        };

                        let tid = gtype[0];
                        const WrapIcon = gtype[1].icon;
                        return <Grid item key={'typebtn' + tid}
                                     {...toPass}
                                     onClick={() => this.props.onSelect(tid)}
                                     style={rowStyle}
                                     container alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>
                            <Grid item style={{fontWeight:700, letterSpacing:1}} xs={6}>
                                {gtype[1].p}
                            </Grid>
                            <Grid item xs={6} >
                                <Avatar className={classes.shadowBorder} style={{float:'right'}}>
                                    <WrapIcon color={'secondary'}  size={'large'} />
                                </Avatar>
                            </Grid>
                            <Grid item xs={12}>
                                {gtype[1].desc}
                            </Grid>
                        </Grid>
                    })}
                    <Grid item {...toPass} key={'typebtncustom'}
                          onClick={() => this.props.onSelect(null)}
                          style={{
                              padding: '4%',
                              textDecoration:'none',
                              backgroundColor: this.props.theme.palette.background.paper
                          }}
                          container alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item style={{fontWeight:700, letterSpacing:1}}>
                            Custom
                        </Grid>
                        <Grid item xs={6} >
                            <Avatar className={classes.shadowBorder} style={{float:'right'}}>
                                <TamIcon iconName={'FaqDice'} noavatar={true} fontSize={'large'} />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            Select your own feature set or email sales@ trackauthoritymusic.com for special requests
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {isLoggedIn: (state.auth.me && state.auth.me.profile) ? true : false};
};

export default connect(mapStateToProps, null)(withStyles(pStyles, {withTheme: true})(TamFeaturesList));
