import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Badge from '@material-ui/core/Badge';
import {pStyles} from "../../Util/ThemeUtils";
import {addAndCheckout} from "../../redux/walletReducer";
import FormattedText from "../FormattedText/FormattedText";
import MediaSlider from "../MediaSlider/MediaSlider";
import {Link} from "react-router-dom";
import UserAvatar from "../UserAvatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddToCartButton from "../../core/checkout/AddToCartButton";
import VariationOrders from "../../core/rewards/VariationOrders";
import ProductActions from "../Products/ProductActions";

class EventTicketsDetail extends React.Component {

    constructor(p) {
        super(p);
        this.state = {cartItem: new Commerce2Json(this.props.product)}
    }

    addAndCheckout() {
        this.props.dispatch(addAndCheckout(this.state.cartItem.toJson()));
    }

    onOrder(vid) {
        const json = new Commerce2Json(this.props.product, vid);
        this.setState({cartItem: json}, () => {
            this.addAndCheckout();
        });
    }

    render() {
        const {classes} = this.props;
        const bundle = this.state.cartItem.get('type', 'target_bundle');

        let timestamp = this.state.cartItem.getTimestamp('field_date_on_sale', 'value');
        if (bundle !== 'default') {
            timestamp = new Date().getTime() - 100; // fake it until we add field_date_on_sale to every type / bundle
        }

        const headerAttrs = {
            title: this.state.cartItem.product.get('title')
        }
        headerAttrs.title = headerAttrs.title;

        let authorLink = "/users/" + this.state.cartItem.get('uid', 'target_id');
        if (this.props.curGroup > 0) {
            authorLink += '/group/' + this.props.curGroup;
        }

        if (this.state.cartItem.get('uid', 'target_id') > 1) {
            headerAttrs.avatar = <Link to={authorLink}><UserAvatar data={this.state.cartItem.getField('uid')}/></Link>
            headerAttrs.subheader =
                <Link to={authorLink}>{this.state.cartItem.get('uid', 'target_label')}</Link>

        }
        headerAttrs.action = <ProductActions me={this.props.me} gid={this.state.cartItem.get('stores', 'gid')} cartItem={this.state.cartItem} dispatch={this.props.dispatch} />


        // field_date_range , field_address, field_link_live, field_recurring

        const allMedia = this.state.cartItem.getAllMedia();
        let timezone = this.state.cartItem.get('field_json', 'timezone');
        let timestring = this.state.cartItem.product.getTimeRange('field_date_range', 'ddd MMM Do \'YY hh:mm a', timezone);
        const address = this.state.cartItem.getAddress('short');

        return (
            <Card className={classes.card}>

                <CardHeader {...headerAttrs} />

                <Typography variant={'body2'} style={{textAlign: 'center'}}>{timestring}</Typography>
                {address &&
                <Typography variant={'body2'} style={{textAlign: 'center', margin: '10px auto'}}>
                    <a target={"_blank"}
                       href={`https://maps.google.com?q=${encodeURI(this.state.cartItem.getAddress('long'))}`}>@ {this.state.cartItem.get("field_address", "given_name") || address}</a>
                </Typography>}
                <Typography variant={'body2'}
                            style={{textAlign: 'center'}}>{this.state.cartItem.getPriceRange()}</Typography>

                {allMedia.length > 0 && <MediaSlider images={allMedia}/>}

                <CardContent className={classes.content}>

                    {this.state.cartItem.getField('body') &&
                        <FormattedText {...this.state.cartItem.getField('body')} displayField={'auto'}/>}

                    {this.state.cartItem.get('type', 'target_id') === 'pickup_match' && authorLink}


                </CardContent>

                {timestamp > new Date().getTime() ?
                    <CardActions className={classes.actions}><strong>On Sale</strong>
                        <u>{this.state.cartItem.getTime('field_date_on_sale')}</u></CardActions>
                    :
                    <Grid style={{margin:'20px auto 40px auto', gap:20}} container alignContent={'center'} alignItems={'flex-start'} justifyContent={'center'}>
                        {this.props.product.variations.map((variation, i) => {
                            const cartItem = new Commerce2Json(this.props.product, variation.target_id);
                            const btn = <AddToCartButton dispatch={this.props.dispatch} cartItem={cartItem} />;

                            return <Grid item xs={6} container={true} key={'event_ticket' + variation['target_id']}
                                         flexDirection={'column'} spacing={2} justifyContent={'center'}
                                         alignItems={'center'} alignContent={'center'}
                                         style={{textAlign: 'center'}}>
                                <Grid item xs={12} ><Badge
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: i % 2 ? 'right' : 'left',
                                    }}
                                    badgeContent={variation.field_stock.length === 0 ? 0 : `${variation.order_count}/${variation.field_stock[0].value}`}
                                    classes={{badge: classes.badgeDark}}>
                                    {btn}
                                </Badge>
                                </Grid>
                                <VariationOrders dispatch={this.props.dispatch} cartItem={cartItem} curGroup={this.props.curGroup} gjson/>
                            </Grid>
                        })}
                    </Grid>
                }

            </Card>
        );
    }
}

EventTicketsDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles, {withTheme:true})(EventTicketsDetail);
