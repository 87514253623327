import React from "react";
import {withRouter} from "react-router";
import {toggleMenu} from "../../redux/authReducer";
import Drawer from "@material-ui/core/Drawer";
import {withStyles} from "@material-ui/core/styles";
import HeaderMenu from "../HeaderMenu";

const drawerWidth = 250;

const useStyles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
});

class ResponsiveDrawer extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname || this.props.location.search !== prevProps.location.search) {
      this.props.dispatch(toggleMenu("close"));
    }
  }

  render() {
    const { classes, theme } = this.props;


    return (
      <nav>
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.props.auth.isMenuOpen}
              onClose={(e) => this.props.dispatch(toggleMenu())}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
            >
              <HeaderMenu
                me={this.props.auth.me}
                curGroup={this.props.auth.curGroup}
                dispatch={this.props.dispatch}
                location={this.props.location}
              />
            </Drawer>
      </nav>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(
  withRouter(ResponsiveDrawer)
);
