import MediaRecorder from "./MediaRecorder";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        profile: state.auth.me.profile,
        balance: state.wallet.balance,
    }
};

export default connect(
    mapStateToProps,
    null
)(MediaRecorder);
