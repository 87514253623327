import React from 'react';
import {Link} from 'react-router-dom';
import Drupal2Json from '../../Util/Drupal2Json';
import {push} from 'connected-react-router';
import UserAvatar from '../../Components/UserAvatar';
import PaginationBlock from '../../Components/PaginationBlock';
import OverlayLoader from '../../Components/OverlayLoader';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MembersTable from '../../Components/MembersTable';
import ItemGrid from '../../Components/ItemGrid';
import Grammer from "../../Util/Grammer";
import {isEnabled} from "../../Util/EnabledFeature";
import {connect} from "react-redux";

class GroupStatsChips extends React.Component {

    constructor(props) {
        super(props);
        this.state = {expanded: '', listUrl: ''};
    }

    handleChange = panel => (event, expanded) => {
        if (panel === this.state.expanded) {
            return this.setState({expanded: false}); // close
        }

        const gid = this.props.group.id[0].value;

        if (panel === 'members') {
            this.props.dispatch(push('/group/' + gid + '/members'));
        } else if (panel === 'emails') {
            this.props.dispatch(push('/group/' + gid + '/emails'));
        } else if (panel === 'playlists') {
            this.props.dispatch(push('/group/' + gid + '/playlists'));
        } else {
            const url = '/group/' + gid + '/' + panel;
            this.props.dispatch(push(url));
            // this.setState({expanded: panel, listUrl: url});
            // this.props.dispatch(listData(url));
        }
    };

    render() {
        const {classes, group} = this.props;
        const json = new Drupal2Json(group);
        let gid = group.id[0].value;
        console.log("GROUPSTAT "  + gid, this.props);


        return (
            <React.Fragment>

                <Grid container direction="row" justifyContent="space-around" alignItems="center">

                    {(isEnabled('groups:owner', true)) &&
                        <Link to={"/users/" + json.get('uid', 'target_id') + '/group/' + json.get('id', 'value')}
                              style={{textDecoration: 'none'}}>
                            <Chip
                                avatar={<UserAvatar variant={'circular'} data={group.uid[0]}/>}
                                label={json.get('uid', 'target_label')}
                                color='secondary'
                            />
                        </Link>
                    }

                    {isEnabled('groups:editors', true) && json.get('field_editors', 'target_id') && json.get('field_editors', 'target_id') !== json.get('uid', 'target_id') &&
                        <Link
                            to={"/users/" + json.get('field_editors', 'target_id') + '/group/' + json.get('id', 'value')}
                            style={{textDecoration: 'none'}}>
                            <Chip
                                avatar={<UserAvatar variant={'circular'} data={group.field_editors[0]}/>}
                                label={json.get('field_editors', 'target_label')}
                                color='secondary'
                            />
                        </Link>
                    }

                    {group.stats && Object.entries(group.stats).map(arr => (arr[1].count === 0) ? '' :
                        <Chip
                            key={arr[0]}
                            href={'/group/' + gid + '/' + arr[0]}
                            avatar={<Avatar className={classes.statCount}>{arr[1].count || 0}</Avatar>}
                            label={this.props.formDrawerOpen > 0 ? Grammer.g(arr[0].charAt(0).toUpperCase() + arr[0].slice(1), 'p') : arr[1].label}
                            classes={{
                                root: classes.chipRoot,
                                avatar: classes.chipAvatar
                            }}
                            className={classes.chip}
                            onClick={this.handleChange(arr[0])}
                        />
                    )}
                </Grid>

                <div style={{width: '100%', marginTop: 15}}>
                    {group.stats && Object.entries(group.stats).map(arr => {
                            if (arr[1].count > 0 && this.state.expanded === arr[0]) {
                                return <div key={`content${arr[0]}`} style={{flexWrap: 'wrap'}}>

                                    {this.props.lists.apiData && this.state.listUrl === this.props.lists.apiData.metadata.url
                                        ?
                                        <PaginationBlock meta={this.props.lists.apiData.metadata}
                                                         dispatch={this.props.dispatch}/>
                                        : ''
                                    }

                                    {(this.props.lists.loading === true) ? <OverlayLoader/> : null}

                                    {
                                        this.state.expanded !== arr[0] || !this.props.lists.apiData ?
                                            'loading...'
                                            :
                                            (this.state.listUrl !== this.props.lists.apiData.metadata.url) ?
                                                'reloading...'
                                                :
                                                (arr[0] === 'members') ?
                                                    <MembersTable apiData={this.props.lists.apiData} me={this.props.me}
                                                                  dispatch={this.props.dispatch}/>
                                                    :
                                                    <ItemGrid lData={this.props.lists.apiData} me={this.props.me}
                                                              dispatch={this.props.dispatch} curGroup={gid}/>
                                    }

                                </div>;
                            }
                            return null;
                        }
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const styles = theme => ({
    panelHeader: {
        width: '100%',
        marginBottom: 0,
        borderRadius: 0
    },
    statCount: {
        padding: theme.spacing(2) + 4,
        fontSize: '100%',
        marginLeft: -4
    },
    chip: {
        marginTop: 4,
        marginBottom: 4,
    },
    chipRoot: {
        border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]),
        borderWidth: '1px',
        backgroundColor: 'transparent',
        textDecoration: 'none'
    },
    chipAvatar: {
        color: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        fontWeight: 900,
        marginLeft: '0!important'
    }
});



const mapStateToProps = (state) => {
    const newState = {
        me:state.auth.me,
        lists: state.lists
    };
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GroupStatsChips));
