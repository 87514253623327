import React from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import {withStyles} from "@material-ui/core/styles";
import FormLoader from "../FormWrapper/FormLoader";
import {formsCancel} from "../../redux/formsReducer";

import ListIcon from '@material-ui/icons/PictureInPicture';
import CloseIcon from "@material-ui/icons/Close";

let drawerWidth = Math.max(window.innerWidth, Math.min(400, Math.round(window.innerWidth / 2)));
let drawerHeight = Math.max(300, Math.round(window.innerHeight / 2.5));
const useStyles = (theme) => ({
    appBar: {
        backgroundColor: alpha(theme.palette.primary.main, .2)
    },
    drawertop: {
        width: '100%',
        height: drawerHeight,
    },
    drawerleft: {
        width: drawerWidth
    },
    drawerright: {
        width: drawerWidth
    },
    drawerbottom: {
        width: '100%',
        height: drawerHeight
    }
})


class DrawerForm extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            anchor: this.props.theme.direction === "rtl" ? "left" : "right"
        }
    }

    moveDocking() {
        let next = {};
        if (this.state.anchor === 'left') {
            next.anchor = 'top';
        } else if (this.state.anchor === 'top') {
            next.anchor = 'right';
        } else if (this.state.anchor === 'right') {
            next.anchor = 'bottom';
        } else if (this.state.anchor === 'bottom') {
            next.anchor = 'left';
        }
        this.setState(next);
    }

    render() {
        const {classes} = this.props;

        if (this.props.forms.api && (this.props.forms.api.bundle === 'tracks' || this.props.forms.api.bundle === 'groups-group_node-tracks')) {
            drawerWidth = Math.max(window.innerWidth, Math.min(400, Math.round(window.innerWidth / 2)));
        } else {
            drawerWidth = Math.max(window.innerWidth, Math.min(300, Math.round(window.innerWidth / 2.4)));
        }

        return (
            <Drawer
                id={'DrawerForm'}
                variant="persistent"
                anchor={this.state.anchor}
                open={this.props.forms.ctx === 'drawer'}
                onClose={() => this.props.dispatch(formsCancel())}
                classes={{paper: classes['drawer' + this.state.anchor]}}
                ModalProps={{keepMounted: true}}
            >
                <AppBar position={"relative"} className={this.props.classes.appBar}>
                    <Toolbar style={{justifyContent:'space-between'}}>
                        <IconButton onClick={() => this.props.dispatch(formsCancel())}>
                            <CloseIcon/>
                        </IconButton>
                        <IconButton onClick={() => this.moveDocking()} >
                            <ListIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <FormLoader forms={this.props.forms} dispatch={this.props.dispatch} />
            </Drawer>
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(DrawerForm);
