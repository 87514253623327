import React, {Component} from 'react';

import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as PlaceChips} from '../../images/PlaceChips.svg';
import {_showNotice} from '../../redux/formsReducer';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ConfirmWager from "../ConfirmWager";

class BetButton extends Component {

    constructor(p) {
        super(p);
        this.state = {showContract: false}
    }

    confirmWager() {
        if (this.props.profile) {
            this.setState({showContract: true});
        } else {
            this.props.dispatch(_showNotice('Please <a href="/login">login</a> first', 'error'));
            return false;
        }
    }

    render() {
        let label = this.props.label || 'Upgrade',
            icon = <SvgIcon component={PlaceChips} color={this.props.color} viewBox='0 0 24 24'/>;

        const toPass = {
            key: `wagerbtn-${this.props.json.get('id')}`,
            'aria-label': 'Upgrade', // keep consistent for Cypress
            onClick: () => this.confirmWager()
        }

        let components = [];
        if (this.state.showContract === true) {
            components.push(<ConfirmWager key={'wagercontract'}
                                          onClose={() => this.setState({showContract: false})}
                                          track={this.props.json.json}/>);
        }

        if (this.props.asButton === true) {
            components.push(<Button
                {...toPass}
                startIcon={icon} variant={'outlined'}
                color={this.props.color} style={{borderRadius: 0}}
            >
                {label}
            </Button>)
        } else if (this.props.showlabel) {
            components.push(<ListItem {...toPass} >
                <IconButton>{icon}</IconButton>
                <ListItemText primary={label}/>
            </ListItem>);
        } else {
            components.push(<IconButton {...toPass} >{icon}</IconButton>);
        }

        return components;
    }
}

BetButton.defaultProps = {
    color: 'primary'
};

BetButton.propTypes = {
    color: PropTypes.string,
    json: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    label: PropTypes.string,
};

export default BetButton;
