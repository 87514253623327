import {getIdbySegment} from "./authActions";

const LOGIN_STARTED = "LOGIN_STARTED"; // for modals / navigation
const LOGIN_SUCCESS = "LOGIN_SUCCESS"; //
const LOGIN_FAILURE = "LOGIN_FAILURE";

const SIGNUP_STARTED = "SIGNUP_STARTED";
const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"; // an opportunity for the client to route what's next
const SIGNUP_FAILURE = "SIGNUP_FAILURE";

const GROUP_CHANGED = "GROUP_CHANGED";
const LOG_OUT = "LOG_OUT"; // generally async anyway (client kills token, doesn't need callback for revoking on server)

const SET_MENU_VISIBILITY = "menu:SET_MENU_VISIBILITY";
const SET_FEATURES = "SET_FEATURES";
const SET_GRAMMER = "SET_GRAMMER";
const SET_NEWGROUP = 'SET_NEWGROUP';
const SET_EDIT_GROUP_MODE = 'SET_EDIT_GROUP_MODE';

// default authentication object
const initialState = {
  me: false,
  loading: false,
  isMenuOpen: false,
  curGroup: 0,
  signUpError: false,
  verifyError: false,
  logInError: false,

  editMenuOpen:false,
  formDrawerOpen:0, // (because we want to be able to navigate the group while making form / menu changes)
  gFeatures: {
    "groups:field_editors": true,
    "groups:field_grammer": true,
    "groups:field_email": true,
    "playlists:field_comments": true,
    "playlist:rating": false,
    "playlist:field_vote_type": false,
    "playlist:fees": "none",
    "tracks:field_comments": true,
    "media:ytsource": true,
    "media:source": "youtube",
  },
  gGrammer: {},
};


export function logInStart() {
  return {
    type: LOGIN_STARTED,
  };
}

export function logInSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    me: payload,
  };
}

export function logInFailure(err) {
  return {
    type: LOGIN_FAILURE,
    error: err,
  };
}

export function signUpStart() {
  return {
    type: SIGNUP_STARTED,
  };
}

export function signUpSuccess(user) {
  return {
    type: SIGNUP_SUCCESS,
    me: user,
  };
}

export function signUpFailure(err) {
  return {
    type: SIGNUP_FAILURE,
    error: err,
  };
}

export function toggleMenu(force, menu) {
  return {
    type: SET_MENU_VISIBILITY,
    force,
    menu
  };
}

export function setFeatures(features) {
  return {
    type: SET_FEATURES,
    payload: features,
  };
}

export function setGrammer(grammer) {
  return {
    type: SET_GRAMMER,
    grammer,
  };
}

export const updateMyGroup = (payload) => ({
  type: SET_NEWGROUP,
  payload: payload
});



export function changeGroup(gid, groups) {
  return {
    type: GROUP_CHANGED,
    gid: gid,
    groups: groups,
  };
}

export function editGroupMode(bool) {
  return {
    type:SET_EDIT_GROUP_MODE,
    ison:bool
  }
}

const authReducer = (draft = initialState, action) => {
  draft.loading = false; // default except when started
  switch (action.type) {
    case LOGIN_STARTED:
      draft.logInError = false;
      draft.loading = true;
      return draft;
    case LOGIN_SUCCESS:
      draft.logInError = false;
      if (action.me.profile || action.me.menu || action.me.groups) {
        draft.me = action.me;
        if (draft.curGroup === 0) {
          let gid = getIdbySegment(document.location.pathname).gid;
          if (gid > 0) {
            draft.curGroup = gid;
          }
        }
      } else if (action.access_token) {
        // already stored
      }
      return draft;
    case LOGIN_FAILURE:
      draft.logInError = action.error;
      return draft;
    case SIGNUP_STARTED:
      draft.loading = true;
      draft.signUpError = false;
      return draft;
    case GROUP_CHANGED:
      // draft.formDrawerOpen = 0; // turn off by default
      if (action.groups) {
        let g = action.gid;
        if (typeof draft.me.groups !== "object") draft.me.groups = {};
        for (let g2 in action.groups) {
          draft.me.groups[g2] = action.groups[g2]; // in case their missing
        }
        if (action.groups[g]) {
          console.log("CHANGING GID : " + g);
          draft.curGroup = parseInt(g);
          draft.gFeatures = JSON.parse(action.groups[g].field_features[0].value);
          draft.gGrammer = JSON.parse(action.groups[g].field_grammer[0].value);
          /* if (draft.formDrawerOpen > 0) {
            draft.formDrawerOpen = 0; //
          } */
        }
      }
      return draft;
    case SIGNUP_SUCCESS:
      draft.signUpError = false; // handles joinGroup and leaveGroup
      if (action.me.profile || action.me.menu || action.me.groups) {
        draft.me = action.me; // subsequent login works better
      }
      return draft;
    case SIGNUP_FAILURE:
      draft.signUpError = action.error;
      return draft;
    case SET_MENU_VISIBILITY:
      let key = action.menu === 'isMenuOpen' || action.menu === 'editMenuOpen' ? action.menu : 'isMenuOpen';
      if (action.force === "close") {
        draft[key] = false;
      } else if (action.force === "open") {
        draft[key] = true;
      } else {
        draft[key] = !draft[key];
      }
      return draft;
    case LOG_OUT:
      return { ...initialState };
    case SET_FEATURES:
      draft.gFeatures = typeof action.payload === 'string' ?  JSON.parse(action.payload) : action.payload;
      return draft;
    case SET_GRAMMER:
      draft.gGrammer = typeof action.grammer === 'string' ?  JSON.parse(action.grammer) : action.grammer;
      return draft;
    case SET_EDIT_GROUP_MODE:
      draft.formDrawerOpen = action.ison;
      if (action.ison) {
        draft.editMenuOpen = true;
      }
      return draft;
    case SET_NEWGROUP:
      draft.formDrawerOpen = 1;
      if (typeof draft.me.groups[draft.curGroup] === 'undefined') {
        draft.me.groups[draft.curGroup] = action.payload
      } else {
        if (draft.curGroup !== 0 && draft.me.groups[0]) {
          delete draft.me.groups[0]; // cleanup
        }
        Object.assign(draft.me.groups[draft.curGroup], action.payload);
      }
      return draft;

    default:
      return draft;
  }
};

export default authReducer;
