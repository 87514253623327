import ImageUpload from './ImageUpload';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
 const newState = {forms : {...state.forms}};
 return newState;
};

export default connect(
 mapStateToProps,
 null
)(ImageUpload);
