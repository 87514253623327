import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import {shippingSave} from "../redux/walletReducer";
import TextField from "@material-ui/core/TextField";

class Address extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            address: {
                "bill-name": "",
                "bill-address": "",
                "bill-city": "",
                "bill-state": "",
                "bill-zip": "",
                "bill-country": ""
            }
        };
        if (props.defaultValue) {
            // 38144 MILLER PL FREMONT CA 94536-3824
            this.state.address = {
                "bill-name": props.defaultValue.customer_name || "",
                'bill-address': props.defaultValue.address_line1 || "",
                'bill-city': props.defaultValue.locality || "",
                'bill-state': props.defaultValue.administrative_area || "",
                'bill-zip': props.defaultValue.postal_code || "",
                'bill-country': props.defaultValue.country_code || ""
            }
        }
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== null && JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors)) {
            this.setState({errors: this.props.errors});
        }
    }

    handleTextChange(e) {
        const address = {...this.state.address}, errors = {...this.state.errors};
        address[e.target.name] = e.target.value;
        if (e.target.name === 'bill-country') {
            address[e.target.name] = address[e.target.name].toUpperCase();
        }
        delete errors[e.target.name];
        this.setState({address: address, errors: errors});
    }

    saveShippingAddress() {
        let address = this.formToObject(this.props.formKey);
        if (address !== false) {
            const payload = {shipping: {...this.state.address}};
            if (this.props.addressId) {
                payload.shipping.id = this.props.addressId;
            }
            this.props.dispatch(shippingSave(payload));
            if (this.props.closeForm) {
                this.props.closeForm();
            }
        }
    }

    formToObject(formId) {
        var form = document.forms[formId], obj = {}, errors = {}, hasErrors = false;
        for (var i = 0; i < form.length; i++) {
            if (typeof form[i] !== 'object' || typeof form[i].name !== 'string') continue;
            if (form[i].checkValidity() === false) {
                errors[form[i].name] = form[i].validationMessage;
                hasErrors = true;
            } else {
                obj[form[i].name] = form[i].value;
            }
        }
        this.setState({errors: errors});
        return hasErrors === false ? obj : false;
    }

    render() {
        const {classes} = this.props;

        const form = <form name={this.props.formKey} style={{marginBottom: 10}}>
            <TextField required={true}
                       style={{margin: '8px 5px 8px 0'}}
                       label={this.props.fullForm === true ? 'Ship to Name' : 'Bill to Name'} name='bill-name' type='text' margin='dense'
                       value={this.state.address['bill-name']}
                       onChange={this.handleTextChange}
                       error={typeof this.state.errors['bill-name'] === 'string'}
                       helperText={this.state.errors['bill-name'] || null}
            />
            <TextField required={true}
                       style={{margin: '8px 5px 8px 0'}}
                       label={'Street Address'} name='bill-address' type='text' margin='dense'
                       value={this.state.address['bill-address']}
                       onChange={this.handleTextChange}
                       error={typeof this.state.errors['bill-address'] === 'string'}
                       helperText={this.state.errors['bill-address'] || null}/>
            <TextField required={true}
                       style={{margin: '8px 5px 8px 0'}}
                       label={'City'} name='bill-city' type='text' margin='dense'
                       value={this.state.address['bill-city']}
                       onChange={this.handleTextChange}
                       error={typeof this.state.errors['bill-city'] === 'string'}
                       helperText={this.state.errors['bill-city'] || null}/>
            <TextField required={true}
                       style={{margin: '8px 5px 8px 0'}}
                       name='bill-state' type='text' margin='dense'
                       label={'State'}
                       value={this.state.address['bill-state']}
                       onChange={this.handleTextChange}
                       error={typeof this.state.errors['bill-state'] === 'string'}
                       helperText={this.state.errors['bill-state'] || null}/>
            <TextField
                style={{margin: '8px 5px 8px 0'}}
                required={true}
                label='Postal Code'
                name='bill-zip' type='number'
                value={this.state.address['bill-zip']}
                onChange={this.handleTextChange}
                inputProps={{
                    "autoComplete": "shipping postal-code",
                    maxLength: 11,
                    minLength: 5,
                    min: 1,
                    max: 9999999999,
                    required: true
                }}
                margin='dense' error={typeof this.state.errors['bill-zip'] === 'string'}
                helperText={this.state.errors['bill-zip'] || null}/>
            <TextField
                style={{width: 60}} label='Country'
                value={this.state.address['bill-country']}
                onChange={this.handleTextChange}
                name='bill-country' type='text' margin='dense'
                error={typeof this.state.errors['bill-country'] === 'string'}
                inputProps={{"autoComplete": "shipping country", maxLength: 2, minLength: 2}}
                helperText={this.state.errors['bill-country'] || null}/>
        </form>;

        if (this.props.fullForm === true) {
            return (<Paper style={{margin: '15px 0', padding: '2%'}}>
                    <Typography variant='h2' className={classes.sectionHead}>Add shipping address</Typography>
                    {form}
                    <Button variant="contained" margin="dense" size={'small'} color="primary"
                            onClick={() => this.saveShippingAddress()}>Add Address</Button>
                </Paper>
            )
        }
        return (<div style={{marginTop: 15}}>
            <Typography variant='h2' className={classes.sectionHead}>Billing Address</Typography>
            {form}
        </div>);
    }
}

Address.defaultValue = {
    errors: {}
}

Address.propTypes = {
    formKey: PropTypes.string.isRequired,
    fullForm: PropTypes.bool,
    errors: PropTypes.object,
    closeForm:PropTypes.func
};

const styles = theme => ({
    sectionHead: {
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 4,
        opacity: 0.4,
        textTransform: 'uppercase',
        fontSize: 13
    }
});

export default withStyles(styles)(Address);
