import Config from './Config';

const GenreList = {
 "857": {
  "target_id": 857,
  "label": "Afro Jazz",
  "weight": 0,
  "icon": Config.api.base + "/sites/default/files/genres/african-roots.png",
  "svg": Config.api.client_host + '/genres/afrojazz.svg'
 },
 "856": {
  "target_id": 856,
  "label": "AfroBeat",
  "weight": 1,
  "icon": Config.api.base + "/sites/default/files/genres/afrobeat.png",
  "svg": Config.api.client_host + '/genres/afrobeat.svg'
 },
 "86": {
  "target_id": 86,
  "label": "Alternative/Indie",
  "weight": 2,
  "icon": Config.api.base + "/sites/default/files/genres/variety_1.png",
  "svg": Config.api.client_host + '/genres/alternative.svg'
 },
 "107": {
  "target_id": 107,
  "label": "Audiobook",
  "weight": 3,
  "icon": Config.api.base + "/sites/default/files/genres/soundtrack.png",
  "svg": Config.api.client_host + '/genres/soundtrack.svg'
 },
 "865": {
  "target_id": 865,
  "label": "Big Band",
  "weight": 4,
  "svg": Config.api.client_host + '/genres/bigband.svg'
 },
 "839": {
  "target_id": 839,
  "label": "Bluegrass",
  "weight": 5,
  "icon": Config.api.base + "/sites/default/files/genres/bluegrass.png",
  "svg": Config.api.client_host + '/genres/bluegrass.svg'
 },
 "852": {
  "target_id": 852,
  "label": "Blues",
  "weight": 6,
  "icon": Config.api.base + "/sites/default/files/genres/blues.png",
  "svg": Config.api.client_host + '/genres/blues.svg'
 },
 "859": {
  "target_id": 859,
  "label": "Bollywood",
  "weight": 7,
  "icon": Config.api.base + "/sites/default/files/genres/bollywood.png",
  "svg": Config.api.client_host + '/genres/bollywood.svg'
 },
 "105": {
  "target_id": 105,
  "label": "Broadway",
  "weight": 8,
  "icon": Config.api.base + "/sites/default/files/genres/musical.png",
  "svg": Config.api.client_host + '/genres/broadway.svg'
 },
 "837": {
  "target_id": 837,
  "label": "Children",
  "weight": 9,
  "icon": Config.api.base + "/sites/default/files/genres/kids.png",
  "svg": Config.api.client_host + '/genres/kids.svg'
 },
 "98": {
  "target_id": 98,
  "label": "Christian",
  "weight": 10,
  "icon": Config.api.base + "/sites/default/files/genres/christian.png",
  "svg": Config.api.client_host + '/genres/christian.svg'
 },
 "848": {
  "target_id": 848,
  "label": "Classic Rock",
  "weight": 11,
  "icon": Config.api.base + "/sites/default/files/genres/variety_0.png",
  "svg": Config.api.client_host + '/genres/classicrock.svg'
 },
 "95": {
  "target_id": 95,
  "label": "Classical",
  "weight": 12,
  "icon": Config.api.base + "/sites/default/files/genres/classical.png",
  "svg": Config.api.client_host + '/genres/classical.svg'
 },
 "838": {
  "target_id": 838,
  "label": "Comedy",
  "weight": 13,
  "icon": Config.api.base + "/sites/default/files/genres/comedy_0.png",
  "svg": Config.api.client_host + '/genres/comedy.svg'
 },
 "96": {
  "target_id": 96,
  "label": "Country",
  "weight": 14,
  "icon": Config.api.base + "/sites/default/files/genres/country.png",
  "svg": Config.api.client_host + '/genres/country.svg'
 },
 "836": {
  "target_id": 836,
  "label": "Dancehall",
  "weight": 15,
  "icon": Config.api.base + "/sites/default/files/genres/dance_0.png",
  "svg": Config.api.client_host + '/genres/dancehall.svg'
 },
 "851": {
  "target_id": 851,
  "label": "Disco",
  "weight": 16,
  "icon": Config.api.base + "/sites/default/files/genres/disco.png",
  "svg": Config.api.client_host + '/genres/disco.svg'
 },
 "850": {
  "target_id": 850,
  "label": "Downtempo",
  "weight": 17,
  "icon": Config.api.base + "/sites/default/files/genres/easylistening.png",
  "svg": Config.api.client_host + '/genres/easylistening.svg'
 },
 "863": {
  "target_id": 863, "label": "Dub", "weight": 18,
  "svg": Config.api.client_host + '/genres/dub.svg'
 },
 "92": {
  "target_id": 92,
  "label": "EDM",
  "weight": 19,
  "icon": Config.api.base + "/sites/default/files/genres/electronic.png",
  "svg": Config.api.client_host + '/genres/techno-man.svg'
 },
 "847": {
  "target_id": 847,
  "label": "Experimental",
  "weight": 20,
  "icon": Config.api.base + "/sites/default/files/genres/experimental.png",
  "svg": Config.api.client_host + '/genres/experimental.svg'
 },
 "835": {
  "target_id": 835,
  "label": "Folk",
  "weight": 21,
  "icon": Config.api.base + "/sites/default/files/genres/folk.png",
  "svg": Config.api.client_host + '/genres/folk.svg'
 },
 "866": {
  "target_id": 866, "label": "Funk", "weight": 22,
  "svg": Config.api.client_host + '/genres/funk.svg'
 },
 "855": {
  "target_id": 855,
  "label": "Gospel",
  "weight": 23,
  "icon": Config.api.base + "/sites/default/files/genres/gospel.png",
  "svg": Config.api.client_host + '/genres/gospel.svg'
 },
 "85": {
  "target_id": 85,
  "label": "Hard Rock",
  "weight": 24,
  "icon": Config.api.base + "/sites/default/files/genres/rock.png",
  "svg": Config.api.client_host + '/genres/hardrock.svg'
 },
 "87": {
  "target_id": 87,
  "label": "Hip Hop",
  "weight": 25,
  "icon": Config.api.base + "/sites/default/files/genres/hiphop.png",
  "svg": Config.api.client_host + '/genres/hiphop.svg'
 },
 "93": {
  "target_id": 93,
  "label": "Jazz",
  "weight": 26,
  "icon": Config.api.base + "/sites/default/files/genres/jazz.png",
  "svg": Config.api.client_host + '/genres/jazz.svg'
 },
 "94": {
  "target_id": 94,
  "label": "Latin Dance",
  "weight": 27,
  "icon": Config.api.base + "/sites/default/files/genres/latin.png",
  "svg": Config.api.client_host + '/genres/latin-dance.svg'
 },
 "864": {
  "target_id": 864, "label": "Latin Regional", "weight": 28,
  "svg": Config.api.client_host + '/genres/latin.svg'
 },
 "844": {
  "target_id": 844,
  "label": "Meditation",
  "weight": 29,
  "icon": Config.api.base + "/sites/default/files/genres/meditation.png",
  "svg": Config.api.client_host + '/genres/meditation.svg'
 },
 "841": {
  "target_id": 841,
  "label": "Metal",
  "weight": 30,
  "icon": Config.api.base + "/sites/default/files/genres/metal.png",
  "svg": Config.api.client_host + '/genres/metal.svg'
 },
 "843": {
  "target_id": 843,
  "label": "New Age",
  "weight": 31,
  "icon": Config.api.base + "/sites/default/files/genres/newage.png",
  "svg": Config.api.client_host + '/genres/newage.svg'
 },
 "842": {
  "target_id": 842,
  "label": "Opera",
  "weight": 32,
  "icon": Config.api.base + "/sites/default/files/genres/opera.png",
  "svg": Config.api.client_host + '/genres/opera.svg'
 },
 "88": {
  "target_id": 88,
  "label": "Pop",
  "weight": 33,
  "icon": Config.api.base + "/sites/default/files/genres/pop.png",
  "svg": Config.api.client_host + '/genres/pop.svg'
 },
 "845": {
  "target_id": 845,
  "label": "Pop en Espa\u00f1ol",
  "weight": 34,
  "icon": Config.api.base + "/sites/default/files/genres/popular.png",
  "svg": Config.api.client_host + '/genres/popenespanol.svg'
 },
 "89": {
  "target_id": 89,
  "label": "R&B",
  "weight": 35,
  "icon": Config.api.base + "/sites/default/files/genres/rnb.png",
  "svg": Config.api.client_host + '/genres/rnb.svg'
 },
 "849": {
  "target_id": 849,
  "label": "Ragtime",
  "weight": 36,
  "icon": Config.api.base + "/sites/default/files/genres/ragtime.png",
  "svg": Config.api.client_host + '/genres/ragtime.svg'
 },
 "846": {
  "target_id": 846,
  "label": "Rap",
  "weight": 37,
  "icon": Config.api.base + "/sites/default/files/genres/rap.png",
  "svg": Config.api.client_host + '/genres/rap.svg'
 },
 "90": {
  "target_id": 90,
  "label": "Roots Reggae",
  "weight": 38,
  "icon": Config.api.base + "/sites/default/files/genres/Reggae.png",
  "svg": Config.api.client_host + '/genres/reggae.svg'
 },
 "853": {
  "target_id": 853,
  "label": "Soft Rock",
  "weight": 39,
  "icon": Config.api.base + "/sites/default/files/genres/softrock.png",
  "svg": Config.api.client_host + '/genres/softrock.svg'
 },
 "91": {
  "target_id": 91,
  "label": "Soul",
  "weight": 40,
  "icon": Config.api.base + "/sites/default/files/genres/soulmusic.png",
  "svg": Config.api.client_host + '/genres/soul.svg'
 },
 "862": {
  "target_id": 862,
  "label": "Techno",
  "weight": 41,
  "icon": Config.api.base + "/sites/default/files/genres/electronic_0.png",
  "svg": Config.api.client_host + '/genres/techno.svg'
 },
 "858": {
  "target_id": 858,
  "label": "World",
  "weight": 42,
  "icon": Config.api.base + "/sites/default/files/genres/world.png",
  "svg": Config.api.client_host + '/genres/world-africa.svg'
 },
 "861": {
  "target_id": 861,
  "label": "World, Africa",
  "weight": 43,
  "icon": Config.api.base + "/sites/default/files/genres/world_0.png",
  "svg": Config.api.client_host + '/genres/world-africa.svg'
 },
 "102": {
  "target_id": 102,
  "label": "World, Asia",
  "weight": 44,
  "icon": Config.api.base + "/sites/default/files/genres/china.png",
  "svg": Config.api.client_host + '/genres/world-asia.svg'
 },
 "860": {
  "target_id": 860,
  "label": "World, Europe",
  "weight": 45,
  "icon": Config.api.base + "/sites/default/files/genres/world_1.png",
  "svg": Config.api.client_host + '/genres/world-europe.svg'
 },
 "867": {
  "target_id": 867, "label": "World, Middle East", "weight": 46,
  "svg": Config.api.client_host + '/genres/world-europe.svg'
 }
};
export default GenreList;
