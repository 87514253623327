// import {fade} from '@material-ui/core/styles/colorManipulator';
// import { lighten } from '@material-ui/core/styles/colorManipulator';
/*
const getColor = (theme, type, opacity) => {
  const color =
    theme.palette[type][theme.palette.type === 'light' ? 'main' : 'dark'];

  if (!opacity) {
    return color;
  }

  return lighten(color, opacity);
};
*/

/*
const getGreyColor = (theme, opacity) => {
  const greyColor = theme.palette.grey['500'];

  if (!opacity) {
    return greyColor;
  }

  return lighten(greyColor, opacity);
};
*/

const drawerWidth = 0;

const PlayerTheme = (theme) => ({
 activePlayerEl : {
   width:'100%',
  "& div": {
   width:'100%!important',
   height:'auto'
  }
 },
 appBar: {
  top: 'auto',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('lg')]: {
   paddingLeft: drawerWidth
  },
 },
 dragHandlers: {
  marginTop: 0
 },
 dragHandler: {
  backgroundColor: theme.palette.secondary.main,
  boxShadow:'0px -3px 6px 0px rgb(0, 0, 0, .8)',
  position: 'absolute', top: -30,
  width:40, height: 30,
  alignContent: 'center',
  padding:3,
  textAlign:'center'
 },
 growHandler: {
  right: 12,
  borderRadius: '16px 16px 0 0',
 },
 shrinkHandler: {
  right: 70,
  height:25,
  top:-25,
  borderRadius: '16px 16px 0 0',
 },
 gridList: {
  width:'100%',
  flexWrap: 'nowrap',
  alignItems: 'center',
  transform: 'translateZ(0)',
 },
 playlistCtx: {
  padding: 0,
 }
});

export default PlayerTheme;
