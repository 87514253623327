import React, {Component} from 'react';
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as PlayYtIcon} from "../../images/youtube.svg";
import IconButton from "@material-ui/core/IconButton";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {getIdbySegment} from "../../redux/authActions";

class PlayButton extends Component {

 handleClick(e) {
  e.preventDefault();
  if (this.props.status === 'playing' && this.props.media === this.props.source && this.props.curTrack && this.props.curTrack.id[0].value === this.props.track.id[0].value) {
   this.props.pausePlayer(); // clicked another button of same track
  } else {
   this.props.playTrack(this.props.track, this.props.source);
  }
  return false;
 }

 render() {
  let icon, label = '';

  let playClass = '';
  let clr = this.props.color;
  if (this.props.status === 'playing' && this.props.media === this.props.source && this.props.curTrack && this.props.curTrack.id[0].value === this.props.track.id[0].value) {
   playClass = `curPlaying ${this.props.source}`;
   clr = (clr === 'primary') ? 'secondary' : 'primary';
  }

  const btnSize = getIdbySegment(document.location.pathname).tid > 0 ? 'large' : 'small'

  if (this.props.source === 'youtube')  {
   icon = <SvgIcon component={PlayYtIcon} color={clr} fontSize={btnSize}  />;
   label = 'Play Youtube';
  } else if (this.props.source === 'audio') {
   icon = <PlayIcon color={clr} fontSize={btnSize} />;
   label = 'Play TaMP3';
  } else if (this.props.source === 'video') {
   icon = <OndemandVideo color={clr} fontSize={btnSize} />;
   label = 'Play TaMP4';
  } else {
   icon = <PlayIcon color={clr} fontSize={btnSize} />;
   label = 'Play Sample'; // ??
  }

  if (this.props.showlabel) {
   return <ListItem aria-label={`${this.props.source} ${this.props.track.id[0].value}`} onClick={(e) => this.handleClick(e) } >
    <IconButton className={playClass} >
     {icon}
    </IconButton>
    <ListItemText primary={label}  />
   </ListItem>;
  }
  return (<IconButton className={playClass}
                      style={{background:'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%)'}}
                      aria-label={`${this.props.source} ${this.props.track.id[0].value}`} onClick={(e) => this.handleClick(e) } >{icon}</IconButton>);
 }
}

PlayButton.defaultProps = {
 color:'primary'
};

PlayButton.propTypes = {
 source:PropTypes.string, // THIS button's media source
 media:PropTypes.string, // actively playing media
 status:PropTypes.string, // whole player's status: 'playing', 'paused', ...
 color:PropTypes.string,
 track: PropTypes.object.isRequired,
 playTrack : PropTypes.func.isRequired,
 testPlay : PropTypes.func.isRequired,
 pausePlayer : PropTypes.func.isRequired
};

export default PlayButton;
