import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import AvatarBadge from "../UserAvatar/AvatarBadge";
import Badge from "@material-ui/core/Badge";
import API from "../../Util/API";
import {withSnackbar} from "notistack";
import {removeFromCart} from "../../redux/walletReducer";
import SanitizedHTML from "react-sanitized-html";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

class OrderUser extends React.Component {

    deleteOrder() {
        if (window.confirm('Are you sure you want to leave this game?')) {

            const noticeParams = {variant:'error', persist:true, action: key => (
                    <IconButton variant="outline-light"
                                onClick={() => { this.props.closeSnackbar(key); }}
                    >
                        <Close />
                    </IconButton>
                ) }

            return API.Delete(`forms/group/${this.props.cartItem.get('stores', 'gid')}/order_item/${this.props.orderuser['order_item_id']}`).then((res) => {
                var msg = API.checkError(res.data);
                if (msg.length > 0) {
                    this.props.enqueueSnackbar(<SanitizedHTML html={msg} />, noticeParams);
                } else {
                    this.props.onDelete();
                    this.props.dispatch(removeFromCart(this.props.cartItem.id()));
                    noticeParams.variant = 'info';
                    this.props.enqueueSnackbar(<SanitizedHTML html={res.data.success} />, noticeParams);
                }
            }).catch((err) => {
                var msg = API.getErrorMsg(err);
                console.log('token check error: ', msg);
                this.props.enqueueSnackbar(<SanitizedHTML html={msg} />, noticeParams);
                return err;
            });
        }

    }

    render() {
        if (!this.props.orderuser) return null;

        let href = `/users/${this.props.orderuser['target_id']}/group/${this.props.curGroup}`;
        const link = <Link to={href} style={{display:'block'}} ><AvatarBadge size={120} variant={'circular'} data={this.props.orderuser} badgeContent={this.props.orderuser['target_label']} /></Link>

        if (this.props.me && this.props.me.profile && this.props.me.profile.uid[0]['value']  === this.props.orderuser['target_id']) {
            return <Badge anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
                          style={{marginTop:12}}
          color={'primary'} badgeContent={<span style={{cursor:'pointer'}} onClick={() => this.deleteOrder()}>leave team</span>}>
                {link}
            </Badge>
        }
        return link;

    }
}

OrderUser.propTypes = {
    orders:PropTypes.array.isRequired,
    curGroup:PropTypes.number.isRequired,
    onDelete: PropTypes.func
};

export default withSnackbar(OrderUser);
