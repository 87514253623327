import React, {Component} from 'react';
import {deleteGroup, getIdbySegment, joinGroup, leaveGroup} from '../../redux/authActions';
import {shareUrl} from '../../redux/helpReducer';

import {Link} from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import LeaveGroup from '@material-ui/icons/CallMissedOutgoing';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LaunchIcon from '@material-ui/icons/Launch';
import BuilderButton from "./BuilderButton";

class GroupActions extends Component {

    confirmDelete(gid, e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete your group?')) {
            this.props.dispatch(deleteGroup(gid));
        }
    }

    confirmGroupLeave(gid, uid, e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to leave?')) {
            this.props.dispatch(leaveGroup(gid, uid));
        }
    }

    joinGroup(e, gid) {
        e.preventDefault();
        this.props.dispatch(joinGroup(gid))
    }

    render() {
        const {gjson, me} = this.props;
        const gid = gjson.get('id', 'value');
        const isMember = gjson.json.membership;
        console.log("IS MEMBER", isMember)
        const tdata = getIdbySegment(document.location.pathname);
        const isOwner = me.profile && gjson.get('uid', 'target_id') === me.profile.uid[0].value;

        return (
            <CardActions style={{flexWrap: 'wrap', justifyContent: 'center'}}>

                {window.REACT_APP_GID === 0 && !tdata.gid ?
                    <div style={{flexGrow: 1, width: '100%', margin: '10px auto 10px auto', textAlign: 'center'}}>
                        <Link to={'/group/' + gid} style={{textDecoration: 'none'}}>
                            <Button variant='contained' color='secondary' startIcon={<LaunchIcon/>}>Launch App</Button>
                        </Link>
                    </div>
                    : ''
                }

                <IconButton aria-label={`Share Group ${gid}`}
                            onClick={e => this.props.dispatch(shareUrl('/group/' + gid))}>
                    <ShareIcon/>
                </IconButton>
                {isOwner ?
                    <React.Fragment>
                        {tdata.gid === gid &&
                            <BuilderButton layout={'button'} gjson={gjson}/>
                        }
                        <IconButton aria-label='Delete Group'
                                    href={"/forms/group/" + gid + "/delete"}
                                    onClick={e => this.confirmDelete(gid, e)}>
                            <DeleteIcon/>
                        </IconButton>
                    </React.Fragment>
                    : (isMember) ?
                        <IconButton aria-label='Leave Group'
                                    href={"/forms/group/" + gid + "/users/" + me.profile.uid[0].value + "/delete"}
                                    onClick={e => this.confirmGroupLeave(gid, me.profile.uid[0].value, e)}>
                            <LeaveGroup/>
                        </IconButton>
                        :
                        (me.profile) ?
                            <Button endIcon={<GroupAdd/>} variant='outlined' aria-label='Join Group'
                                    href={"/forms/group/" + gid + "/members/add"}
                                    onClick={(e) => this.joinGroup(e, gid)}>
                                <small>Join</small>
                            </Button>
                            :

                            <Link to={`/group/${gid}/signup`}>
                                <IconButton aria-label='Group Registration'>
                                    <GroupAdd />
                                </IconButton>
                            </Link>
                }
            </CardActions>

        );
    }
}

export default GroupActions;
