import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const EFXTYPES = {
    "CLIP" : { label:'Clip', props:['start_time', 'end_time']},
    "PLAYBACK" : { label:'Playback Speed', props:['start_time', 'end_time', 'amount']},
    "ZOOM" : { label:'Zoom', props:['start_time', 'end_time', 'amount', 'x', 'y']},
};

class ClipEfx extends Component {

    render() {
        const {cjson} = this.props;

        return(<React.Fragment>
            {cjson.json.field_videfx.map(efx => {
                const etype = EFXTYPES[efx.efx_type];
                if (!etype) return console.warn('invalid efx type ' + efx.efx_type);
                return <div key={`efx-${efx.efx_type}-${efx.start_time}`}>
                    <strong>{etype.label}</strong>: {etype.props.map(p => <span style={{marginRight:5}}><strong>{p.replace('_time', '')}</strong>: <small>{efx[p]}</small></span>)}
                </div>
            })}
            </React.Fragment>
        );
    }
}

ClipEfx.propTypes = {
    classes: PropTypes.object.isRequired,
    cjson: PropTypes.object.isRequired,
};

const styles = theme => ({
    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0,
        position: 'relative'
    }
});

export default withStyles(styles, {withTheme: true})(ClipEfx);
