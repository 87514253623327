import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from "@material-ui/core/CardActions";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import UserAvatar from "../../Components/UserAvatar";
import ReplayActions from "./ReplayActions";
import {withRouter} from "react-router-dom";
import Grammer from "../../Util/Grammer";
import HighlightsLoader from "../clips/HighlightsLoader";

class Replay extends Component {

    render() {
        const {classes, jreplay} = this.props;

        const baseLink = `/group/${jreplay.get('gid', 'target_id')}/replays/${jreplay.get('id', 'value')}`

        const tagged = <AvatarGroup max={9} style={{flexGrow: 1, width: '100%'}}>
            {jreplay.json.field_leaders.map(o => <UserAvatar variant={'circular'} key={o.target_id} data={o}/>)}
        </AvatarGroup>

        // <Link to={baseLink}>
        let subheader = [<div key={'replay-created-date'}>{jreplay.getTime('created', 'MMM Do \'YY')}</div>];
        if (jreplay.get('field_track_count') > 0) {
            subheader.push(<div key={'trackscount'}>{jreplay.get('field_track_count') + ' ' + Grammer.g('tracks', 'p')}</div>)
        }

        return (<div className={classes.container}>
                <Card className={classes.headCard}>
                    <div style={{height:jreplay.get('field_cover', 'url') ? 250 : 100,
                        backgroundImage:`url(${jreplay.get('field_cover', 'url')})`,
                        backgroundPosition:"center center", backgroundSize:'cover' }}>
                        <CardHeader
                            style={{backgroundColor:'rgba(0, 0, 0, .5)'}}
                            avatar={<img src={jreplay.get('field_sport', 'url')}/>}
                            title={jreplay.get('field_name', 'value')}
                            subheader={subheader}
                            action={<ReplayActions jreplay={jreplay}
                                                   dispatch={this.props.dispatch}/>}
                        />
                    </div>

                    <CardActions>
                        {tagged}
                    </CardActions>
                </Card>


                {this.props.match.params.pid && <HighlightsLoader playlist={jreplay} {...this.props} />}
            </div>
        );
    }
}

Replay.propTypes = {
    classes: PropTypes.object.isRequired,
    jreplay: PropTypes.object.isRequired // Drupal2Json of playlist_gc (replay)
};

const styles = theme => ({
    container: {
        width: '100%',
        background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(10,119,213,0) 50%, ${theme.palette.background.paper} 100%)`,
        marginBottom: 25
    },
    headCard : {
        maxWidth:900,
        margin:'auto'
    }
});

export default withStyles(styles, {withTheme: true})(withRouter(Replay));
