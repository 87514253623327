import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';

class PlaylistTimeline extends Component {

 translateTime(key, label) {
  let val = this.props.json.getTime(key, key === 'field_awarding_starts' ? 'MMMM Do YYYY, h:mm:ss a' : 'MMMM Do YYYY'); // uses moment format
  if (val && new Date(this.props.json.get(key)) < new Date().getTime()) {
   label = label.replace('Starts', 'Started');
  }
  return [val, label];
 }

 render() {
  const listingStart = this.translateTime('field_listening_starts', 'Published');
  const ratingStart = this.translateTime('field_rating_starts', 'Rating Starts');
  const awardingStart = this.translateTime('field_awarding_starts', 'Payouts and Awards');

  if (!listingStart[0] && !ratingStart[0] && !awardingStart[0]) return '';

  return (
   <Grid container spacing={1}
    direction='row'
    justifyContent='space-around'
    alignContent='flex-start'
    alignItems='center'
    style={{marginTop:35}}>

    {listingStart[0] !== ratingStart[0] ?
     <Grid item ><ListItemText primary={listingStart[1]} secondary={listingStart[0]} /></Grid>  : ''}

    {ratingStart[0] === null ?
     <Grid item ><ListItemText primary={ratingStart[1]} secondary={'Once enough of us buy-in'} /></Grid>
     :
    <Grid item ><ListItemText primary={ratingStart[1]} secondary={ratingStart[0]} /></Grid>
    }

    {(awardingStart[0] === null && this.props.json.get('field_pool_min') > 0) ?
     <Grid item ><ListItemText primary={awardingStart[1]} secondary={'Two weeks after the pool reaches $'+ this.props.json.get('field_pool_min') } /></Grid>
     :
     (awardingStart[0] !== null) ?
      <Grid item ><ListItemText primary={awardingStart[1]} secondary={awardingStart[0]} /></Grid>
      : ''}
   </Grid>
  );
 }
}

export default PlaylistTimeline;
