import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MediaItem from "../../Components/MediaItem";
import {Link} from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import HighlightActions from "./HighlightActions";
import {withRouter} from "react-router";
import EditableTitle from "./EditableTitle";

class HighlightBlock extends Component {

    buildTitle() {
        // format efx json
    }

    render() {
        const {classes} = this.props;
        const json = new Drupal2Json(this.props.clip);
        const gid = json.get('gid', 'target_id');
        const pid = json.get('field_playlist_gc', 'target_id');
        const entityLink = `/group/${gid}/replays/${pid}/highlights/${json.get('id', 'value')}`
        let editLink = null;
        let footage = null;
        if (this.props.clip.node) {
            let footage = new Drupal2Json(this.props.clip.node);
            const rid = footage.get('field_product', 'target_id');
            const footageLink = `/group/${gid}/rewards/${rid}/footage/${json.get('entity_id', 'target_id')}`
            editLink = `${footageLink}/clips/${json.get('id', 'value')}`;
        }

        const toPass = {
            title: json.can_edit(this.props.me) ? <EditableTitle title={json.getLabel()} entityLink={editLink} me={this.props.me} /> : json.getLabel()
        };
        if (json.get('field_user_tags', 'target_label')) {
            let useLink = "/users/" + json.get('field_user_tags', 'target_id');
            if (gid) {
                useLink += '/group/' + gid;
            }
            toPass.subheader = <React.Fragment><small>Tagged</small> <Link to={useLink}>{json.get('field_user_tags', 'target_label')}</Link></React.Fragment>;
        }

        if (!this.props.match.params.pid && footage) {
            toPass.avatar = <img src={footage.get('field_sport', 'url')}/>;
        }

        let media = json.findPropByVal('field_clip', 'youtube', 'target_bundle');
        if (!media) media = this.props.clip.field_clip[0];

        return (<Card className={classes.card} data-href={entityLink}>
                <div style={{maxWidth:800, margin:'auto'}} >
                    <CardHeader {...toPass} />
                    <MediaItem entityLink={entityLink} media={media} poster={json.get('field_cover', 'url')} />
                </div>
                <CardActions>
                    <HighlightActions editLink={editLink} entityLink={entityLink} clip={json} />
                </CardActions>
            </Card>
        );
    }
}

HighlightBlock.propTypes = {
    classes: PropTypes.object.isRequired,
    clip: PropTypes.object.isRequired
};

const styles = theme => ({
    card: {
        background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(10,119,213,0) 50%, ${theme.palette.background.paper} 100%)`,
        marginBottom:25,
        marginTop:25
    }
});

export default withStyles(styles, {withTheme: true})(withRouter(HighlightBlock));
