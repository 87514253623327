import React, {Component} from 'react';
import API from "../../Util/API";
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles";
import Config from "../../Config";
import {loadForm} from "../../redux/formsReducer";

class Collage extends Component {

    constructor(p) {
        super(p);
        this.state = {covers:false}
    }

    componentDidMount() {
        let search = document.location.search;
        API.Get('/tam/covers/all' + search).then((res) => {
            this.setState({covers:res.data.data});
        });
    }

    onClick(c) {
        if (this.props.isLoggedIn === true) {
            this.props.dispatch(loadForm(`/forms/track/${c.nid}/edit`, 'drawer'))
        }
    }

    render() {
        if (this.state.covers === false) return 'loading...';
        return (
            <Grid container className={this.props.classes.collage}>
                {this.state.covers.map((c,i) =>
                    <a href={`${Config.api.base}/node/${c.nid}/edit`} target={"_blank"} key={'collage'+i}
                       onClick={e => this.onClick(c)}
                    >
                    <Grid item
                 className={this.props.classes.imgBox} alt={c['nid']}
                 style={{backgroundImage:`url(${process.env.REACT_APP_CDN_URL + c['url']})`}}
                ></Grid></a>)}
            </Grid>
        );
    }
}


const styles = theme => ({
    collage : {
      display:'flex',
      flexWrap:'wrap',
      alignContent: 'flex-start'
    },
    imgBox: {
        height:50,
        width:50,
        backgroundSize:'cover',
        backgroundPosition:'center center',
    }
});


export default withStyles(styles)(Collage);
