import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Drupal2Json from '../../Util/Drupal2Json';
import RatingStar from '../RatingStar';
import BetButton from '../BetButton';
import CommentButton from '../CommentButton';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import SkipNext from '@material-ui/icons/SkipNext';
import MoreVert from '@material-ui/icons/MoreVert';
import {shareUrl} from '../../redux/helpReducer';
import PlayButton from "../PlayButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Grammer from '../../Util/Grammer';
import Copier from "../Copier";
import {playNext} from '../../redux/playerReducer';
import {loadForm} from "../../redux/formsReducer";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {isEnabled} from "../../Util/EnabledFeature";

class TrackActions extends Component {
 constructor(props) {
  super(props);
  this.state = {expanded: false};
 }

 openMenu(e) {
  this.setState({expanded: true, anchorEl: e.currentTarget});
 }

 closeMenu(e) {
  this.setState({expanded: false});
 }

 ellipse(str) {
  let max = Math.round(window.innerWidth / 17);
  if (!str || String(str).length < max) return str;
  return String(str).substr(0, max - 3) + '...';
 }

 render() {
  const {classes} = this.props;
  const json = new Drupal2Json(this.props.track);
  const node = new Drupal2Json(this.props.track.node);
  const trackUrl = `/group/${json.get('gid', 'target_id')}/playlists/${json.get('field_playlist_gc', 'target_id')}/tracks/${json.get('id', 'value')}`;

  const inContext = [];
  const inPage = [];


  if (this.props.ctas['title'] === true) {
   inPage.push(
    <span key={'track-title'}>
     <Typography variant={'h5'} color='secondary'><strong>{this.ellipse(node.get('title'))}</strong></Typography>
     <Typography variant={'h6'} color='textSecondary'><strong>{this.ellipse(node.get('field_artists'))}</strong></Typography>
    </span>
   );
  }

  if (isEnabled('tracks:rating', true)) {
   inContext.push(<ListItem alignItems="center" key='ratebtn' className={classes.actionLine}>
    <RatingStar showSlider={this.state.showSlider} color={this.props.color} tjson={json}/>
    <ListItemText onClick={e => this.setState({showSlider: !this.state.showSlider})}
                  primary={'Vote!'}/></ListItem>);

   if (this.props.ctas['rate'] === true) {
    inPage.push(<RatingStar color={this.props.color} tjson={json} key='ratebtn'/>);
   }
  }

  const isStreamingCasino = isEnabled('playlists:field_judge', 'streamgains-yt');

  if (isEnabled('playlists:cta', 'bet') || isStreamingCasino) {
   inContext.push(<BetButton showlabel={true} color={this.props.color} json={json} profile={this.props.profile}
                             dispatch={this.props.dispatch} key='betbutton' className={classes.actionLine}/>);

   if (this.props.ctas['bet'] === true) {
    inPage.push(<BetButton color={this.props.color === 'primary' ? 'secondary' : 'primary'} json={json} profile={this.props.profile}
                           asButton={isStreamingCasino}
                           label={window.store.getState().auth.gFeatures['playlists:field_judge'] === 'streamgains-yt' ? 'Wager' : window.store.getState().auth.gFeatures['playlists:cta']}
                           dispatch={this.props.dispatch} key='betbutton'/>);
   }
  }

  if (node.findPropByVal('field_media', 'youtube', 'target_bundle') === false) {
   // do nothing
  } else {
   inContext.push(<PlayButton source='youtube' showlabel={true} color={this.props.color}
    track={this.props.track} key='youtube' className={classes.actionLine}/>);
   if (this.props.ctas['youtube'] === true)
    inPage.push(<PlayButton source='youtube' color={this.props.color} track={this.props.track}
     key='youtube'/>);
  }

  if (node.findPropByVal('field_media', 'audio', 'target_bundle') === false) {
   // do nothing
  } else {
   inContext.push(<PlayButton source='audio' showlabel={true} color={this.props.color} track={this.props.track}
    key='audio' className={classes.actionLine}/>);
   if (this.props.ctas['mp3'] === true)
    inPage.push(<PlayButton source='audio' color={this.props.color} track={this.props.track} key='audio'/>);

  }

  if (node.findPropByVal('field_media', 'video', 'target_bundle') === false) {
   // do nothing
  } else {
   inContext.push(<PlayButton source='video' showlabel={true} color={this.props.color} track={this.props.track}
    key='video' className={classes.actionLine}/>);
   if (this.props.ctas['mp3'] === true)
    inPage.push(<PlayButton source='video' color={this.props.color} track={this.props.track} key='video'/>);

  }

  inContext.push(<ListItem alignItems="center" className={classes.actionLine} key='sharebtn'
   aria-label={`Share Track ${json.get('id', 'value')}`}
   onClick={e => this.props.dispatch(shareUrl(trackUrl))}>
   <IconButton>
    <ShareIcon color={this.props.color}/>
   </IconButton>
   <ListItemText primary='Share'/>
  </ListItem>);
  if (this.props.ctas['share'] === true) {
   inPage.push(<IconButton key='sharebtn' aria-label={`Share Track ${json.get('id', 'value')}`}
    onClick={e => this.props.dispatch(shareUrl(trackUrl))}>
    <ShareIcon color={this.props.color}/>
   </IconButton>);
  }

  if (isEnabled('tracks:field_comments', true)) {
   inContext.push(<ListItem alignItems="center" key='commentbtn' className={classes.actionLine}>
    <CommentButton profile={this.props.profile} showComments={this.state.showComments}
     color={this.props.color} json={json} dispatch={this.props.dispatch}/>
    <ListItemText onClick={e => this.setState({showComments: !this.state.showComments})}
     primary={'Comments'}/></ListItem>);

   if (this.props.ctas['comments'] === true) {
    inPage.push(<CommentButton profile={this.props.profile} color={this.props.color} json={json}
     key='commentbtn' dispatch={this.props.dispatch}/>);
   }
  }

  if (isEnabled('tracks:create', true)) {
   if (this.props.profile) {
    inContext.push(<ListItem aria-label={'copy track'} alignItems="center" key='copybtn'
                             className={classes.actionLine}>
     <Copier source={json} color={this.props.color}/>
     <ListItemText primary={'Copy Track'}/></ListItem>);
   }
  }

  inContext.push(<ListItem alignItems="center" key='metabtn' className={classes.actionLine}>
   <IconButton>
    <Link to={trackUrl}>
     <InfoIcon color={this.props.color}/>
    </Link>
   </IconButton>
   <ListItemText primary={<Link style={{textDecoration: 'none'}}
    to={trackUrl}>{Grammer.g('Tracks', 's')} Homepage</Link>}/></ListItem>);
  if (this.props.ctas['info'] === true) {
   inPage.push(<Link key='metabtn' to={trackUrl}>
    <IconButton aria-label="Track Homepage">
     <InfoIcon color={this.props.color}/>
    </IconButton></Link>);
  }

  if (this.props.profile) {
   inContext.push(<ListItem alignItems="center" key='editbtn' className={classes.actionLine}
    style={{cursor: 'pointer'}}
    href={"/forms" + trackUrl + '/edit'}
    onClick={() => this.props.dispatch(loadForm("/forms" + trackUrl + '/edit', 'drawer'))}>
    <IconButton>
     <EditIcon color={this.props.color}/>
    </IconButton>
    <ListItemText primary={'Edit ' + Grammer.g('tracks', 's')}/>
   </ListItem>);
   if (this.props.ctas['edit'] === true) {
    inPage.push(
     <IconButton key='editbtn'
                 href={"/forms" + trackUrl + '/edit'}
                 onClick={() => this.props.dispatch(loadForm("/forms" + trackUrl + '/edit', 'drawer'))}>
      <EditIcon color={this.props.color}/>
     </IconButton>);
   }
  }


  if (this.props.ctas['next'] === true) {
   inPage.push(
    <IconButton key={'nextbtn'} aria-label="Play Next" onClick={e => this.props.dispatch(playNext())}>
     <SkipNext color={this.props.color}/>
    </IconButton>);
  }


  const pUrl = `/group/${json.get('gid', 'target_id')}/playlists/${json.get('field_playlist_gc', 'target_id')}`;

  return (
   <Grid container justifyContent={'space-between'} alignItems='center'
    wrap='nowrap'>
    {inPage.length === 1 ? <div className={classes.primeCta}>{inPage}</div> : inPage}
    <IconButton size='small' aria-haspopup="true" aria-label={`Track Menu ${json.get('id', 'value')}`} onClick={e => this.openMenu(e)}>
     <MoreVert size='small' />
    </IconButton>
    <Popover
     anchorEl={this.state.anchorEl}
     onClose={e => this.closeMenu(e)}
     open={this.state.expanded}>
     <Card>
      <CardHeader
       avatar={
        <Link
         to={pUrl}
         title={json.get('field_playlist_gc', 'target_label')}
        ><Avatar
          variant="rounded"
          title={json.get('field_playlist_gc', 'target_label')}
          src={json.get('field_playlist_gc', 'url')}
         /></Link>
       }
       subheader={<Link
        to={pUrl}
        title={json.get('field_playlist_gc', 'target_label')}
       >{json.get('field_playlist_gc', 'target_label')}</Link>}
       title={<div>
        <Typography variant={'h5'}> {node.get('title')}</Typography>
        <Typography variant={'h6'}>{node.get('field_artists')}</Typography>
       </div>}

      />
      <CardContent>
       <List dense={true}>
        {inContext}
       </List>
      </CardContent>
      <CardActions>
       <AvatarGroup max={9} style={{margin: '3px 0 0 20px'}}>
        {this.props.track.node.field_genres.map(o => (
         <Avatar key={o.target_id} classes={{root: classes.genreIcon}}>
          <img
           height={20}
           src={o.url}
           alt={o.target_label}/>
         </Avatar>
        ))}
       </AvatarGroup>
      </CardActions>
     </Card>
    </Popover>
   </Grid>
  );
 }
}

TrackActions.defaultProps = {
 color: 'primary',
 ctas: {
  'youtube': true,
  'title': false,
  'mp3': false,
  'rate': false,
  'comments' : false,
  'info': false,
  'edit': false,
  'delete': false,
  'bet': false,
  'share': false
 }
};

TrackActions.propTypes = {
 color: PropTypes.string,
 ctas: PropTypes.object.isRequired,
 track: PropTypes.object.isRequired,
 dispatch: PropTypes.func.isRequired,
 profile: PropTypes.object
};

const styles = theme => ({
 actionLine: {
  display: 'flex',
  alignItems: 'center'
 },
 primeCta: {
  borderRadius: 45,
  backgroundColor: theme.palette.action.selected,
 },
 genreIcon: {
  backgroundColor: theme.palette.background.paper+"!important",
  borderColor: theme.palette.background.paper+"!important"
 }
});


export default withStyles(styles, {withTheme: true})(TrackActions);
