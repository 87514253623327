import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {withRouter} from "react-router";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: '5px auto 5px auto',
        padding: '0 5px',
        fontSize: 14,
        width: '100%',
        flexGrow: 1,
        color: theme.palette.grey[500],
    }
});

class ListMeta extends Component {

    handleStateChange(e) {

    }

    makeLabel(filter) {
        let str = filter;
        if (str.indexOf(".") > -1) {
            str = str.substring(str.indexOf(".") + 1);
        }
        str = str.replace('_', ' ');
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    render() {
        // const {meta} = this.props.meta;

        return (
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap' style={{padding: '2%'}}>
                {this.props.meta.total_items > 0 &&
                    <Grid item>
                        <Typography
                            variant={'h5'}>Showing {`${this.props.meta.start_index + 1} - ${this.props.meta.end_index} of ${this.props.meta.total_items}`}</Typography>
                    </Grid>
                }

                <Grid item c>
                    <Chip
                        aria-label={`sort label`}
                        onClick={() => console.log('')}
                        avatar={<Avatar>sort</Avatar>}
                        style={{marginRight:8}}
                        label={`${this.makeLabel(this.props.meta.sort)}, ${this.props.meta.sort_dir}`}
                    />

                    <Chip
                        aria-label={`State label`}
                        onClick={() => console.log('')}
                        avatar={<Avatar>state</Avatar>}
                        label={`${this.makeLabel(this.props.meta.state)}`}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(ListMeta))
