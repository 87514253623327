import ConfirmWager from './ConfirmWager';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    const newProps = {};
    newProps.meId = (state.auth.me && state.auth.me.profile) ? state.auth.me.profile.uid[0].value : false;
    newProps.balance = state.wallet.balance;
    if (state.wallet.paymethods.length > 0) {
        const pay = state.wallet.paymethods.findIndex(card => card.is_default > 0);
        if (pay) {
            newProps.paymethodId = pay.id
        }
    };
    return newProps;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmWager);
