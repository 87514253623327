import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";

// import {setfont} from "../../redux/authReducer";

class FontSelector extends Component {

    constructor(p) {
        super(p);
        this.typography = {
            h1 : {weight:900, family:''},
            h2 : '',
            h3 : '',
            h4 : '',
            h5 : '',
            h6 : '',
            h7 : '',
            body1 : '',
            body2 : '',
            subtitle1 : '',
            subtitle2 : '',
            caption : '',
            overline : '',
        }
        this.state = {};
        for(let i in this.typography) {
            this.state[i] = '';
        }
    }

    buildUrl(font) {
        // https://developers.google.com/fonts/docs/developer_api?apix_params=%7B%22prettyPrint%22%3Atrue%7D
        /* var apiUrl = [];
        apiUrl.push('https://fonts.googleapis.com/css?family=');
        apiUrl.push(font.family.replace(/ /g, '+'));
        if (contains('italic', font.variants)) {
            apiUrl.push(':');
            apiUrl.push('italic');
        }
        if (contains('greek', font.subsets)) {
            apiUrl.push('&subset=');
            apiUrl.push('greek');
        }

         */

        // url: 'https://fonts.googleapis.com/css?family=Anonymous+Pro:italic&subset=greek'
        // var url = apiUrl.join('');
    }

    onBlur() {
        // validate url
        // this.props.dispatch(setfont(font));
    }

    render() {

        return (
            <div style={{padding:'1%'}}>
                <div>Select weights to include</div>
                <div>Include Italic</div>
                {Object.entries(this.typography).map(arr => {
                    let key = arr[0];
                    return <TextField value={this.state[key]}
                                       type={'url'}
                                       fullWidth={true}
                                       onBlur={() => this.onBlur()}
                                       onChange={e => this.setState({[key]:e.currentTarget.value})}
                                       name={'font-'+key}
                                       label={key}
                            />
                })}
            </div>
        );


    }
}

export default FontSelector;
