import React from 'react';
import {Link} from 'react-router-dom';
import Drupal2Json from '../../Util/Drupal2Json';
import SanitizedHTML from 'react-sanitized-html';
import GroupActions from '../../Components/GroupActions';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Config from '../../Config';
import {pStyles} from "../../Util/ThemeUtils";
import Typography from "@material-ui/core/Typography";

class GroupBlock extends React.Component {

    render() {
        const {classes} = this.props;
        const item = this.props.data;
        const json = new Drupal2Json(item);

        let body = json.get('field_body', 'summary');
        if (!body) body = json.get('field_body', 'value');

        return (
            <Card className={classes.card} style={{textAlign:'center', padding:'3%'}}>
                {json.get('field_cover', 'url') ?
                    <Link to={"/group/" + json.get('id', 'value')}>
                        <img
                            className={classes.max100}
                            src={json.get('field_cover', 'url')}
                            alt="Group Cover"
                        />
                    </Link> : ''}

                <Typography style={{marginTop:15}} variant={'h3'} ><Link to={"/group/" + json.get('id', 'value')}>
                    {json.get('label', 'value')}
                </Link></Typography>
                {body && <SanitizedHTML allowedTags={Config.allowedTags} html={body} />}
                {/* <CardHeader
                    avatar={
                        <Link to={"/users/" + json.get('uid', 'target_id') + '/group/' + json.get('id', 'value')}>
                            <UserAvatar data={item.uid[0]}/>
                        </Link>
                    }
                    title={
                        <Link to={"/users/" + json.get('uid', 'target_id') + '/group/' + json.get('id', 'value')}>
                            {json.get('uid', 'target_label')}
                        </Link>
                    }
                /> */}
                <GroupActions dispatch={this.props.dispatch} me={this.props.me} gjson={json}/>

            </Card>
        );
    }
}

export default withStyles(pStyles)(GroupBlock);
