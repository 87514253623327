import React, {Component} from 'react';
import ProgressLoading from '../../Components/ProgressLoading';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";
import {getIdbySegment} from "../../redux/authActions";
import PropTypes from "prop-types";
import {storeTrackList} from "../../redux/playerReducer";
import API from "../../Util/API";
import {loadForm, submitTrack} from "../../redux/formsReducer";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Drupal2Json from "../../Util/Drupal2Json";
import TrackActions from "../../Components/TrackActions/TrackActions";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import LaunchIcon from '@material-ui/icons/Launch';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import RemoteAPI from "../../Util/RemoteAPI";

class MetaBuilder extends Component {

 constructor(props) {
  super(props);
  this.url = document.location.search;
  this.state = {mbIds: {}, searchType: 'open', mbUrl : '',
   fields: ['mb_id', 'album', 'year', 'artist_mb_id'], // lyrics
   Mb2Tam:false};
 }

 componentDidMount() {
  RemoteAPI.Get('/assets/Mb2Tam.json').then(json => {
   console.log(json.data);
   this.setState({Mb2Tam:json.data});
  });
  this.refresh();
 }

 refresh() {
  const tdata = getIdbySegment(document.location.pathname);

  this.url = `/group/${tdata.gid}/playlists/${tdata.pid}/tracks?perpage=500&fields=${this.state.fields.join('|')}`;

  API.Get(this.url).then((res) => {
   this.props.dispatch(storeTrackList(tdata.gid, tdata.pid, res.data, false));
  });
 }

 componentDidUpdate(prevProps) {
  if (prevProps.ptlist.loading === true && !prevProps.ptlist.data && this.props.ptlist.loading === false) {
   this.props.onLoaded(); // PlaylistGC autoscrolls to the list
  }
 }

 getTidsFromTag(tags, genres) {
  let aliasTids = {};
  for(let i=0; i < tags.length; i++) {
   let tag = tags[i];
   if (typeof this.state.Mb2Tam[tag] === 'undefined') {
    console.log("Unmapped MB Genre: " + tag);
   } else {
    let terms = this.state.Mb2Tam[tag];
    for(let e=0; e < terms.length; e++) {
     aliasTids[terms[e].target_id] = terms[e];
    }
   }
  }

  console.log('aliases tids ', aliasTids, ' of ', tags);

  if (!genres || genres.length === 0) {
   return Object.values(aliasTids);
  }
  let toAdd = [];
  for (let tid in aliasTids) {
   let has = genres.find(g => g.target_id === tid);
   if (!has) {
    toAdd.push(aliasTids[tid]);
   }
  }
  console.log("ADD ", toAdd, ' to ', genres);
  console.log(toAdd);
 }

 searchMusicBrainz(json, type) {
  if (!type) type = 'recording';
  // https://musicbrainz.org/ws/2/recording/?query=recording:%22water%20runs%20dry%22%20AND%20artist:Boyz%20II%20Men&fmt=json&inc=aliases+artist+genres
  let url = "https://musicbrainz.org/ws/2/"+type+"/?query=title:" + encodeURIComponent(`"${json.get('title')}"`);
  if (json.get('field_artists')) {
   url += '%20AND%20artist:' + encodeURIComponent(json.get('field_artists'));
  }
  url += "&fmt=json&limit=10";

  let nid = json.get('nid');
  API.Get(url).then((res) => {
   let all = {...this.state.mbIds};
   if (typeof all[nid] === 'undefined') all[nid] = {search: res.data};
   else all[nid].search = res.data;
   console.log(all);
   this.setState({mbIds: all});
  });
 }

 lookupMusicBrainz(mbid, nid, type) {
  if (!mbid) return false;
  // ex. https://musicbrainz.org/ws/2/work/?query=work:water%20runs%20dry%20AND%20artist:Boyz%20to%20Men&fmt=json&inc=aliases+artist+genres
  let url = "https://musicbrainz.org/ws/2/recording/" + mbid;
  url += "?inc=artist-credits+releases+tags&fmt=json";

  API.Get(url).then((res) => {
   let all = {...this.state.mbIds};
   if (typeof all[nid] === 'undefined') all[nid] = {lookup: res.data};
   else all[nid].lookup = res.data;
   all[nid].mbid = mbid;
   console.log(all);
   this.setState({mbIds: all});
  });

  /// https://acousticbrainz.org/api/v1/low-level?recording_ids=96db702b-059b-442c-9002-53ee413d2c65&features=tonal.key_key;tonal.key_scale;tonal.key_strength;rhythm.bpm
 }

 handleMbUrlSearch(url, nid) { // ex. https://musicbrainz.org/recording/c3ca44c3-2770-46d1-b3db-06405c60b004
  if (url.indexOf('https://') === 0) {
   this.lookupMusicBrainz(url.substr(url.lastIndexOf('/') + 1), nid);
  } else if (url.indexOf('recording') > 0 || url.indexOf('release') > 0) {
   this.lookupMusicBrainz(url, nid);
  } else {
   window.alert('Please enter the recording URL or ID');
  }
 }

 buildMbUrl(json, type) {
  if (!type) type = 'recording';
  let url = `https://musicbrainz.org/search?&type=${type}&method=indexed&query=title:%22${encodeURIComponent(json.get('title'))}%22`;
  if (json.get('field_artists')) {
   url += `%22%20AND%20artist:${encodeURIComponent(json.get('field_artists'))}`;
  }
  return url;
 }

 updateField(item, fieldname, val) {
  let tjson = new Drupal2Json(item);
  let obj = {'field_playlist_gc':[{target_id:item.field_playlist_gc[0].target_id}], id:item.id}; // minimum for MP3_UPDATE
  obj[fieldname] = [{'value':val}];
  this.props.dispatch(submitTrack(`/forms/group/${tjson.get('gid', 'target_id')}/playlists/${tjson.get('field_playlist_gc', 'target_id')}/tracks/${tjson.get('id')}/edit`, obj));
 }

 /* onMbIdChange(val, nid) {
        let all = {...this.state.mbIds};
        if (typeof all[nid] === 'undefined') all[nid] = {url: val};
        else all[nid].url = val;
        this.setState({mbIds: all});
    }  */

 buildRow(item, i) {
  let json = new Drupal2Json(item.node);
  let nid = json.get('nid');
  return <React.Fragment key={'tr-meta-' + i}>
   <TableRow>
    <TableCell component="th" scope="row">
     {this.state.searchType === 'open' ?
      <React.Fragment><a href={this.buildMbUrl(json, 'recording')} target='_blank' rel="noopener noreferrer">
       <Button startIcon={<LaunchIcon/>}>Recordings</Button>
      </a>
      <a href={this.buildMbUrl(json, 'release')} target='_blank' rel="noopener noreferrer">
       <Button startIcon={<LaunchIcon/>}>Releases</Button>
      </a>
      </React.Fragment>
      :
      <Button variant='contained' onClick={e => this.searchMusicBrainz(json)}>Search</Button>
     }

    </TableCell>
    <TableCell component="th" scope="row">
     {json.get('title')}
     {json.get('field_mb_id') ?
      <div><a href={`https://musicbrainz.org/recording/${json.get('field_mb_id')}`} target='_blank'
       rel="noopener noreferrer">MB Recording</a></div> :

      ''}

     {
      this.state.mbIds[nid] && this.state.mbIds[nid].mbid && this.state.mbIds[nid].url !== json.get('field_mb_id') ?
       <React.Fragment><IconButton size={'small'} onClick={e => this.updateField(item, 'field_mb_id', this.state.mbIds[nid].url)}><CheckIcon/></IconButton></React.Fragment>  : ''
     }

    </TableCell>
    <TableCell component="th" scope="row">
     {json.get('field_artists')}
     {json.get('field_artist_mb_id') ?
      <div><a href={`https://musicbrainz.org/artist/${json.get('field_artist_mb_id')}`}
       target='_blank' rel="noopener noreferrer">MB Artist</a></div> :
      ''
     }
    </TableCell>
    <TableCell component="th" scope="row">{json.get('field_album')}</TableCell>
    <TableCell component="th" scope="row">{json.get('field_year')}</TableCell>
    <TableCell component="th" scope="row">
     {item.node.field_genres.map(o => o.target_label).join(' - ')}
    </TableCell>
    <TableCell>
     <Button variant='contained' size='small'
      disabled={this.state.mbUrl === ''}
      onClick={e => this.handleMbUrlSearch(this.state.mbUrl, nid)}>Lookup</Button>

     { this.state.mbIds[nid] && this.state.mbIds[nid].lookup && this.state.mbUrl === this.state.mbIds[nid].lookup.id  ?
      <IconButton size={'small'} onClick={e => this.updateField(item, 'field_mb_id', this.state.mbIds[nid].lookup.id)}></IconButton>
      : ''
     }
    </TableCell>
    <TableCell component="th" scope="row">
     <TrackActions key={'metaActions-' + nid}
      profile={this.props.me.profile} track={item}
      dispatch={this.props.dispatch}/></TableCell>
   </TableRow>
   {(this.state.mbIds[nid] && this.state.mbIds[nid].search) ?
    this.state.mbIds[nid].search.recordings.map((o, i) =>
     <TableRow key={'mbrecordings-'+i} style={{background: 'red'}}>
      <TableCell></TableCell>
      <TableCell>{o['artist-credit'][0].name}</TableCell>
      <TableCell>{o.title}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><Button onClick={e => this.lookupMusicBrainz(o.id, nid)}>view
                            meta</Button></TableCell>
     </TableRow>)
    : ''}

   {(this.state.mbIds[nid] && this.state.mbIds[nid].lookup) ?
    this.state.mbIds[nid].lookup.releases.map((o, i) => {
     let hasTags = this.state.mbIds[nid].lookup.tags.map(e => e.name);
     let tagsToAdd = this.getTidsFromTag(hasTags, item.node.field_genres);
     let year = new Date(o.date).getFullYear();
     return (<TableRow key={'mblookup-'+i} style={{background: 'rgba(255,255,255,.2)'}}>
      <TableCell>
       {o.country}
      </TableCell>
      <TableCell>
       {
        this.state.mbIds[nid].lookup.title === json.get('title') ?
         '"' :
         <React.Fragment>{this.state.mbIds[nid].lookup.title} <IconButton size={'small'}
          onClick={e => this.updateField(item, 'title', o.title)}><CheckIcon/></IconButton></React.Fragment>
       }
      </TableCell>
      <TableCell>
       {
        o['artist-credit'][0].name === json.get('field_artists') ?
         '"' : <React.Fragment>{o['artist-credit'][0].name} <IconButton size={'small'}
          onClick={e => this.updateField(item, 'field_artists', o['artist-credit'][0].name)}><CheckIcon/></IconButton></React.Fragment>
       }
      </TableCell>
      <TableCell>
       {
        o.title === json.get('field_album') ?
         '"' : <React.Fragment>{o.title} <IconButton size={'small'}
          onClick={e => this.updateField(item, 'field_album', o.title)}><CheckIcon/></IconButton></React.Fragment>
       }
      </TableCell>
      <TableCell>
       {
        year === json.get('field_year') ?
         o.date : <React.Fragment>{o.date} <IconButton size={'small'}
          onClick={e => this.updateField(item, 'field_year', year)}><CheckIcon/></IconButton></React.Fragment>
       }
      </TableCell>
      <TableCell>
       {hasTags.length === 0 ? ''
        :
        (tagsToAdd.length === 0)
         ?
         '"'
         :
         <React.Fragment>
                                    Add {tagsToAdd.map(t => t.label).join(' - ')}
          <IconButton size={'small'}
           onClick={e => this.updateField(item, 'field_genres', tagsToAdd)}><CheckIcon/></IconButton>
         </React.Fragment>
       }
      </TableCell>
      <TableCell>

      </TableCell>
      <TableCell></TableCell>
     </TableRow>);
    })
    : ''
   }
  </React.Fragment>;
 }

 render() {
  const {classes} = this.props;
  let list = [];

  if (!this.props.me.profile) return <div key='metabuilder-error'
   style={{width: '100%', textAlign: 'center', margin: '20px auto'}}>
   <Typography variant='h2'>Please Login</Typography></div>;

  if (this.props.ptlist.data === false || this.props.ptlist.loading === true) {
   list = <ProgressLoading key='MetaBuilder-loading'/>;
  } else if (this.props.ptlist.error) {
   list = <div key='MetaBuilder-error'><Typography variant='h2'>{this.props.ptlist.error}</Typography></div>;
  } else {
   if (this.props.ptlist.data.length === 0) {
    return <Typography variant='h4' key='MetaBuilder-noresults' style={{margin: '10px 5px'}}>There are no
                    public {Grammer.g('tracks', 'p')} in this {Grammer.g('playlists', 's')} available yet. Add
                    yours.</Typography>;
   }

   if (this.props.ptlist.data.length === 0) {
    list.push(<Button variant={'outlined'} startIcon={<PlaylistAdd/>}
     onClick={(e) => this.props.dispatch(loadForm("/forms/group/" + this.props.playlist.gid[0].target_id + '/playlists/' + this.props.playlist.id[0].value + '/tracks/add', 'dialog'))}>
     {`Add your ${Grammer.g('Tracks', 's')}`}
    </Button>);
   } else {
    list = this.props.ptlist.data.map((item, i) => {
     return this.buildRow(item, i);
    });
   }
  }


  return (
   <div className={classes.paperBg}>
    <TableContainer style={{maxHeight: '80vh'}}>
     <Table className={classes.table} size="small" stickyHeader>
      <TableHead>
       <TableRow>
        <TableCell className={classes.headCell}>
         <Select value={this.state.searchType}
          onChange={e => this.setState({searchType: this.state.searchType === 'open' ? 'search' : 'open'})}>
          <option value='search'>Search</option>
          <option value='open'>Launch</option>
         </Select>
        </TableCell>
        <TableCell className={classes.headCell}>Track</TableCell>
        <TableCell className={classes.headCell}>Artist</TableCell>
        <TableCell className={classes.headCell}>Album</TableCell>
        <TableCell className={classes.headCell}>Year</TableCell>
        <TableCell className={classes.headCell}>Genres</TableCell>
        <TableCell className={classes.headCell}>
         <a href='https://musicbrainz.org/'
          rel="noopener noreferrer"
          target='_blank'>MusicBrainz</a>
         <FormControl>
          <InputLabel htmlFor={'headURL'}>URL or ID</InputLabel>
          <Input value={this.state.mbUrl} onChange={e => this.setState({mbUrl:e.currentTarget.value})} id={'headURL'} type='text' />
         </FormControl>
        </TableCell>
        <TableCell className={classes.headCell}>Play</TableCell>

       </TableRow>
      </TableHead>
      <TableBody>
       {list}
      </TableBody>
     </Table>
    </TableContainer>
   </div>
  );
 }
}

MetaBuilder.defaultProps = {
 perpage: 50
};

MetaBuilder.propTypes = {
 perpage: PropTypes.number
};

export const tableStyles = theme => ({
 table: {
  minWidth: '100%',
  backgroundColor: theme.palette.background.paper,
 },
 headCell: {
  textAlign: 'left',
  padding: 2
 },
 priceCell: {
  fontWeight: 800,
  color: theme.palette.secondary.main
 }
});

export default withStyles(tableStyles, {withTheme: true})(MetaBuilder);
