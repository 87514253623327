import React, {Component} from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CreditCard from '../../FormFields/CreditCard/CreditCard';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Cancel';
import ProductGift from "../../Components/ProductGift/ProductGift";
import {changeMyCurrency, closeWallet} from "../../redux/walletReducer";
import Popover from '@material-ui/core/Popover';

import {MenuItem} from "@material-ui/core";
import {Money} from "@material-ui/icons";


const giftProductJson = {
    "product_id": [
        {
            "value": 46
        }
    ],
    "type": [
        {
            "target_id": "sponsorship",
            "target_bundle": "sponsorship",
            "target_type": "commerce_product"
        }
    ],
    "uid": [
        {
            "target_id": 1
        }
    ],
    "status": [
        {
            "value": 1
        }
    ],
    "stores": [
        {
            "target_id": 3,
            "target_label": "Public TAM Marketplace",
            "target_bundle": "online",
            "target_type": "commerce_store"
        }
    ],
    "title": [
        {
            "value": "Play-It-Forward"
        }
    ],
    "variations": [
        {
            "target_id": 40,
            "target_label": "PlayItForward",
            "target_bundle": "product_gift",
            "target_type": "commerce_product_variation",
                "variation_id": [
                    {
                        "value": 40
                    }
                ],
                "type": [
                    {
                        "target_id": "product_gift"
                    }
                ],
                "uid": [
                    {
                        "target_id": 1
                    }
                ],
                "status": [
                    {
                        "value": 1
                    }
                ],
                "product_id": [
                    {
                        "target_id": 46
                    }
                ],
                "sku": [
                    {
                        "value": "gom-sponsorfriend"
                    }
                ],
                "title": [
                    {
                        "value": "PlayItForward"
                    }
                ],
                "list_price": [
                    {
                        "number": null,
                        "currency_code": null
                    }
                ],
                "price": [
                    {
                        "number": 0.99,
                        "currency_code": "USD"
                    }
                ],
                "created": [
                    {
                        "value": 1583053004
                    }
                ],
                "changed": [
                    {
                        "value": 1583053004
                    }
                ],
                "field_product_variation_type": []
        }
    ],
    "created": [
        {
            "value": 1583052898
        }
    ],
    "changed": [
        {
            "value": 1583053004
        }
    ],
    "body": [
        {
            "value": "<p>Send a friend cash, music, or merchandise</p>",
            "summary": "",
            "format": "basic_html"
        }
    ],
    "field_recipient": []
};

class ManageFunds extends Component {

    constructor(p) {
        super(p);
        this.state = {method: this.props.method || 'payment', currencyMenu:false, anchorEl:null};
    }

    openMenu(e) {
        this.setState({currencyMenu: true, anchorEl: e.currentTarget});
    }

    closeMenu() {
        this.setState({currencyMenu: false});
    }

    render() {
        // TODO: change wrapper on ctx not dialog
        const toolbar = <Toolbar>
            <Grid container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'} spacing={1} style={{padding:'2% 1%'}}>
                <Grid item>
                    <Tabs value={this.state.method}
                          variant="fullWidth"
                          indicatorColor="primary" >
                        <Tab value={'payment'} label={'Checkout'} color={this.state.method === 'payment' ? 'primary' : 'secondary'} onClick={() => this.setState({method:'payment'})} variant={'contained'} />
                        {/* <Tab value={'deposit'} label={'Deposit'} color={this.state.method === 'deposit' ? 'primary' : 'secondary'} onClick={() => this.setState({method:'deposit'})} variant={'contained'} /> */}
                        {/* <Tab value={'withdraw'} label={'Withdraw'} color={this.state.method === 'withdraw' ? 'primary' : 'secondary'} onClick={() => this.setState({method:'withdraw'})} variant={'contained'} /> */}
                        {/* <Tab value={'transfer'} label={'Transfer'} color={this.state.method === 'transfer' ? 'primary' : 'secondary'} onClick={() => this.setState({method:'transfer'})} variant={'contained'} /> */}
                        <Tab value={'mycurrency'} label={<Money size='small' />} onClick={e => this.openMenu(e)} variant={'contained'} />
                        <Popover
                            anchorEl={this.state.anchorEl}
                            onClose={() => this.closeMenu()}
                            open={this.state.currencyMenu}>
                            <MenuItem onClick={() => this.props.dispatch(changeMyCurrency('USD'))}>US Dollar (USD)</MenuItem>
                            <MenuItem onClick={() => this.props.dispatch(changeMyCurrency('MXN'))}>Mexican Peso (MXN)</MenuItem>
                        </Popover>
                    </Tabs>
                </Grid>
                {this.props.asDialog === true &&
                <Grid item>
                    <Button endIcon={<CloseIcon color='primary' />} size={'small'} onClick={() => this.props.dispatch(closeWallet())}
                                aria-label="close" id="close-button">
                        Close
                    </Button>
                </Grid>
                }
            </Grid>
            </Toolbar>

        const form = this.state.method === 'transfer' ?
                <ProductGift dispatch={this.props.dispatch} data={giftProductJson} me={this.props.me}  />
                :
            this.state.method === 'deposit' ?
                <Typography variant={'h3'} style={{paddingTop:30, textAlign:'center'}}>Wallet deposits coming soon.</Typography>
                :
                <CreditCard formMethod={this.state.method} />


        if (this.props.asDialog === true) {
            return (
                <Dialog maxWidth='xl' scroll='body' fullWidth={true} fullScreen={window.innerWidth < 600} style={{padding:'1%'}}
                        onClose={() => this.props.dispatch(closeWallet())} open={true}>
                    <DialogContent style={{padding:'0'}}>
                        <AppBar position={'relative'} color={'default'}>{toolbar}</AppBar>
                        {form}
                    </DialogContent>
                </Dialog>
            );
        }

        return <div style={{width:'100%', paddingTop:21}}>
            <AppBar position={'relative'} color={'default'}>
            {toolbar}
            </AppBar>
            <div style={{padding:'2%'}}>
                {form}
            </div>
        </div>

    }
}

export default ManageFunds;
