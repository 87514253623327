import {connect} from 'react-redux';
import {closeForm, submitForm} from '../../redux/formsReducer';
import DialogForm from './DialogForm';

const mapStateToProps = (state) => {
 var newState = {me:state.auth.me};
 newState.forms = state.forms;
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  submitForm: (data, url) => {
   dispatch(submitForm(data, url));
  },
  closeForm : () => {
   dispatch(closeForm());
  }
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(DialogForm);
