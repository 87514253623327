import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router';
import TamIcon from '../TamIcon';
import UserAvatar from '../UserAvatar';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {joinGroup} from "../../redux/authActions";
import Grammer from "../../Util/Grammer";
import {withStyles} from '@material-ui/core/styles';
import {headFootStyles} from "../../Util/ThemeUtils";
import Button from "@material-ui/core/Button";

class MyMenuLink extends Component {

    buildLink(link, gid, linkId) {
        var url = link.uri;
        if (url.substr(0, 1) !== '/') url = '/' + url;
        if (this.props.profile) {
            url = url.replace(':aid', this.props.profile.uid[0].value);
        }

        if (gid > 0) {
            url = url.replace(':gid', gid);
        }
        if (this.props.group) {
            // url = url.replace(':gowneruid', this.props.group.uid[0].target_id); // owner id
            url = url.replace(':gownermemberid', this.props.group.uid[0].target_gc); // owner member id
        }

        if (this.props.group && this.props.group.membership) {
            if (this.props.profile) {
                url = url.replace(':memberid', this.props.group.membership.id[0].value);
            } else {
                return null;
            }
        }
        if (url.indexOf(':uid') > 0) { // TAM user signs into brand site but isn't a member yet.
            if (this.props.profile) {
                url = '/users/' + this.props.profile.uid[0].value
                link.icon = '%aprofilepic'
                link.title = 'My Account'
            } else {
                return null;
            }
        }

        let title = (typeof link.long === 'string' && this.props.layout === 'link') ? link.long : link.title;
        if (typeof link.header === 'string' && this.props.layout === 'button') {
            title = link.header;
        }

        if (linkId === 'wallet') {
            return (<NavLink className={this.props.classes.navLink} activeClassName="selected" to={gid > 0 ? `/group/${gid}/wallet` : '/wallet'} >
                {title}
            </NavLink>);
        } else if (linkId === 'join') {
            return (<Button onClick={e => this.props.dispatch(joinGroup(gid))}
                            variant={'outlined'}
                            endIcon={<TamIcon noavatar={true} iconName={link.icon} color={'primary'} />}
            >
                {title}
            </Button>);
        }

        let icon = false;
        if (link.icon === '%aprofilepic') {
            icon = <UserAvatar data={Object.assign({...this.props.profile})} size={35} />
        } else if (link.icon === '%gprofilepic' && this.props.group) {
            icon = <UserAvatar  data={Object.assign({...this.props.profile}, {...this.props.group.membership})} size={35} />
        } else if (link.icon === '%gownerpic' && this.props.group) {
            icon = <UserAvatar data={this.props.group.uid[0]} size={35} />;
        } else if (link.icon === '%gmenuicon' && this.props.group && this.props.group.field_cover.length > 0) {
            icon = <Avatar style={{height:35, width:35}} classes={{img:this.props.classes.imgContain}} alt={this.props.group.label[0].value} src={this.props.group.field_cover[0].url}  />
        } else if (link.icon) {
            icon = <TamIcon noavatar={true} iconName={link.icon} color={'primary'} />
        }

        if (this.props.layout === 'button') {
            if (icon && link.icon.indexOf("%") === 0) {
                return <NavLink className={this.props.classes.navButtons} to={url} aria-label={title} >
                    {icon}
                </NavLink>
            }
            return (<NavLink className={this.props.classes.navLink} activeClassName="selected" to={url} aria-label={title} >
                    <Button className={this.props.classes.navButtons} selected={this.props.location.pathname === url}
                            variant={'outlined'}
                            endIcon={icon}
                    >
                        {Grammer.replaceAll(title)}
                    </Button>
                </NavLink>
            );
        } else {
            return (
                <NavLink className={this.props.classes.navLink} activeClassName="selected" to={url} aria-label={title} >
                    <MenuItem className={this.props.classes.menuItem} selected={this.props.location.pathname === url} >
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={Grammer.replaceAll(title)}  />
                    </MenuItem>
                </NavLink>
            );
        }


    }

    render () {
        return this.buildLink(this.props.link, this.props.gid, this.props.linkId);
    }
}

export default withRouter(withStyles(headFootStyles)(MyMenuLink));
