import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";

class StyleGuide extends Component {

    render() {
        return <div>
            <Typography variant={'h1'}>Test H1</Typography>
            <Typography variant={'h2'}>Test H2</Typography>
            <Typography variant={'h3'}>Test H3</Typography>
        </div>

    }


}

export default StyleGuide;
