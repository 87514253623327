import API from '../Util/API';
import {_showNotice} from './formsReducer';
import {getIdbySegment} from "./authActions";
import WAGER from "../apps/tam/wager.json";
import Commerce2Json from "../Util/Commerce2Json";

const TRANSFER_STARTED = 'TRANSFER_STARTED';
const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS';
const TRANSFER_FAILURE = 'TRANSFER_FAILURE';
const TRANSFER_ADDFUNDS = 'TRANSFER_ADDFUNDS';
const WALLET_CLOSE = 'WALLET_CLOSE';
const WALLET_OPEN = 'WALLET_OPEN';
const WALLET_ADD_PENDING = 'WALLET_ADD_PENDING';
const ADD_WAGER_TO_CART = 'ADD_WAGER_TO_CART';
const CART_ITEM_ADD = 'CART_ITEM_ADD';
const CART_ITEM_QUANTITY = 'CART_ITEM_QUANTITY';
const CART_ITEM_REMOVE = 'CART_ITEM_REMOVE';
const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
const LS_CART_KEY = 'TamCart'; // TODO: add user id
const WALLET_MYCURRENCY = 'WALLET_MYCURRENCY';


const EXCHANGE_RATES = {
    'MXN' : {'USD':.20, 'TAC':.20},
    'USD' : {'MXN': 20, 'TAC':1},
    'TAC' : {'USD':1, 'MXN':.20}
}

export const changeCurrency = (num, currency) => {
    const {mycurrency} = window.store.getState().wallet;
    if (mycurrency === currency) return num;
    return num * EXCHANGE_RATES[currency][mycurrency];
}

export function transferStart(process) {
    return {
        type: TRANSFER_STARTED,
        process:process
    };
}

export function transferSuccess(payload) {
    return {
        type: TRANSFER_SUCCESS,
        payload
    };
}

export function checkoutSuccess(payload) {
    return {
        type: CHECKOUT_SUCCESS,
        payload
    };
}

export function transferFailure(err) {
    return {
        type: TRANSFER_FAILURE,
        error: err
    };
}

export function addWagerToCart(track, playlist, currency, delta) {
    return {
        type: ADD_WAGER_TO_CART,
        track,
        playlist,
        currency,
        delta
    };
}

export function requestDeposit(amt, reason) {
    return {
        type: TRANSFER_ADDFUNDS,
        amount: amt,
        reason: reason
        //, callback : clk,
    };
}

export function addAndCheckout(cartItem) {
    return {
        type: CART_ITEM_ADD,
        cartItem
    };
}

export function changeCartQuantity(vid, count) {
    return {
        type: count === 0 ? CART_ITEM_REMOVE : CART_ITEM_QUANTITY,
        vid,
        count
    };
}

export function removeFromCart(vid) {
    return {
        type: CART_ITEM_REMOVE,
        vid
    };
}

export function addPendingPayout(payout) {
    return {
        type: WALLET_ADD_PENDING,
        payout
    };
}

export function closeWallet() {
    return {
        type: WALLET_CLOSE
    };
}

export function openWallet() {
    return {
        type: WALLET_OPEN
    };
}

export function changeMyCurrency(currency) {
    return {
        type: WALLET_MYCURRENCY,
        currency
    };
}



function handleApi(dispatch, url, payload, onSuccess) {
    dispatch(transferStart(url));

    return API.Post(url, payload).then((res) => {
        let msg = API.checkError(res.data);
        if (msg.length > 0) {
            dispatch(transferFailure(msg));
        } else {
            onSuccess(res.data);
        }
    }).catch((err) => {
        let msg = API.getErrorMsg(err);
        dispatch(transferFailure(msg));
        return err;
    });
}

export function cardSave(payload) {
    return (dispatch) => {
        handleApi(dispatch, `/forms/wallet/card/add`, payload, (data) => {
            dispatch(transferSuccess(data));
            dispatch(_showNotice('Card Saved', 'success'));
        })
    }
}

export function cardDelete(cid) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;
        handleApi(dispatch, `/forms/wallet/card/${cid}/delete`, null, (data) => {
            dispatch(transferSuccess(data));
            dispatch(_showNotice('Card deleted', 'success'));
        })
    };
}

export function shippingSave(payload) {
    return (dispatch) => {
        handleApi(dispatch, `/forms/wallet/shipping/add`, payload, (data) => {
            dispatch(transferSuccess(data));
            dispatch(_showNotice('Address Saved', 'success'));
        })
    }
}

export function shippingDelete(profile_id) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;
        handleApi(dispatch, `/forms/wallet/shipping/${profile_id}/delete`, null, (data) => {
            dispatch(transferSuccess(data));
            dispatch(_showNotice('Shipping address deleted', 'success'));
        })
    };
}

export function sendTransfer(obj) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;

        if (typeof state.auth.me.profile.roles['verified_cc'] !== 'number') { // < cover $20 if verified
            return dispatch(requestDeposit(obj.amount, 'Please add a payment method.'));
        }

        handleApi(dispatch, "/forms/wallet/transfer", obj, (data) => {
            dispatch(transferSuccess(data));
            dispatch(_showNotice('Funds transfer successful', 'success'));
        })
    };
}

export function makeWalletWithdrawal(obj) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;

        dispatch(transferStart('withdraw'));

        return API.Post("/forms/wallet/withdraw", obj).then((res) => {
            var msg = API.checkError(res.data);
            if (msg.length > 0) {
                dispatch(transferFailure(msg));
            } else {
                dispatch(transferSuccess(res.data));
                dispatch(_showNotice('Withdrawal successful', 'warning'));
            }

            const tdata = getIdbySegment(document.location.pathname);
            tdata.verb = msg.length > 0 ? 'withdrawal-failed' : 'withdrawal';
            tdata.value = obj.amount;
            tdata.currency = obj.currency;
            tdata.dom_ref = state.wallet.ctx;
            tdata.uid = state.auth.me.profile.uid[0].value;
            if (!tdata.gid && state.auth.me.brandId) tdata.gid = state.auth.me.brandId;
            window.logUse.logEvent('transaction', tdata);

        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            console.log('token check error: ', msg);
            dispatch(transferFailure(msg));
            return err;
        });
    };
}

export function makeWalletDeposit(obj) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;

        dispatch(transferStart('deposit'));

        return API.Post("/forms/wallet/deposit", obj).then((res) => {
            var msg = API.checkError(res.data);
            if (msg.length > 0) {
                dispatch(transferFailure(msg));
            } else {
                if (state.wallet.ctx === 'confirmwager' || state.wallet.ctx === 'managefunds') {
                    dispatch(closeWallet(res.data));
                }
                dispatch(transferSuccess(res.data));
                dispatch(_showNotice('Deposit successful'));
            }

            const tdata = getIdbySegment(document.location.pathname);
            tdata.verb = msg.length > 0 ? 'deposit-failed' : 'deposited';
            tdata.value = obj.amount;
            tdata.currency = obj.currency;
            tdata.dom_ref = state.wallet.ctx;
            tdata.uid = state.auth.me.profile.uid[0].value;
            if (!tdata.gid && state.auth.me.brandId) tdata.gid = state.auth.me.brandId;
            window.logUse.logEvent('transaction', tdata);

        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            console.log('token check error: ', msg);
            dispatch(transferFailure(msg));
            return err;
        });
    };
}

export function checkWalletBalance(gid) {
    return (dispatch, getState) => {
        var state = getState();
        if (state.wallet.loading === true) return false;

        dispatch(transferStart('balance'));

        const url = gid > 0 ? `/wallet/balance?gid=${gid}&shipping=true` : "/wallet/balance?shipping=true";

        return API.Get(url).then((res) => {
            var msg = API.checkError(res.data);
            if (msg.length > 0) {
                dispatch(transferFailure(msg));
            } else {
                dispatch(transferSuccess(res.data));
            }
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            console.log('token check error: ', msg);
            dispatch(transferFailure(msg));
            return err;
        });
    };
}

const initialState = {
    balance: {usd:{net:0, deposited:0, spent:0, withdrawn:0}, tac:{earned:[], spent:[], net:0}, forceCheck:true},
    shipping: [],
    paymethods: [],
    mycurrency:localStorage.getItem('mycurrency') || 'USD',
    loading: false,
    process:'',
    error: false,
    ctx: false, // this reducer hsa multiple ctx names, rather than just 'dialog'
    cartItems: [],

    product: false, // obsolete
    variant: false, // obsolete
    cartValue: false, // obsolete
    reason: false, // obsolete
    gc: false // group_content item (used for DialogView )
};

if (localStorage.getItem(LS_CART_KEY)) {
    initialState.cartItems = JSON.parse(localStorage.getItem(LS_CART_KEY));
}

const walletReducer = (draft = initialState, action) => {
    draft.loading = false; // default except when started
    let has = false;
    switch (action.type) {
        case TRANSFER_STARTED:
            draft.error = false;
            draft.loading = true;
            draft.process = action.process;
            return draft;
        case CHECKOUT_SUCCESS:
            draft.error = false;
            draft.loading = false;
            draft.cartItems = [];
            if (action.payload.balance) {
                Object.assign(draft.balance, action.payload.balance); // WARN: make sure to handle TAC|USD when leaving groups
            }
            localStorage.removeItem(LS_CART_KEY)
            return draft;
        case TRANSFER_SUCCESS:
            draft.error = false;
            draft.loading = false;
            if (action.payload.balance) {
                Object.assign(draft.balance, action.payload.balance); // WARN: make sure to handle TAC|USD when leaving groups
            }
            if (draft.balance.forceCheck) {
                delete draft.balance.forceCheck;
            }
            if (action.payload.shipping) {
                draft.shipping = action.payload.shipping;
            }
            if (action.payload.paymethods) {
                draft.paymethods = action.payload.paymethods;
            }
            return draft;
        case TRANSFER_FAILURE:
            if (action.error !== false) console.error('transfer error: ', action.error);
            draft.error = action.error;
            return draft;
        case WALLET_CLOSE:
            draft.error = false;
            draft.reason = false;
            draft.ctx = false;
            return draft;
        case WALLET_MYCURRENCY:
            draft.mycurrency = action.currency;
            localStorage.setItem('mycurrency', action.currency);
            return draft;
        case WALLET_OPEN:
            draft.ctx = 'managefunds';
            return draft;
        case ADD_WAGER_TO_CART:
            const sku = `wager-${action.uid}-${action.track.id[0].value}-${action.delta}`;
            has = draft.cartItems.findIndex(c => c.variation.sku[0].value === sku
                && c.variation.field_wager_value[0].value === action.delta)

            if (has == -1) {
                const newWager = JSON.parse(JSON.stringify(WAGER));
                newWager.variations[0].target_label  = action.track.label[0].value;
                newWager.variations[0].price  = [{number: action.playlist.field_buy_in[0].value, currency_code:action.currency.toUpperCase()}];
                newWager.variations[0].entity.sku  =  [{value:sku}]
                newWager.variations[0].entity.price  = newWager.variations[0].price;
                newWager.variations[0].entity.field_gc_ref = [{target_id: action.track.id[0].value}];
                newWager.variations[0].entity.title = [{value: action.track.label[0].value}];
                newWager.variations[0].entity.field_wager_value = [{value: action.delta}];
                newWager.track = action.track;
                newWager.playlist = action.playlist;
                newWager.title = [{value: action.track.label[0].value}];
                const cartItem = new Commerce2Json(newWager)
                draft.cartItems.push(cartItem.toJson())
                localStorage.setItem(LS_CART_KEY, JSON.stringify(draft.cartItems))
            }

            return draft;
        case WALLET_ADD_PENDING:
            let pending = draft.balance.tac.earned.find(o => o.status === 'pending');
            if (!pending) {
                draft.balance.tac.earned.push({total:action.payout, status:'pending'});
            } else {
                pending.total += action.payout;
            }
            return draft;
        case CART_ITEM_REMOVE:
            has = draft.cartItems.findIndex(c => c.variation.variation_id[0].value === action.vid)
            if (has > -1) {
                draft.cartItems[has].count -= 1
                if (draft.cartItems[has].count === 0) {
                    draft.cartItems.splice(has, 1)
                }
            } else {
                draft.cartItems.splice(has, 1)
            }
            localStorage.setItem(LS_CART_KEY, JSON.stringify(draft.cartItems))
            return draft;
        case CART_ITEM_QUANTITY:
            has = draft.cartItems.findIndex(c => c.variation.variation_id[0].value === action.vid)
            if (has < 0) return false; // should never happen
            if (action.count === 0) {
                draft.cartItems.splice(has, 1)
            }
            if (!action.count) return draft; // parseInt failed
            draft.cartItems[has].count = action.count
            localStorage.setItem(LS_CART_KEY, JSON.stringify(draft.cartItems))
            return draft;
        case CART_ITEM_ADD:
            has = draft.cartItems.findIndex(c => c.variation.variation_id[0].value === action.cartItem.variation.variation_id[0].value)
            if (has > -1) {
                draft.cartItems[has].count += 1
            } else {
                draft.cartItems.push(action.cartItem)
            }
            localStorage.setItem(LS_CART_KEY, JSON.stringify(draft.cartItems))
            return draft;
        case TRANSFER_ADDFUNDS:
            draft.ctx = 'managefunds';
            draft.reason = action.reason;
            draft.variant = action.variant;
            draft.product = action.product;
            if (action.amount) {
                draft.cartValue = parseFloat(action.amount).toFixed(2);
            } else if (action.variant) {
                draft.cartValue = action.variant.getPrice();
            }
            return draft;
        default:
            return draft;

    }
};

export default walletReducer;
