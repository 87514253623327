import React, {Component} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";
import {NavLink} from "react-router-dom";

class AddContentMenu extends Component {

    render() {
        const {gid} = this.props;

        return (
            <div>
                <NavLink className={this.props.classes.navLink} activeClassName="selected" to={`/forms/group/${gid}/rewards/add`} >
                    <MenuItem className={this.props.classes.menuItem} >
                        <ListItemText primary={<span>Create <b>{Grammer.g('Rewards', 'p')}</b></span>} />
                    </MenuItem>
                </NavLink>
                <NavLink className={this.props.classes.navLink} activeClassName="selected" to={`/forms/group/${gid}/playlists/start`} >
                    <MenuItem className={this.props.classes.menuItem} >
                        <ListItemText primary={<span>Create <b>{Grammer.g('Playlists', 'p')}</b></span>} />
                    </MenuItem>
                </NavLink>
                <NavLink className={this.props.classes.navLink} activeClassName="selected" to={`/group/${gid}/emails?tab=invite`} >
                    <MenuItem className={this.props.classes.menuItem} >
                        <ListItemText primary={<span>Send <b>{Grammer.g('Emails', 'p')}</b></span>}  />
                    </MenuItem>
                </NavLink>

                <div style={{padding:'5% 3%'}}>
                <Typography variant={'body2'} gutterBottom={true}>* <b>{Grammer.g('Tracks', 'p')}</b> must be created within a {Grammer.g('Playlists', 's')} </Typography>
                    <Typography variant={'body2'}>* Email sales @ trackauthoritymusic.com to create <b>{Grammer.g('Rewards', 'p')}</b> </Typography>
                </div>
            </div>
        );


    }
}

export default AddContentMenu;
