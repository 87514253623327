import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import {Link} from 'react-router-dom';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Cancel";
import PropTypes from 'prop-types';
import API from '../../Util/API';
import VoteNotice from '../VoteNotice';

import Dialog from '@material-ui/core/Dialog';
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import MediaItem from "../MediaItem";
import Divider from "@material-ui/core/Divider";
import {raterStyles, sliderStyle} from "../../Util/ThemeUtils";
import confetti from "canvas-confetti";
import {addPendingPayout} from "../../redux/walletReducer"; // https://www.skypack.dev/view/canvas-confetti
const StyledSlider = withStyles(sliderStyle)(Slider);

class RatingSlider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {disabled: false, countType:props.countType};
        this.postRating = this.postRating.bind(this);
    }

    logRater(verb, extra) {
        const tdata = Object.assign({
            tid: this.props.tjson.get("id"),
            pid: this.props.tjson.get("field_playlist_gc", "target_id"),
            gid: this.props.tjson.get("gid", "target_id"),
            verb: verb
        }, extra);
        if (this.props.isLoggedIn > 0) {
            tdata.uid = this.props.isLoggedIn;
        }
        window.logUse.logEvent('rate', tdata);
    }

    componentDidMount() {
        this.logRater('view');
    }

    closeRater() {
        if (this.props.ratingComplete)
            return this.props.ratingComplete(false);
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            x: (rect.left + window.scrollX) / window.innerWidth,
            y: (rect.top + window.scrollY) / window.innerHeight
        };
    }

    postRating = (value, voteType) => {
        if (this.props.isLoggedIn === false) {
            return this.props.enqueueSnackbar('You must login or register to vote', {variant: 'error'});
        }
        if (!this.props.verified_email) {
            return this.props.enqueueSnackbar('Please verify your email to vote', {variant: 'error'});
        }

        this.setState({disabled: true});
        API.Post('/group/' + this.props.tjson.get('gid', 'target_id') + '/playlists/' + this.props.tjson.get('field_playlist_gc', 'target_id') + '/tracks/' + this.props.tjson.get('id') + '/rate',
            {'vote': value, 'vote_type': voteType.target_id})
            .then((res) => {
                if (typeof res.data.error === 'string') {
                    this.logRater('failed');
                    return this.props.enqueueSnackbar(res.data.error);
                } else if (!res.data.success) {
                    return this.props.enqueueSnackbar('Something went wrong');
                }

                const conOpts = {
                    particleCount: 150,
                    spread: 120,
                    zIndex:999999,
                    colors:[this.props.theme.palette.primary.main, this.props.theme.palette.primary.light, this.props.theme.palette.primary.dark,
                        this.props.theme.palette.secondary.main, this.props.theme.palette.secondary.light, this.props.theme.palette.secondary.dark]
                }

                const keyId = ['trate', this.props.tjson.get("id", "value"), voteType.target_id].join('-')
                if (document.getElementById(keyId)) {
                    conOpts.origin = this.getOffset(document.getElementById(keyId))
                }
                confetti(conOpts);

                const obj = res.data;
                obj.sort = 'groupindex';  // or myindex based on PlaylistControls

                this.props.updateTrackRating(obj, this.props.tjson.json); // this updates the slider to the TrackList control

                if (obj.payout > 0) {
                    this.props.dispatch(addPendingPayout(obj.payout))
                    this.logRater('payout', {payout: obj.payout, vote:voteType.target_id, value:value});
                    obj.action = <Typography variant={'body1'}><em>Payout Pending Approval</em>. <Link onClick={e => this.closeRater(e)} to={`/group/${this.props.tjson.get('gid', 'target_id')}/wallet/activity`}>View Earnings</Link></Typography>;
                } else {
                    this.logRater('voted', {vote:voteType.target_id, value:value});
                    obj.action = <Link onClick={e => this.closeRater(e)} to={`/group/${this.props.tjson.get('gid', 'target_id')}/wallet/activity`}>View Earnings</Link>;
                }

                obj.message = <div>
                    <Typography variant={'body1'}>
                        {(this.props.vote_types.length > 1) && <b>{voteType.target_label} - </b>}
                        <u>{this.props.tjson.get('label')}</u>
                        { (this.props.tjson.json.node.field_artists.length > 0) && <span> by <em>{this.props.tjson.json.node.field_artists[0].value}</em></span>}
                    </Typography>
                    <Typography variant={'h2'} align={'center'}>You rated <b>{value}%</b> {(obj.payout > 0) && <span> and earned <b><sup>₮</sup>{obj.payout.toFixed(2)}</b></span>}</Typography>
                </div>;
                this.props.enqueueSnackbar(obj.success, {variant: 'success', content: <VoteNotice onClose={() => this.props.closeSnackbar(keyId)} posted={obj}/>, action:obj.action, key:keyId, anchorOrigin:{vertical:'top', horizontal:'right'}, autoHideDuration:7000});
                if (this.props.ratingComplete && this.props.vote_types.length < 2) {
                    this.props.ratingComplete(false);
                }
                this.setState({disabled: false}); // enabled

            }).catch((err) => {
            this.setState({disabled: false});
            this.props.enqueueSnackbar('Rating failed. Are you a member of this group?', {variant: 'error'});
            console.log("VOTE FAILED", err);
            return err;
        });
    }

    MyThumbComponent(props) {
        return (<span {...props} id={props['aria-labelledby']}></span>);
    }

    renderVoteType(voteType, i) {
        const {classes} = this.props;
        let curVal = voteType[this.state.countType];
        if (!voteType[this.state.countType]) {
            curVal = 0;
        }
        let media = false;
        if (voteType.thumbnail)  {
            media = <Grid item><MediaItem media={voteType}
                                          height={120}
                                          style={{height:120, width:213}} /></Grid>
        }

        const keyId = ['prate', this.props.tjson.get("id", "value"), voteType.target_id].join('-')

        return (<Grid item
                      xs={12} md={6} lg={4}
                      className={classes.raterRoot}
                      key={'trate-' + this.props.tjson.get("id", "value") + '-' + i} >

            <div className={this.props.classes.voteTypeBlock}>
            <Grid container style={{marginBottom:25, flexWrap:'nowrap'}} spacing={1}>
                <Grid item style={{flexGrow:1}}>
                    <Typography variant='h3' className={'ctaLabel'} gutterBottom={true}>
                        {voteType.target_label}
                    </Typography>
                    {voteType.target_desc &&
                        <Typography variant='h4' className={'ctaLabel'} >
                            {voteType.target_desc}
                        </Typography>
                    }
                    {curVal > 0 &&
                    <Typography variant='h4' className={classes.valueNumber}>{voteType.count === 0 ? 'unrated' : <>{curVal}<sup>%</sup> from {voteType.count} {voteType.count === 1 ? 'vote' : 'votes'}</>}
                    </Typography>
                    }
                </Grid>
                {media}
            </Grid>

            <StyledSlider
                defaultValue={curVal}
                aria-labelledby={keyId}
                ThumbComponent={this.MyThumbComponent}
                aria-label='Track Rating Slider'
                step={1} max={100} min={0}
                disabled={this.state.disabled}
                onChangeCommitted={(e, value) => this.postRating(value, voteType)}
                valueLabelDisplay="auto"
            />
            </div>
        </Grid>)
    }


    render() {
        let tally = 0, count = 0;
        if (this.props.vote_types.length > 0) {
            this.props.vote_types.forEach(voteType => {
                if (voteType[this.state.countType]) {
                    count += voteType.count;
                    tally += voteType[this.state.countType] * voteType.count;
                }
            });
            if (count > 0) {
                tally = Math.round(tally / count);
            }
        }

        const html = <React.Fragment>
            {tally > 0 && <Typography variant='h4' color={'primary'}>Overall: {tally}% from {count} {count === 1 ? 'vote' : 'votes'}</Typography>}
            <Divider style={{marginBottom:30}} />
            <Grid container spacing={8} >
                {this.props.vote_types.map((voteType, i) => this.renderVoteType(voteType, i))}
            </Grid>
        </React.Fragment>

        if (this.props.layout === 'block'){
            return html;
        }

        return (<Dialog maxWidth='xl' fullWidth={true} open={true}>
                <DialogTitle>
                    Rate. &nbsp;
                    <IconButton onClick={e => this.closeRater()} style={{float: 'right'}} variant="outlined"
                                color="secondary" size='small' aria-label='Close Window'
                    ><CloseIcon/>
                    </IconButton>

                </DialogTitle>

                <DialogContent>
                    <Typography variant='h3'>{this.props.tjson.get('label')}</Typography>
                    {this.props.tjson.json.node.field_artists.length > 0 && <Typography variant={'h5'}>{this.props.tjson.json.node.field_artists[0].value}</Typography>}
                    <Typography variant={'subtitle1'}>{this.props.tjson.get('field_playlist_gc', 'target_label')}</Typography>
                    {html}
                </DialogContent>
            </Dialog>
        );
    }
}

RatingSlider.defaultProps = {
    countType: 'avg'
}

RatingSlider.propTypes = {
    tjson: PropTypes.object.isRequired,
    ratingComplete: PropTypes.func,
    countType: PropTypes.string.isRequired,
    vote_types: PropTypes.array.isRequired
};

export default withSnackbar(withStyles(raterStyles, {withTheme:true})(RatingSlider));
