import React, {Component} from 'react';
import {connect} from 'react-redux';
import {alpha} from "@material-ui/core/styles/colorManipulator";
import {darken, lighten, withStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Grammer from "../../Util/Grammer";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import OverlayLoader from '../../Components/OverlayLoader';
import {Link} from 'react-router-dom';
import {getIdbySegment} from "../../redux/authActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import CardHeader from "@material-ui/core/CardHeader";
import Address from "../Address";
import CardInfo from "../CardInfo";
import {
    cardDelete,
    changeCartQuantity,
    changeCurrency,
    checkoutSuccess,
    checkWalletBalance,
    closeWallet,
    makeWalletDeposit,
    makeWalletWithdrawal,
    removeFromCart,
    shippingDelete,
    transferFailure,
    transferStart,
    transferSuccess
} from '../../redux/walletReducer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import {_showNotice} from "../../redux/formsReducer";
import {withRouter} from "react-router";
import MyCart from "../../Util/MyCart";
import {ReactComponent as PlaceChips} from "../../images/PlaceChips.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import ConfirmWager from "../../Components/ConfirmWager";
import API from "../../Util/API";
import PriceCurrency from "../PriceCurrency";


class CreditCard extends Component {

    constructor(props) {
        super(props);
        // GET TEST CARDS HERE: https://cloud9paymentgateway.com/w/index.php?title=TSYS_Test_Account && more docs here: https://testlink.c9pg.com/apidocs/ (devtestportaluser1 && Q9@9fuYp^)
        this.state = {
            errors: {},
            amountWithdraw: 0,
            showAddShipping: false, showAddPayment: false, showWagerContract:false,
            cardIndex: this.props.wallet.paymethods.findIndex(card => card.is_default > 0),
            shipIndex: this.props.wallet.shipping.findIndex(card => card.is_default > 0),
            usdBalance: changeCurrency(this.props.wallet.balance.usd.net, 'USD'),
            tacBalance: changeCurrency(this.props.wallet.balance.tac.net, 'TAC'),
            tacApply: 0, useWallet: this.props.myCart.getTotal(),
        };

        if (this.state.shipIndex < 0 && this.props.wallet.shipping.length > 0) {
            this.state.shipIndex = 0;
        }
        if (this.state.cardIndex < 0 && this.props.wallet.paymethods.length > 0) {
            this.state.cardIndex = 0;
        }

        this.useCard = this.useCard.bind(this);
    }

    componentDidMount() {
        if (!this.props.me || !this.props.me.profile) {
            this.props.dispatch(closeWallet());
            return this.props.history.push('/login');
        } else if (this.props.wallet.balance.forceCheck === true) {
            this.props.dispatch(checkWalletBalance(this.props.curGroup));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let newState = {};

        let finalStep = {bet:1, deposit:1, withdraw:1, checkout:1};
        if (typeof finalStep[this.props.wallet.process] === "number" && this.props.wallet.loading === false && prevProps.wallet.loading === true && this.props.wallet.error === false) {
            this.props.dispatch(closeWallet());
            const tdata = getIdbySegment(document.location.pathname);
            return this.props.history.push(tdata.gid ? `/group/${tdata.gid}/wallet/activity` : '/wallet/activity');
        }
        if (this.props.wallet.paymethods.length !== prevProps.wallet.paymethods.length) {
            newState.cardIndex = this.props.wallet.paymethods.findIndex(card => card.is_default > 0);
            if (newState.cardIndex < 0 && this.props.wallet.paymethods.length > 0) newState.cardIndex = 0;
            if (this.state.showAddPayment === true && prevState.showAddPayment === true) {
                this.setState({showAddPayment:false}); // card just added
            }
        }
        if (this.props.wallet.shipping.length !== prevProps.wallet.shipping.length) {
            newState.shipIndex = this.props.wallet.shipping.findIndex(card => card.is_default > 0);
            if (newState.shipIndex < 0 && this.props.wallet.shipping.length > 0) newState.shipIndex = 0;
        }

        if (this.props.wallet.mycurrency !== prevProps.wallet.mycurrency) {
            newState.usdBalance = changeCurrency(this.props.wallet.balance.usd.net, 'USD');
            newState.tacBalance = changeCurrency(this.props.wallet.balance.tac.net, 'TAC');
        }

        if (this.props.wallet.reason && (!prevProps.wallet.reason || prevProps.wallet.reason !== this.props.wallet.reason)) {
            const tdata = getIdbySegment(document.location.pathname); // only works when ctx is dialog. should be the case for reason prompts
            if (!tdata.gid && this.props.me.brandId) {
                tdata.gid = this.props.me.brandId;
            }
            tdata.verb = 'requested';
            tdata.value = this.props.myCart.getTotal();
            tdata.currency = this.props.wallet.mycurrency;
            tdata.dom_ref = this.props.wallet.ctx;
            tdata.uid = this.props.me.profile.uid[0].value;
            window.logUse.logEvent('transaction', tdata);
            newState.useWallet = Math.min(this.state.usdBalance, this.props.myCart.getTotal());
        } else if (this.props.myCart.getTotal() !== prevProps.myCart.getTotal()) {
            newState.useWallet = Math.min(this.state.usdBalance, this.props.myCart.getTotal());
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }

    }

    handlePayment() {
        const payload = {
            currencies: {'tac': this.state.tacApply}
        };
        payload.currencies[this.props.wallet.mycurrency.toLowerCase()] = this.state.useWallet;

        const errors = {}
        if (this.state.cardIndex < 0) {
            errors.paymethod = 'Please enter a payment method and billing address';
        } else {
            payload.paymethod = this.props.wallet.paymethods[this.state.cardIndex];
        }

        const cart_items = this.props.myCart.getOrder(); // restructure as {vid:#, quanitity:4, "store_id":#}
        if (cart_items && cart_items.length > 0) {
            payload.cart_items = cart_items;
        }

        if (this.props.myCart.hasShippable() === true && this.state.shipIndex < 0) {
            errors.shipAddress = 'Please enter a shipping address';
        } else {
            payload.shipping = this.props.wallet.shipping[this.state.shipIndex];
        }

        if (Object.keys(errors).length > 0) {
            return this.setState({errors:errors});
        }

        let tdata = getIdbySegment(document.location.pathname);
        if (tdata.gid) {
            payload.gid = tdata.gid;
        }
        if (this.props.myCart.getItems().length > 0) {
            this.checkoutAll(payload);
        } else {
            return this.props.dispatch(makeWalletDeposit(payload));
        }
    }

    checkoutAll(obj) {
        const tdata = getIdbySegment(document.location.pathname);
        tdata.value = obj.currencies;
        tdata.cart_items = obj.cart_items;
        tdata.dom_ref = this.props.wallet.ctx;
        tdata.uid = this.props.me.profile.uid[0].value;
        if (!tdata.gid && this.props.me.brandId) tdata.gid = this.props.me.brandId;

        this.props.dispatch(transferStart('checkout'));

        return API.Post(`/forms/wallet/checkout`, obj).then((res) => {
            var msg = API.checkError(res.data);
            if (msg.length > 0) {
                this.props.dispatch(transferFailure(msg));
                tdata.verb = 'checkout-failed';
            } else {
                tdata.verb = 'checkout';
                this.props.dispatch(checkoutSuccess(res.data));
                this.props.dispatch(closeWallet());
                this.props.dispatch(_showNotice('Checkout Successful'));
            }
            window.logUse.logEvent('transaction', tdata);

        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            console.log('token check error: ', msg);
            tdata.verb = 'checkout-error';
            this.props.dispatch(transferFailure(msg));
            window.logUse.logEvent('transaction', tdata);
            return err;
        });
    }

    buildChargeWarning() {
        const price = this.props.myCart.getTotal();
        let html = [];
        const style = {listStyle:'none', padding:0, margin:'10px 0 0 0'};
        if (price === 0) {
            html.unshift(<span key={'useWallet'}>Everything in your cart is free. However a credit card is still required for verification and security. You will not be charged.</span>);
        } else {
            const spending = changeCurrency(this.state.useWallet + this.state.tacApply, this.props.wallet.mycurrency);
            if (spending < price) {
                html.unshift(<span key={'useWallet'}>Add at least <PriceCurrency currency={this.props.wallet.mycurrency} num={(price - spending)}  /> or <PriceCurrency currency={'TAC'} num={price - spending}  /></span>);
            } else {
                style.color = this.props.theme.palette.success.main;
                if (this.state.tacApply > 0) {
                    html.push(<span key={'tacCred'}>{`${<PriceCurrency currency={'TAC'} num={this.state.tacApply}  />} will be traded from your wallet.`}</span>); // so they realize the field already assumes wallet will be emptied
                }
                if (this.state.useWallet > 0) {
                    if (this.state.useWallet > this.state.usdBalance) {
                        let diff =  this.state.useWallet - this.state.usdBalance;
                        if (this.state.usdBalance > 0) {
                            html.push(<span key={'useWallet'}>Your full wallet balance will be drafted, and <PriceCurrency currency={this.props.wallet.mycurrency} num={diff}  /> will be charged to your payment method.</span>); // so they realize the field already assumes wallet will be emptied
                        } else {
                            html.push(<span key={'useWallet'}><PriceCurrency currency={this.props.wallet.mycurrency} num={diff}  /> will be charged to your payment method.</span>);
                        }
                    } else {
                        html.push(<span key={'useWallet'}><PriceCurrency currency={this.props.wallet.mycurrency} num={this.state.useWallet}  /> will be drafted from your wallet.</span>); // so they realize the field already assumes wallet will be emptied
                    }
                    if (this.state.useWallet > price) {
                        html.push(<span key={'remainder'}><PriceCurrency currency={this.props.wallet.mycurrency} num={this.state.useWallet - price}  /> will be left in your wallet for later use or withdrawal.</span>); // so they realize the field already assumes wallet will be emptied
                    }
                }
            }
        }


        if (html.length === 0) return null;
        return <div className={this.props.classes.payprompt} style={style}>{html}</div>

    }

    deleteShipping(p) {
        if (window.confirm('Are you sure you want to delete this shipping method?') === true) {
            this.props.dispatch(shippingDelete(p));
        }
    }

    deleteCard(card) {
        const cards = [...this.props.wallet.paymethods];
        if (!card.id) {
            let has = cards.findIndex(c => {
                for(let prop in card) {
                    if (card[prop] !== c[prop]) {
                        return false;
                    }
                }
                return true;
            })
            if (has > -1) {
                if (has === this.state.cardIndex) {
                    this.setState({cardIndex:-1});
                }
                cards.splice(has, 1);
                this.props.dispatch(transferSuccess({paymethods:cards}));
            } else {
                console.error("State managed Cards malformed");
            }
        } else if (window.confirm('Are you sure you want to delete this card?') === true) {
            let has = cards.findIndex(c => c.id == card.id)
            if (has === this.state.cardIndex) {
                this.setState({cardIndex:-1});
            }
            this.props.dispatch(cardDelete(card.id));
        }
    }

    useCard(payload) {
        const cards = [...this.props.wallet.paymethods];
        let has = cards.findIndex(card => {
            for(let prop in payload.paymethod) {
                if (card[prop] !== payload.paymethod[prop]) {
                    return false;
                }
            }
            return true;
        })
        if (has > -1) {
            // make sure card exists
            let card = cards[has];
            for(let prop in payload.address) {
                if (card[prop] !== payload.address[prop]) {
                    return this.props.dispatch(_showNotice('This card already exists with a different address. Please delete it first', 'warning'));
                }
            }
        } else {
            let newCard = Object.assign({}, payload.paymethod, payload.address);
            cards.unshift(newCard);
        }
        this.setState({showAddPayment:false})
        this.props.dispatch(transferSuccess({paymethods:cards}));
    }

    handleWithdraw() {
        return this.props.dispatch(makeWalletWithdrawal({
            amount: this.state.amountWithdraw,
            currency: 'USD',
            venmo:this.state.venmo
        }));
    }

    renderWithdrawForm() {
        return (<div className={this.props.classes.paperBg} style={{padding:'1%'}}>

            <TextField
                label={'Venmo Account'}
                variant={'outlined'}
                fullWidth={true}
                value={this.state.venmo}
                onChange={e => this.setState({venmo:e.target.value})}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><img alt={'venmo'} src={'/assets/venmo.png'} /></InputAdornment>
                }}
                type='text' margin='dense'
            />

            <TextField
                label={'Amount to Withdraw'}
                variant={'outlined'}
                fullWidth={true}
                value={this.state.amountWithdraw}
                onChange={e => this.setState({amountWithdraw: Math.min(this.state.usdBalance, parseFloat(e.target.value || 0))})}
                helperText={`Your wallet balance is ${<PriceCurrency currency={this.props.wallet.mycurrency} num={this.state.usdBalance}  />}`}
                type='number' margin='dense'
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
            />

            <p>All withdrawals are sent via Venmo within 4 business days, unless the amount matches any exact payment made within the last 30 days, in which case we will simply refund you for that payment to the same card immediately.</p>

            <Button variant="contained" margin="normal" color="secondary"
                    disabled={this.state.amountWithdraw === 0}
                    onClick={() => this.handleWithdraw()}>Withdraw</Button>
        </div>);
    }

    onSpendingChange(e, name) {
        let val = Math.max(0, parseFloat(e.target.value || 0).toFixed(2));
        if (name === 'useWallet') {
            if (this.props.formMethod === 'deposit') {
                val = {useWallet: val}
            } else {
                val = {useWallet : Math.min(this.props.myCart.getTotal(), val)}
            }
            if (val.useWallet + this.state.tacApply > this.props.myCart.getTotal()) {
                val.tacApply = Math.max(0, this.props.myCart.getTotal() - val.useWallet);
            }
        } else if (name === 'tacApply') {
            val = {tacApply: val}
            val = {tacApply: Math.min(this.props.myCart.getTotal(), Math.min(this.state.tacBalance, val))}
            if (this.state.useWallet + val.tacApply > this.props.myCart.getTotal()) {
                val.useWallet = Math.max(0, this.props.myCart.getTotal() - val.tacApply);
            }
        } else {
            return console.warn('unknown charge type')
        }
        this.setState(val)
    }

    renderSpecialRequests() {

    }

    renderSectionBalance() {
        return (<Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="walletBalance"
            >
                <Grid item style={{flexGrow:1}}>Current Wallet Balances</Grid>
                <Grid item>
                    <span style={{color: this.props.theme.palette.primary.main}}>
                        <PriceCurrency currency={'TAC'} num={this.state.tacBalance} />
                    </span>
                </Grid>
                <Grid item>
                    <Divider orientation={'vertical'} variant={'middle'} />
                </Grid>
                <Grid item>
                    <span style={{color: this.props.theme.palette.primary.main}}>
                        <PriceCurrency currency={this.props.wallet.mycurrency} num={this.state.usdBalance} />
                    </span>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item xs={12} sm={6}>
                        <CardHeader
                            style={{width: '100%'}}
                            avatar={<Avatar size={'small'} color={'primary'}>{Grammer.tac('₮')}</Avatar>}
                            title={<span style={{color: this.props.theme.palette.primary.main}}>
                                <PriceCurrency currency={'TAC'} num={this.state.tacBalance}  />
                            </span>}
                            subheader={<React.Fragment>
                                Your {Grammer.tac('TAC')} earned by curating {Grammer.g('Playlists', 'p')}.
                                {this.state.tacBalance > 0 && <div><Link to={this.props.curGroup > 0 ? `/group/${this.props.curGroup}/wallet/activity` : '/wallet/activity'}>View Earnings</Link></div>}
                            </React.Fragment>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardHeader
                            style={{width: '100%'}}
                            avatar={<Avatar size={'small'} color={'primary'}>$</Avatar>}
                            title={<span style={{color: this.props.theme.palette.primary.main}}>
                                <PriceCurrency currency={this.props.wallet.mycurrency} num={this.state.usdBalance}  />
                            </span>}
                            subheader={<div>Your wallet balance is {this.state.usdBalance > 0 ? <Link to={this.props.curGroup > 0 ? `/group/${this.props.curGroup}/wallet/activity` : '/wallet/activity'}>View Transactions</Link> : ''}</div>}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>)
    }

    renderSectionAccounts() {

        const {classes} = this.props;

        function getCardString(card) {
            if (card.brand) {
                return card.brand  + ': ' + card.cardname + ' - Expires ' + card.expiration; // server managed
            } else {
                return card.cardnumber.substr(-4) + ' - Expires ' + card['cc-exp']; // state managed
            }
        }

        let cta = 'Add';
        if (this.state.cardIndex > -1) {
            cta = getCardString(this.props.wallet.paymethods[this.state.cardIndex])
        }

        return (<Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="linked-banks"
            >
                <Grid item style={{flexGrow:1}}>Linked Banks</Grid>
                <Grid item>
                    {cta}
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Grid item xs={12}>
                        <Button style={{margin:'-25px 0 0 0'}} color={'primary'} onClick={e => this.setState({showAddPayment:true})}>Add</Button>
                    </Grid>

                    <Grid container alignContent={'center'} justifyContent={'space-between'} >
                        {this.props.wallet.paymethods && this.props.wallet.paymethods.map((o, i) =>
                            (<Grid item key={'paymethod'+i} className={i === this.state.cardIndex ? classes.selectedCard : classes.cardBg}>
                                <CardHeader
                                    avatar={<Avatar onClick={e => this.setState({cardIndex:i, showAddPayment:false})}>{o.brand ? o.brand.substr(0, 3) : 'new'}</Avatar>}
                                    title={<Typography onClick={e => this.setState({cardIndex:i, showAddPayment:false})}>{getCardString(o)}</Typography>}
                                    subheader={this.addressToString(o)}
                                    action={<IconButton size={'small'} onClick={() => this.deleteCard(o)}><DeleteIcon /></IconButton>}
                                />
                            </Grid>))}
                    </Grid>

                    {this.state.showAddPayment === true &&
                    <React.Fragment>
                        <Divider/>
                        <CardInfo dispatch={this.props.dispatch} onChange={this.useCard} />
                    </React.Fragment>
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>)
    }

    renderSectionShipping() {
        const {classes} = this.props;

        let cta = 'Add';
        if (this.state.shipIndex > -1) {
            cta = this.addressToString(this.props.wallet.shipping[this.state.shipIndex]);
        }

        return (<Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="shipping-addresses"
            >
                <Grid item style={{flexGrow:1}}>Shipping Address</Grid>
                <Grid item>
                    {cta}
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Grid item xs={12}>
                        <Button style={{margin:'-25px 0 0 0'}} color={'primary'} onClick={e => this.setState({showAddShipping:true})}>Add</Button>
                    </Grid>

                    <Grid container alignContent={'center'} justifyContent={'space-between'} >
                    {this.props.wallet.shipping.map((o, i) => (<Grid item key={'ship'+o.profile_id}
                            className={i === this.state.shipIndex ? classes.selectedCard : classes.cardBg}
                            style={{maxWidth:240}}>
                            <CardHeader title={<Typography onClick={() => this.setState({shipIndex:i, showAddShipping:false})}>{this.addressToString(o)}</Typography>}
                                        action={<IconButton size={'small'} onClick={() => this.deleteShipping(o.profile_id)}><DeleteIcon /></IconButton>}
                            />
                        </Grid>
                    ))}
                </Grid>

                {this.state.showAddShipping === true && <Address formKey={'shippingAddress'} dispatch={this.props.dispatch} fullForm={true} closeForm={() => this.setState({showAddShipping:false})} />}
                </Grid>
            </AccordionDetails>
        </Accordion>)
    }

    renderSectionProducts(){
            let items = this.props.myCart.getItems();
            if (items.length === 0) return null;

            const allItems = items.map((cartItem, i) => {

                const headerAttrs = {
                    title : cartItem.getTitle(),
                    subheader : <PriceCurrency currency={cartItem.get('price', 'currency_code')} num={cartItem.get('price', 'number')} />,
                    avatar: <Avatar variant={'square'} className={this.props.classes.imgContain} src={cartItem.getAvatarImg()} alt="Reward Cover"/>
                }

                headerAttrs.title = <Link to={cartItem.getMyUrl()} onClick={e => this.props.dispatch(closeWallet())}>{headerAttrs.title}</Link>

                if (cartItem.get('type', 'target_id') === 'wager') {
                    const toPass = {onClick: () => this.setState({showWagerContract:cartItem.product.json.track})}
                    const prediction = cartItem.get('field_wager_value') ? ` for a ${cartItem.get('field_wager_value')}% Delta Prediction.` : null;
                    headerAttrs.title = <div {...toPass}>Wager {headerAttrs.subheader} on <em>{cartItem.get('title')}</em> {prediction}</div>;
                    headerAttrs.subheader = <div {...toPass}><u>Read Full Terms</u></div>;
                    headerAttrs.avatar = <Avatar {...toPass}><SvgIcon component={PlaceChips} viewBox='0 0 24 24' /></Avatar>;
                }

                headerAttrs.action = (cartItem.count > 1) ?
                    <TextField type={'number'} variant={'filled'} value={cartItem.count} label={"Quantity"}
                               size={'small'}
                               style={{maxWidth:70}}
                               onChange={e => this.props.dispatch(changeCartQuantity(cartItem.id(), parseInt(e.target.value || 0)))} />
                :
                    <IconButton onClick={() => this.props.dispatch(removeFromCart(cartItem.id()))}>
                        <DeleteIcon/>
                    </IconButton>


                return <Grid item key={'cartItem' + i}>
                    <CardHeader {...headerAttrs} />
                </Grid>
            });

            if (allItems.length === 1)  return allItems[0];
            return <React.Fragment>
                {allItems}
                <Typography color={'primary'} style={{textAlign:'center', marginBottom:20}} variant={'subtitle2'}>
                    <PriceCurrency currency={this.props.wallet.mycurrency} num={this.props.myCart.getTotal()} showCode={true} showSymbol={true} />
                </Typography>
            </React.Fragment>
    }

    addressToString(o) {
        let props = ['given_name', 'address_line1', 'locality', 'administrative_area', 'postal_code', 'country_code'];
        return props.reduce((acc, val) => {
            if (o[val]) acc += ' ' + o[val];
            return acc;
        }, '')
    }

    renderSectionPayments() {
        return (<Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="payment-options"
            >
                <Grid item style={{flexGrow:1}}>Payment Options</Grid>
                <Grid item>

                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container justifyContent={'space-between'} alignContent={'center'} spacing={2}>
                    <Grid item xs={12} sm={this.props.formMethod === 'payment' ? 6 : 12}>
                        <TextField
                            fullWidth={true}
                            label={"Charge Card"}
                            variant={'outlined'}
                            type={'number'} margin='dense'
                            value={(this.state.useWallet).toFixed(2)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            onChange={e => this.onSpendingChange(e, 'useWallet')}
                            />

                    </Grid>

                    {this.props.formMethod === 'payment' &&
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth={true}
                            label={<span>From {Grammer.tac('TAC')}</span>}
                            variant={'outlined'}
                            type={'number'} margin='dense'
                            disabled={this.state.tacBalance === 0}
                            value={(this.state.tacApply).toFixed(2)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">&#x20AE;</InputAdornment>
                            }}
                            onChange={e => this.onSpendingChange(e, 'tacApply')}
                            />
                    </Grid>
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>)
    }

    render() {
        if (!this.props.me || !this.props.me.profile) return 'login';
        if (this.props.wallet.balance.forceCheck === true) return 'Loading wallet balance...';
        if (this.props.formMethod === 'withdraw') return this.renderWithdrawForm();

        const {classes} = this.props;

        const spending = this.state.useWallet + this.state.tacApply;

        return (
            <div className={classes.container}>
                {(this.props.wallet.loading === true) ? <OverlayLoader /> : null}
                {this.props.wallet.reason && <p className={classes.prompt}>{this.props.wallet.reason}</p>}

                {this.state.errors['paymethod'] && <p className={classes.prompt}>{this.state.errors['paymethod']}</p>}
                {this.state.errors['shipAddress'] && <p className={classes.prompt}>{this.state.errors['shipAddress']}</p>}

                {(this.state.showWagerContract) && <ConfirmWager key={'wagercontract'}
                                              onClose={() => this.setState({showWagerContract:false})}
                                              track={this.state.showWagerContract} />}

                {this.renderSectionProducts()}
                {this.renderSectionBalance()}
                {this.renderSectionAccounts()}
                {this.props.myCart.hasShippable() && this.renderSectionShipping()}
                {this.renderSectionPayments()}

                {this.props.wallet.error ? <p className={classes.prompt}>{this.props.wallet.error}</p> : ''}

                {this.buildChargeWarning()}

                <Button variant="contained" margin="normal" color="primary"
                        style={{marginTop: 30}}
                        disabled={
                                spending < this.props.myCart.getTotal() || this.state.cardIndex < 0
                                || (this.state.shipIndex < 0 && this.props.myCart.hasShippable() === true)
                        }
                        fullWidth={true}
                        onClick={(e) => this.handlePayment()}>{this.props.formMethod === 'payment' ? 'Place your order' : 'Place your ' + this.props.formMethod}</Button>

            </div>

        );
    }
}

const styles = theme => ({
    paperBg : {
        backgroundColor:alpha(theme.palette.background.paper, 0.75)
    },
    container: {
        padding: '2% 3%',
        '& sup': {
            fontSize: '75%'
        }
    },
    tacVal: {
        fontWeight: 800,
        color: theme.palette.primary.main,
    },
    usdVal: {
        fontWeight: 800,
        color: theme.palette.secondary.main,
        margin: 0,
        fontSize: '16px',
        textAlign: 'right'
    },
    placeholder: {
        color: 'grey'
    },
    payprompt: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        textShadow: '1px 1px .1px 3px' + theme.palette.action.active
    },
    prompt: {
        fontSize: '120%',
        textAlign: 'center',
        color: theme.palette.error.main,
        textShadow: '1px 1px .1px 3px' + theme.palette.action.active
    },
    selectedCard : {
        borderRadius:8,
        boxShadow:`0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
        backgroundColor: theme.palette.background.type === 'light' ? lighten(theme.palette.background.paper, .2) : darken(theme.palette.background.paper, .1)
    },
    cardBg : {
        textDecoration:'underline',
        cursor:'pointer',
        color:theme.palette.action.disabled,
    },
    sectionHead: {
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 4,
        opacity: 0.4,
        textTransform: 'uppercase',
        fontSize: 15
    },
    imgContain: {
        '& img': {
            objectFit: 'contain',
            backgroundSize: 'contain'
        }
    }
});


const mapStateToProps = (state) => {
    const newState = {
        me:state.auth.me,
        wallet: state.wallet,
        myCart : new MyCart(state.wallet.cartItems),
        curGroup:state.auth.curGroup
    };
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, {withTheme: true})(CreditCard)));
