import API from "../Util/API";
import Config from "../Config";
import {push} from "connected-react-router";
import {_showNotice} from "./formsReducer";
import {getParam} from "../Util/WindowUtils";

import {
    changeGroup,
    logInFailure,
    logInStart,
    logInSuccess,
    setFeatures,
    setGrammer,
    signUpFailure,
    signUpStart,
    signUpSuccess,
} from "./authReducer";

const segmentMap = {
  // expects /group/9/playlists/8321/tracks/8483/edit
  users: "aid",
  members: "uid",
  group: "gid",
  playlists: "pid",
  tracks: "tid",
  comments: "cid",
  marketplace: "rid",
  faqs: "nid",
  footage: "fid",
  clips: "fcid",
  highlights: "fcid",
};

export function changeApp(gid) {
  return (dispatch) => {
    API.Get("/appstartup/menu/" + gid).then((res) => {
      dispatch(changeGroup(gid, res.data.groups));
    });
  };
}

export function getIdbySegment(url) {
  const tdata = {};
  let parts = url.split("/");
  if (parts[1] === "kauai") {
    tdata.gid = 61
    parts.shift();
  } // alias
  if (parts[1] === "forms") {
    // since /forms/group/9/playlists/8321/tracks/8483/edit
    parts.shift();
    tdata.verb = parts[parts.length - 1]; // add / edit / delete
  }
  Object.entries(segmentMap).forEach(([segName, param]) => {
    let index = parts.indexOf(segName);
    if (index > 0 && parseInt(parts[index + 1]) > 0) {
      tdata[param] = parseInt(parts[index + 1]);
    }
  });
  tdata.bundle = parts
    .reverse()
    .find(
      (e) =>
        !parseInt(e) &&
        e !== "delete" &&
        e !== "add" &&
        e !== "edit" &&
        e !== ""
    );
  if (!tdata.bundle) delete tdata.bundle;
  if (!tdata.gid) tdata.gid = window.REACT_APP_GID;
  return tdata;
}

const redirectLogin = function () {
  var segs = document.location.pathname.split("/");
  if (segs.length > 1) {
    if (
      segs[1] !== "info" &&
      segs[1] !== "marketplace" &&
      segs[1] !== "groups" &&
      segs[1] === "group" &&
      segs.length !== 3 &&
      segs[1] !== "login" &&
      segs[1] !== "otp" &&
      segs[1] !== "register"
    ) {
      if (segs.length > 2) {
        document.location.href = `/group/${segs[2]}/signin`;
      } else {
        document.location.href = '/signin';
      }
    }
  }
};

export function deleteGroup(gid) {
  return (dispatch) => {
    dispatch(signUpStart());
    const apiurl = "/forms/group/" + gid + "/delete";
    API.Delete(apiurl)
      .then((res) => {
        var msg = API.checkError(res.data);
        if (msg.length > 0) {
          dispatch(signUpFailure(msg));
        } else {
          if (res.data.success) dispatch(_showNotice(res.data.success, 'success-persist', {persist:true}));
          dispatch(signUpSuccess(res.data));
          // TODO: remove from menu
          dispatch(initApp(apiurl, "group-deleted"));
          dispatch(push("/groups"));
        }
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        console.log("token check error: ", msg);
        dispatch(signUpFailure(msg));
        return err;
      });
  };
}

export function joinGroup(gid) {
  return (dispatch) => {
    dispatch(signUpStart());
    const apiurl = "/forms/group/" + gid + "/members/add";
    API.Post(apiurl)
      .then((res) => {
        var msg = API.checkError(res.data);
        if (msg.length > 0) {
          dispatch(signUpFailure(msg));
        } else {
          if (document.location.pathname == '/group/' + gid) {
            document.location.reload();
          } else {
            if (res.data.success) dispatch(_showNotice(res.data.success, 'success-persist', {persist:true}));
            dispatch(signUpSuccess(res.data));
            dispatch(initApp(apiurl, "group-joined"));
            if (res.data.gid) {
              res.data.gid = gid;
            }
            let dest = buildNextUrl(res.data);
            dispatch(push(dest));
          }
        }
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        console.log("token check error: ", msg);
        dispatch(signUpFailure(msg));
        return err;
      });
  };
}

export function leaveGroup(gid, uid) {
  return (dispatch) => {
    dispatch(signUpStart());

    const apiurl = "/forms/group/" + gid + "/users/" + uid + "/delete";
    API.Delete(apiurl)
      .then((res) => {
        var msg = API.checkError(res.data);
        if (msg.length > 0) {
          dispatch(signUpFailure(res.data.error));
          dispatch(_showNotice(res.data.error));
        } else {
          if (document.location.pathname == '/group/' + gid) {
            document.location.reload();
          } else {
            if (res.data.success) dispatch(_showNotice(res.data.success, 'success-persist', {persist:true}));
            dispatch(signUpSuccess(res.data));
            dispatch(initApp(apiurl, "group-left"));
            dispatch(push("/group/" + gid));
          }
        }
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        console.log("token check error: ", msg);
        dispatch(_showNotice(msg));
        dispatch(signUpFailure(msg));
        return err;
      });
  };
}

function buildNextUrl(data) {
  let dest = getParam("q", document.location.search, false);
  if (!dest) {
    dest = `/my-profile`;
    if (data.gid && data.uid) {
      dest = `/forms/users/${data.uid}/group/${data.gid}/edit`;
      // dest = `/forms/group/${data.gid}/members/${data.uid}/edit`;
    } else if (data.uid) {
      dest = `/forms/users/${data.uid}/edit`;
    }
  } else {
    dest = decodeURIComponent(dest); // fix 'waitlist' ending
    // TODO: validate it's not a valid front end url
    if (dest.indexOf('/') !== 0) return `/my-profile`; // illegal redirect. must be relative path
  }
  return dest;
}

export function otpLogin() {


  return (dispatch) => {
    const apiurl = document.location.pathname + document.location.search;
    API.Get(apiurl)
      .then((res) => {
        var msg = API.checkError(res.data),
          dest = "/login", variant = 'error'
        if (msg.length > 0) {
          dispatch(initApp(apiurl, "otp-login-failed"));
        } else if (res.data.access_token) {
          if (res.data.randpass) {
            msg = "Your new Account Management PassCode is " + res.data.randpass + ". Use it only to change your linked Email Address or Delete your account. Request a new one anytime from your Account page";
            variant = 'success-persist';
            delete res.data.randpass;
          }
          delete res.data.success; // pass can include password
          delete res.data.email; // pass can include password

          res.data.created_time = res.data.apiversion
            ? res.data.apiversion
            : Math.floor(new Date().getTime() / 1000);
          localStorage.setItem(Config.api.tokName, JSON.stringify(res.data));
          localStorage.setItem(
            Config.api.tokName + "_apiversion",
            res.data.created_time
          );

          // TODO
          dest = buildNextUrl(res.data);
          dispatch(initApp(apiurl, "otp-login"));
        } else if (res.data.randpass) {
          dispatch(getToken(res.data.email, res.data.randpass));
          msg = "Your new Account Management PassCode is " + res.data.randpass + ". Use it only to change your linked Email Address or Delete your account. Request a new one anytime from your Account page";
          variant = 'success-persist';
          dest = buildNextUrl(res.data);
        } else {
          dispatch(initApp(apiurl, "otp-login-failed"));
          msg = "There was an issue with your one time password. Please try again";
        }
        dispatch(push(dest));
        if (msg.length > 0) dispatch(_showNotice(msg, variant));
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        dispatch(_showNotice(msg, 'error'));
        dispatch(initApp(apiurl, "otp-login-failed"));
        dispatch(push("/login?error=otpfailed"));
      });
  };
}

export function initApp(apiurl, verb) {
  const tdata = getIdbySegment(apiurl);
  tdata.verb = verb;
  tdata.webwersion = window.REACT_APP_VERSION_ID;

  return (dispatch, getState) => {
    let initurl = `/appstartup?windowWidth=${window.innerWidth}&windowHeight=${window.innerHeight}`;

    if (!tdata.gid) tdata.gid = getState().auth.curGroup;
    if (tdata.gid) initurl += `&gid=${tdata.gid}`;

    API.Get(initurl)
      .then((res) => {
        res.data.created_time = res.data.apiversion
          ? res.data.apiversion
          : Math.floor(new Date().getTime() / 1000);
          localStorage.setItem(Config.api.tokName + "_apiversion", res.data.created_time
        );

        if (!tdata.gid && res.data.brandId) {
          console.warn("FALLING BACK ON brandID", tdata, res.data)
          tdata.gid = res.data.brandId;
        }
        if (res.data.profile && !res.data.brandId && tdata.gid > 0 && (!res.data.groups || typeof res.data.groups[tdata.gid] === "undefined")) {
          dispatch(joinGroup(tdata.gid));
        } else {
          if (res.data.groups && tdata.gid > 0 && res.data.groups[tdata.gid] && res.data.groups[tdata.gid].field_features) {
            if (res.data.groups[tdata.gid].field_features.length > 0)
              dispatch(setFeatures(res.data.groups[tdata.gid].field_features[0].value));
            if (res.data.groups[tdata.gid].field_grammer.length > 0)
              dispatch(setGrammer(res.data.groups[tdata.gid].field_grammer[0].value));
          } else if (res.data.groups && res.data.brandId > 0 && res.data.groups[res.data.brandId] && res.data.groups[res.data.brandId].field_features) {
            if (res.data.groups[res.data.brandId].field_features.length > 0)
              dispatch(setFeatures(res.data.groups[res.data.brandId].field_features[0].value));
            if (res.data.groups[res.data.brandId].field_grammer.length > 0)
              dispatch(setGrammer(res.data.groups[res.data.brandId].field_grammer[0].value));
          }
        }
        dispatch(logInSuccess(res.data)); // misnomer since failed login still returns array('menu'=>[])

        tdata.apiversion = res.data.apiversion;
        if (res.data.profile && res.data.profile.uid) {
          tdata.uid = res.data.profile.uid[0].value;
          if (res.data.brandId) {
            window.logUse.setUserProperties({
              uid: tdata.uid,
              brandId: res.data.brandId,
            });
          } else {
            window.logUse.setUserProperties({ uid: tdata.uid });
          }
        }

        let tokens = API.getLocalTokens();
        if (tokens && !res.data.profile) {
          tdata.verb = "expired";
          dispatch(
            logInFailure('Your session expired. Please <a href="'+(tdata.gid > 0 ? `/group/${tdata.gid}/signin` : '/signin')+'">login</a> again')
          );
          redirectLogin();
        } else {
          if (tokens) tdata.verb = "return";
          else tdata.verb = "visit";
          //  TODO: redirect to q= or /membership profile
        }
        window.logUse.logEvent("init", tdata);
      })
      .catch((err) => {
        console.error("appstartup error: ", err);
        var msg = API.getErrorMsg(err);
        dispatch(logInFailure('Startup Error: ' + msg));
        /* if (API.getLocalTokens()) {
          localStorage.removeItem(Config.api.tokName);
          document.location.href = tdata.gid > 0 ? `/group/${tdata.gid}/signin` : '/signin';
        } only on failure of refresh token */
        tdata.verb = "startup-failed";
        window.logUse.logEvent("init", tdata);
        return err;
      });
  };
}

export function getToken(user_email, password, scope) {
  return (dispatch) => {
    dispatch(logInStart());

    var formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("client_id", Config.api.client_id);
    formData.append("client_secret", Config.api.client_secret);
    formData.append("username", user_email);
    formData.append("password", password);
    if (scope) {
      formData.append("scope", scope);
    } else {
      //  formData.append('scope', 'authenticated administrator verified_cc verified_fb verified_email ui_developer');
    }

    var req = {
      url: "/oauth/token",
      method: "POST",
      headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        "Content-Type": `multipart/form-data; boundary=` + formData._boundary,
      },
      data: formData,
    };
    const tdata = getIdbySegment(document.location.pathname);
    tdata.value = user_email;
    API.Request(req)
      .then((res) => {
        var msg = API.checkError(res.data);
        if (msg.length > 0) {
          dispatch(logInFailure('Token Failed: ' + msg));
          dispatch(_showNotice(msg, 'warning'));
          tdata.verb = "login-failed";
          window.logUse.logEvent("init", tdata);
        } else {
          res.data.created_time = res.data.apiversion
            ? res.data.apiversion
            : Math.floor(new Date().getTime() / 1000);
          const storeLocal = res.data;
          storeLocal['email'] = undefined;
          storeLocal['success'] = undefined; // might include plain text password in notice
          localStorage.setItem(Config.api.tokName, JSON.stringify(storeLocal));
          localStorage.setItem(
            Config.api.tokName + "_apiversion",
            res.data.created_time
          );

          if (window.PasswordCredential && navigator.credentials && document.getElementById('login-form') && !window.Cypress) {
            // let credentialForm = document.getElementById('login-form');
            let credential = new window.PasswordCredential({
              id: 'Login Form',
              password: password,
              name: user_email,
              iconURL: "https://trackauthoritymusic.com/skin/favicon-32x32.png"
            });
            window.navigator.credentials.store(credential).then(() => {
              console.log("CREDS STORED!", credential);
            })

          }

          var dest = getParam("q", document.location.search, tdata.gid > 0 ? `/group/${tdata.gid}` : "/");
          dispatch(logInSuccess(res.data));
          dispatch(initApp("/oauth/token", "login"));

          setTimeout(() => {
            dispatch(push(dest));
          }, 300) // TODO: fix from race condition!!
        }
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        console.log("tokencheck error: ", msg);
        dispatch(_showNotice(msg, 'warning'));
        dispatch(logInFailure('Token Error: ' + msg));
        tdata.verb = "login-failed";
        window.logUse.logEvent("init", tdata);
        return err;
      });
  };
}

export function registerForm(email, gids, coupon) {
  return (dispatch) => {
    dispatch(signUpStart());

    const tdata = getIdbySegment(document.location.pathname);
    var obj = {
      email: email,
      gids: typeof gids === 'string' ? gids.split(",") : gids,
    };
    if (tdata.gid && obj.gids.indexOf(tdata.gid) < 0) {
      obj.gids.push(tdata.gid);
    }
    if (obj.gids.length > 0) {
      tdata.gids = gids;
    } else {
      delete obj['gids'];
    }

    if (coupon && coupon.length > 0) {
      obj.coupon = coupon;
      tdata.coupon = coupon;
    }

    let password = "";
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    while (password.length < 10)
      password += alphabet.charAt(
        Math.floor(Math.random() * alphabet.length)
      ); // maybe store to autologin on success
    obj.isRandom = true;

    obj.password = password;
    tdata.value = email;

    API.Post("/forms/users/add", obj)
      .then((res) => {
        if (typeof res.data.error !== "undefined") {
          dispatch(signUpFailure(res.data.error));
          tdata.verb = "register-failed";
        } else {
          tdata.verb = "registered";
          window.logUse.logEvent("init", tdata);
          dispatch(_showNotice(res.data.success, 'success-persist', {persist:true}));
          dispatch(signUpSuccess(res.data));
          dispatch(getToken(email, password));
        }
        window.logUse.logEvent("init", tdata);
      })
      .catch((err) => {
        var msg = API.getErrorMsg(err);
        console.log("token check error: ", msg);
        dispatch(signUpFailure(msg));
        tdata.verb = "register-failed";
        window.logUse.logEvent("init", tdata);
        return err;
      });
  };
}
