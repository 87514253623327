import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {withStyles} from "@material-ui/styles";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import PropTypes from 'prop-types';

class TrackStatsGraph extends Component {

    constructor(p) {
        super(p);
        this.formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    sumDay(d) {
        return d.viewCount + d.likeCount + d.commentCount + d.favoriteCount;
    }

    calcGrowth() {
        const data = Object.values(this.props.stats);
        if (data.length > 1) {
            const l = data.length - 1;
            return this.formatter.format(this.sumDay(data[l]) / this.sumDay(data[0]));
        }
        return 0;
    }

    renderStatsText() {
        const {classes, stats} = this.props;

        const data = [];
        for (let timestamp in stats) {
            data.push(<Typography variant={"body2"} className={classes.statsLine} key={`stats${timestamp}`}>
                As of {moment(timestamp * 1000).format('MMM Do \'YY')}: &nbsp;
                {this.formatter.format(stats[timestamp].viewCount)} <em>Views</em>, &nbsp;
                {this.formatter.format(stats[timestamp].likeCount)} <em>Likes</em>, &nbsp;
                {this.formatter.format(stats[timestamp].commentCount)} <em>Comments</em>, &nbsp;
                {this.formatter.format(stats[timestamp].favoriteCount)} <em>Favorites</em>
            </Typography>)
        }

        return data;
    }

    renderGraph() {
        const {stats} = this.props;
        const data = [];
        for (let timestamp in stats) {
            data.push({
                day:moment(timestamp * 1000).format('MMM Do \'YY'),
                Views:stats[timestamp].viewCount,
                Likes:stats[timestamp].likeCount,
                Comments:stats[timestamp].commentCount,
                Favorites:stats[timestamp].favoriteCount
            })
        }

        return <ResponsiveContainer width={'100%'} height={200}>
                <AreaChart margin={{ top: 0, right: 20, left: 10, bottom: 0 }} data={data} >
                    <Tooltip />
                    <XAxis dataKey="day" hide={true} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Area type="monotone" dataKey="Views" stroke={this.props.theme.palette.secondary.main} fill={this.props.theme.palette.secondary.main} yAxisId={0} />
                    <Area type="monotone" dataKey="Likes" stroke={this.props.theme.palette.primary.light} fill={this.props.theme.palette.secondary.light} yAxisId={1} />
                    <Area type="monotone" dataKey="Comments" stroke={this.props.theme.palette.primary.dark} fill={this.props.theme.palette.secondary.dark} yAxisId={2} />
                    <Area type="monotone" dataKey="Favorites" stroke={this.props.theme.palette.primary.main} fill={this.props.theme.palette.secondary.main} yAxisId={3} />
                </AreaChart>
            </ResponsiveContainer>
    }

    render() {
        const growth = this.calcGrowth();

        return <div>
            {Object.keys(this.props.stats).length > 1 && <p style={{margin:'3px 0 0 0', textAlign:'center'}}>{growth}% Delta</p>}
            {Object.keys(this.props.stats).length > 2 ? this.renderGraph() : this.renderStatsText()}
        </div>

    }


}

TrackStatsGraph.propTypes = {
    classes: PropTypes.object.isRequired,
    stats: PropTypes.object.isRequired,
};


const styles = theme => ({
    statsLine : {
        margin:'0 4px 3px 4px', fontSize:'90%',
        paddingTop:2,
        textAlign:'right',
        borderTop: `.5px ${theme.palette.divider} solid`
    }
});

export default withStyles(styles, {withTheme: true})(TrackStatsGraph);
