import React from 'react';
import {listData} from '../../redux/listDataReducer';
import Drupal2Json from '../../Util/Drupal2Json';
import {push} from 'connected-react-router';
import SanitizedHTML from 'react-sanitized-html';
import GroupActions from '../../Components/GroupActions';
import Config from '../../Config';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {isEnabled} from "../../Util/EnabledFeature";
import GroupStatsChips from "./GroupStatsChips";

class GroupDetails extends React.Component {

 constructor(props) {
  super(props);
  this.state = {expanded: '', listUrl: ''};
 }

    handleChange = panel => (event, expanded) => {
     if (panel === this.state.expanded) {
      return this.setState({expanded: false}); // close
     }

     const gid = this.props.data.id[0].value;

     if (panel === 'members') {
      this.props.dispatch(push('/group/' + gid + '/members'));
     } else if (panel === 'emails') {
      this.props.dispatch(push('/group/' + gid + '/emails'));
     } else if (panel === 'playlists') {
      this.props.dispatch(push('/group/' + gid + '/playlists'));
     } else {
      const url = '/group/' + gid + '/' + panel;
      this.setState({expanded: panel, listUrl: url});
      this.props.dispatch(listData(url));
     }
    };

    render() {
     const item = this.props.data;
     const json = new Drupal2Json(item);
     let gid = item.id[0].value;

     let vidHTML = null;
     let bodyHTML = json.get('field_body', 'value');
     if (!bodyHTML) bodyHTML = json.get('field_body', 'summary');  // TODO: replace with field_body, 'summary'
     if (bodyHTML) {
      if (bodyHTML.indexOf('VIDEOTOUR') > -1) {
       let dims = {w: 375, h: 812}; // iphone X
       dims.h = Math.min(812, Math.floor(window.innerHeight / 1.2));
       let ratio = dims.h / 812;
       dims.w = Math.floor(dims.w * ratio);

       let vidPath = gid === 10 ? '/videos/gid_10/mobile/auto-jsl.mp4' : gid === 11 ? '/videos/gid_8/mobile/auto-oursoundtrack.mp4' : gid === 6 ? '/videos/gid_6/mobile/beatbazaar.mp4' : '/videos/gid_9/mobile/auto-ftb.mp4';

       vidHTML = <Grid item xs={12} sm={6} style={{marginBottom:20}}><div className="mobile" style={{width: dims.w, height: dims.h}}>
        <div className="deviceMask">
         <video
          style={{objectFit: 'cover'}}
          width={'100%'} height={'100%'}
          autoPlay muted playsInline
          src={'https://trackauthoritymusic.com' + vidPath} type="video/mp4"
         />
        </div>
       </div></Grid>;
      }
      bodyHTML = <SanitizedHTML allowedTags={Config.allowedTags.concat('a')}
       allowedAttributes={Config.allowedAttributes}
       html={bodyHTML}/>;
     }

     return (
      <React.Fragment>

       <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{marginTop:20}}>
        <Grid item xs={12} sm={6} style={{alignSelf: 'center', textAlign: 'center'}}>

         {json.get('field_cover', 'url') ?
          <img style={{width: '100%', maxWidth: 400, marginBottom: 10}}
           src={json.get('field_cover', 'url')}
           alt={json.get('field_cover', 'alt')}/> : ''
         }

         <Typography variant='h1' style={{marginBottom: 10}}>{json.get('label', 'value')}</Typography>

         {bodyHTML}

         {isEnabled('groups:field_event_dates', true) && json.get('field_event_dates', 'value') &&
         <Typography variant='subtitle2' style={{marginBottom: 10}}>{json.getTime('field_event_dates', 'MMMM Do YYYY, h:mm')} {json.get('field_event_dates', 'end_value') ? ' - ' + json.getTime('field_event_dates', 'MMMM Do YYYY, h:mm', 'end_value') : null}</Typography>
         }

        </Grid>

        {vidHTML}

       </Grid>

       <GroupActions dispatch={this.props.dispatch} me={this.props.me} gjson={json}/>
       <GroupStatsChips dispatch={this.props.dispatch} group={this.props.data} />
      </React.Fragment>
     );
    }
}

export default GroupDetails;
