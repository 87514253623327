import React, {Component} from 'react';
import AutoCompleteEntity from '../AutoCompleteEntity';
import Config from '../../Config';
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import {isEnabled} from "../../Util/EnabledFeature";

const catList = {
    1: "Film & Animation",
    2: "Autos & Vehicles",
    10: "Music",
    15: "Pets & Animals",
    17: "Sports",
    18: "Short Movies",
    19: "Travel & Events",
    20: "Gaming",
    21: "Videoblogging",
    22: "People & Blogs",
    23: "Comedy",
    24: "Entertainment",
    25: "News & Politics",
    26: "Howto & Style",
    27: "Education",
    28: "Science & Technology",
    29: "Nonprofits & Activism",
    30: "Movies",
    31: "Anime/Animation",
    32: "Action/Adventure",
    33: "Classics",
    34: "Comedy",
    35: "Documentary",
    36: "Drama",
    37: "Family",
    38: "Foreign",
    40: "Sci-Fi/Fantasy",
    41: "Thriller",
    42: "Shorts",
    43: "Shows",
    44: "Trailers",
};

class YoutubeSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {category: isEnabled('media:ytsource', true) ? 0 : 10};
        if (typeof window.store.getState().auth.gFeatures['media:ytsource'] === 'number' && window.store.getState().auth.gFeatures['media:ytsource'] > 0)  {
            this.state.category = window.store.getState().auth.gFeatures['media:ytsource'];
        }
    }
    componentDidMount() {
        if (typeof window.store.getState().auth.gFeatures['media:ytsource'] === 'number' && window.store.getState().auth.gFeatures['media:ytsource'] > 0)  {
            this.setState({category: window.store.getState().auth.gFeatures['media:ytsource']});
        }
    }

    render() {
        const {classes, ...inpProps} = this.props;
        inpProps.id = 'youtube-search-input';
        let url = 'https://www.googleapis.com/youtube/v3/search?maxResults=16&part=snippet&type=video&key=' + Config.google.key + '&q=:query';
        if (parseInt(this.state.category) > 0) {
            url += '&videoCategoryId=' + this.state.category;
        }

        if (isEnabled('media:ytsource', true)) {
            return (<Grid container wrap={'wrap-reverse'}>
                <Grid item style={{flexGrow:1, marginRight:5, width:(window.innerWidth < 500 ? '100%' : 'auto')}}>
                    <AutoCompleteEntity
                        {...inpProps}
                        source='youtube'
                        apiurl={url}
                        placeholder={isEnabled('media:ytsearch', true) ? 'Search Youtube Music or paste URL' : 'Paste the Youtube URL here'}
                    />
                </Grid>
                <Grid item style={{display:'none'}}></Grid>
                <Grid item style={{width:(window.innerWidth < 500 ? '99%' : 'auto')}}>
                    <Select
                        variant={'outlined'}
                        label={'Category'}
                        fullWidth={true}
                        onChange={e => this.setState({category: e.target.value.toString()})}
                        value={this.state.category}
                    >
                        <option key='noselection' value='0'>All categories</option>
                        {Object.entries(catList).map(arr => (
                            <option key={arr[0]} value={arr[0].toString()}>{arr[1]}</option>
                        ))}
                    </Select>
                </Grid>
            </Grid>);
        } else {
            return <AutoCompleteEntity
                {...inpProps}
                source='youtube'
                apiurl={url}
                placeholder={isEnabled('media:ytsearch', true) ? 'Search Youtube Music or paste URL' : 'Paste the Youtube URL here'}
            />
        }


    }
}

export default YoutubeSearch;
