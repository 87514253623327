import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {debounce} from "throttle-debounce";
import Form2Json from '../../Util/Form2Json';
import MediaForm from '../../Components/MediaForm';
import ImageUpload from '../../Components/ImageUpload';
import OverlayLoader from '../../Components/OverlayLoader';
import ChipSelector from '../../Components/ChipSelector';
import AutoCompleteEntity from '../../Components/AutoCompleteEntity';
import StartDate from '../../FormFields/StartDate';
import PropTypes from 'prop-types';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Switch from '../../FormFields/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRange from "@material-ui/icons/DateRange";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import LinkIcon from "@material-ui/icons/Link";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Typography from "@material-ui/core/Typography";
import FormInstructions from "../../Components/FormInstructions";
import GenreSelector from "../../Components/GenreSelector";
import {pStyles} from "../../Util/ThemeUtils";
import {NavLink} from "react-router-dom";
import GroupBranding from "../NewGroupPlans/GroupBranding";
import {
    _showNotice,
    addFieldWidget,
    addNodeEntry,
    changeFieldVal,
    changeFieldVals,
    removeFieldWidget,
    setNodeEntries,
    submitDelete,
    submitForm,
    submitTrack
} from '../../redux/formsReducer';
import {updateMyGroup} from "../../redux/authReducer";
import {updateEntity} from "../../redux/entityDataReducer";
import {updateListItem} from "../../redux/listDataReducer";
import {updatePlayerTrack} from "../../redux/playerReducer";
import {connect} from "react-redux";
import MyGeoLocation from "../../Util/MyGeoLocation";
import {Place} from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import AddIcon from "@material-ui/icons/Add";
import API from "../../Util/API";
import {getIdbySegment} from "../../redux/authActions";

class FormWrapper extends Component {

    nestedForms = {'entity': 'fields', 'node': 'subform'};

    typeMap = {
        "string": this.renderStringField, // value
        "text_format": this.renderStringField, // value
        "text": this.renderStringField, // value
        "text_long": this.renderStringField, // value
        "text_with_summary": this.renderStringField, // value
        "string_long": this.renderStringField, // value
        "address": this.renderAddressField,
        'commerce_plugin_item:recurring_period': this.renderRecurringField, // value
        "email": this.renderStringField, // value
        "password": this.renderStringField, // value
        "telephone": this.renderPhoneField, // value
        "float": this.renderIntField, // value
        'decimal': this.renderIntField, // value
        'commerce_price': this.renderIntField, // value
        'integer': this.renderIntField, // value
        'boolean': this.renderBoolField, // value
        "select": this.renderListField, // value???
        "radios": this.renderListField, // value???
        "checkboxes": this.renderCheckboxesField, // value???
        "list_string": this.renderListField,  // value
        "datetime": this.renderDateField, // value
        "daterange": this.renderDateRangeField, // value / value2
        "timestamp": this.renderTimestampField,
        "fieldset": this.renderLinkField, // uri
        "link": this.renderLinkField, // uri
        "path": this.renderLinkField, // uri
        "color_field_type": this.renderColorField, // color, opacity
        'image': this.renderImgField, // target_id, fids, url
        'managed_file': this.renderImgField, // fid
        'entity_reference': this.renderEntityRefField, // target_id
        "voting_api_field": this.renderVotingField, // target_id
        "language": this.renderHiddenField, // value
        'hidden': this.renderHiddenField, // value/target_id
        'geolocation': this.renderGeoLocation,
        'commerce_stock_level': this.renderStockLevel,
    };

    constructor(props) {
        super(props);
        this.updateTheme = this.updateTheme.bind(this);
        this.updateThemeLag = debounce(500, this.updateTheme, this.updateTheme);
        this.getFieldByName = this.getFieldByName.bind(this);
        this.renderFieldType = this.renderFieldType.bind(this);
        for (let format in this.typeMap) {
            this.typeMap[format].bind(this);
        }
        this.state = {
            itemAttrs: {},
            focused: false, // {sourceName:'entity', field_name:'field_cover', entry:[]}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.focused) return false;

        let cur = {...this.state.focused};
        const real = this.props.forms.api[cur.sourceName][cur.field_name];

        if (!this.props.forms.api[cur.sourceName].id || this.props.forms.api[cur.sourceName].id.length === 0) { // can't be in redux if it doesn't yet exist
            return false;
        }

        if (JSON.stringify(prevProps.forms.api[cur.sourceName][cur.field_name]) !== JSON.stringify(real)) {
            this.debounceReduxDispatch(real, cur)
        }
    }

    debounceReduxDispatch(real, cur) {
        setTimeout(() => {

            if (JSON.stringify(this.props.forms.api[cur.sourceName][cur.field_name]) === JSON.stringify(real)) {
                cur.entry = real;
                this.setState({focused: cur});

                const toPass = {[cur.field_name]: real};
                const entity = this.props.forms.api[cur.sourceName];

                this.props.dispatch(updateEntity(entity, cur.field_name)); // entityReducer
                this.props.dispatch(updateListItem(entity, cur.field_name)); // listReducer

                if (this.props.forms.api.bundle === 'groups') {
                    this.props.dispatch(updateMyGroup(toPass)); // state.auth.me[curGroup]
                }

                if (this.props.forms.api.bundle === 'groups-group_node-tracks' || this.props.forms.api.bundle === 'tracks') {
                    let track = Object.assign({}, this.props.forms.api.entity, {node: this.props.forms.api.node});
                    this.props.dispatch(updatePlayerTrack(track));
                }

            }
        }, 1000)
    }

    onFocused(sourceName, field_name) {
        if (this.state.focused && this.state.focused.field_name === field_name) {
            return false;
        }
        this.setState({focused: {sourceName, field_name}});
    }

    trackTextChange(val, field_name, index, propname, sourceName) {
        this.props.dispatch(changeFieldVal(val, field_name, index, propname, sourceName));
    }

    trackFieldChanges(val, field_name, index, propname, sourceName) {
        this.onFocused(sourceName, field_name);
        this.props.dispatch(changeFieldVal(val, field_name, index, propname, sourceName));
    }

    handleDateChange(val, field_name, index, propname, sourceName) {
        this.onFocused(sourceName, field_name);
        if (val) val = val.format('YYYY-MM-DD HH:mm:ss');
        this.props.dispatch(changeFieldVal(val, field_name, index, propname, sourceName));
    }

    handleTimestampChange(val, field_name, index, propname, sourceName) {
        this.onFocused(sourceName, field_name);
        if (val) val = val.format('X');
        this.props.dispatch(changeFieldVal(val, field_name, index, propname, sourceName));
    }

    handleSelection(val, field_name, sourceName) {
        this.onFocused(sourceName, field_name);
        if (typeof val === 'object' && !val.length) val = [val];
        this.props.dispatch(changeFieldVals(val, field_name, sourceName));
    }

    mediaChanged(e) {
        console.log(e);
    }

    updateTheme(event) {
        event.stopPropagation();
        event.preventDefault();
        if (!event.target || !event.target.value) {
            return false;
        }

        const val = event.target.value, field_name = event.target.name;
        const sourceName = event.target.getAttribute('data-sourceName');
        this.trackTextChange(val.substr(1), field_name, 0, 'color', sourceName); // TOOD: get sourceName
        return false;
    }

    removeFieldsNotRendered(entity, sourceName) {
        if (entity.field_address) { // default country code makes submission invalid when not visible;
            const field = this.getFieldByName('field_address', sourceName);
            if (!field) {
                const toPass = {...entity}
                delete toPass['field_address'];
                return toPass;
            }
        }
        return entity;
    }

    handleSubmit(evt) {
        evt.preventDefault();
        var report = evt.currentTarget.form.reportValidity();
        if (!report) {
            return alert('Invalid Form');
        }

        let fields = this.removeFieldsNotRendered(this.props.forms.api.entity, 'entity');
        const action = evt.currentTarget.form.getAttribute('action');


        const requiresTitle = this.findField('title') || this.findField('label') || this.findField('field_name')
        if (requiresTitle) {
            if (!fields.title && !fields.label && !fields.field_name) {
                return this.props.dispatch(_showNotice('Please give this a title', 'error'));
            }
        }

        if (this.props.forms.api.bundle === "groups-group_node-tracks") {
            fields = Object.assign({}, fields, this.removeFieldsNotRendered(this.props.forms.api.node, 'node'));
            this.props.dispatch(submitTrack(action, fields));
        } else if (this.props.forms.api.bundle === "commerce_product") {
            const key = 'variations'; // this.props.forms.api.bundle === "commerce_product" ? 'variations' : 'nodes';
            const toPass = { ...fields }
            toPass[key] = [];
            for(let i in this.props.forms.api) {
                if (i.indexOf('node-') === 0) {
                    toPass[key].push(this.removeFieldsNotRendered(this.props.forms.api[i], i));
                }
            }
            /*
            if (toPass[key].length === 0) {
                toPass[key].push(this.props.forms.api.node);
            }
            */
            this.props.dispatch(submitTrack(action, toPass));
        } else {
            this.props.dispatch(submitForm(action, fields));
            // TODO: create subnodes
        }
        return false;
    }

    renderIntField(field, entry, index, sourceName) {
        if (field.field_name === 'field_status') {
            return this.renderBoolField(field, entry, index, sourceName);
        }

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        if (field.settings.prefix === '$') {
            inpProps.endAdornment = (<InputAdornment position="end"><AttachMoney/></InputAdornment>);
        } else if (field.settings.prefix) {
            inpProps.startAdornment = (<InputAdornment position="start">{field.settings.prefix}</InputAdornment>);
        } else if (field.settings.suffix) {
            inpProps.endAdornment = (<InputAdornment position="end">{field.settings.prefix}</InputAdornment>);
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                    style={{minWidth: '75%'}}
                    inputProps={inpProps.inputProps}
                    type='number'
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>);
    }

    renderHtmlField(field, entry, index, sourceName) {
        return 'TODO: wysiwyg editor';
    }

    renderRecurringField(field, entry, index, sourceName) {
        const inpProps = Form2Json.settingsToProps(field, entry, index);
        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                    style={{minWidth: '75%'}}
                    inputProps={inpProps.inputProps}
                    type='number'
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>);
    }

    renderAddressField(field, entry, index, sourceName) {
        const props = {
            given_name: 'Venue Name',
            // additional_name: null,
            address_line1: 'Address',
            // address_line2: null,
            // dependent_locality: "City",
            // family_name: null,
            // langcode: null,
            locality: "City",
            // organization: null,
            administrative_area: 'State',
            postal_code: "Zip Code",
            country_code: "Country Code",
            // sorting_code: null
        }
        let inputs = [];
        for (let p in props) {
            inputs.push(<FormControl style={{marginRight: 4}}>
                <InputLabel>{props[p]}</InputLabel>
                <Input
                    type={'text'} name={`${field.field_name}-${p}`}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, p, sourceName)}
                    value={entry[index] ? entry[index][p] : ''}
                />
            </FormControl>)
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={8} style={{maxWidth: '100%', flexGrow: 1}}>
                {inputs}
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </Grid>);
    }

    renderStringField(field, entry, index, sourceName) {

        /*
        let key = this.props.forms.api.bundle + ':' + field.field_name;
        if (this.props.gFeatures[key] === 'basicHTML' || this.props.gFeatures[key] === 'richHTML') {
          return this.renderHtmlField(field, entry, index, sourceName);
        }
        */

        const inpProps = Form2Json.settingsToProps(field, entry, index);
        if (field.type === 'text_with_summary') {
            inpProps.margin = "dense";
            inpProps.type = 'textarea';
            inpProps.multiline = true;
            inpProps.rows = 3;
            // TODO: 'add summary option'
        } else if (field.type === 'password') {
            inpProps.type = 'password';
        } else if (field.type === 'email') {
            inpProps.type = 'email';
        } else {
            inpProps.type = 'text';
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={8}
                  style={{maxWidth: '100%', flexGrow: 1}}><FormControl fullWidth>
                <InputLabel htmlFor={inpProps.id}>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>);
    }

    renderPhoneField(field, entry, index, sourceName) {

        const inpProps = Form2Json.settingsToProps(field, entry, index);
        inpProps.type = 'phone';

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}
                  style={{maxWidth: '100%', flexGrow: 1}}><FormControl fullWidth>
                <InputLabel htmlFor={inpProps.id}>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>);
    }

    renderColorField(field, entry, index, sourceName) {

        const inpProps = Form2Json.settingsToProps(field, entry, index);
        inpProps.type = 'color';
        if (!inpProps.inputProps) inpProps.inputProps = {};
        inpProps.inputProps['data-sourceName'] = sourceName;

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Input
                    className={'colorField'}
                    {...inpProps}
                    value={entry[index] ? '#' + entry[index].color : ''}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={this.updateThemeLag}
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl>
                <div style={{clear: 'both', float: 'left', width: '100%', height: 1}}>&nbsp;</div>
            </Grid>);
    }

    renderListField(field, entry, index, sourceName) {

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        let allowed = (!field.settings.allowed_values && field.widget && field.widget[0] && field.widget[0]['#options']) ?
            field.widget[0]['#options'] : field.settings.allowed_values;

        if (typeof inpProps.defaultValue === 'object' && inpProps.defaultValue.length === 0) inpProps.defaultValue = '';
        else if (typeof inpProps.defaultValue === 'object' && inpProps.defaultValue.length === 1) inpProps.defaultValue = inpProps.defaultValue[0];

        let cardinality = [];
        if (entry.length > 0 && (field.cardinality === 0 || field.cardinality > index + 1)) {
            cardinality.push(<IconButton key={index + 1} aria-label="Add"
                                         onClick={e => this.props.dispatch(addFieldWidget(field.field_name, index, sourceName))}><AddCircle/></IconButton>);
            if (index > 0) {
                cardinality.push(<IconButton key={index - 1} aria-label="Remove"
                                             onClick={e => this.props.dispatch(removeFieldWidget(field.field_name, index, sourceName))}><RemoveCircle/></IconButton>);
            }
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel htmlFor={inpProps.id}>{field.label}</InputLabel>
                <Select
                    native
                    {...inpProps}
                    onChange={e => this.trackFieldChanges(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                >
                    {Object.entries(allowed).map(arr => (
                        <option key={arr[0]} value={(arr[0] === '_none') ? -1 : arr[0]}>
                            {arr[1]}
                        </option>
                    ))}
                </Select>
                <FormHelperText>{field.description}</FormHelperText>
                <span>{cardinality} </span>
            </FormControl></Grid>);
    }

    renderDateRangeField(field, entry, index, sourceName) {
        const inpProps = {required: true};
        inpProps.name = field.field_name;
        inpProps.label = field.label;
        inpProps.onError = console.log;
        inpProps.onChange = e => this.handleDateChange(e, field.field_name, index, 'value', sourceName);
        inpProps.InputProps = {endAdornment: (<InputAdornment position="end"><DateRange/></InputAdornment>)}; // inputProps?
        if (entry.length === 0) {
//             inpProps.disablePast = true;
            inpProps.disableFuture = false;
        }
        inpProps.clearable = true;
        inpProps.format = 'L h:mm a';
        return (<Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <FormControl style={{marginBottom: 8, width: '100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker {...inpProps}
                                        value={entry[index] ? new Date(entry[index].value) : null}/>
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl style={{width: '100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker {...Object.assign(inpProps, {
                            label: 'Ends',
                            value: (entry[index] ? new Date(entry[index].end_value) : null),
                            onChange: (e) => this.handleDateChange(e, field.field_name, index, 'end_value', sourceName)
                        })} />
                    </MuiPickersUtilsProvider>
                </FormControl>
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </Grid>
        );
    }

    renderTimestampField(field, entry, index, sourceName) {

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        if (!inpProps.required) inpProps.clearable = true;

        if (entry[index] && entry[index].value) {
            inpProps.value = new Date(entry[index].value * 1000);
        }

        inpProps.label = field.label;
        inpProps.onError = console.log;
        inpProps.onChange = e => this.handleTimestampChange(e, field.field_name, 0, 'value', sourceName);
        inpProps.InputProps = {endAdornment: (<InputAdornment position="end"><DateRange/></InputAdornment>)}; // inputProps?
        inpProps.format = 'L h:mm a';

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={MomentUtils} key={`${field.field_name}_${index}_${sourceName}`}>
                        {(field.field_name === 'field_birthdate') ?
                            <DatePicker {...inpProps} />
                            :
                            <DateTimePicker {...inpProps} />
                        }
                    </MuiPickersUtilsProvider>
                    {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
                </FormControl></Grid>
        );
    }

    renderDateField(field, entry, index, sourceName) {

        if (field.field_name === 'field_awarding_starts' || field.field_name === 'field_listening_starts' || field.field_name === 'field_rating_starts') {
            return <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <StartDate
                    handleDateChange={(val) => this.handleDateChange(val, field.field_name, index, 'value', sourceName)}
                    field={field} entry={entry} index={index}/>
            </Grid>;
            // TODO: hide awarding_starts if listening || rating hasn't started
        }

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        if (!inpProps.required) inpProps.clearable = true;

        if (entry[index] && entry[index].value) {
            inpProps.value = entry[index].value.indexOf('T') > -1 || entry[index].value.indexOf(':') > -1 ? new Date(entry[index].value) : new Date(entry[index].value + 'T12:00:00');
        } else {
            // inpProps.value = Form2Json.getDefaultValue(field, entry, index)
        }

        inpProps.label = field.label;
        inpProps.onError = console.log;
        inpProps.onChange = e => this.handleDateChange(e, field.field_name, 0, 'value', sourceName);
        inpProps.InputProps = {endAdornment: (<InputAdornment position="end"><DateRange/></InputAdornment>)}; // inputProps?
        if (field.field_name === 'field_birthdate') {
            inpProps.disablePast = false;
            inpProps.disableFuture = true;
            inpProps.format = 'YYYY-MM-DD';
        } else {
            inpProps.format = 'L h:mm a';
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={MomentUtils} key={`${field.field_name}_${index}_${sourceName}`}>
                        {(field.field_name === 'field_birthdate') ?
                            <DatePicker {...inpProps} />
                            :
                            <DateTimePicker {...inpProps} />
                        }
                    </MuiPickersUtilsProvider>
                    {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
                </FormControl></Grid>
        );
    }

    renderVotingField(field, entry, index, sourceName) {
        return false;
    }

    renderEntityRefField(field, entry, index, sourceName) {

        let bundle = Form2Json.getBundle(field);
        let el = null;

        if (index > 0 && entry.length > 1 && (typeof entry[index].target_id === 'undefined' || entry[index].target_id === null)) {
            // return false; // dont render empty placeholder widgets
        }

        let cardinality = [];
        if (field.cardinality > entry.length && (field.cardinality === 0 || field.cardinality > index + 1)) {
            cardinality.push(<IconButton key={`add-${field.field_name}-entity-${index + 1}`} aria-label="Add"
                                         onClick={e => this.props.dispatch(addFieldWidget(field.field_name, index, sourceName))}><AddCircle/></IconButton>);
            if (index > 0) {
                cardinality.push(<IconButton key={`remove-${field.field_name}-entity-${index - 1}`} aria-label="Remove"
                                             onClick={e => this.props.dispatch(removeFieldWidget(field.field_name, index, sourceName))}><RemoveCircle/></IconButton>);
            }
        }
        if (bundle === 'media') {
            return <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} >
                <MediaForm field={field} entry={entry} index={0} onChange={this.mediaChanged} dispatch={this.props.dispatch} />
                <span>{cardinality}</span>
            </Grid>
        } else {
            el = <React.Fragment><AutoCompleteEntity
                onSelected={(e, field) => this.trackFieldChanges(e, field.field_name, index, field['data-propname'], sourceName)}
                apiurl={'/autocomplete/' + bundle + '/:query'}
                source={bundle} field={field} entry={entry} index={index}/>
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </React.Fragment>
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                {el}
                <span>{cardinality}</span>
            </Grid>
        );
    }

    renderCheckboxesField(field, entry, index, sourceName) {
        if (index > 0) {
            // console.warn(`There should only be 1 ${field.field_name}. Found ${index} on ${sourceName}. Changes to any instance will overwrite the whole field`, field, entry);
            return false;
        }
        if (field.field_name === 'field_genres') {
            // console.log("RENDER CHECKBOX selections: " + selected.length, selected)
            return (<Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} >
                <InputLabel>{field.label}</InputLabel>
                <GenreSelector key={'genres-' + this.props.forms.apiurl}
                               selected={entry} options='all'
                               onChange={(selected) => this.handleSelection(selected, field.field_name, sourceName)}
                />
            </Grid>);
        }
        return <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
            <InputLabel>{field.label}</InputLabel>
            <ChipSelector field={field} entry={entry} index={index}
                          onChange={(selected) => this.handleSelection(selected, field.field_name, sourceName)}
            />
            {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
        </Grid>;
    }

    renderImgField(field, entry, index, sourceName) {

        if (index > 0 && entry.length > 1 && (typeof entry[index].target_id === 'undefined' || entry[index].target_id === null)) {
            return false; // dont render empty placeholder widgets
        }

        let cardinality = [];
        if (entry.length > 0 && (field.cardinality === 0 || field.cardinality > index + 1)) {
            cardinality.push(<IconButton size={'small'} key={'add' + index + 1} aria-label="Add"
                                         onClick={e => this.props.dispatch(addFieldWidget(field.field_name, index, sourceName))}><AddCircle/></IconButton>);
            if (index > 0) {
                cardinality.push(<IconButton size={'small'} key={'remove' + index - 1} aria-label="Remove"
                                             onClick={e => this.props.dispatch(removeFieldWidget(field.field_name, index, sourceName))}><RemoveCircle/></IconButton>);
            }
        }

        return (
            <Grid style={{position: 'relative'}} key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <ImageUpload entry={entry} field={field} index={index} sourceName={sourceName}
                             onFocused={() => this.onFocused(sourceName, field.field_name, entry)}/>
                {cardinality}
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </Grid>
        );
    }

    renderLinkField(field, entry, index, sourceName) {

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel htmlFor={inpProps.id}>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    type="url"
                    endAdornment={
                        <InputAdornment position="end">
                            <LinkIcon/>
                        </InputAdornment>
                    }
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>
        );
    }

    renderBoolField(field, entry, index, sourceName) {
        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}>
                <Switch field={field} entry={entry} index={index}
                        onChange={checked => this.trackFieldChanges(checked, field.field_name, index, field['data-propname'], sourceName)}/>
            </Grid>
        );
    }

    renderGeoLocation(field, entry, index, sourceName) {
        const handleGeoLocation = (res) => {
            if (typeof res === 'string') {
                // geo location failed
                this.setState({sortby: 'created'});
            } else {
                this.setState({mylocation: res});
            }
        }

        return <MyGeoLocation key={`${field.field_name}_${index}_${sourceName}`}
                              triggered={new Date().getTime()}
                              onError={(res) => handleGeoLocation(res)}
                              onLocated={(res) => handleGeoLocation(res)}>
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item>
                <Place/>
            </Grid>
        </MyGeoLocation>
    }

    renderStockLevel(field, entry, index, sourceName) {
        // TODO: incorporate "always in stock"!
        if (field.field_name === 'field_status') {
            return this.renderBoolField(field, entry, index, sourceName);
        }

        const inpProps = Form2Json.settingsToProps(field, entry, index);

        if (field.settings.prefix === '$') {
            inpProps.endAdornment = (<InputAdornment position="end"><AttachMoney/></InputAdornment>);
        } else if (field.settings.prefix) {
            inpProps.startAdornment = (<InputAdornment position="start">{field.settings.prefix}</InputAdornment>);
        } else if (field.settings.suffix) {
            inpProps.endAdornment = (<InputAdornment position="end">{field.settings.prefix}</InputAdornment>);
        }

        return (
            <Grid key={`${field.field_name}_${index}_${sourceName}`} item xs={12} sm={6} md={4}><FormControl fullWidth>
                <InputLabel>{field.label}</InputLabel>
                <Input
                    {...inpProps}
                    onFocus={() => this.onFocused(sourceName, field.field_name, entry)}
                    onChange={e => this.trackTextChange(e.currentTarget.value, field.field_name, index, field['data-propname'], sourceName)}
                    value={entry[index] ? entry[index][field['data-propname']] : ''}
                    style={{minWidth: '75%'}}
                    inputProps={inpProps.inputProps}
                    type='number'
                />
                {field.description ? <FormHelperText>{field.description}</FormHelperText> : ''}
            </FormControl></Grid>);
    }

    renderHiddenField(field, entry, index, sourceName) {
        let hidden = Form2Json.getDefaultValue(field, entry, index);
        return (<input
            key={`${field.field_name}_${index}_${sourceName}`}
            type='hidden' name={field.field_name}
            value={hidden}
        />);
    }

    renderAddRemoveButtons(max, fieldname, entry, index, sourceName) {
        if (typeof max === 'undefined' || max === null || max !== 0) return null;
        const cardinality = [];
        if (max === -1 || entry.length === index) {
            cardinality.push(<IconButton key={this.buildFieldKey(fieldname, index, sourceName, 'add')} aria-label="Add"
                                         onClick={e => this.props.dispatch(addFieldWidget(fieldname, index, sourceName))}><AddCircle/></IconButton>);
        }
        cardinality.push(<IconButton key={this.buildFieldKey(fieldname, index, sourceName, 'remove')} aria-label="Remove"
                                     onClick={e => this.props.dispatch(removeFieldWidget(fieldname, index, sourceName))}><RemoveCircle/></IconButton>);
        return cardinality;
    }

    buildFieldKey(fieldname, index, sourceName, suffix) {
        return `${fieldname}_${index}_${sourceName}_${suffix}`;
    }

    buildFormKey(suffix) {
        if (!this.props.forms.api) return 'eform-loading';
        let keyName = 'eform-' + this.props.forms.apiurl;
        if (suffix) keyName += suffix
        return keyName;
    }

    handleDelete(form) {
        if (window.confirm('Are you sure you want to remove this?')) {
            this.props.dispatch(submitDelete(form.apiurl.substring(0, form.apiurl.lastIndexOf('/')) + '/delete'));
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    findField(fieldname, parent) {
        const {fieldschema} = this.props.forms.api;
        for(let i=0; i < fieldschema.length; i++) {
            const has = fieldschema[i].children.find(x => x.field_name === fieldname && x.sourceName === parent); // never recursive yet
            if (has) return has;
        }
        return null;
    }

    buildFields() {
        const { fieldschema } = this.props.forms.api;
        let inputs = [];
        if (this.props.forms.apiurl.indexOf('/tracks/') > -1 && (!this.props.forms.api.node.field_media || this.props.forms.api.node.field_media.length === 0)) { // only show media form once node is selected or created
            const field = this.findField('field_media', 'node');
            inputs.push(<MediaForm key={"field_media" + 0} field={field} entry={[]} index={0} dispatch={this.props.dispatch} />);
            return inputs;
        }

        for(let i=0; i < fieldschema.length; i++) {
            if (fieldschema[i].widget === 'top') {
                inputs = inputs.concat(fieldschema[i].children.map((field) => this.buildComponents(field, this.props.forms.api[field.sourceName], field.sourceName)));
            } else {

                const num = this.props.forms.api.nodeCount > 0 ? this.props.forms.api.nodeCount : 1;
                let subinputs = [];
                for(let i2=1; i2 <= num; i2++) {

                    const subfields = fieldschema[i].children.map((field) => {
                        const subkey = field.sourceName + '-' + i2;
                        if (typeof this.props.forms.api[subkey] !== 'undefined') {
                            return this.buildComponents(field, this.props.forms.api[subkey], subkey, i2);
                        } else {
                            return this.buildComponents(field, this.props.forms.api[field.sourceName], field.sourceName, i2);
                        }
                    });

                    if (fieldschema[i].cardinality !== 0) {
                        subinputs.push(<Grid key={this.buildFieldKey(`fieldgroup${i}`, i2, fieldschema[i].widget)}
                                          container
                                          spacing={1}
                                          direction="row"
                                          justifyContent="space-between"
                                          alignContent="stretch"
                                          alignItems="stretch" style={{position: 'relative'}} >
                        <span style={{position:'absolute', top:-7, left:-7, backgroundColor:'#ff0000', borderRadius:18, height:18, width:18, textAlign:'center', fontSize:13, fontWeight:900}}>{i2}</span>
                        {subfields}</Grid>)
                    } else {
                        subinputs = subfields;
                    }
                }

                const toPass = {};
                if (typeof fieldschema[i].apiurl === 'string') {
                    toPass.onChange = (e, expanded) => {
                        if (expanded === false) return false;
                        return API.Get(fieldschema[i].apiurl).then((res) => {
                            if (res.data.data) {
                                if (res.data.data.length === 0) {
                                    this.props.dispatch(addNodeEntry());
                                } else {
                                    this.props.dispatch(setNodeEntries(res.data.data));
                                }
                            }
                        }).catch((err) => {
                            const tdata = getIdbySegment(fieldschema[i].apiurl);
                            tdata.verb = 'failed';
                            window.logUse.logEvent('load_form', tdata);
                            var msg = API.getErrorMsg(err);
                            console.error(msg);
                        });
                    }
                }

                inputs.push(<Grid item xs={12} style={{padding:0}} key={'fieldgrouppanel-' + i} ><Accordion {...toPass}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />} >
                        <Typography variant='h4'>{fieldschema[i].label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="space-between"
                            alignContent="stretch"
                            alignItems="stretch"
                        >

                            {subinputs}

                            {fieldschema[i].cardinality && fieldschema[i].cardinality !== 1 && <Grid xs={12} style={{marginTop:5}}><Button fullWidth={true} variant={'outlined'} size={'small'}
                                                                                                   startIcon={<AddIcon color={'secondary'} />}
                                                                                                   onClick={() => this.props.dispatch(addNodeEntry())}
                                                                                                   color={'secondary'} >Add Another</Button></Grid> }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                </Grid>)
            }
        }
        return inputs;
    }

    renderFieldType(fieldType, fieldName, sourceName, index) {
        const ref = this.getFieldByName(fieldName, sourceName);
        return this.typeMap[fieldType].call(this, ref.field, ref.entry, index || 0, sourceName);
    }

    getFieldByName(fieldname, sourceName) {
        const {fieldschema} = this.props.forms.api;
        for(let i=0; i < fieldschema.length; i++) {
            const has = fieldschema[i].children.find(x => x.field_name === fieldname && x.sourceName === sourceName); // never recursive yet
            if (has) {
                const entry =  this.props.forms.api[sourceName] ? this.props.forms.api[sourceName][fieldname] : [];
                return {entry:entry, field:has}
            }
        }
        return null;
    }

    buildComponents(field, source, sourceName, subindex) {
        const inputs = [];
        let fieldname = field.field_name;
        let entry = (source && typeof source[fieldname] !== 'undefined') ? source[fieldname] : [];

        if (fieldname === 'revision_log' && this.props.forms.apiurl.indexOf('/add') > 0) {
            return inputs;
        }
        if (field.settings && field.settings.hidden === true) {
            let key = this.props.forms.api.bundle + ':' + field.field_name;
            if (!this.props.gFeatures[key]) {
                return inputs;
            }
        }
        // let fieldkey = `${field.field_name}_${sourceName}_${inputs.length}`;

        let type = field.type;
        if (type === 'entity_reference' && field.widget && field.widget[0] && field.widget[0]['#type'] && field.widget[0]['#type'] !== 'fieldset') {
            type = field.widget[0]['#type']; // intentional override from entity_reference to checkboxes and other html elements
        }

        if (typeof this.typeMap[type] !== 'function') console.log("MISSING FIELD TYPE HANDLER " + type, field);
        else if (entry.length === 0) {
            let component = this.typeMap[type].call(this, field, [], 0, sourceName);
            if (component) {
                inputs.push(component);
            } else console.log("FIELD TYPE HANDLER RETURNED EMPTY on empty entry: " + type, field);
        } else {
            for (let c = 0; c < entry.length; c++) {
                let component = this.typeMap[type].call(this, field, entry, c, sourceName);
                if (component) {
                    inputs.push(component);
                } else console.log("FIELD TYPE HANDLER RETURNED EMPTY: " + type, field);
                // if (field.settings && field.settings.target_type === 'taxonomy_term') break; // only render first
                if (field.settings && field.settings.target_type === 'media') {
                    break; // MediaForm handles all entries
                }
            }
        }

        return inputs;
    }

    stateToEntity() {
        return this.props.api.entity; // TODO: merge node??
    }

    /*
    subFormCallbacks() {
        const clks = {renderFieldType: this.renderFieldType};
        for (let format in this.typeMap) {
            clks[this.typeMap[format].name] = this.typeMap[format];
        }
        console.log('CALLBACKS', clks);
        return clks;
    }
     */

    render() {
        const form = this.props.forms;
        if (form.loading === true) return <OverlayLoader/>;
        if (form.error) return <Typography variant='h2'>{form.error}</Typography>;
        if (!form.api) return 'loading...';

        let inputs = this.buildFields();

        const formKey = this.buildFormKey();

        if (form.apiurl.indexOf('/group/add') > -1) {
            return <GroupBranding fields={inputs}
                                  formKey={formKey}
                                  dispatch={this.props.dispatch}
                                  formData={this.stateToEntity()}/>
        }

        let instructions = null;
        if (form.api.instructions && form.api.entity) {
            if (form.api.bundle === "groups-group_membership") {
                instructions = <FormInstructions step={'step2'} instructions={Object.assign({}, form.api.instructions, {
                    component: <Typography variant='h5'>To change your password or other Account details go to <NavLink
                        to={`/forms/users/${form.api.entity.entity_id[0].target_id}/edit`}>Edit
                        Account</NavLink></Typography>
                })}/>
            } else if (form.api.bundle !== "groups") {
                instructions = <FormInstructions step={'step1'} instructions={form.api.instructions}/>
            }
        }

        return (
            <Grid container direction={'column'} justifyContent={'center'} style={{padding: '1%'}}>

                {instructions}

                <form method='POST' action={form.apiurl} name={formKey}
                      className={'taForm ' + form.ctx + ' ' + this.props.classes.taForm} key={formKey}>

                    <div style={{padding: '1%'}}>
                        <Grid
                            container
                            style={{
                                marginTop: form.apiurl.indexOf('/tracks/') > -1 ? 10 : 20,
                                marginBottom: 20,
                                gap: 10
                            }}
                            spacing={4}
                            direction="row"
                            justifyContent="space-between"
                            alignContent="stretch"
                            alignItems="stretch"
                        >
                            {inputs}
                        </Grid>
                    </div>

                    {this.props.hideActions !== true &&

                        <Grid
                            container
                            style={{marginTop: 20, marginBottom: 20, gap:10}}
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignContent={'stretch'}
                            alignItems={'stretch'}
                            wrap={'nowrap'}
                        >
                            <Button
                                variant="contained"
                                fullWidth={true}
                                margin="normal"
                                color="primary"
                                type='submit'
                                disabled={form.loading === true}
                                onClick={(e) => this.handleSubmit(e)}>Submit</Button>

                            {form.ctx === 'page' &&
                                <Button
                                    variant="outlined"
                                    fullWidth={true}
                                    margin="normal"
                                    color="secondary"
                                    disabled={form.loading === true}
                                    onClick={(e) => this.goBack(e)}>Cancel</Button>
                            }

                            {(form.api.entity && form.api.entity.id && form.api.entity.id.length > 0) && <Button
                                variant="outlined"
                                margin="normal"
                                color="primary"
                                fullWidth={true}
                                disabled={form.loading === true}
                                onClick={(e) => this.handleDelete(form)}>{form.apiurl.indexOf('/tracks/') > 0 ? 'Remove' : 'Delete'}</Button>}
                        </Grid>
                    }

                </form>

            </Grid>
        );
    }
}

FormWrapper.propTypes = {
    theme: PropTypes.object.isRequired,
    forms: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func
};


const mapStateToProps = (state) => {
    return {forms: state.forms}
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pStyles, {withTheme: true})(FormWrapper));
