import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Badge from '@material-ui/core/Badge';
import {pStyles} from "../../Util/ThemeUtils";
import FormattedText from "../FormattedText/FormattedText";
import MediaSlider from "../MediaSlider/MediaSlider";
import {Link} from "react-router-dom";
import UserAvatar from "../UserAvatar";
import Typography from "@material-ui/core/Typography";
import {withSnackbar} from "notistack";
import AddToCartButton from "../../core/checkout/AddToCartButton";
import ProductActions from "../Products/ProductActions";
import Grid from "@material-ui/core/Grid";

class EventTicketsBlock extends React.Component {

    constructor(p) {
        super(p);
        this.state = {cartItem: new Commerce2Json(this.props.product)}
    }

    render() {
        const {classes} = this.props;
        const bundle = this.state.cartItem.get('type', 'target_bundle');

        let timestamp = this.state.cartItem.getTimestamp('field_date_on_sale', 'value');
        if (bundle !== 'default') {
            timestamp = new Date().getTime() - 100; // fake it until we add field_date_on_sale to every type / bundle
        }

        const headerAttrs = {
            title : this.state.cartItem.product.get('title')
        }
        if (this.state.cartItem.get('uid', 'target_id') > 1) {
            headerAttrs.title = <Link to={`/group/${this.props.curGroup}/rewards/${this.state.cartItem.get('product_id')}`}>{headerAttrs.title}</Link>;
            let authorLink = "/users/" + this.state.cartItem.get('uid', 'target_id');
            if (this.props.curGroup > 0) {
                authorLink += '/group/' + this.props.curGroup;
            }
            headerAttrs.avatar = <Link to={authorLink}><UserAvatar data={this.state.cartItem.getField('uid')}/></Link>
            headerAttrs.subheader =
                <Link to={authorLink}>{this.state.cartItem.get('uid', 'target_label')}</Link>
        }
        headerAttrs.action = <ProductActions me={this.props.me} gid={this.state.cartItem.get('stores', 'gid')} cartItem={this.state.cartItem} dispatch={this.props.dispatch} />

        // field_date_range , field_address, field_link_live, field_recurring
        const allMedia = this.state.cartItem.getAllMedia();
        let timezone = this.state.cartItem.get('field_json', 'timezone');
        let timestring = this.state.cartItem.product.getTimeRange('field_date_range', 'ddd MMM Do \'YY hh:mm a', timezone);
        const address = this.state.cartItem.getAddress('short');

        return (
            <Card className={['rBlock', classes.card].join(' ')}>

                <CardHeader {...headerAttrs} />

                <Typography variant={'body2'} style={{textAlign: 'center'}}>{timestring}</Typography>
                {address &&
                    <Typography variant={'body2'} style={{textAlign: 'center', margin: '10px auto'}}>
                        <a target={"_blank"}
                           href={`https://maps.google.com?q=${encodeURI(this.state.cartItem.getAddress('long'))}`}>@ {this.state.cartItem.get("field_address", "given_name") || address}</a>
                    </Typography>}
                <Typography variant={'body2'}  style={{textAlign: 'center'}}>{this.state.cartItem.getPriceRange()}</Typography>

                {allMedia.length > 0 && <MediaSlider images={allMedia}/>}

                {this.state.cartItem.getField('body') &&
                    <CardContent className={classes.content}><FormattedText {...this.state.cartItem.getField('body')} displayField={'auto'} /></CardContent>}

                <CardActions className={classes.actions}>

                    {timestamp > new Date().getTime() ? <p><strong>On Sale</strong>
                            <u>{this.state.cartItem.getTime('field_date_on_sale')}</u></p>
                        :
                        this.props.product.variations.map((variation, i) => {
                            const cartItem = new Commerce2Json(this.props.product, variation.target_id);
                            const btn = <AddToCartButton dispatch={this.props.dispatch} cartItem={cartItem} index={i} />;

                            if (variation.field_stock.length === 0 || typeof variation.order_count === 'undefined') return <Grid item key={'sel-variation' + variation.target_id}>{btn}</Grid>;

                            return (
                                <Grid item key={'sel-variation' + variation.target_id}><Badge
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: i % 2 ? 'left' : 'right',
                                    }}
                                    badgeContent={`${variation.order_count || 0}/${variation.field_stock[0].value}`}
                                    classes={{badge: classes.badgeDark}}>
                                    {btn}
                                </Badge></Grid>
                            )

                        })
                    }
                </CardActions>

            </Card>
        );
    }
}

EventTicketsBlock.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles, {withTheme:true})(withSnackbar(EventTicketsBlock));
