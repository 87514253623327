import AutoCompleteEntity from './AutoCompleteEntity';
import {connect} from 'react-redux';


const mapStateToProps = (state) => {
 var newState = {}; // forms:{...state.forms}
 newState.location = state.router.location;
 if (state.player.playlist || (state.forms.apiurl && state.forms.apiurl.indexOf('/tracks/') > -1))  {
  newState.hasPlaylist = true; // autocomplete for MP3s due to + from dashboard or new track form without a playlist
 }
 return newState;
};

// const mapDispatchToProps = dispatch => dispatch;

export default connect(
 mapStateToProps,
 null
)(AutoCompleteEntity);
