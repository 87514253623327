import React from 'react';
import Commerce2Json from '../../Util/Commerce2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {pStyles} from "../../Util/ThemeUtils";
import {withSnackbar} from "notistack";
import PurchaseWithContractAction from "../../core/checkout/PurchaseWithContractAction";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {withRouter} from "react-router-dom";

class VariationSelector extends React.Component {

    constructor(p) {
        super(p);
        this.state = {cartItem: new Commerce2Json(this.props.product), selected:0}
        this.linkTo = `/group/${this.state.cartItem.get('stores', 'gid')}/rewards/${this.state.cartItem.get('product_id')}`;
    }

    handleSelect(e) {
        if (e.target.value === 'details') {
            this.props.history.push(this.linkTo);
        } else {
            this.setState({selected:e.target.value});
        }

    }

    render() {

        let hasOrders = false;
        let menuItems = this.props.product.variations.map((variation, i) => {
            const cartItem = new Commerce2Json(this.props.product, variation.target_id);
            if (cartItem.get("order_count") > 0) {
                hasOrders = true;
            }
            return <PurchaseWithContractAction
                    component={MenuItem}
                    value={variation.target_id}
                    key={`select${variation.target_id}`}
                    dispatch={this.props.dispatch}
                    showOrderCountAs={'avatar'}
                    cartItem={cartItem} />
        })

        return <Select id={'teamselector-'+this.linkTo}
                                value={this.state.selected}
                                onChange={e => this.handleSelect(e)}
                                fullWidth={true}
                                variant={'outlined'}
        >
            <MenuItem value='0' >Enter Match
            </MenuItem>
            {hasOrders && <MenuItem value='details' >View all signups</MenuItem>}
            {menuItems}
        </Select>
    }
}

VariationSelector.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pStyles, {withTheme: true})(withSnackbar(withRouter(VariationSelector)));
