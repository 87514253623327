import React, {Component} from 'react';
import SearchField from "./SearchField";
import Grid from "@material-ui/core/Grid";
// import SearchCard from "./SearchCard";
import {connect} from "react-redux";
import ItemGrid from "../../Components/ItemGrid";
import OverlayLoader from "../../Components/OverlayLoader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grammer from "../../Util/Grammer";
import {getIdbySegment} from "../../redux/authActions";

class SearchView extends Component {

    constructor(p) {
        super(p);
        this.state = {results: false}
    }

    onReloaded(results) {
        this.setState({results: results});
    }

    listenToPagination() {

    }

    renderResults() {
        let sections = [];
        for (let sec in this.state.results) {
            if (!this.state.results[sec] || !Array.isArray(this.state.results[sec])) continue;

            const toPass = {'data': this.state.results[sec]};
            toPass.metadata = this.state.results.metadata;

            sections.push(
                <React.Fragment key={`tamsearch-${sec}`}>
                    <div style={{margin: '30px auto 10px auto'}}>
                        <Typography style={{textTransform:'capitalize'}} variant={'body1'} >{Grammer.g(sec, 'p')}</Typography>
                        <Divider variant={'fullWidth'}/>
                    </div>
                    <Grid item container>
                        <ItemGrid lData={toPass} me={this.props.me} dispatch={this.props.dispatch}
                                  showLocation={true} curGroup={this.props.curGroup}/>
                    </Grid>

                </React.Fragment>)
        }
        if (typeof window.debugArray === 'object' && window.debugArray !== null) {
            console.log("DEBUG ARRAY", window.debugArray)
        }
        return sections;
    }

    render() {

        return (
            <div id={'SearchView'} style={{width: '100%', padding: '2%', marginTop: 10}}>
                <SearchField gid={this.props.curGroup} onResults={results => this.onReloaded(results)}/>
                <div style={{marginTop: 15}}>
                    {this.state.results === false ? <OverlayLoader/> : this.renderResults()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const parts = getIdbySegment(document.location.pathname);
    const newState = {me: state.auth.me, curGroup: typeof parts.gid === "number" ? parts.gid : state.auth.curGroup};
    newState.emails = state.emails;
    newState.location = state.router.location;
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchView);
