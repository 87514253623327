import React, {Component} from 'react';
import AutoCompleteEntity from "../../Components/AutoCompleteEntity";
import PropTypes from 'prop-types';

class MemberSelector extends Component {

    render() {

        const {classes, gid, ...passable} = this.props;

        return (
            <AutoCompleteEntity
                {...passable}
                size={'small'}
                apiurl={`/autocomplete/group/${gid}/members/:query`}
                source='members'
                placeholder='Search Athletes by name'
            />
        );
    }
}


MemberSelector.propTypes = {
    onChange : PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    field: PropTypes.object.isRequired,
    entry: PropTypes.array // TODO: type check when empty
};

MemberSelector.defaultProps = {
    index : 0,
    entry : [],
    field : {
        "field_name": "field_users",
        "label": "Tag an athlete",
        "type": "entity_reference",
        "bundle": "members",
        "default_value": [],
        "settings": {
            "handler_settings": {
                "target_bundles": {
                    "audio": "audio",
                    "remote_video": "remote_video",
                    "video": "video",
                    "youtube": "youtube"
                }
            },
            "target_type": "members"
        },
        "#type": "container",
        "cardinality": 0
    }};


export default MemberSelector;
