import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import ClipTypeSelector from "../taxonomy/ClipTypeSelector";
import {withSnackbar} from "notistack";
import MemberSelector from "../users/MemberSelector";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class UserTagForm extends Component {

    constructor(props) {
        super(props);
        this.state = {...this.props.user_tag};

        this.submit = this.submit.bind(this);
    }

    submit() {
        const payload = {
            target_id: this.state.target_id,
            bounding_box: this.state.bounding_box,
            frametime: this.state.frametime,
            cliptype: this.state.cliptype
        };
        this.props.onSubmit(payload);

    }

    notice(variant) {
        return {
            variant: 'variant', persist: true, action: key => (
                <IconButton variant="outline-light"
                            onClick={() => {
                                this.props.closeSnackbar(key);
                            }}
                >
                    <Close/>
                </IconButton>
            )
        }
    }


    render() {
        const {clip} = this.props;

        return (
            <div className={this.props.classes.card}>

                <div style={{marginBottom: 8}}>
                    <MemberSelector gid={clip.get('gid', 'target_id')}
                                    selected={this.state}
                                    entry={[this.state]}
                                    index={0}
                                    onSelected={(sel) => this.setState({target_id: sel.target_id})}/>
                </div>

                <div style={{marginBottom: 8}}>
                    <ClipTypeSelector selected={this.state.cliptype ? [{target_id:this.state.cliptype}] : []}
                                      multiple={false}
                                      onChange={(sel) => this.setState({cliptype: sel.length > 0 ? sel[0] : null})}/>
                </div>

                <div style={{marginBottom: 8}}>
                    <TextField
                        fullWidth={true}
                        variant='outlined'
                        label={'Bounding Box'}
                        placeholder={'[x1, y1, x2, y2] as percentage from top left'}
                        size={'small'}
                        value={this.state.bounding_box}
                        onChange={(e) => this.setState({bounding_box: e.target.value})}/>
                </div>

                <div style={{marginBottom: 8}}>
                    <TextField
                        fullWidth={true}
                        variant='outlined'
                        placeholder={'00:00:00'}
                        label={'Frame time'}
                        size={'small'}
                        value={this.state.frametime}
                        onChange={(e) => this.setState({frametime: e.target.value})}/>
                </div>

                <Button variant={'outlined'} color={'primary'} size={'small'} onClick={this.submit}>Add Tag</Button>

            </div>
        );

    }
}

UserTagForm.defaultProps = {
    color: 'primary'
};

UserTagForm.propTypes = {
    clip: PropTypes.object,
    user_tag: PropTypes.object
};

const styles = theme => ({
    actionLine: {
        display: 'flex',
        alignItems: 'center'
    },
    actionItem: {
        marginBottom: 15
    },
    card: {
        background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(10,119,213,0) 50%, ${theme.palette.background.paper} 100%)`,
    }
});


export default withStyles(styles, {withTheme: true})(withSnackbar(UserTagForm));
