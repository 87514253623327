import React, {Component} from 'react';
import AutoCompleteEntity from '../AutoCompleteEntity';
import PropTypes from 'prop-types';

class TamTrackSearch extends Component {

 render() {

  var {classes, ...passable} = this.props;

  var url = '/autocomplete/tracks/:query';

  return (
   <AutoCompleteEntity
    {...passable}
    apiurl={url}
    source='tracks'
    placeholder='Search Track Authority Music'
   />
  );
 }
}


TamTrackSearch.propTypes = {
 onChange : PropTypes.func.isRequired,
 index: PropTypes.number.isRequired,
 field: PropTypes.object.isRequired,
 entry: PropTypes.array // TODO: type check when empty
};

TamTrackSearch.defaultProps = {
 index : 0,
 entry : [],
 field : {
  "field_name": "field_media",
  "label": "Media",
  "description": "Upload your audio or video files, add from Youtube, or other video libraries",
  "type": "entity_reference",
  "bundle": "tracks",
  "default_value": [],
  "settings": {
   "handler_settings": {
    "target_bundles": {
     "audio": "audio",
     "remote_video": "remote_video",
     "video": "video",
     "youtube": "youtube"
    }
   },
   "target_type": "media"
  },
  "#type": "container",
  "cardinality": 0
 }};


export default TamTrackSearch;
