import React, {Component} from "react";
import {toggleMenu} from "../../redux/authReducer";
import {Link} from "react-router-dom";
import Drupal2Json from "../../Util/Drupal2Json";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import {withStyles} from "@material-ui/core/styles";
import MyMenuLink from "../HeaderMenu/MyMenuLink";
import {headFootStyles} from "../../Util/ThemeUtils";
import ShoppingCartCheckout from "@material-ui/icons/ShoppingCart";
import {openWallet} from "../../redux/walletReducer";
import {getStartupParam} from "../../Util/WindowUtils";
import BuilderButton from "../GroupActions/BuilderButton";

class Header extends Component {

  render() {
    const { classes } = this.props;

    const segs = document.location.pathname.split("/");
    let gjson = false;
    if (this.props.me.brandId && this.props.me.groups[this.props.me.brandId]) {
      gjson = new Drupal2Json(this.props.me.groups[this.props.me.brandId]);
    } else if (this.props.curGroup > 0) {
      if (typeof this.props.me.groups[this.props.curGroup] !== "undefined") {
        gjson = new Drupal2Json(this.props.me.groups[this.props.curGroup]);
      }
    }

    let extrabtns = [];
    if (this.props.me.menu) {
      const {menu} = gjson ? gjson.json : this.props.me;
      for(let i in menu) {
        if (menu[i] && menu[i].header) {
          extrabtns.push(<MyMenuLink key={`menulink${i}-${gjson ? gjson.get('id') : 'tam'}`} linkId={i} link={menu[i]}
                                     gid={gjson ? gjson.get('id') : null}
                                     layout={'button'}
                                     profile={this.props.me.profile}
                                     group={gjson ? gjson.json : null} />);
          if (extrabtns.length > 0 && window.innerWidth < this.props.theme.breakpoints.values.sm) {
            break; // only one for small screens
          }
        }
      }
    }

    let toggleBtn = (
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="start"
        key="hamburger"
        onClick={(e) => this.props.dispatch(toggleMenu())}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    );

    if (this.props.cartTotal > -1) {
      extrabtns.unshift(<Badge key={'CartTotal'}
                               id={'MyCartTotal'}
                               onClick={() => this.props.dispatch(openWallet())}
                               badgeContent={this.props.cartTotal} max={99999999} showZero={false} color={'secondary'} style={{marginRight:15, marginTop:4}}>
        <ShoppingCartCheckout />
      </Badge>)
    }

    if (this.props.isGroupAdmin === true) {
      toggleBtn = [
        toggleBtn,
        <BuilderButton layout={'icon'} gjson={gjson} />
      ]
    }

    const appStyle = {};
    let topPad = 0;
    if (getStartupParam('paddingTop').length > 0) {
      topPad += parseInt(getStartupParam('paddingTop'));
    }
    if (topPad > 0) {
      appStyle.paddingTop = topPad;
    }

    if (segs.length === 1 || segs[1] === "") {
      return (
        <AppBar style={appStyle} elevation={0} className={classes.appBarHome} key="appHeader" id="mainheader" aria-label={this.props.me.profile ? 'authenticated' : 'anonymous'}>
          <Toolbar>{toggleBtn}</Toolbar>
        </AppBar>
      );
    }

    let logo = null, link = '/';
    if (gjson === false) {
      logo = <img src={`/assets/${this.props.theme.palette.background.type === "light" ? 'logo' : 'logo_white'}.png`} alt="logo" style={{ maxHeight: 46, maxWidth:'100%'}} />;
    } else {
      if (!this.props.me.brandId || gjson.get('field_parent_group', 'target_id') > 0) {
        link = "/group/" + gjson.get("id")
      }
      if (gjson.get("field_cover", "url")){
        logo = <img src={gjson.get("field_cover", "url")} alt="logo" style={{ height: 46}} />
      }

    }

    return (
      <AppBar style={appStyle} className={classes.appBar} key="appHeader" id="mainheader" aria-label={this.props.me.profile ? 'authenticated' : 'anonymous'}>
        <Toolbar>
          {toggleBtn}
          {gjson && gjson.get("field_cover", "url") && window.innerWidth > this.props.theme.breakpoints.values.sm &&
          <Typography
              component={Link} to={link}
              key={'groupHomeTitleLink'} variant="h1" className={classes.title}>
            {gjson.get("label")}
          </Typography>
          }
          <span id={'cypressContextCloser'} >&nbsp;</span>
          <Link to={link}  className={classes.title}>
            {logo}
          </Link>
          {extrabtns}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(headFootStyles, { withTheme: true })(Header);
