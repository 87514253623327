import API from '../Util/API';
import {push} from 'connected-react-router';
import Config from "../Config";
import {getIdbySegment, initApp} from './authActions';
import {updatePlayerTrack} from "./playerReducer";
import {entityDataSuccess} from "./entityDataReducer";
import {addPendingPayout} from "./walletReducer";

const FORM_DATA_CANCEL = 'forms:FORM_DATA_CANCEL';
const FORM_DATA_SUCCESS = 'forms:FORM_DATA_SUCCESS';
const FORM_DATA_FAILURE = 'forms:FORM_DATA_FAILURE';
const FORM_UPLOAD_FAILED = 'forms:FORM_UPLOAD_FAILED';
const FORM_DATA_STARTED = 'forms:FORM_DATA_STARTED';
const FORM_DATA_COMPLETED = 'forms:FORM_DATA_COMPLETED';

const FORM_UPLOAD_STARTED = 'forms:FORM_UPLOAD_STARTED';
const FORM_UPLOAD_COMPLETED = 'forms:FORM_UPLOAD_COMPLETED';
const FORM_MEDIA_ADD = 'forms:FORM_MEDIA_ADD'; // just adds the item to the MediaForm table before form submission
const FORM_MEDIA_DELETE = 'forms:FORM_MEDIA_DELETE'; // just adds the item to the MediaForm table before form submission

const FORM_POPULATE_TRACK = 'forms:FORM_POPULATE_TRACK';
const FORM_POPULATE_PLAYLIST = 'forms:FORM_POPULATE_PLAYLIST';
const FORM_POPULATE_REWARD = 'forms:FORM_POPULATE_REWARD';
const FORM_NOTICE = 'forms:FORM_NOTICE';
const FORM_ADD_ENTRY = 'forms:FORM_ADD_ENTRY';
const FORM_SET_ENTRIES = 'forms:FORM_SET_ENTRIES';
const FORM_ADD_WIDGET = 'forms:FORM_ADD_WIDGET';
const FORM_REMOVE_WIDGET = 'forms:FORM_REMOVE_WIDGET';
const FORM_CLEAR_ERROR = 'forms:FORM_CLEAR_ERROR';
const FORM_SET_FIELD_VALUE = 'forms:FORM_SET_FIELD_VALUE';
const FORM_SET_FIELD_VALUES = 'forms:FORM_SET_FIELD_VALUES';
const FORM_TOGGLE_FIELD = 'FORM_TOGGLE_FIELD';

const formsSuccess = (api, apiurl) => ({
    type: FORM_DATA_SUCCESS,
    payload: api,
    apiurl: apiurl
});

const formsStarted = (apiurl, ctx) => ({
    type: FORM_DATA_STARTED,
    ctx: ctx,
    apiurl: apiurl
});

const formsFailure = error => ({
    type: FORM_DATA_FAILURE,
    error: error
});

export const formsCancel = () => ({
    type: FORM_DATA_CANCEL,
    loading: false
});

const formCompleted = () => ({
    type: FORM_DATA_COMPLETED,
    payload: initialState
});


const formImageFailed = (field_name, index, sourceName, err) => ({
    type: FORM_UPLOAD_FAILED,
    field_name, index, sourceName, err
});


export const formImagePosting = (field_name, index, sourceName) => ({
    type: FORM_UPLOAD_STARTED,
    field_name,
    index,
    sourceName
});

export const formImagePosted = (fileArray, field_name, index, sourceName) => ({
    type: FORM_UPLOAD_COMPLETED,
    fileArray,
    field_name,
    sourceName,
    index
});

export const _showNotice = (msg, variant, options) => ({
    type: FORM_NOTICE,
    msg: msg,
    variant: variant || 'info',
    options: options
});

export const _clearNotes = () => ({
    type: FORM_CLEAR_ERROR
});

export function toggleHiddenField(field_name, bundle) {
    return {
        type: FORM_TOGGLE_FIELD,
        field_name,
        bundle
    };
}


export function setNodeEntries(entries) {
    return {
        type: FORM_SET_ENTRIES,
        entries
    };
}


export function addNodeEntry() {
    return {
        type: FORM_ADD_ENTRY
    };
}

export function addFieldWidget(field_name, index, sourceName) {
    return {
        type: FORM_ADD_WIDGET,
        field_name,
        index,
        sourceName
    };
}

export function removeFieldWidget(field_name, index, sourceName) {
    return {
        type: FORM_REMOVE_WIDGET,
        field_name,
        index,
        sourceName
    };
}

export function addMediaItem(obj, field) {
    return {
        type: FORM_MEDIA_ADD,
        payload: obj,
        field: field
    };
}

export function deleteMedia(target_id, field_name, sourceName) {
    return {
        type: FORM_MEDIA_DELETE,
        target_id: target_id,
        field_name: field_name,
        sourceName: sourceName
    };
}

export function populateTrack(obj, field) {
    return {
        type: FORM_POPULATE_TRACK,
        payload: obj,
        field: field
    };
}

export function populatePlaylist(obj, apiurl) {
    return {
        type: FORM_POPULATE_PLAYLIST,
        payload: obj,
        apiurl: apiurl
    };
}

export function populateReward(obj) {
    return {
        type: FORM_POPULATE_REWARD,
        payload: obj
    };
}

export function changeFieldVal(val, field_name, index, prop, sourceName) {
    return {
        type: FORM_SET_FIELD_VALUE,
        val: val,
        field_name: field_name,
        index: index,
        prop: prop,
        sourceName: sourceName
    };
}


export function changeFieldVals(val, field_name, sourceName) {
    return {
        type: FORM_SET_FIELD_VALUES,
        val: val,
        field_name: field_name,
        sourceName: sourceName
    };
}


export const closeForm = () => {
    return dispatch => {
        dispatch(formsCancel());
    };
};


export const loadPasswordReminder = (email) => {
    return (dispatch, getState) => {
        dispatch(formsStarted('/otp/account_otp/send', 'dialog'));
        const state = getState();
        const obj = {
            fieldschema: [{
                label: 'entity', widget: 'top', children: [{
                    "field_name": "mail",
                    "label": "Your Email",
                    "description": "We will send you a one time login link",
                    "type": "email",
                    "bundle": "user",
                    "data-propname": "value",
                    'sourceName': 'entity',
                    "default_value": email,
                    "#required": true,
                    "cardinality": 1
                }]
            }],
            entity: {
                mail: [{value: email}]
            },
            instructions: {caption: 'Send me a login link'}
        }

        if (state.auth.curGroup > 0) {
            obj.fieldschema[0].children.push({
                "field_name": "gid",
                "type": "hidden",
                "data-propname": "target_id",
                "default_value": [
                    {
                        "target_id": state.auth.curGroup
                    }
                ],
                "sourceName": "entity"
            })
            obj.entity.gid = [{"target_id": state.auth.curGroup}]
        }
        dispatch(formsSuccess(obj, '/otp/account_otp/send'));
        return false;
    };
};

export const loadForm = (apiurl, ctx) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.forms.loading === true) return false;

        if (apiurl.indexOf('/group/start') === 0) {
            if (!state.auth.me.profile) {
                dispatch(push('/login'));
                return dispatch(_showNotice('Please <a href="/login">login</a> first', 'error'));
            }
        } else if (apiurl.indexOf('/tracks') > 0 || apiurl.indexOf('/members') > 0 || apiurl.indexOf('/users') > 0 || apiurl.indexOf('/playlists') > 0) {
            if (!state.auth.me.profile) {
                return dispatch(_showNotice('Please <a href="/login">login</a> first', 'error'));
            } else if (typeof state.auth.me.profile.roles['verified_email'] !== 'number' && typeof state.auth.me.profile.roles['verified_fb'] !== 'number') {
                return dispatch(_showNotice('You must first verify your email. You can resend your link from "My Account" page', 'error'));
            }
        }

        if (!ctx) ctx = 'page';
        dispatch(formsStarted(apiurl, ctx));

        const tdata = getIdbySegment(apiurl);
        tdata.dom_ref = ctx;
        if (state.auth.me && state.auth.me.profile) {
            tdata.uid = state.auth.me.profile.uid[0].value;
        }

        return API.Get(apiurl).then((res) => {
            if (res.data.error) {
                dispatch(formsFailure(res.data.error));
            } else if (res.data.message === '') {
                dispatch(formsFailure('Unauthorized'));
            } else {
                dispatch(formsSuccess(res.data, apiurl));
            }

            window.logUse.logEvent('load_form', tdata);

        }).catch((err) => {
            tdata.verb = 'failed';
            window.logUse.logEvent('load_form', tdata);
            var msg = API.getErrorMsg(err);
            dispatch(formsFailure(msg));
        });
    };
};

export const submitTrack = (apiurl, data) => {
    return (dispatch, getState) => {

        const state = getState();
        if (state.forms.loading === true) return false;

        dispatch(formsStarted(apiurl, state.forms.ctx));

        console.log(apiurl, data);

        const req = {url: apiurl, data: data};
        req.method = apiurl.substring(apiurl.lastIndexOf('/') + 1) === 'edit' ? 'PUT' : 'POST';

        API.Request(req).then((res) => {
            if (res.data.error) {
                dispatch(formsFailure(res.data.error));
            } else {
                dispatch(formCompleted());

                if (typeof res.data.payout === 'number' && res.data.payout > 0) {
                    dispatch(addPendingPayout(res.data.payout))
                    dispatch(_showNotice(res.data.success));
                }

                let url = apiurl.substring('/forms'.length, apiurl.lastIndexOf('/')); // strip 'forms' and verb
                if (url.substring(url.lastIndexOf('/')) === '/tracks') {
                    url = url.substring(0, url.lastIndexOf('/')); // remove trailing /tracks
                }

                if (state.forms.ctx === 'page') {
                    dispatch(push(url + '?v=' + new Date().getTime())); // entityReducer dispatches own updates
                } else {
                    if (req.method === 'POST') url += '/' + res.data.target_id; // on add, we need to target this track
                    API.Get(url).then(updated => {
                        dispatch(updatePlayerTrack(updated.data));
                        if (state.entity.apiData && (
                            state.entity.apiData.type[0].target_id === 'groups-group_node-tracks'
                            || state.entity.apiData.type[0].target_id === 'groups-group_node-clips')) {
                            dispatch(entityDataSuccess(updated.data)); // WARN: might not be fully normalized!?
                        }
                    });
                }
            }
        }).catch((err) => {
            let msg = API.getErrorMsg(err);
            dispatch(formsFailure(msg));
        });
    };
};

export const submitForm = (apiurl, data) => {
    return (dispatch, getState) => {

        const state = getState();
        if (state.forms.loading === true) return false;

        dispatch(formsStarted(apiurl, state.forms.ctx));

        console.log(apiurl, data);

        const req = {url: apiurl, data: data};
        req.method = apiurl.substring(apiurl.lastIndexOf('/') + 1) === 'edit' ? 'PUT' : 'POST';

        API.Request(req).then((res) => {
            if (res.data.error) {
                dispatch(formsFailure(res.data.error));
            } else {
                dispatch(formCompleted());
                if (apiurl === '/otp/account_otp/send') {
                    return dispatch(_showNotice(res.data.success));
                }

                if (typeof res.data.payout === 'number' && res.data.payout > 0) {
                    dispatch(addPendingPayout(res.data.payout))
                    dispatch(_showNotice(res.data.success));
                }

                if (apiurl.indexOf('/group/start') === 0) {
                    dispatch(push('/group/' + res.data.target_id));
                    return dispatch(initApp('/group/' + res.data.target_id, 'group-created')); // update form
                }

                let url = apiurl.substring('/forms'.length, apiurl.lastIndexOf('/')); // strip verb

                if (state.forms.ctx === 'page' || apiurl.indexOf("/playlists/add") > 1) {
                    dispatch(push(url + '?v=' + new Date().getTime()));
                }

            }
        }).catch((err) => {
            let msg = API.getErrorMsg(err);
            dispatch(formsFailure(msg));
        });
    };
};

export const submitDelete = (apiurl) => {
    return (dispatch, getState) => {

        var state = getState();
        if (state.forms.loading === true) return false;

        dispatch(formsStarted(apiurl));

        API.Request({url: apiurl, method: 'DELETE'}).then((res) => {
            if (res.data.error) {
                dispatch(formsFailure(res.data.error));
            } else {
                dispatch(formCompleted());
                var parts = apiurl.split('/'); // ex. ["", "forms", "group", "1", "playlists", "2471", "delete"]
                var dest = '/' + parts.slice(2, 5).join('/');
                if (apiurl.indexOf('/tracks') > -1) {
                    dest += '/' + parts[5]; // a track was deleted to redirect to it's playlist by ID
                }
                console.log("Delete Redirect: ", dest);
                dispatch(push(dest));
                // TODO: update player.tracklist if deleted came from playing
            }
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            dispatch(formsFailure(msg));
        });
    };
};

export const submitPublicMedia = (el, filename, meantFor) => {

    let formData = new FormData();
    let type = '';
    if (typeof el === 'object' && el.files) {
        const file = el.files[0];
        type = file.type; // file.type.split('/'); // audio|video
        type = type.split('/');
        formData.append('files[]', el.files[0], file.name);
    } else {
        formData.append("files[]", el, filename);
    }

    formData.append('filename', filename);
    // dispatch(formImagePosting('field_media', index, sourceName));

    return API.Request({
        url: `/forms/media/public/add?meantfor=${encodeURIComponent(meantFor)}`,
        headers: {'Content-Type': `multipart/form-data; boundary=` + formData._boundary},
        method: 'POST',
        data: formData
    })

};

export const updateMediaField = (el, filename, title, ppp, copyright_owner, currency, index, field) => {
    return (dispatch, getState) => {

        const state = getState();
        if (state.forms.loading === true) return false;

        let formData = new FormData();
        let type = '';
        if (typeof el === 'object' && el.files) {
            const file = el.files[0];
            type = file.type; // file.type.split('/'); // audio|video
            if (type !== 'audio/mp3' && type !== 'video/mp4') {
                let ext = file.name.substring(file.name.lastIndexOf('.') + 1); // mime is sometimes wrong? check extension
                if (ext !== 'mp3' && ext !== 'mp4') {
                    return dispatch(_showNotice('Invalid file type. Please select mp3 or mp4 files only', 'error'));
                }
            }
            type = type.split('/');
            formData.append('files[]', el.files[0], file.name);
        } else {
            formData.append("files[]", el, filename);
            type = ['video'];
        }

        const parts = state.forms.apiurl.split('/'); // ex. /forms/group/9/playlists/8484/tracks/add

        formData.append('title', title);
        formData.append('filename', filename);
        formData.append('gid', parts[3]);
        formData.append('pid', parts[5]);
        formData.append('ppp', ppp);
        formData.append('copyright_owner', copyright_owner);
        formData.append('currency', currency);

        dispatch(formImagePosting(field.field_name, index, field.sourceName));

        API.Request({
            url: "/forms/media/" + type[0] + "/add",
            headers: {'Content-Type': `multipart/form-data; boundary=` + formData._boundary},
            method: 'POST',
            data: formData
        }).then((res) => {
            var err = API.checkError(res.data);
            if (err.length > 0) {
                dispatch(formImageFailed(field.field_name, index, field.sourceName, err))
            } else {
                // TODO: addRole('artist') if transaction_id is present
                dispatch(addMediaItem(res.data, field));

                if (type[0] === 'video') {

                    let video = document.createElement('video');
                    video.preload = 'auto';
                    video.muted = true;
                    video.autoplay = true;
                    var snapshot = function (e) {
                        video.pause();
                        video.removeEventListener('loadeddata', snapshot);
                        // video.currentTime = 3;
                        console.log(e, video.videoWidth, video.videoHeight, video.duration);
                        var canvas = document.createElement('canvas');
                        var ctx = canvas.getContext('2d');
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                        canvas.toBlob(blob => {
                            console.log(blob);
                            dispatch(updateImageField(blob, {
                                field_name: 'field_cover',
                                bundle: 'tracks',
                                filename: title + '.png'
                            }, 0, field.sourceName));
                            URL.revokeObjectURL(video.src);
                        }, 'image/png');
                    };
                    video.addEventListener('loadeddata', snapshot);
                    if (typeof el === 'object' && el.files) {
                        video.src = URL.createObjectURL(el.files[0]);
                    } else {
                        video.src = URL.createObjectURL(el);
                    }
                }

            }
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            dispatch(formImageFailed(field.field_name, index, field.sourceName, msg))
        });
    };
};

export const updateImageField = (el, field, index, sourceName) => {
    return (dispatch, getState) => {

        var state = getState();
        if (state.forms.loading === true) return false;

        var formData = new FormData();
        if (typeof el === 'object' && el.files) {
            formData.append('file', el.files[0]);
        } else {
            formData.append('file', el, field.filename);
        }

        var type = 'node';
        if (field.bundle === 'groups') {
            type = 'group';
        } else if (field.bundle.indexOf('groups-') === 0) {
            type = 'group_content';
        } else if (field.bundle === 'user' || field.bundle === 'account') {
            type = 'user';
        }

        dispatch(formImagePosting(field.field_name, index, sourceName));

        var req = {
            url: "/file/upload/" + type + "/" + field.bundle + "/" + field.field_name,
            // url: "/file/upload/node/playlists/field_cover",
            headers: {'Content-Type': `multipart/form-data; boundary=` + formData._boundary},
            method: 'POST',
            data: formData
        };
        // req.headers['Content-Disposition'] = 'file;filename="filename.jpg"';

        API.Request(req).then((res) => {
            if (!res.data.fid || res.data.fid.length === 0) {
                dispatch(formImageFailed(field.field_name, index, sourceName, 'upload failed'))
            } else {
                res.data.uri[0].url = Config.api.base + res.data.uri[0].url;
                /* if (state.auth.formDrawerOpen > 0) {
                 let fieldVal = [{target_id:res.data.fid[0].value, url:res.data.uri[0].url}];
                 dispatch(updateEntity({[field.field_name]:fieldVal})) // state.entity
                 dispatch(updateMyGroup({[field.field_name]:fieldVal})); // state.auth.me.groups[curGroup]
                } */
                dispatch(formImagePosted(res.data, field.field_name, index, sourceName)); // state.forms
            }
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            dispatch(formImageFailed(field.field_name, index, sourceName, msg))
        });
    };
};

const initialState = {
    ctx: false,
    loading: false,
    api: null,
    apiurl: null,
    error: null
};

export default function formsReducer(newState = initialState, action) {
    var i = 0;
    switch (action.type) {
        case FORM_CLEAR_ERROR:
            delete newState.notice;
            newState.error = false;
            return newState;
        case FORM_NOTICE:
            newState.notice = action;
            return newState;
        case FORM_DATA_STARTED:
            if (newState.apiurl !== action.apiurl) {
                // TODO: don't erase on /delete
                newState.api = null;
            }
            newState.loading = true;
            newState.ctx = action.ctx;
            newState.apiurl = action.apiurl;
            return newState;
        case FORM_DATA_SUCCESS:
            newState.loading = false;
            newState.error = null;
            newState.api = action.payload;
            // TODO: populate .entity / node from field defaults!!!
            /* if (typeof newState.api.entity !== 'undefined' && typeof newState.api.entity[action.field_name] !== 'undefined') {
               // set Field defaults to new entity
               let field = newState.api.fields.find(f => f.field_name === action.field_name);
               if (!field) {
                console.warn('is this field missing or feature removed? ', action);
                return newState;
               }
               if (action.val === null) {
                field.default_value = null;
                break;
               } else {
                if (typeof field.default_value[action.index] === 'undefined') field.default_value[action.index] = {};
                field.default_value[action.index][action.prop] = action.val;
                break;
               }
              }
              */
            return newState;
        case FORM_DATA_COMPLETED:
            return {...action.payload}; // resets to initialState
        case FORM_DATA_FAILURE:
            newState.loading = false;
            newState.error = action.error;
            return newState;
        case FORM_DATA_CANCEL:
            newState.loading = false;
            newState.ctx = false;
            return newState;
        case FORM_UPLOAD_STARTED:
            if (!newState.api[action.sourceName][action.field_name][action.index]) newState.api[action.sourceName][action.field_name][action.index] = {};
            newState.api[action.sourceName][action.field_name][action.index].loading = true;
            return newState;
        case FORM_UPLOAD_COMPLETED:
            let entry = {
                target_id: action.fileArray.fid[0].value,
                title: action.fileArray.filename[0].value,
                alt: action.fileArray.filename[0].value,
                url: action.fileArray.uri[0].url
            }
            if (!newState.api[action.sourceName][action.field_name]) {
                newState.api[action.sourceName][action.field_name] = []
            }
            newState.api[action.sourceName][action.field_name][action.index] = entry;
            return newState;
        case FORM_UPLOAD_FAILED:
            delete newState.api[action.sourceName][action.field_name][action.index].loading;
            newState.notice = action.error;
            return newState;
        case FORM_MEDIA_ADD:
            const {fieldschema} = newState.api;
            for (let i = 0; i < fieldschema.length; i++) {
                const field = fieldschema[i].children.find(x => x.field_name === action.field.field_name && x.sourceName === action.field.sourceName); // never recursive yet
                if (field) {
                    if (typeof newState.api[field.sourceName] === 'undefined') {
                        newState.api[field.sourceName] = {};
                    }
                    if (typeof newState.api[field.sourceName][field.field_name] === 'undefined') {
                        newState.api[field.sourceName][field.field_name] = [action.payload];
                    } else {
                        const exists = newState.api[field.sourceName][field.field_name].findIndex(element => element.target_id === action.payload.target_id);
                        if (exists > -1) newState.api[field.sourceName][field.field_name][exists] = action.payload;
                        else newState.api[field.sourceName][field.field_name].push(action.payload);
                    }
                    newState.api[field.sourceName][field.field_name][0].loading = false;

                    if (newState.api.node && newState.api.node.type[0].target_id === 'tracks') {
                        if (!newState.api.node.title || !newState.api.node.title[0] || newState.api.node.title[0].value === '') {
                            newState.api.node.title = [{'value': action.payload.target_label}];
                        }
                        if (action.payload.target_author) {
                            if (!newState.api.node.field_artists || !newState.api.node.field_artists[0] || newState.api.node.field_artists[0].value === '') {
                                newState.api.node.field_artists = [{'value': action.payload.target_author}];
                            }
                        }
                    }

                    return newState
                }
            }
            return newState;
        case FORM_MEDIA_DELETE:
            const i = newState.api[action.sourceName][action.field_name].findIndex(element => element.target_id === action.target_id);
            if (i > -1) newState.api[action.sourceName][action.field_name].splice(i, 1);
            return newState;
        case FORM_POPULATE_TRACK:
            newState.loading = false;
            newState.error = null;
            newState.api.node = action.payload;
            return newState;
        case FORM_POPULATE_PLAYLIST :
            newState.loading = false;
            newState.error = null;
            newState.api = action.payload;
            return newState;
        case FORM_POPULATE_REWARD:
            newState.api.node = action.payload;
            if (!newState.api.entity || newState.api.entity.entity_id[0].target_id !== newState.api.node.nid[0].value) {
                newState.api.entity = {
                    entity_id: [{
                        target_id: newState.api.node.nid[0].value,
                        target_label: newState.api.node.title[0].value
                    }],
                    title: newState.api.node.title,
                    field_cover: newState.api.node.field_cover,
                    field_description: newState.api.node.body,
                };
            }
            return newState;
        case FORM_REMOVE_WIDGET:
            newState.api[action.sourceName][action.field_name].splice(action.index, 1);
            return newState;
        case FORM_ADD_WIDGET:
            let copy = {...newState.api[action.sourceName][action.field_name][action.index]};
            // if (!copy.value && !copy.target_id) copy.target_id = -1;
            newState.api[action.sourceName][action.field_name].splice(action.index, 0, copy);
            return newState;
        case FORM_SET_ENTRIES:
            action.entries.forEach((obj, i2) => {
                const subkey = 'node-' + (i2 + 1); // not 0 based index
                newState.api[subkey] = obj;
            });
            newState.api.nodeCount = action.entries.length;
            return newState;
        case FORM_ADD_ENTRY:
            newState.api.nodeCount = newState.api.nodeCount ? newState.api.nodeCount + 1 : 1;
            const subkey = 'node-' + newState.api.nodeCount;
            newState.api[subkey] = newState.api.node; // should always be the empty one when using multiples
            return newState;
        case FORM_TOGGLE_FIELD:
            if (newState.api && action.bundle === newState.api.bundle) {
                let field = newState.api.fields.find(o => o.field_name === action.field_name);
                if (field && field.settings) {
                    field.settings.hidden = !field.settings.hidden;
                }
            }
            return newState;
        case FORM_SET_FIELD_VALUES:
            if (!newState.api[action.sourceName][action.field_name]) {
                newState.api[action.sourceName][action.field_name] = []
            }
            newState.api[action.sourceName][action.field_name] = action.val;
            return newState;
        case FORM_SET_FIELD_VALUE:
            let source = newState.api[action.sourceName]; // 'entity' or 'node' ...

            if (!source[action.field_name]) {
                source[action.field_name] = []; // seen on account.user || account.current_pass
            }

            // likely a new multi-value entry
            if (!source[action.field_name][action.index]) {
                source[action.field_name][action.index] = {};
            }

            // set the value
            if (action.val === null) {
                source[action.field_name].splice(action.index, 1); // delete the entry
            } else {
                if (typeof action.val === 'object' && action.val[action.prop]) {
                    source[action.field_name][action.index] = action.val; // from an autocomplete {target_id, target_label, ...}
                } else {
                    source[action.field_name][action.index][action.prop] = action.val;
                }
            }
            return newState;
        default:
            return newState;
    }
}
