import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import SanitizedHTML from "react-sanitized-html";
import Config from '../../Config';

class FormInstructions extends Component {

 render() {
  return (
   <div style={{marginBottom:10}}>
    {this.props.instructions.caption ? <Typography variant='h2' style={{textDecoration:'underline'}} >{this.props.instructions.caption}</Typography> : ''}
    {this.props.instructions[this.props.step] ? <SanitizedHTML allowedTags={Config.allowedTags} html={this.props.instructions[this.props.step]} /> : ''}
    {this.props.instructions.component ? this.props.instructions.component : ''}
   </div>

  );
 }
}

FormInstructions.propTypes = {
 step: PropTypes.string,
 instructions: PropTypes.object.isRequired
};

FormInstructions.defaultProps = {
 step:'step1'
};


export default FormInstructions;
