import React, {Component} from 'react';
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import Config from '../../Config';
import Badge from '@material-ui/core/Badge';
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import {tileStyles} from "../TrackTile/TrackTile";

class GenreButton extends Component {

 handleClick() {
  this.props.onClick({target_id:this.props.target_id});
 }

 render() {
  return (
   <Grid item style={{marginBottom: 10, marginTop: 5, marginRight:20, padding:0, backgroundColor:'transparent'}}>
    <Badge
     badgeContent={this.props.count} color="secondary"
     anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
     <Chip
      color={this.props.isSelected === true ? 'primary' : 'default'}
      size="small"
      onClick={e => this.handleClick()}
      avatar={<Avatar
       classes={{img:this.props.classes.genreAvatar}}
       src={(!this.props.uri) ? this.props.uri : `${Config.api.base}/tam/genres/${this.props.target_id}/png`}
       alt={this.props.label}
      />}
      label={this.props.label}
     />
    </Badge>
   </Grid>
  );
 }
}

GenreButton.defaultProps = {
 isSelected: false,
 count: 0
};

GenreButton.propTypes = {
 onClick: PropTypes.func.isRequired,
 count: PropTypes.number,
 label: PropTypes.string.isRequired,
 target_id: PropTypes.number.isRequired,
 uri: PropTypes.string,
 isSelected: PropTypes.bool
};

export default withStyles(tileStyles)(GenreButton);
