/* eslint no-console: 0 */
/* eslint no-useless-constructor: 0 */

import moment from 'moment';
import Config from '../Config';
import {isEnabled} from "./EnabledFeature";

/*
moment.relativeTimeThreshold('d', 30*12);
moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ",
        s:  "s",
        m:  "m",
        mm: "%d m",
        h:  "h",
        hh: "%d h",
        d:  "d",
        dd: "%d d",
        M:  "a mth",
        MM: "%d mths",
        y:  "y",
        yy: "%d y"
    }
});
 */

class Drupal2Json {

 constructor (json) {
  this.json = json;
 }

 getConfig(prop) {
  if (prop) return Config[prop];
  return Config;
 }

 count(field, prop) {
  if (typeof this.json[field] === 'undefined') return -1;
  if (typeof this.json[field] === 'number' || typeof this.json[field] === 'string') return 0; // this isn't a array and can't be looped
  if (typeof this.json[field] === 'boolean') return 0;
  return this.json[field].length;
 }

 get (field, prop, delta) {
  if (typeof this.json[field] === 'undefined') return null;
  if (typeof this.json[field] === 'number' || typeof this.json[field] === 'string') return this.json[field]; // (ex. node.title)
  if (!prop) prop = 'value';
  if (delta) {
   if (typeof this.json[field][delta] === 'undefined' || typeof this.json[field][delta][prop] === 'undefined') return null;
   return this.json[field][delta][prop];
  }

  for(let i in this.json[field]) {
   if (typeof this.json[field][i][prop] !== 'undefined') {
    return this.json[field][i][prop];
   }
  }
  return null;
 }

 getLabel() {
  if (this.json.field_name && this.json.field_name.length > 0) return this.json.field_name[0]['value'];
  if (this.json.label) return this.json.label[0]['value'];
  return this.json.title;
 }

 timeAgo(field) {
  var time = this.get(field, 'value');
  if (time) {
   if (parseInt(time, 0) >= 1354562010 && parseInt(time, 0) < 1300000000000) time = time * 1000;
   return moment(time).fromNow();
  }
  return 'recently';
 }

 getTime(field, format, prop, timezone) {
  var time = this.get(field, prop || 'value');
  if (time) {
   if (time === '1970-01-01T00:00:00+00:00') return 'never';
   if (parseInt(time, 0) >= 1354562010 && parseInt(time, 0) < 1300000000000) time = time * 1000;
   if (!format) format = 'MMMM Do YYYY, h:mm:ss a';
   if (timezone) {
    // moment(time).tz(timezone).format(format);
    return new Date(time).toLocaleDateString(
        undefined,
        {
         weekday:'short',
         year: 'numeric',
         month: 'long',
         day: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
         timeZoneName:"short",
         timeZone: timezone
        }
    );
   }
   return moment(time).format(format);
  }
  return null;
 }

 getTimeRange(field, format, timezone) {
  let start = this.getTime(field, format, 'value', timezone);
  if (!start) return null;

  let end_value = this.getTime(field, format, 'end_value', timezone);
  if (!end_value) return start;

  const diff = moment(this.get(field, 'end_value')).diff(moment(this.get(field, 'value')), 'hours');
  if (diff > 24) {
   return  start + ' - ' + end_value
  } else {
   return  start + ' - ' + moment(this.get(field, 'end_value')).format('h:ssA')
  }
 }

 getTimestamp(field, prop, delta) {
  var time = this.get(field, prop, delta);
  if (time) {
   if (time === '1970-01-01T00:00:00+00:00') return 0;
   return moment(time).valueOf();
  }
  return 0;
 }

 findPropByVal(field, value, prop) {
  for(var i in this.json[field]){
   var val = this.json[field][i][prop];
   if (value === val) {
    return this.json[field][i];
   }
  }
  return false;
 }

 getImgPath(tests) { // also handles names
  // if (!tests) tests = {'field_headshot':'url', 'user_picture':'url', 'field_fb_id':'value'};  'uid':'url',
  for(var t in tests) {
   var val = this.get(t, tests[t]);
   if (val) {
    if (t === 'field_fb_id') return 'https://graph.facebook.com/' + val + '/picture?width=70&height=70&redirect=true';
    if (tests[t] === 'avatar' || t === 'field_avatar') {
     return Config.api.client_host+'/avatars/black/' + val + '.png';
    }
    return val;
   }
  }
  return false;
 }

 getAvatarPath(tests, hue) {
  for(var t in tests) {
   if (tests[t] === 'avatar' || t === 'field_avatar') {
    var val = this.get(t, tests[t]);
    if (val) {
     return Config.api.client_host+'/avatars/'+(hue === 'light' ? 'black' : 'white')+'/' + val + '.png';
    }
   }
  }
  return false;
 }

 can_add(me) {
  const type = this.get('type', 'target_id');
  if (!me || !me.profile || !me.profile.uid) {
   if (type === 'default' || type === 'user') {
    return true;
   }
   return false;
  }

  const isAdmin = this.isGroupAdmin(me);
  if (type === 'groups' || isAdmin === true) return true;

  if (type === 'groups-group_node-playlists') {
   return isEnabled('playlists:create', true);
  } if (type === 'tracks' || type === 'groups-group_node-tracks') {
   return isEnabled('tracks:create', true);
  } if (type === 'clips' || type === 'groups-group_node-clips') {
   return isEnabled('tracks:create', true);
  }
  return isEnabled('rewards:create', true);
 }

 can_edit(me) { // only receives profile
  if (!me || !me.profile || !me.profile.uid) return 'You must sign-in first'

  const type = this.get('type', 'target_id');
  if (type === 'default' || type === 'user') {
   if (me.profile.uid[0].value === this.get('uid', 'value')) {
    return true; // my account
   }
   return 'Only the owner can edit their own account';
  }
  if (type === 'groups-group_node-tracks' || type === 'tracks') {
   return true; // editorial permissions are currently always allowed to any TA members
  }
  if (type === 'groups-group_membership') {
   if (me.profile.uid[0].value === this.get('entity_id', 'target_id')) {
    return true; // my membership profile
   }
   return 'Only the owner can edit their own membership';
  }

  if (me.profile.uid[0].value === this.get('uid', 'target_id')) {
   return true; // my content
  }
  const isAdmin = this.isGroupAdmin(me); // groups owners & admins
  if (isAdmin === false) {
   return 'You are not the owner or admin of this content';
  }
  return true;
 }

 can_delete(me) {
  if (!me || !me.profile || !me.profile.uid) return 'You must sign-in first'

  const type = this.get('type', 'target_id');
  if (type === 'default' || type === 'user') {
   if (me.profile.uid[0].value === this.get('uid', 'value')) {
    return true; // my account
   }
   return false;
  }
  if (me.profile.uid[0].value === this.get('uid', 'target_id')) {
   return true; // owner of content
  }
  if (type === 'groups') {
   if (me.profile.uid[0].value === this.get('uid', 'target_id')) {
    return true; // group owner only
   }
   return false;
  }
  return this.isGroupAdmin(me); // memberships can be deleted by group owners & admins
 }

 isGroupAdmin(me) { // receives full auth obj
  if (!me || !me.profile || !me.groups) return false;
  var type = this.get('type', 'target_id'), gid = false;
  if (type === 'groups') {
   gid = this.json.id[0].value;
  } else if (this.json.gid) {
   gid = this.json.gid[0].target_id;
  }

  if (!gid) {
   console.log("NO GROUP CONTEXT on isGroupAdmin");
   return false;
  }

  if (typeof me.groups[gid] === 'undefined') return false;

  var gjson = this;
  if (type !== 'groups') {
   gjson = new Drupal2Json(me.groups[gid]);
  }

  const uid = me.profile.uid[0].value;
  if (gjson.get('uid', 'target_id') === uid) return true; // is group author

  return (gjson.findPropByVal('field_editors', uid, 'target_id') != false);
 }

}

export default Drupal2Json;
