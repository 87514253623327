import React, {Component} from 'react';
import Config from '../../Config';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Drupal2Json from '../../Util/Drupal2Json';
//import Image from '../Image';
import EditIcon from '@material-ui/icons/Edit';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ReceiptIcon from '@material-ui/icons/Receipt';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {loadPasswordReminder} from '../../redux/formsReducer';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SanitizedHTML from 'react-sanitized-html';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import ItemGrid from '../ItemGrid';

import {listData} from '../../redux/listDataReducer';
import PaginationBlock from '../../Components/PaginationBlock';
import OverlayLoader from '../../Components/OverlayLoader';
import Typography from "@material-ui/core/Typography";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import Grammer from "../../Util/Grammer";
import Sport12Stats from "../Sport12/Sport12Stats";
import API from "../../Util/API";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Cancel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {withSnackbar} from "notistack";
import {getStartupParam} from "../../Util/WindowUtils";

const styles = theme => ({
    paperBg: {
        backgroundColor: alpha(theme.palette.background.paper, 0.75)
    },
    heroContainer: {
        position: 'relative',
        height: '33vh',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100%',
        margin: '0 0 30px 0',
        padding: 0,
    },
    profilePic: {
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        left: '20%',
        bottom: -25,
        width: 150,
        height: 150,
        padding: 5,
        border: '1px solid #000'
    },
    titleRow: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& h1': {
            margin: theme.spacing(0),
        },
        '& h5': {
            margin: theme.spacing(0),
            fontSize: '90%',
            fontStyle: 'italic',
            fontWeight: 'normal',
            textDecoration: 'none!important'
        },
        '& h6': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            fontSize: '80%',
            fontStyle: 'italic',
            fontWeight: 'normal'
        }
    },
    paddedContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%'
    },
    editBtns: {
        textAlign: 'center'
    },
    blockquote: {
        fontStyle: 'italic'
    },
    noUnderline: {
        textDecoration: 'none'
    },
    panelHeader: {
        width: '100%',
        marginBottom: 0
    },
    statCount: {
        padding: theme.spacing(2) + 4,
        fontSize: '100%',
        marginLeft: -4
    },
    chip: {
        marginTop: 4,
        marginBottom: 4,
        marginRight: theme.spacing(1),
    },
    chipRoot: {
        border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]),
        borderWidth: '1px',
        backgroundColor: 'transparent',
        textDecoration: 'none'
    },
    chipAvatar: {
        color: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        fontWeight: 900,
        height: 22 + '!important',
        width: 22 + '!important',
        marginLeft: '0!important'
    }
});


class MemberProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: '', listUrl: '', password:''};
        if (this.props.match && this.props.match.params.aid && this.props.match.params.gid && this.props.match.params.bundle) {
            this.state = {expanded: this.props.match.params.bundle, listUrl: this.props.match.url};
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.aid && this.props.match.params.gid && this.props.match.params.bundle) {
            const el = document.getElementById(`${this.props.match.params.bundle}-header`);
            setTimeout(() => {
                if (el) {
                    el.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        offset: {top: -100, left: 0}
                    })
                }
            }, 250);
        }
    }

    getUid() {
        return (this.props.data.entity_id) ? this.props.data.entity_id[0].target_id : this.props.data.uid[0].value;
    }

    handleChange(panel, isChip) {
        // const gid = this.props.data.gid[0].value;
        // const isMember = (this.props.me.groups && typeof this.props.me.groups[gid] !== 'undefined') ? true : false;
        if (panel === this.state.expanded) {
            return this.setState({expanded: false}); // just close
        }

        var url = '/users/';
        if (this.props.data.entity_id) {
            url += this.props.data.entity_id[0].target_id + '/group/' + this.props.data.gid[0].target_id + '/' + panel;
        } else {
            url += this.props.data.uid[0].value + '/' + panel;
        }
        this.setState({expanded: panel, listUrl: url}, () => {
            const el = document.getElementById(`${panel}-header`);
            el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                offset: {top: -100, left: 0}
            })
        });

        this.props.dispatch(listData(url));
    };

    logout() {
        localStorage.removeItem(Config.api.tokName);
        if (this.props.data.entity_id && this.props.data.gid[0].target_id) {
            document.location.href = `/group/${this.props.data.gid[0].target_id}/signin`;
        } else {
            document.location.href = '/signin?logged-out=true';
        }

    }

    promptDelete() {
        if (this.state.password.length < 1) {
            this.props.enqueueSnackbar('Please provide your current password to delete your account', {variant:'error'});
        } else if (window.confirm('Are you sure you want to delete your entire account?')) {
            API.Delete(`/forms/users/${this.getUid()}/delete`, {data:{password:this.state.password}})
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        this.props.enqueueSnackbar(msg, {variant:'error'});
                    } else {
                        this.props.enqueueSnackbar(res.data.success, {variant:'info'});
                        this.props.history.push('/')
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.enqueueSnackbar(msg, {variant:'error'});
                    return err;
                });
        }
    }


    render() {
        const {classes, data} = this.props;
        var ujson = new Drupal2Json(data);
        var bgImg = ujson.get('field_cover', 'url');
        const gid = ujson.get('gid', 'target_id');

        var proImg = ujson.getImgPath({'field_headshot': 'url', 'user_picture': 'url'});
        if (!proImg) proImg = ujson.getAvatarPath({
            'field_avatar': 'value',
            'entity_id': 'avatar'
        }, this.props.theme.palette.type);
        var uname = ujson.getImgPath({
            'field_name': 'value',
            'target_label': 'value',
            'label': 'value',
            'name': 'value'
        });

        return (
            <Grid container
                  className={classes.paperBg}
                  style={{width: '100%'}}
                  direction="column"
                  justifyContent="flex-start">
                <div className={classes.heroContainer}
                     style={bgImg !== false ? {backgroundImage: "url(" + bgImg + ")"} : null}>
                    <div className={classes.profilePic} style={{backgroundImage: "url(" + proImg + ")"}}>&nbsp;</div>
                </div>
                {ujson.can_edit(this.props.me) === true &&
                    <div className={classes.editBtns}>
                        <Link
                            to={(data.gid) ? "/forms/group/" + gid + "/members/" + data.id[0].value + "/edit" : '/forms/users/' + data.uid[0].value + '/edit'}
                            className={classes.noUnderline}>
                            <Button color="secondary" variant='outlined' disableElevation={true}
                                    style={{marginRight: 5}}
                                    startIcon={<EditIcon/>}
                            >Edit Profile</Button>
                        </Link>
                        <Link to={data.gid ? `/group/${gid}/wallet` : '/wallet'} className={classes.noUnderline}>
                            <Button color="secondary" variant='outlined' disableElevation={true} style={{marginLeft: 5}}
                                    startIcon={<WalletIcon/>}
                            >My Wallet</Button>
                        </Link>
                        <Link to={data.gid ? `/group/${gid}/wallet/activity` : '/wallet/activity'}
                              className={classes.noUnderline}>
                            <Button color="secondary" variant='outlined' disableElevation={true} style={{marginLeft: 5}}
                                    startIcon={<ReceiptIcon/>}
                            >View Transactions</Button>
                        </Link>

                        {this.props.me.profile && (!this.props.me.profile.roles || typeof this.props.me.profile.roles.verified_email !== 'number') ?
                            <Button
                                onClick={e => this.props.dispatch(loadPasswordReminder(this.props.me.profile.mail.length > 0 ? this.props.me.profile.mail[0]['value'] : null))}
                                color="primary" variant='contained' disableElevation={true} style={{marginLeft: 5}}
                                startIcon={<WalletIcon/>}
                            >Email Validation Link</Button> : ''
                        }
                    </div>
                }
                <div className={classes.paddedContainer}>
                    <div className={classes.titleRow}>

                        <Typography variant='h2'>{uname}</Typography>
                        {ujson.get('field_tagline', 'value') ?
                            <SanitizedHTML html={ujson.get('field_tagline', 'value')}
                                           allowedTags={Config.allowedTags}/> : ''}

                    </div>

                    <Grid container direction="row" justifyContent="space-around" alignItems="center">
                        {Object.entries(data.stats).map(arr =>
                            !arr[1].count ? null : <Chip
                                key={arr[0]}
                                aria-label={`Member Stat Count ${arr[0]}`}
                                avatar={<Avatar className={classes.statCount}>{arr[1].count || 0}</Avatar>}
                                label={this.props.formDrawerOpen > 0 ? Grammer.g(arr[0].charAt(0).toUpperCase() + arr[0].slice(1), 'p') : arr[1].label}
                                classes={{
                                    root: classes.chipRoot,
                                    avatar: classes.chipAvatar
                                }}
                                className={classes.chip}
                                onClick={() => this.handleChange(arr[0], true)}
                            />
                        )}

                    </Grid>

                    <List component="ul" dense={true} style={{display: 'flex'}}>
                        <ListItem key='field_last_login'>
                            <ListItemText primary='Last Login'
                                          secondary={ujson.getTime('field_last_login', 'MMM Do \'YY')}/>
                        </ListItem>
                        <ListItem key='created'>
                            <ListItemText primary='Joined' secondary={ujson.getTime('created', 'MMM Do \'YY')}/>
                        </ListItem>
                        {ujson.get('field_locale') ?
                            <ListItem key='locale'>
                                <ListItemText primary={ujson.get('field_locale')} secondary='Locale'/>
                            </ListItem> : ''}
                        {ujson.get('field_birthdate') ?
                            <ListItem key='field_birthdate'>
                                <ListItemText primary='Birthdate'
                                              secondary={ujson.getTime('field_birthdate', 'MMM Do \'YY')}/>
                            </ListItem> : ''
                        }
                        <ListItem key='field_ta_cred'>
                            <ListItemText primary={Grammer.g("T.A.Credibility")}
                                          secondary={Grammer.tac("₮") + (ujson.get('field_ta_cred') || 0)}/>
                        </ListItem>

                        {ujson.get('field_inviter', 'target_id') && ujson.get('field_inviter', 'target_id') > 1 &&
                            <ListItem key='field_inviter'>
                                <ListItemText primary='Invited By' secondary={(
                                    <Link
                                        to={`/users/${ujson.get('field_inviter', 'target_id')}/group/${ujson.get('gid', 'target_id')}`}>
                                        {ujson.get('field_inviter', 'target_label')}
                                    </Link>
                                )}/>
                            </ListItem>
                        }

                    </List>

                    {gid === 56 && <Sport12Stats data={this.props.data.field_json[0]}/>}

                </div>

                {Object.entries(data.stats).map(arr => {
                        if (arr[1].count) {
                            return <Accordion key={arr[0]} className={classes.panelHeader}
                                              aria-label={`Member Stats ${arr[0]}`}
                                              expanded={this.state.expanded === arr[0]}
                                              onChange={() => this.handleChange(arr[0])}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={arr[0] + '-content'}
                                    id={arr[0] + '-header'}
                                >
                                    {this.props.formDrawerOpen > 0 ? Grammer.g(arr[0], 'p') : arr[1].label}
                                </AccordionSummary>
                                <AccordionDetails style={{flexWrap: 'wrap'}}>
                                    {this.props.lists.apiData && this.state.listUrl === this.props.lists.apiData.metadata.url
                                        ?
                                        <PaginationBlock hideAddButton={true} meta={this.props.lists.apiData.metadata}
                                                         dispatch={this.props.dispatch}/>
                                        : ''
                                    }

                                    {(this.props.lists.loading === true) ? <OverlayLoader/> : null}

                                    {
                                        this.state.expanded !== arr[0] || !this.props.lists.apiData ?
                                            'loading...'
                                            :
                                            (!this.props.lists.apiData || this.state.listUrl !== this.props.lists.apiData.metadata.url) ?
                                                'reloading...'
                                                :
                                                <ItemGrid lData={this.props.lists.apiData} me={this.props.me}
                                                          dispatch={this.props.dispatch} curGroup={this.props.curGroup}/>
                                    }
                                </AccordionDetails>
                            </Accordion>;
                        }
                        return '';
                    }
                )}

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 40,
                    marginBottom: 30,
                    marginRight: 20
                }}>
                    <Button color={'secondary'} onClick={() => this.logout()}>Logout</Button>
                    <Button color={'secondary'} onClick={() =>  this.setState({deletePrompt: true})}>Delete Account</Button>
                    { (getStartupParam('appOS').length > 0) && <Button color={'secondary'} onClick={() => document.location.reload()}>Update App</Button> }
                </div>

                <Dialog maxWidth='xl' fullWidth={true} open={this.state.deletePrompt} >
                    <DialogTitle>
                        Delete your Account
                        <Button onClick={() => this.setState({deletePrompt: false})} style={{float:'right'}}
                                variant="outlined" color="secondary" size='small' aria-label={'Close Dialog'} startIcon={<CloseIcon />}>Close</Button>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            defaultValue={''}
                            variant="outlined"
                            label={'Provide your current password'}
                            onChange={e => this.setState({password:e.currentTarget.value})}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color={'secondary'} onClick={() => this.promptDelete()}>Delete Account</Button>
                    </DialogActions>
                </Dialog>

            </Grid>
        );
    }
}

MemberProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(withRouter(withSnackbar(MemberProfile)));
