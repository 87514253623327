import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import API from "../../Util/API";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
import NotAvail from '@material-ui/icons/NotInterested';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grammer from "../../Util/Grammer";
import {editGroupMode} from "../../redux/authReducer";

class GroupTitle extends Component {

    constructor(p) {
        super(p);
        this.state = {label: p.label || '', loading: false, isAvailable: false, searched: false}
    }

    onSelected() {
        if (this.state.isAvailable === true) {
            this.props.onSelected(this.state.label)
        }
    }

    onBlur() {
        if (this.state.isAvailable === true && this.props.gid) {
            this.props.onSelected(this.state.label)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.gid !== prevProps.gid) {
            this.props.dispatch(editGroupMode(false));
            this.setState({label:this.props.label});
        }
    }

    checkTitleAvailable(label) {
        this.setState({label: label, loading: true, searched: true}, () => {
            if (this.state.label.length < 2) return false;
            setTimeout(() => {
                if (this.state.label === label) {
                    return API.Get(`/autocomplete/group-titles/${label}`).then((res) => {
                        this.setState({loading: false, isAvailable: res.data.available});
                    });
                }
            }, 400)
        })
    }

    render() {
        const inputProps = this.state.searched === false ? null : {
            endAdornment: (
                <InputAdornment position="end">
                    {this.state.loading === true ? <CircularProgress size={20}/> : (this.state.isAvailable ?
                        <CheckIcon fontSize={'small'} color={'primary'}/> :
                        <NotAvail fontSize={'small'} color={'disabled'}/>)}
                </InputAdornment>
            )
        }

        return (
            <div>
                <TextField value={this.state.label}
                           variant={'filled'}
                           onBlur={() => this.onBlur()}
                           onChange={(e) => this.checkTitleAvailable(e.currentTarget.value)}
                           label={`Name your ${Grammer.g('Groups', 's')}`}
                           fullWidth={true}
                           style={{margin: '10px auto 10px auto'}}
                           helperText={'change anytime'}
                           InputProps={inputProps}
                />
                {this.state.searched === true && this.state.label.length > 2 && this.state.isAvailable === false && <Typography variant={'subtitle2'} color={'error'}>Name unavailable</Typography>}

                {!this.props.gid &&
                <Button
                    disabled={!this.state.isAvailable}
                    variant={'contained'}
                    color={'primary'}
                    fullWidth={true}
                    onClick={() => this.onSelected()}>
                    Build your {Grammer.g('Groups', 's')}
                </Button>
                }
            </div>
        );


    }
}

export default GroupTitle;
