import React, {Component} from 'react';
import Config from '../../Config';
import YTVideo from "../../Components/YTVideo/YTVideo";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";

class Youtube extends Component {

 constructor(p) {
  super(p);
  this.GoogleAuth = null;
  this.state = {results:false, videos:[], ytSignedIn:false};
 }

 componentDidMount() {
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.setAttribute("async", true);
  script.onload = (e) => {
   return this.loadYtApi();
  };
  document.body.appendChild(script);
 }

 loadYtApi() {
  console.log('init client', window.google);

  window.onload = function () {
   window.google.accounts.id.initialize({
    client_id: Config.google.client,
    callback: this.handleGoogleSignin
   });
   window.google.accounts.id.prompt();
  };

  /*

  window.gapi.load('client:auth2', e => {
   window.gapi.client.init({
    'apiKey': Config.google.key,
    'clientId': Config.google.client,
    'scope': 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl',
    'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest']
   }).then(function () {

    that.GoogleAuth = window.gapi.auth2.getAuthInstance();
    that.GoogleAuth.isSignedIn.listen(e => {
     this.setState({ytSignedIn: true});
     that.searchMyLikes();
    });
    const isIn = that.GoogleAuth.isSignedIn.get();
    if (!isIn) {
     that.GoogleAuth.signIn();
    } else {
     this.setState({ytSignedIn: true});
     that.searchMyLikes();
    }
   });
  });

   */
 }

 handleGoogleSignin() {
  this.setState({ytSignedIn: true});
  this.searchMyLikes();
 }

 updateSigninStatus(isSignedIn) {
  if (isSignedIn) {
   this.isAuthorized = true;
   if (this.currentApiRequest) {
    this.sendAuthorizedApiRequest(this.currentApiRequest);
   }
  } else {
   this.isAuthorized = false;
  }
 }

 sendAuthorizedApiRequest(requestDetails) {
  this.currentApiRequest = requestDetails;
  if (this.isAuthorized) {
   // window.gapi.client.request(requestDetails)
   this.currentApiRequest = {};
  } else {
   this.GoogleAuth.signIn();
  }
 }

 searchMyLikes() {
  const that = this;
  const filters = {
   "part": ["snippet,contentDetails,statistics"], // id, topicDetails fileDetails
   "myRating": "like",
   "videoCategoryId" : 10
  };
  if (this.state.results && this.state.results.nextPageToken) {
   filters.pageToken = this.state.results.nextPageToken;
  }
  window.gapi.client.youtube.videos.list(filters).then(function(response) {
   console.log("YT LIKES Response", response);
   that.setState({results:response.result, videos:response.result.items});
  }, function(err) { console.error("YT Error", err); });
 }

 render() {
  if (this.state.ytSignedIn === false) {
   return (<div id="g_id_onload"
                data-client_id={Config.google.client}
                data-login_uri={`https://${Config.api.client_host}/appstartup`} >
    Signing In
   </div>);
  }

  if (this.state.results === false) {
   return (<div>Loading...</div>);
  }
  return <div>
   <h3>{`${this.state.results.pageInfo.resultsPerPage} / ${this.state.results.pageInfo.totalResults}`}</h3>
   <Grid container
    spacing={2}
    direction='row'
    justifyContent='space-around'
    alignContent='space-around'
    alignItems='flex-start' >
    {this.state.videos.map((o,i) => {
     return <Grid item xs={6} sm={4} md={3} key={i} > <YTVideo vid={o} /> </Grid>;
    })}</Grid>
   <Button onClick={e => this.searchMyLikes()}>Next page</Button>
  </div>;
 }

}

export default Youtube;
