import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Cancel';
import FormLoader from "../FormWrapper/FormLoader";

class DialogForm extends Component {

 render() {
  const form = this.props.forms;
  if (form.ctx !== 'dialog') return null;
  var key = form.apiurl;
  if (form.api && form.api.entity && form.api.entity.entity_id && form.api.entity.entity_id.length > 0) key += '-' + form.api.entity.entity_id[0].target_id;

  const inpProps = {};
  if (this.props.windowWidth < 500) {
   inpProps.fullScreen = true; // it seems setting fullScreen=false throws off fullWidth
  }

  return (
   <Dialog maxWidth='xl' scroll='body' fullWidth={true} {...inpProps}
    onClose={(e) => this.props.closeForm()} aria-labelledby={key} open={true}>
    <AppBar>
     <Toolbar>
      <IconButton edge="start" color="inherit" onClick={(e) => this.props.closeForm()} aria-label="close">
       <CloseIcon/>
      </IconButton>
      <Typography variant="h6">
       {(form.api && form.api.instructions) ? 'Close' : 'Loading form...'}
      </Typography>
     </Toolbar>
    </AppBar>
    <DialogContent style={{paddingTop:20, minHeight:300}}>
     <FormLoader />
    </DialogContent>
   </Dialog>
  );
 }
}

DialogForm.propTypes = {
 onClose: PropTypes.func,
 open: PropTypes.bool,
};

export default DialogForm;
