import React from 'react';
import PropTypes from 'prop-types';
import {addAndCheckout, openWallet} from "../../redux/walletReducer";
import Avatar from "@material-ui/core/Avatar";
import AddedToCart from "../prompts/AddedToCart";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const StyledButton = withStyles((theme) => ({
    root: {
      paddingLeft:10,
      paddingRight:10
    },
    iconSizeMedium: {
        backgroundColor:theme.palette.secondary.dark,
        borderRadius:4,
        marginRight:8,
        '& img': {
            padding:4,
            objectFit: 'contain',
            backgroundSize:'contain'
        }
    }
}), {withTheme:true})(Button);

class AddToCartButton extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            showPrompt:false
        }
        this.onContinue = this.onContinue.bind(this);
        this.onCheckout = this.onCheckout.bind(this);
    }

    onContinue() {
        this.setState({showPrompt:false});
    }

    onCheckout() {
        this.setState({showPrompt:false});
        this.props.dispatch(openWallet(true));
    }

    onOrder() {
        this.props.dispatch(addAndCheckout(this.props.cartItem.toJson()));
        this.setState({showPrompt:true});
    }

    render() {
        const toPass = {};
        if (this.props.cartItem.variation.get('field_media', 'target_bundle') === 'image') {
            toPass.startIcon = <Avatar
                variant={'circular'}
                src={this.props.cartItem.variation.get('field_media', 'url')} />;
        }

        let btn = <StyledButton elevation={2} onClick={() => this.onOrder()}
                          variant={'outlined'} {...toPass}>
                    {this.props.cartItem.getVariationTitle()}
                </StyledButton>

        if (this.state.showPrompt === true) {
            return <React.Fragment>
                {btn}
                <AddedToCart cartItem={this.props.cartItem}
                             onContinue={this.onContinue} onCheckout={this.onCheckout} />
            </React.Fragment>
        }

        return btn;

    }
}

AddToCartButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    cartItem: PropTypes.object.isRequired
};

export default AddToCartButton;
