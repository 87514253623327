import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Grammer from "../../Util/Grammer";
import featuresSchema from '../../apps/tam/features-schema.json';
import {pStyles} from "../../Util/ThemeUtils";
import {connect} from "react-redux";
import {setFeatures} from "../../redux/authReducer";
import FeatureItem from "./FeatureItem";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";

class TamFeaturesPrice extends Component {

    constructor(p) {
        super(p);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.game_type) {
            this.setFeaturesForm(this.props.game_type);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.game_type !== this.props.game_type) {
            this.setFeaturesForm(this.props.game_type)
        }
    }

    setFeaturesForm(gtype) {
        let features = {};
        Object.entries(featuresSchema).forEach((arr, i) => {
            let key = arr[0];
            let feat = arr[1];
            if (feat.types && feat.types[gtype]) {
                let opt = feat.options.find(o => o.value === feat.types[gtype]);
                if (opt) {
                    features[key] = opt.value;
                }
            }
        })
        this.props.dispatch(setFeatures(features));
    }

    toggleSection(section) {
        if (this.state[section] === true) {
            this.setState({[section]:false})
        } else {
            this.setState({[section]:true})
        }
    }

    render() {
        const {classes} = this.props;

        const reGrouped = {}
        Object.entries(featuresSchema).forEach((arr, i) => {
            let section = arr[1].section || arr[0].split(':')[0];
            if (typeof reGrouped[section] === 'undefined') reGrouped[section] = [];
            reGrouped[section].push(
                    <FeatureItem key={`feattools-${section}-${arr[0]}`}
                                 feature={arr[1]} feature_id={arr[0]} gFeatures={this.props.gFeatures} />)

        })

        return (
            <Box className={classes.paperBg} >
                {Object.entries(reGrouped).map((gr, i) => <Accordion key={`groupfeat-${gr[0]}-acc`}
                               style={{width:'100%', padding:0, margin:0}}
                               elevation={2}
                               expanded={this.state[gr[0]] === true}
                               onChange={() => this.toggleSection(gr[0])} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={gr[0]+'-ftcontent'}
                            id={gr[0] + '-ftheader'}
                            style={{color:this.props.theme.palette.secondary.main, textTransform:'capitalize'}}
                        >
                            {Grammer.g(gr[0], 's')}
                        </AccordionSummary>
                        {this.state[gr[0]] === true &&
                            <AccordionDetails className={classes.altBlocks} style={{padding:3, flexDirection:'column'}}>
                                {gr[1]}
                            </AccordionDetails>
                        }
                    </Accordion>
                )}
                <Grid container justifyContent={'space-between'} style={{marginTop:20, fontSize:11, textAlign:'center', fontWeight:600}}>
                    <Grid item xs={12}>
                        Email sales@ trackauthoritymusic.com for any special requests
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gFeatures: state.auth.gFeatures
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pStyles, {withTheme:true})(TamFeaturesPrice));
