import React, {Component} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {findAndPlay} from "../../redux/playerReducer";
import {loadForm} from "../../redux/formsReducer";
import {withStyles} from "@material-ui/styles";
import {pStyles} from "../../Util/ThemeUtils";
import {Link} from "react-router-dom";
import {withSnackbar} from "notistack";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";

class PlaylistCardHeader extends Component {

    loadForm(e, url) {
        e.preventDefault();
        this.props.dispatch(loadForm(url, 'drawer'));
    }

    listenToPlaylist(e, gid, pid) {
        e.preventDefault();
        this.props.dispatch(findAndPlay(null, pid, gid, null));
    }


    render() {
        const {productj, classes} = this.props;
        if (!productj.get('field_playlist_music', 'target_id')) return false;

        const pid = this.props.productj.get('field_playlist_music', 'target_id');
        let gid = this.props.productj.get('field_playlist_music', 'gid')
        if (!gid) gid = 0; // this.props.productj.get('stores', 'gid'); // doesnt work because linked playlist can be from another group's store, often FantasyTrackBall

        return <List
            dense={true}
            className={classes.playlistCardHeader}
            aria-label='view product background playlist'
        >
            <ListItem
            >
                {productj.get('field_playlist_music', 'url') &&
                    <ListItemAvatar><img width={48} src={productj.get('field_playlist_music', 'url')}/></ListItemAvatar>}

                <ListItemText primary={'Gametime Playlist'}
                              secondary={productj.get('field_playlist_music', 'target_label')}/>

                <ListItemSecondaryAction>
                    {gid > 0 && <IconButton edge="end" aria-label="add" component={Link} style={{marginRight: 15}}
                                            onClick={(e) => this.loadForm(e, `/forms/group/${gid}/playlists/${pid}/tracks/add`)}
                                            to={`/forms/group/${gid}/playlists/${pid}/tracks/add`}>
                        <PlaylistAdd/>
                    </IconButton>}
                    <IconButton edge="end" aria-label="play" onClick={(e) => this.listenToPlaylist(e, gid, pid)}>
                        <PlayIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>

    }
}

export default withStyles(pStyles, {withTheme: true})(withSnackbar(PlaylistCardHeader));
