import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import {playTrack} from '../../redux/playerReducer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drupal2Json from '../../Util/Drupal2Json';
import IconButton from '@material-ui/core/IconButton';
import PaginationBlock from '../../Components/PaginationBlock';
import ProgressLoading from '../../Components/ProgressLoading';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import moment from 'moment';

import {Link} from 'react-router-dom';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as PlayYtIcon} from '../../images/youtube.svg';

//import {ReactComponent as PlaceChips} from '../../images/PlaceChips.svg';


class BetsGrid extends Component {

 handleTileClick(e, track) {
  if (e.target && e.target.nodeName.toUpperCase() === 'IMG') {
   this.props.dispatch(playTrack(track));
   return false;
  }
 }

 render() {
  if (this.props.apiData.metadata.url.indexOf(this.props.location.pathname) === -1) return <ProgressLoading />;

  const {classes} = this.props;
  const body = [];

  for(var f in this.props.apiData.data) {
   const item = this.props.apiData.data[f];
   const node = new Drupal2Json(item.track.node);

   body.push(<Grid key={'user-'+body.length}
    container
    direction='row'
    justifyContent="center"
    alignContent="space-between"
    alignItems="center" className={classes.row} >
    <Grid item xs={6} >
     <GridListTile style={{height:160, maxWidth:300}} component='div' onClick={e => this.handleTileClick(e, item.track)} >
      <img src={node.get('field_cover', 'url')} className={classes.carouselImg} alt='track cover'  />
      <GridListTileBar
       title={node.get('title')}
       subtitle={node.get('field_artists')}
       classes={{
        root: classes.titleBar
       }}
       actionIcon={
        <div className={classes.actionRow}>
         {node.findPropByVal('field_media', 'youtube', 'target_bundle') === false ? '' :
          <IconButton aria-label="Youtube" onClick={(e) => this.props.dispatch(playTrack(item.track, 'youtube'))} >
           <SvgIcon component={PlayYtIcon} color="primary" />
          </IconButton>}
         {node.findPropByVal('field_media', 'audio', 'target_bundle') === false ? '' :
          <IconButton aria-label="MP3" onClick={(e) => this.props.dispatch(playTrack(item.track, 'audio'))} >
           <PlayIcon color="primary" />
          </IconButton>}
         {node.findPropByVal('field_media', 'video', 'target_bundle') === false ? '' :
          <IconButton aria-label="Video" onClick={(e) => this.props.dispatch(playTrack(item.track, 'video'))} >
           <OndemandVideo color="primary" />
          </IconButton>}
        </div>
       }
      />
     </GridListTile>
     <div>on <Link to={"/group/" + item.track.gid[0].target_id + '/playlists/' + item.track.field_playlist_gc[0].target_id} ><em>{item.track.field_playlist_gc[0].target_label}</em></Link></div>
    </Grid>
    <Grid item xs={6} style={{paddingLeft:5}}>
     <h3 className={classes.line}>
      <sup>{item.currency === 'USD' ? '$' : '₮'}</sup>
      {item.net_amount}
     </h3>
     <p className={classes.line}><small>{moment(item.created * 1000).format('MMM Do \'YY, h:mma')}</small></p>
    </Grid>
   </Grid>
   );
  }
  return (
   <Paper className={classes.root}>
    {this.props.apiData.metadata.total_items > this.props.apiData.data.length ?
     <PaginationBlock meta={this.props.apiData.metadata} dispatch={this.props.dispatch} />
     :
     <Toolbar>
      <Typography variant="h6" display="inline" gutterBottom color='secondary'>
       {this.props.apiData.metadata.page_title}
      </Typography>
      <span style={{flexGrow:1}}></span>
      <Link to={"/wallet/activity"} ><small>View Transaction History</small></Link>
     </Toolbar>
    }

    <div className={classes.table}>
     {body}
    </div>
   </Paper>
  );
 }

}

const styles = theme => ({
 root: {
  marginTop: theme.spacing(3),
  marginLeft:theme.spacing(1),
  marginRight:theme.spacing(1),
  width:'100%'
 },
 row : {
  padding:theme.spacing(1),
  marginBottom:theme.spacing(1),
  borderBottom:'1px solid ' + theme.palette.primary.main
 },
 line : {
  margin:'0 0 2px 0'
 },
 titleBar : {
  paddingRight:5
 }
});

export default withRouter(withStyles(styles)(BetsGrid));
