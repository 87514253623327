import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import API from '../../Util/API';
import SanitizedHTML from 'react-sanitized-html';
import Config from '../../Config';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {getIdbySegment} from "../../redux/authActions";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";

class DraftOrderContract extends Component {
    constructor(props) {
        super(props);
        this.state = {expanded: false, contract: ''};
        this.applyListeners = this.applyListeners.bind(this);
        this.noticeParams = {variant:'error', persist:true, action: key => (
                <IconButton variant="outline-light"
                            onClick={() => { this.props.closeSnackbar(key); }}
                >
                    <Close />
                </IconButton>
            ) }
    }

    componentDidMount() {
        this.reloadContract();
        window.addEventListener('click', this.applyListeners, false);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.applyListeners, false);
    }

    reloadContract() {
        API.Get(`/wallet/contract/group/${this.props.cartItem.get('stores', 'gid')}/variation/${this.props.cartItem.id()}`)
            .then((res) => {
                if (typeof res.data === 'object' && res.data.error) {
                    this.setState({contract: res.data.error});
                } else {
                    this.setState({contract: res.data.html}, this.applyListeners);
                }
            });
    }

    applyListeners(evt) {
        if (evt && evt.target.getAttribute('data-toggle') === 'collapse') {
            this.handleHtmlButton(evt.target);
        }
    }

    handleHtmlButton(btn) {
        let targets = document.querySelectorAll(btn.getAttribute('data-target'));
        targets.forEach(tar => {
            tar.classList.toggle("show");
        });
    }

    initTracking() {
        const tdata = getIdbySegment(document.location.pathname);
        tdata.verb = 'waitlist-failed';
        tdata.product_id = this.props.cartItem.getProductId();
        tdata.variation_id = this.props.cartItem.id();
        // tdata.uid = this.props.meId;
        return tdata;
    }

    onAgree(e) {
        e.preventDefault();

        const tdata = this.initTracking();
        return API.Post(`forms/group/${this.props.cartItem.get('stores', 'gid')}/variation/${this.props.cartItem.id()}/waitlist`).then((res) => {

            var msg = API.checkError(res.data);
            if (msg.length > 0) {
                this.props.onClose(); // probably needs to "Leave Team"
                this.props.enqueueSnackbar(<SanitizedHTML html={msg} />, this.noticeParams);
            } else {
                tdata.verb = 'waitlist';
                this.noticeParams.variant = 'info';
                this.props.enqueueSnackbar(<SanitizedHTML html={res.data.success} />, this.noticeParams);
                this.props.onAgree();
            }
            window.logUse.logEvent('order-draft', tdata);
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            this.props.enqueueSnackbar(<SanitizedHTML html={msg} />, this.noticeParams);
            console.log('token check error: ', msg);
            window.logUse.logEvent('order-draft-failed', tdata);
            return err;
        });
    }

    render() {
        const {cartItem, classes} = this.props;

        return (
            <Dialog maxWidth='xl' fullWidth={true} open={true} aria-label={'Contract Dialog'}>

                <div style={{padding: '6px', width: '100%'}}>
                    <Typography variant='overline'>Sign-up</Typography>
                    <Typography variant='subtitle2'>{cartItem.getTitle()}</Typography>

                    {this.state.contract === '' ?
                        <Typography variant='h2' style={{textAlign: 'center'}}>Loading Contract...</Typography>
                        : <SanitizedHTML allowedTags={Config.allowedTags.concat('a')}
                                         allowedAttributes={Config.allowedAttributes} html={this.state.contract}/>
                    }
                </div>
                <div style={{padding: '4px 10px 8px 10px', width: '100%'}}>
                    <Button onClick={e => this.onAgree(e)}
                            style={{margin: 5}}
                            aria-label='I Agree'
                            variant="contained" color="primary" >
                        I Agree
                    </Button>

                    <Button aria-label='Cancel' style={{margin: 5}} onClick={e => this.props.onClose()}
                            variant="outlined"
                            color="secondary">Cancel</Button>
                </div>

            </Dialog>
        );
    }
}


const styles = () => ({
    twoLineButton: {
        lineHeight: 1,
        '& > span': {
            textAlign: 'left',
            lineHeight: 1
        },
        '& .caption': {
            fontSize: 11,
            display: 'block',
            margin: 0,
            lineHeight: 1
        }
    }
});

DraftOrderContract.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    cartItem: PropTypes.object.isRequired
};

export default withStyles(styles)(withSnackbar(DraftOrderContract));
