import React, {Component} from "react";
import {changeGroup, editGroupMode, toggleMenu} from "../../redux/authReducer";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";

class BuilderButton extends Component {

    toggleEditGroup(gjson) {
        if (!this.props.formDrawerOpen) {
            this.props.dispatch(changeGroup(gjson.get('id'), [gjson.json]));
            this.props.dispatch(editGroupMode(true));
        } else {
            this.props.dispatch(toggleMenu(false, 'editMenuOpen'))
        }
    }

    render() {
        const {gjson, layout} = this.props;

        if (layout === 'icon') {
            return <IconButton
                color="inherit"
                aria-label="Open Group Builder"
                onClick={(e) => this.toggleEditGroup(gjson)}
            >
                <SettingsIcon/>
            </IconButton>
        }

        return  <Button
            style={{margin:'0 5%'}}
            aria-label="Open Group Builder"
            variant={'contained'}
            color={'secondary'}
            onClick={(e) => this.toggleEditGroup(gjson)}
            startIcon={<SettingsIcon color={'primary'} />}
        >
            Open Builder
        </Button>
    }
}

const mapStateToProps = (state) => {
    if (!state.auth.me || !state.auth.me.groups || !state.auth.me.profile) return false;
    const st = {
        editMenuOpen: state.auth.editMenuOpen,
        formDrawerOpen: state.auth.formDrawerOpen,
    };

    return st;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderButton);
