import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grammer from "../Util/Grammer";
import {changeCurrency} from "../redux/walletReducer";

class PriceCurrency extends Component {

    constructor(props) {
        super(props);
    }

    getSymbol(currency) {
        const CURRENCY_SYMBOLS = {
            'USD':'$',
            'MXN':'$',
            'TAC':Grammer.tac('₮')
        }
        return CURRENCY_SYMBOLS[currency];
    }

    render() {
        let num = changeCurrency(this.props.num, this.props.currency);
        if (!num) {
            num = 0; // free
        } else if (typeof num === 'number') {
            num = (num).toFixed(2)
        } else {
            num = (parseFloat(num, 2)).toFixed(2)
        }
        if (this.props.showCode && this.props.showSymbol) {
            return <React.Fragment><sup>{this.getSymbol(this.props.currency)}</sup>{num} {this.props.currency.toUpperCase()}</React.Fragment>
        }
        if (this.props.showCode) {
            return <React.Fragment>{num} {this.props.currency.toUpperCase()}</React.Fragment>
        }
        if (this.props.showSymbol) {
            return <React.Fragment><sup>{this.getSymbol(this.props.currency)}</sup>{num}</React.Fragment>
        }
        return num;
    }
}

PriceCurrency.defaultProps = {
    showSymbol:true,
    showCode:false
}

PriceCurrency.propTypes = {
    currency : PropTypes.string.isRequired,
    num: PropTypes.number.isRequired,
    showSymbol: PropTypes.bool,
    showCode: PropTypes.bool,
};

export default PriceCurrency;
