import RatingStar from './RatingStar';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = (state, ownProps) => {
 const newProps = {...ownProps};
 newProps.isLoggedIn = (state.auth.me && state.auth.me.profile) ? state.auth.me.profile.uid[0].value : false;
 newProps.verified_email = (state.auth.me && state.auth.me.profile && typeof state.auth.me.profile.roles.verified_email === 'number') ? true : false;

 const pid = newProps.tjson.get('field_playlist_gc', 'target_id');
 if (state.player.tracklist[pid] && state.player.tracklist[pid].metadata) {
  newProps.voteType = state.player.tracklist[pid].metadata.vote_type; // whatever the list was sorted by
  newProps.metadata = state.player.tracklist[pid].metadata;
 } else {
  newProps.voteType = newProps.tjson.get('field_vote_type', 'target_id');
 }
 return newProps;
};

RatingStar.defaultProps = {
 color:'primary'
};

RatingStar.propTypes = {
 color:PropTypes.string,
 tjson : PropTypes.object.isRequired,
 voteType : PropTypes.any.isRequired, // false or string
};

export default connect(
 mapStateToProps,
 null
)(RatingStar);
