import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import API from '../../Util/API';
import SanitizedHTML from 'react-sanitized-html';
import Grid from '@material-ui/core/Grid';
import {addWagerToCart, requestDeposit} from '../../redux/walletReducer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Config from '../../Config';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as PlaceChips} from '../../images/PlaceChips.svg';
import Typography from "@material-ui/core/Typography";
import Grammer from "../../Util/Grammer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Drupal2Json from "../../Util/Drupal2Json";
import {_showNotice} from "../../redux/formsReducer";
import {getIdbySegment} from "../../redux/authActions";

class ConfirmWager extends Component {
    constructor(props) {
        super(props);
        this.state = {expanded: false, currency: 'USD', contract: '', delta: undefined, playlist: null};
        this.applyListeners = this.applyListeners.bind(this);
    }

    componentDidMount() {
        this.reloadContract();
        window.addEventListener('click', this.applyListeners, false);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.applyListeners, false);
    }

    handleDelta(e) {
        this.setState({delta: e.currentTarget.value});
    }

    reloadContract() {
        API.Get(`/wallet/contract/tracks/${this.props.track.id[0].value}?currency=${this.state.currency}`)
            .then((res) => {
                if (typeof res.data === 'object' && res.data.error) {
                    this.setState({contract: res.data.error});
                } else {
                    this.setState({contract: res.data.html, playlist: res.data.playlist}, this.applyListeners);
                }
            });
    }

    applyListeners(evt) {
        if (evt && evt.target.getAttribute('data-toggle') === 'collapse') {
            this.handleHtmlButton(evt.target);
        }
    }

    handleHtmlButton(btn) {
        let targets = document.querySelectorAll(btn.getAttribute('data-target'));
        targets.forEach(tar => {
            tar.classList.toggle("show");
        });
    }

    changeContractCurrency(e) {
        this.setState({'currency': e.target.value, 'contract': ''}, this.reloadContract);
    }

    initTracking() {
        const tdata = getIdbySegment(document.location.pathname);
        tdata.verb = 'paid-failed';
        tdata.gid = this.props.track.gid[0].target_id;
        tdata.pid = this.props.track.field_playlist_gc[0].target_id;
        tdata.tid = this.props.track.id[0].value;
        tdata.value = this.props.playlist.field_buy_in[0].value;
        tdata.currency = this.state.currency;
        tdata.uid = this.props.meId;
        return tdata;
    }

    submitWager(e) {
        e.preventDefault();
        if (!this.props.paymethodId) {
            this.props.dispatch(addWagerToCart(this.props.track, this.state.playlist, this.state.currency, this.state.delta));
            return this.props.dispatch(requestDeposit(this.state.playlist.field_buy_in[0].value, 'Please add a payment method.'));
        } else {
            const tdata = this.initTracking();

            const amt = this.props.playlist.field_buy_in[0].value;

            if (this.props.balance.tac.net < amt) {
                return this.props.dispatch(_showNotice(`You need ${(amt - this.props.balance.tac.net).toFixed(2)} more. You can pay in USD or earn ${Grammer.tac('TAC')} by rating and curating music in any playlist`, 'error'));
            }

            const payload = {currencies: {[this.state.currency]: amt}, gid:tdata.gid, delta:this.state.delta};
            payload.cart_items = [{vid:57, quantity:1, tid:this.props.track.id[0].value}];

            return API.Post("/forms/wallet/checkout", payload).then((res) => {
                var msg = API.checkError(res.data);
                if (msg.length > 0) {
                    window.logUse.logEvent('wager', tdata);
                } else {
                    if (this.state.currency === 'TAC') {
                        this.props.dispatch(_showNotice('₮' + amt + ' (Credibility) has been traded for your bet. Share the playlist to help raise the minimum pool.', 'warning'));
                    } else {
                        this.props.dispatch(_showNotice('$' + amt + ' paid. Share the playlist to help raise the minimum pool.', 'warning'));
                    }
                    tdata.verb = 'paid';
                    this.props.onClose();
                }
                window.logUse.logEvent('wager', tdata);
            }).catch((err) => {
                var msg = API.getErrorMsg(err);
                console.log('token check error: ', msg);
                window.logUse.logEvent('wager', tdata);
                return err;
            });
        }
    }

    render() {
        const json = new Drupal2Json(this.props.track);
        const judger = window.store.getState().auth.gFeatures['playlists:field_judge'];
        const label = judger === 'streamgains-yt' ? 'Wager' : window.store.getState().auth.gFeatures['playlists:cta'];

        const pjson = this.state.playlist ? new Drupal2Json(this.state.playlist) : false;

        return (
            <Dialog maxWidth='xl' fullWidth={true} open={true} aria-label={'Contract Dialog'}>
                <Grid container justifyContent='space-between' alignItems='center' style={{padding: 5}} wrap='nowrap'>
                    <Grid item>
                        <Typography variant='body1'
                                    style={{textDecoration: 'underline'}}>{label} on {json.get('label')} from {json.get('field_playlist_gc', 'target_label')}</Typography>
                    </Grid>
                    <Grid item>
                        <Select name='currency' aria-label='select a currency'
                                value={this.state.currency}
                                onChange={(e) => this.changeContractCurrency(e)}
                        >
                            <MenuItem value='USD'>Use USD ($)</MenuItem>
                            <MenuItem value='TAC'>Use {Grammer.tac('TAC')} (₮)</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <div style={{padding: '4px', width: '100%'}}>
                    {this.state.contract === '' ?
                        <Typography variant='h2' style={{textAlign: 'center'}}>Loading Contract...</Typography>
                        : <SanitizedHTML allowedTags={Config.allowedTags.concat('a')}
                                         allowedAttributes={Config.allowedAttributes} html={this.state.contract}/>
                    }
                </div>


                {pjson && pjson.getTimestamp('field_awarding_starts') < new Date().getTime() ?
                    <div style={{padding: '4px 10px 8px 10px', width: '100%'}}>
                        <Button aria-label='Cancel' style={{margin: 5}} onClick={e => this.props.onClose()}
                                variant="contained"
                                color="primary">Close</Button>

                        The window to place wagers has closed. Awards will be announced soon.
                    </div>

                    :

                    <React.Fragment>
                        {judger === 'streamgains-yt' &&
                            <div style={{margin: 'auto 10px auto 10px'}}>
                                <TextField type={'number'} variant={'filled'}
                                    value={this.state.delta}
                                    label={"Delta Prediction"}
                                    InputProps={{
                                        autoComplete: 'off',
                                        endAdornment: <InputAdornment
                                            position="end">%</InputAdornment>,
                                    }}
                                    size={'small'}
                                    fullWidth={true}
                                    helperText={"By what percentage will this track change in engagement"}
                                    onChange={e => this.handleDelta(e)}/>
                            </div>
                        }
                        <div style={{padding: '4px 10px 8px 10px', width: '100%'}}>
                            <Button onClick={e => this.submitWager(e)}
                                    style={{margin: 5}}
                                    aria-label='I Agree'
                                    variant="contained" color="primary"
                                    disabled={this.state.contract === '' || (judger === 'streamgains-yt' && this.state.delta === 0)}
                                    startIcon={<SvgIcon component={PlaceChips} color='secondary' viewBox='0 0 24 24'/>}>
                                Complete {label}
                            </Button>


                            <Button aria-label='Cancel' style={{margin: 5}} onClick={e => this.props.onClose()}
                                    variant="outlined"
                                    color="secondary">Cancel</Button>
                        </div>
                    </React.Fragment>

                }
            </Dialog>
        );
    }
}

export default ConfirmWager;
