import React, {Component} from 'react';
import API from "../../Util/API";
import PickupMatchHeader from "../../Components/ProductEvents/PickupMatchHeader";
import ClipsLoader from "./ClipsLoader";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FootageBlock from "./FootageBlock";
import PaginationBlock from "../../Components/PaginationBlock";
import HighlightBlock from "./HighlightBlock";
import {Route} from "react-router";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";

class HighlightsLoader extends Component {

    constructor(p) {
        super(p);
        this.state = {footage: false, allfootage:false, product:false}
    }
    componentDidMount() {
        this.refresh()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.fid !== prevProps.match.params.fid) {
            this.refresh();
        }
    }

    refresh() {
        let apiurl = `/group/${this.props.match.params.gid}`;
        if (this.props.match.params.pid) {
            apiurl += `/replays/${this.props.match.params.pid}/highlights`
        } else if (this.props.match.params.rid) {
            apiurl += `/rewards/${this.props.match.params.rid}/highlights`
            if (this.props.match.params.fid) {
                apiurl += `/footage/${this.props.match.params.fid}/clips`
            }
        }

        // <Route exact path='/group/:gid/rewards/:rid/highlights' component={HighlightsLoader} />
        // <Route exact path='/group/:gid/replays/:pid/highlights/:hid' component={HighlightsLoader}/>
        // <Route exact path='/group/:gid/rewards/:rid/footage/:fid/clips/:hid' component={HighlightsLoader}

        if (this.props.match.params.hid) {
            apiurl += `/${this.props.match.params.hid}`
            API.Get(apiurl).then((res) => {
                this.setState({footage: res.data, allfootage:false});
            }).catch((err) => {
                alert(API.getErrorMsg(err));
                this.setState({loading: false});
            });
        } else {
            API.Get(apiurl).then((res) => {
                this.setState({allfootage: res.data, footage:false});
            }).catch((err) => {
                alert(API.getErrorMsg(err));
                this.setState({loading: false});
            });
        }
    }

    render() {
        if (this.state.footage) return <HighlightBlock clip={this.state.footage} me={this.props.me} expanded={true} />;
        if (this.state.allfootage) return <div>
            <Grid container justifyContent={'space-around'} spacing={1}>
                {this.state.allfootage.data.map((footage, i) => <Grid xs={12} sm={6} md={4} item key={`highlights${i}`} ><HighlightBlock clip={footage} me={this.props.me} expanded={false}/></Grid>)}
            </Grid>
            {this.state.allfootage.metadata.total_items > this.state.allfootage.data.length
                &&
                <PaginationBlock meta={this.state.allfootage.metadata}/>
            }</div>
        return 'loading...'
    }
}

HighlightsLoader.propTypes = {
    match: PropTypes.object.isRequired,
};


const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HighlightsLoader));
