import {connect} from 'react-redux';

import EntityView from './EntityView';

const mapStateToProps = (state) => {
 var newState = {
  me:state.auth.me,
  editMenuOpen: state.auth.editMenuOpen,
  formDrawerOpen: state.auth.formDrawerOpen,
  curGroup: state.auth.curGroup
 };
 newState.entity = state.entity;
 newState.lists = state.lists;
 newState.location = state.router.location;
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(EntityView);
