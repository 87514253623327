import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SanitizedHTML from 'react-sanitized-html';
import Typography from "@material-ui/core/Typography";
import Config from '../../Config';
import {loadFaq} from "../../redux/helpReducer";

class FaqItem extends Component {

 constructor(props) {
  super(props);
  this.applyListeners = this.applyListeners.bind(this);
 }

 componentDidMount() {
  window.addEventListener('click', this.applyListeners, false);
 }

 componentWillUnmount() {
  window.removeEventListener('click', this.applyListeners, false);
 }

 applyListeners(evt) {
  if (evt && evt.target.getAttribute('data-toggle') === 'collapse') {
   this.handleHtmlButton(evt.target);
  }
  if (this.props.dispatch && evt && evt.target.getAttribute('href')) {
   this.handleReduxLink(evt);
  }
 }

 handleReduxLink(evt) {
  let href = evt.target.getAttribute('href');
  if (href.indexOf('/faqs/') === 0) {
   evt.preventDefault();
   let nid = href.split('/')[2];
   this.props.dispatch(loadFaq(nid, 'dialog'));
  }
 }

 handleHtmlButton(btn) {
  let targets = document.querySelectorAll(btn.getAttribute('data-target'));
  targets.forEach(tar => {
   tar.classList.toggle("show");
  });
 }

 render() {
  const { faq } = this.props;
  return (<div style={{padding:'2%'}}>
   {faq.permissions ? <SanitizedHTML allowedTags={Config.allowedTags} html={faq.permissions} /> : '' }
   {faq.topicId === 803 ? <Typography variant='subtitle2' style={{marginBottom:0,  marginTop:15, fontStyle:'italic', textDecoration:'underline'}}>LEGAL DEFINITION</Typography> : '' }
   <SanitizedHTML allowedTags={Config.allowedTags.concat('a', 'img')} allowedAttributes={Config.allowedAttributes} html={faq.definition} />
   {faq.ctas && faq.ctas.length > 0 ?
    <div>
     <Typography variant='subtitle2' style={{marginBottom:2, marginTop:15, fontStyle:'italic', textDecoration:'underline'}}>UI ELEMENTS</Typography>
     <div>
      {faq.ctas.map( o => {
       return o.target_id ? <Button key={o.target_id} style={{margin:'0 5px 5px 0'}} disabled variant='outlined'>{o.target_label}</Button> : '';
      })}
     </div>
    </div>
    : ''
   }
  </div>);
 }
}

const styles = theme => ({
 tabPanel: {
  color:theme.palette.secondary.main,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  width:'100%',
 },
 detailsRoot : {
  paddingTop:0
 },
 paperBg : {
  backgroundColor:theme.palette.background.paper,
 },
});


export default withStyles(styles)(FaqItem);
