import MediaUpload from './MediaUpload';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
 const newState = {
  forms :state.forms,
  profile:state.auth.me.profile,
  balance: state.wallet.balance
 };
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};


export default connect(
 mapStateToProps,
 mapDispatchToProps
)(MediaUpload);
