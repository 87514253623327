import {connect} from 'react-redux';
import ManageFunds from './ManageFunds';

const mapStateToProps = (state) => {
 var newState = {me:state.auth.me};
 newState.wallet = state.wallet;
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(ManageFunds);
