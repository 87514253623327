import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {withRouter} from 'react-router';
import API from '../../Util/API';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import CompareArrows from '@material-ui/icons/CompareArrows';
import SwitchUI from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grammer from "../../Util/Grammer";
import {MenuItem} from "@material-ui/core";
import MyGeoLocation from "../../Util/MyGeoLocation";
import {getParam} from "../../Util/WindowUtils";
import FormHelperText from "@material-ui/core/FormHelperText";
import {withSnackbar} from "notistack";

const TYPEMAP = [
    'rewards',
    'playlists',
    'tracks',
    'members'
];

class SearchField extends Component {

    constructor(props) {
        super(props);
        this.state = {q: this.props.query, loading: false, error: false,
            sortby: 'field_geolocation', mylocation:{triggered:-1},
            getrewards:false, gettracks:true, getplaylists:false, getmembers:false
        };
        let selected = getParam('types[]', document.location.search);
        console.log('fitler search by types', selected);
        if (selected) {
            TYPEMAP.forEach(t => {
                this.state[`get${t}`] = (selected === t || selected.indexOf(t) > -1)
            })
        }
        this.q = this.props.query;
    }

    componentDidMount() {
        this.runQuery(this.q);
    }

    testQuery(q) {
        if (q !== this.q) {
            setTimeout(() => {
                if (q !== this.q) {
                    this.q = q;
                    this.runQuery(q)
                }
            }, 500)
        }
    }

    runQuery(q) {
        let searchUrl = `/group/${this.props.gid}/search?q=${q}`
        if (this.state.sortby !== 'created') {
            if (!this.state.mylocation['lat']) {
                return console.log("waiting for location", this.state.mylocation);
            }
            searchUrl += `&sort=${this.state.sortby}&latlng=${this.state.mylocation.lat},${this.state.mylocation.lng}`
        }
        let types = [];
        TYPEMAP.forEach(t => {
            if (this.state[`get${t}`] === true) {
                types.push(`types[]=${t}`);
            }
        })
        if (types.length > 0 && types.length < 4) {
            searchUrl += '&' + types.join('&')
        }

        var perpage = getParam('perpage', this.props.location.search, null);
        if (perpage) {
            searchUrl += '&perpage=' + perpage
        }

        API.Get(searchUrl).then((res) => {
            this.props.onResults(res.data);
            if (this.state.error !== false) {
                this.setState({error: false});
            }
        }).catch((err) => {
            alert(API.getErrorMsg(err));
            this.setState({loading: false, error: err});
        });
    }

    handleTypeChange(e, t) {
        this.setState({[`get${t}`]:!this.state[`get${t}`]}, () => this.runQuery(this.state.q));
    }

    handleSort(e) {
        const st = {sortby: e.target.value, mylocation: {...this.state.mylocation}}
        if (e.target.value === 'field_geolocation') {
            st.mylocation.triggered = new Date().getTime();
        }
        this.setState(st);
    }

    handleGeoLocation(res) {
        if (typeof res === 'string') {
            this.props.enqueueSnackbar(res, {variant:'error'});
            this.setState({sortby:'created'}, () => this.runQuery(this.state.q));
        } else {
            this.setState({mylocation:res}, () => this.runQuery(this.state.q));
        }
    }

    render() {
        return <Grid container justifyContent={'space-between'} alignItems={'flex-start'} alignContent={'flex-start'} spacing={1}>
            <Grid item style={{flexGrow:1}}>
                <TextField
                    fullWidth
                    defaultValue={this.props.query}
                    variant="outlined"
                    placeholder={this.props.placeholder}
                    onChange={e => this.testQuery(e.currentTarget.value)}
                    InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                            <InputAdornment position="end">
                                {this.state.loading === false ? <CompareArrows/> : <CircularProgress/>}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={this.state.sortby}
                    variant="outlined"
                    select
                    onChange={e => this.handleSort(e)}
                >
                    <MenuItem value={'created'}>Created Date</MenuItem>
                    <MenuItem value={'field_geolocation'}><MyGeoLocation triggered={this.state.mylocation.triggered}
                                                                         onError={(res) => this.handleGeoLocation(res)}
                                                                         onLocated={(res) => this.handleGeoLocation(res)} >
                        <span>
                            By Distance
                        </span>
                    </MyGeoLocation></MenuItem>

                </TextField>
            </Grid>
            <Grid item container justifyContent={'space-between'} alignItems={'flex-start'} alignContent={'flex-start'} >
                {TYPEMAP.map(t => {
                    let label = '';
                    if (t === 'playlists') {
                        label = <span>{Grammer.g(t, 'p')} <FormHelperText>of {Grammer.g('Rewards', 'p')}</FormHelperText></span>
                    } else if (t === 'tracks') {
                        label = <span>{Grammer.g(t, 'p')} <FormHelperText>of {Grammer.g('Playlists', 'p')}</FormHelperText></span>
                    } else if (t === 'rewards') {
                        label = <span><FormHelperText>Upcoming</FormHelperText> {Grammer.g(t, 'p')} </span>
                    } else {
                        label = <span><FormHelperText>MVPs &amp; </FormHelperText> {Grammer.g(t, 'p')} </span>
                    }

                    return <FormControlLabel
                        key={`searchtype-${t}`}
                        control={
                            <SwitchUI
                                checked={this.state[`get${t}`]}
                                onChange={(e) => this.handleTypeChange(e, t)}
                            />
                        }
                        labelPlacement={'end'}
                        label={label}
                    />
                }
                )}
            </Grid>
        </Grid>
    }
}

SearchField.defaultProps = {
    query: '',
    placeholder: 'Search',

}

SearchField.propTypes = {
    onResults: PropTypes.func,
    placeholder: PropTypes.string,
    query: PropTypes.string,
    gid: PropTypes.number.isRequired
};


export default withRouter(withSnackbar(SearchField));
