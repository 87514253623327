import React, {Component} from 'react';
import PropTypes from "prop-types";
import API from "../../Util/API";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from '@material-ui/core/Chip';
import TextField from "@material-ui/core/TextField";


class ClipTypeSelector extends Component {


    constructor(props) {
        super(props);
        const selected = (props.selected) ? props.selected.map(s => s.target_id) : []
        this.state = {q: '', options: [], selected: selected};
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("cliptypes") && localStorage.getItem("cliptypes") !== '[]') {
            this.setState({options: JSON.parse(localStorage.getItem("cliptypes"))});
        } else {
            API.Get('/autocomplete/terms/cliptypes').then(res => {
                localStorage.setItem("cliptypes", JSON.stringify(res.data))
                this.setState({options: res.data});
            }).catch(err => {
                console.log(err);
                this.setState({options: []});
            });
        }
    }

    componentWillUnmount() {
        localStorage.removeItem("cliptypes")
    }

    handleChange(e) {
        const val = e.target.value;
        let obj = [val]
        if (this.props.multiple === true) { // keep array for compability
            obj = [...this.state.selected];
            var isSelected = this.state.selected.find((v, s) => v === val);
            if (!isSelected) obj.push(val);
            else {
                obj.filter((v, s) => {
                    if (v === val) {
                        obj.splice(s, 1);
                    }
                    return v;
                });
            }
        }
        this.setState({selected: obj});
        if (this.props.onChange) {
            this.props.onChange(obj);
        }
    }

    renderChips(selected) {

        return (
            <div style={{whiteSpace: 'pre-wrap'}}>
                {selected.map(i => {
                    var value = this.state.options.find((obj, s) => obj.target_id.toString() === i.toString());
                    if (!value) return null;
                    if (this.props.multiple === false) return value.target_label;

                    return (
                        <Chip key={i} label={value.target_label} className={this.props.classes.chip}/>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <TextField
                select
                variant={'outlined'}
                fullWidth={true}
                size={'small'}
                label={'Clip Types'}
                value={this.state.selected}
                onChange={this.handleChange}
                renderValue={(selected) => this.renderChips(selected)}
                inputProps={{
                    name: 'ClipTypeSelector',
                    id: 'ClipTypeSelector',
                }}
            >
                {this.state.options.map(value => (
                    <MenuItem value={value.target_id} key={value.target_id}>
                        {this.props.multiple === true && <Checkbox checked={this.state.selected.find(i => i === value.target_id)}/>}
                        {value.target_label}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
}

ClipTypeSelector.defaultProps = {
    selected: [],
    options: 'all',
    multiple: false,
};

ClipTypeSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    options: PropTypes.any.isRequired,
    multiple: PropTypes.bool,
};

export default ClipTypeSelector;
