import React from "react";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import ReactPlayer from 'react-player'
import Config from '../../Config';
import {Link} from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../../Util/API";
import AuthenticatedVideo from "./AuthenticatedVideo";
import {getParam} from "../../Util/WindowUtils";

class MediaItem extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            rendered: false
        };
    }

    renderDelay(isVisible) {
        if (isVisible === true) {
            this.setState({rendered: true});
        }
    }

    buildPlayerConfig(media) {
        const config = (this.props.poster) ? {attributes: {poster:  this.props.poster}} : {}
        if (media === 'youtube') return config;
        const tokens = API.getLocalTokens();
        config.file = {}
        if (tokens.access_token) {
            config.file.debug = true;
            config.file.xhrSetup = (xhr, url) => {
                xhr.open("GET", url, true);
                if (tokens.access_token) {
                    xhr.setRequestHeader(
                        "Authorization",
                        `Bearer ${tokens.access_token}`
                    );
                }
            }
            config.file.hlsOptions = {
                debug: true,
                attributes: {
                    controlsList: 'nodownload',
                },
                http: {
                    headers: {
                        Authorization: 'Bearer ' + tokens.access_token,
                    },
                },
                xhrSetup: (xhr, url) => {
                    xhr.open("GET", url, true);
                    if (tokens.access_token) {
                        xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${tokens.access_token}`
                        );
                    }
                }
            }
        }
        if (media === 'audio') {
            config.file.forceAudio = true;
        } else {
            config.file.forceVideo = true;
        }
        return config;

    }

    render() {
        const {media, linkTo, ...passAlong} = this.props;

        passAlong.config = this.buildPlayerConfig(media);

        if (media.thumbnail) {
            passAlong.light = media.thumbnail;
            passAlong.width = 212;
        }

        if (media.pathname) {
            if (!window.debugArray) window.debugArray = {'has':{}, 'missing':{}};
            window.debugArray['has'][media.pathname] = this.props.entityLink || 1;
        }
        if (media.missingfile) {
            if (!window.debugArray) window.debugArray = {'has':{}, 'missing':{}};
            window.debugArray['missing'][media.missingfile] = this.props.entityLink || 1;
        }

        if (media.target_bundle === 'image') {
            if (linkTo) {
                return <Link to={linkTo}>
                    <img src={media.url.indexOf("http") === 0 ? media.url : Config.api.cdn + media.url}
                         style={{width: '100%', textAlign: 'center'}} alt={media.target_label} {...passAlong} />
                </Link>
            }
            return <img src={media.url.indexOf("http") === 0 ? media.url : Config.api.cdn + media.url}
                        style={{width: '100%', textAlign: 'center'}} alt={media.target_label} {...passAlong} />
        } else if (media.target_bundle === 'video') {
            return <ReactPlayer url={Config.api.cdn + media.url} controls preload={'metadata'} width={'100%'} {...passAlong} />
        } else if (media.target_bundle === 'remote_video') {
            return <ReactPlayer url={media.url} controls width={'100%'} {...passAlong} />
        } else if (media.target_bundle === 'youtube') {
            return <ReactPlayer url={media.target_youtube} controls width={'100%'} {...passAlong} />
        } else if (media.target_bundle === 'document') {
            if (linkTo) {
                return <Link to={linkTo}>
                    <PdfIcon/>
                </Link>
            }
            // TODO: icons per doc target_bundle && doc viewer
            return <PdfIcon/>
        } else if (media.target_bundle === 'footage' || media.url.indexOf("/media/stream") > -1) {
            return <VisibilitySensor offset={{bottom:window.scrollY > 100 ? window.innerHeight/1.5 : 0}} partialVisibility='bottom' onChange={e => this.renderDelay(e)}>
                {({isVisible}) =>
                    this.state.rendered === true  ?
                        <AuthenticatedVideo start_time={getParam('start_time', linkTo)} poster={this.props.poster} apiurl={media.url}  />
                        : <CircularProgress style={{margin:'auto', display:'block'}}
                                            onClick={e => this.renderDelay(true)} />
                }
            </VisibilitySensor>
        }
        return null;
    }
}

export default MediaItem;
