import API from '../Util/API';
import {getIdbySegment} from './authActions';

import {updatePlayerTrack} from './playerReducer';
import {updateListItem} from './listDataReducer';

const ITEM_DATA_SUCCESS = 'entity:ITEM_DATA_SUCCESS';
const ITEM_DATA_FAILURE = 'entity:ITEM_DATA_FAILURE';
const ITEM_DATA_STARTED = 'entity:ITEM_DATA_STARTED';
const ITEM_DATA_UPDATE = 'ITEM_DATA_UPDATE';
// const WAGER_UPDATED = 'entity:WAGER_UPDATED';

export const entityDataSuccess = apiData => ({
 type: ITEM_DATA_SUCCESS,
 payload: {...apiData}
});

const entityDataStarted = (url) => ({
 type: ITEM_DATA_STARTED,
 url: url
});

const entityDataFailure = error => ({
 type: ITEM_DATA_FAILURE,
 error: error
});

export const updateEntity = (payload, field_name) => ({
 type: ITEM_DATA_UPDATE,
 payload,
 field_name
});

export const updateTrackRating = (obj, track) => {
 return (dispatch, getState) => {

  const state = getState();

  const newTrack = JSON.parse(JSON.stringify(track));
  const voteTypes = obj[obj.sort]; // selects groupindex or myindex
  for(let i=0; i < newTrack.field_vote_type.length; i++) {  // loop to preserve target_label
   if (voteTypes[i].target_id === newTrack.field_vote_type[i].target_id) {
    newTrack.field_vote_type[i] = Object.assign(newTrack.field_vote_type[i], voteTypes[i]);
   }
  }

  if (state.entity.apiData && state.entity.apiData.type[0].target_id === 'groups-group_node-tracks') {
   dispatch(entityDataSuccess(newTrack));
  }

  if (state.lists.apiData && state.lists.apiData.metadata.type === 'tracks') {
   dispatch(updateListItem(newTrack));
  }

  if (state.player.tracklist || state.player.curTrack) {
   dispatch(updatePlayerTrack(newTrack));
  }
 };

};

export const entityData = (url) => {
 return (dispatch, getState) => {

  const state = getState();
  if (state.entity.loading === true) return false;

  dispatch(entityDataStarted(url));

  API.Get(url).then((res) => {
   const msg = API.checkError(res.data);
   const tdata = getIdbySegment(url);
   tdata.bundle = url.split('/');
   tdata.bundle = tdata.bundle[tdata.bundle.length - 2];
   if (msg.length > 0) {
    tdata.verb = 'failed';
    dispatch(entityDataFailure(msg));
   } else {
    if (res.data.type[0].target_id === 'groups') {
      if (state.auth.formDrawerOpen > 0 && state.auth.curGroup > 0 && state.auth.me) {
        Object.assign(res.data, state.auth.me.groups[state.auth.curGroup])
      }
    }
    dispatch(entityDataSuccess(res.data));
    if (res.data.type[0].target_id === 'groups-group_node-tracks' || res.data.type[0].target_id === 'groups-group_node-clips') {
     dispatch(updatePlayerTrack(res.data));
     dispatch(updateListItem(res.data));
    }
    tdata.verb = 'view';
   }

   if (state.auth.me && state.auth.me.profile) {
    tdata.uid = state.auth.me.profile.uid[0].value;
   }
   window.logUse.logEvent('load_entity', tdata);


  }).catch((err) => {
   var msg = API.getErrorMsg(err);
   dispatch(entityDataFailure(msg));
  });
 };
};

const initialState = {
 loading: false,
 apiData: false,
 url:'',
 error: null
};

export default function entityDataReducer(draft = initialState, action) {
 switch (action.type) {
  case ITEM_DATA_STARTED:
   draft.loading = true;
   draft.url = action.url;
   return draft;
  case ITEM_DATA_SUCCESS:
   draft.loading = false;
   draft.error = null;
   draft.apiData = {...action.payload};
   return draft;
  case ITEM_DATA_FAILURE:
   draft.loading = false;
   draft.error = action.error;
   return draft;
  case ITEM_DATA_UPDATE:
   if (!draft.apiData) {
    console.warn('race condition on entity-group edit update? ' + draft.url)
   } else {
    if (action.payload.type[0].target_id === draft.apiData.type[0].target_id) {
     if (action.field_name) {
      Object.assign(draft.apiData[action.field_name], action.payload[action.field_name]);
     } else {
      Object.assign(draft.apiData, action.payload);
     }
    }

   }
   return draft;
  default:
   return draft;
 }
}
