import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

class LegalView extends Component {

 componentDidMount() {
  window.logUse.logEvent('view', {page:'legalview'});
 }


 render() {
  return (
   <div className={this.props.classes.paperContainer} >
    <Typography variant='h3'>Terms on Spending &amp; Earning Credibility (<em>&#x20AE;AC</em>) </Typography>
    <ul>
     <li>Your <em>&#x20AE;AC</em> is calculated based on your contributions to our communal playlists. Adding lyrics, biogrpahical informational and other meta data pay different amounts. More details on the full formula will be released with the full launch of all features, security and services</li>
     <li>$1 <em>&#x20AE;AC</em> is always exactly equal to $1 US Dollar when spent in any TAM Group or MarketPlace.</li>
     <li>Eventually <em>&#x20AE;AC</em> can be withdrawn into US Dollars but we're also still working on these conversion rates</li>
    </ul>

    <Typography variant='h3'>Royalties &amp; Artistic Rights</Typography>
    <ul>
     <li>Currently music in <em>Track Authority</em> is only played through <a href='https://www.youtube.com/static?template=terms' rel="noopener noreferrer" target='_blank'>YouTube</a> per their terms and conditions.</li>
     <li>We will update this page once our stremaing subscriptions and royalty contracts are complete.</li>
     <li>If your music is represented here or you can help solve the many challenges to implementing transparent play counting for copyright owners,
                  please reach out to us: royalties@trackauthoritymusic.com</li>
    </ul>

    <Typography variant='h3'>Redeeming Awards</Typography>
    <ul>
     <li>Cash awards must be redeemed electronically, as a charge-back or refund to your credit or debit card. Transaction fees may apply.</li>
     <li>Non-cash awards must be redeemed as described on that Playlist page or through communication with the Group Administrator. To resolve any issues please email support@trackauthoritymusic.com</li>
     <li>Founders, Admins, and Owners are ineligible to win any sponsored awards or win any Casino Music Groups</li>
    </ul>

    <Typography variant='h3'>Indemnification</Typography>
    <ul>
     <li>Apple, iTunes, Youtube, Amazon Music Cloud and all other third parties shown in this app are not sponsors of <em>Track Authority Music</em></li>
    </ul>

    <Typography variant='h3'>Group Ownership Rates</Typography>
    <ul>
     <li>Searching and adding music through Youtube is only free within the <a rel="noopener noreferrer" target='_blank' href="https://developers.google.com/youtube/v3/determine_quota_cost">usage limits provided by Youtube</a>. Currently that is around 10,000 requests per day</li>
    </ul>

    <Grid container
     direction='row'
     justifyContent='center'
     alignContent='center'
     alignItems='center'
    >
     <img alt='faq prompt' src="/assets/menuQuestion.png" style={{height:30, marginRight:10}}/>
     <Link to="/help">Read More FAQs</Link>
    </Grid>
   </div>
  );
 }
}

const styles = theme => ({
 paperContainer: {
  width:'100%',
  backgroundColor:theme.palette.background.paper,
  padding:theme.spacing(2),
  marginTop:0,
  marginBottom:theme.spacing(3),
  justifyContent:'center',
 },
 groupLogo: {
  width:'100%'
 },
 primary : {
  color:theme.palette.primary.main
 },
 list : {
  marginTop:0,
  listStyle:'decimal',
 },
 listItem :{
  marginBottom:5,
 },
 headerTitle : {
  fontWeight:'normal',
  margin:0,
 },
 subHeading : {
  marginTop:10,
  marginBottom:4,
  fontWeight:'normal',
  textDecoration:'underline'
 },
 ctaText: {
  marginTop:theme.spacing(1),
  marginBottom:theme.spacing(2),
 },
 helpText : {
  color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
  fontSize:'90%',
  textIndent:theme.spacing(5),
  fontStyle:'italic'
 }
});

export default withRouter(withStyles(styles)(LegalView));
