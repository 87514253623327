import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {withRouter} from 'react-router';
import Drupal2Json from '../../Util/Drupal2Json';
import TamIcon from '../TamIcon';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {changeApp, getIdbySegment, joinGroup} from "../../redux/authActions";
import {withStyles} from '@material-ui/core/styles';
import {headFootStyles} from "../../Util/ThemeUtils";
import MyMenuLink from "./MyMenuLink";
import Grammer from "../../Util/Grammer";
import EntityList from "../EntityList";
import {getStartupParam} from "../../Util/WindowUtils";

class HeaderMenu extends Component {

 constructor(props) {
  super(props);
  const gid = getIdbySegment(document.location.pathname).gid;
  this.state = { expanded: (gid > 0 ? 'menupanel'+gid : 0) }; // 0 === untouched, false === closed, # === gid
 }

 componentDidUpdate() {
  if (this.state.expanded === 0) { // only overwrite 0
   let gid = getIdbySegment(document.location.pathname).gid;
   if (gid > 0) {
    this.setState({expanded:'menupanel'+gid});
   }
  }
 }

 handleChange(gid) {
  if (this.state.expanded === 'menupanel'+gid) {
   this.setState({expanded: false});  // false to forcibly close all
  } else {
   if (!this.props.me.groups || !this.props.me.groups[gid] || !this.props.me.groups[gid].menu) {
    this.props.dispatch(changeApp(gid));
   }
   this.setState({expanded: 'menupanel'+gid});
  }
 }

 buildMenu(menu, gid, parentId) {
  let paths = [];
  let n = 0;
  for(let i in menu) {
   if (i === 'subgroups') {
    if (this.props.me.groups) {
     paths.push(<ListSubheader key={'subgroupheader'+gid} className={this.props.classes.subheader}>{Grammer.replaceAll(menu[i].title)}</ListSubheader>);
     paths = paths.concat(this.buildSubGroups(gid));
    } else if (gid > 0) {
     console.warn("maybe misconfigured, should have subgroups"); // except when parent is first created
    }
   } else if (i === 'grouphosts') {
     let hosts = this.buildHosts(gid);
     if (hosts.length > 1) {
      paths.push(<ListSubheader key={'subhostheader'+gid} className={this.props.classes.subheader}>{Grammer.replaceAll(menu[i].title)}</ListSubheader>);
     }
     paths.push(<div key={'allhosts-'+gid} style={{paddingLeft:16}}>{hosts}</div>);
   } else if (i === 'wallet') {
    let walletUrl = menu[i].uri;
    if (walletUrl === '/wallet') {
     walletUrl = gid > 0 ? `/group/${gid}/wallet` : (this.props.curGroup > 0 ? `/group/${this.props.curGroup}/wallet` : '/wallet') ;
    } else {
     walletUrl = walletUrl.replace(':gid', gid);
    }
    paths.push(
     <NavLink className={this.props.classes.navLink} activeClassName="selected" to={walletUrl} key={'mywallet-'+gid +'-'+n} >
      <MenuItem className={this.props.classes.menuItem} selected={this.props.location.pathname.indexOf('/wallet') > -1} >
       <ListItemIcon>
         <TamIcon noavatar={true} iconName={menu[i].icon} color={'primary'} />
       </ListItemIcon>
       <ListItemText primary={menu[i].title} />
      </MenuItem>
     </NavLink>
    );
   } else if (i === 'join') {
    paths.push(
     <MenuItem key={'join-'+gid} onClick={e => this.props.dispatch(joinGroup(gid))} className={this.props.classes.menuItem} >
      <ListItemIcon>
       <TamIcon noavatar={true} iconName={menu[i].icon} color={'primary'} />
      </ListItemIcon>
      <ListItemText primary={menu[i].title} />
     </MenuItem>
    );
   } else {
    paths.push(<MyMenuLink key={'menuheadlink'+i}  linkId={n} link={menu[i]} gid={gid} layout={'link'}
                           profile={this.props.me.profile}
                           group={gid > 0 ? this.props.me.groups[gid] : null} />);
    n++;
   }
  }
  return <MenuList key={'menulist-'+gid}>{paths}</MenuList>;
 }

 buildSubGroups(srcGid) {
  let parents = {};
  for(let gid in this.props.me.groups) {
   if (parseInt(srcGid) === parseInt(gid)) {
    console.log(srcGid + " skip itself "  + gid + ' current is ' + this.props.curGroup)
    continue;
   }
   let group = this.props.me.groups[gid];
   group = new Drupal2Json(group);
   let parentId = group.get('field_parent_group', 'target_id') ? group.get('field_parent_group', 'target_id') : 0;
   if (parentId === gid) {
    console.warn("misconfigured parent group (cannot reference itself)")
    continue;
   }
   let menu = (this.state.expanded === 'menupanel'+gid && group.json.menu) ? this.buildMenu(group.json.menu, gid, parentId) : 'Loading...';
   if (typeof parents[parentId] === 'undefined') {
    parents[parentId] = (parentId === 0 || this.props.me.brandId > 0) ? [] : [<ListSubheader component={Link} to={`/group/${parentId}`} key={'parentgroup-'+parents} className={this.props.classes.subheader}>
     {group.get('field_parent_group', 'target_label')}
    </ListSubheader>];
   }
   parents[parentId].push(
    <Accordion
     className={this.props.classes.menuItem}
     key={'menuhead-'+gid}
     expanded={this.state.expanded === 'menupanel'+gid}
     onChange={(e, o) => this.handleChange(gid)} >
     <AccordionSummary expandIcon={<ExpandMoreIcon />}
      className={this.props.classes.summary}
      aria-label={'Expand Group Menu ' + gid}
      classes={{content:this.props.classes.w100}}>
      <ListItemIcon>
       {(group.get('field_cover', 'url')) ?
        <Avatar alt={group.get('label')} src={group.get('field_cover', 'url')} />
        :
        <Avatar alt={group.get('label')} >{group.get('label').substr(0,3).toLowerCase()}</Avatar>}
      </ListItemIcon>
      <Typography
       className={this.props.classes.ellipsis}
       variant="inherit" noWrap>{group.get('label')}
      </Typography>
     </AccordionSummary>
     <AccordionDetails>
      {menu}
     </AccordionDetails>
    </Accordion>
   );
  }

  return Object.values(parents);
 }

 buildHosts(gid) {
  let group = this.props.me.groups[gid];
  let label = group.field_editors && group.field_editors.length > 0 ? null : "Host"
  let hosts = [<EntityList key={'grouphost-'+gid} entity={group} map={{'uid': label}}/>]
  if (group.field_editors && group.field_editors.length > 0) {
   hosts = hosts.concat(<EntityList key={'grouphost-editors-'+gid} entity={group} map={{'field_editors': null}}/>)
  }
  return hosts;
 }

 render () {
  let paths = null;
  if (!this.props.me) {
   paths = 'loading';
  } else if (this.props.me.menu) {
    paths = this.buildMenu(this.props.me.menu, this.props.me.brandId || false);
  } else {
   paths = 'no menu configured';
  }

  let styles = {};
  if (getStartupParam('paddingTop').length > 0) {
   styles.paddingTop = parseInt(getStartupParam('paddingTop'));
  }

  return <div id='DrawerMenu' style={styles} className={this.props.classes.container}>{paths}</div>;
 }
}

export default withRouter(withStyles(headFootStyles)(HeaderMenu));
