import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";


class VariationSelector extends React.Component {

    constructor(p) {
        super(p);
        this.state = { variant_id: p.variations[0].target_id}
    }

    handleMenuItemClick(id) {
        this.setState({variant_id:id}, () => {
            this.props.onVariationChange(this.state.variant_id)
        })
    };

    render() {
        const orderBtn = <Button variant='outlined' onClick={() => this.props.onOrder(this.state.variant_id)}>{this.props.cta}</Button>

        if (this.props.variations.length === 1) {
            return orderBtn;
        }

        return (
            <div>
                <ButtonGroup variant='outlined' aria-label="purchase variation button">
                    <TextField
                        select
                        variant='outlined'
                        size={'small'}
                        value={this.state.variant_id}
                        onChange={(e) => this.handleMenuItemClick(e.target.value)} >
                        {this.props.variations.map((option) => (
                            <MenuItem
                                value={option.target_id}
                                key={'sel-variation'+option.target_id}
                                selected={option.target_id === this.state.variant_id}
                            >
                                {option.target_label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {orderBtn}
                </ButtonGroup>
            </div>
        );
    }

}


VariationSelector.propTypes = {
    variations: PropTypes.array.isRequired,
    cta: PropTypes.string.isRequired,
    onVariationChange:PropTypes.func.isRequired,
    onOrder:PropTypes.func.isRequired
}


export default VariationSelector
