import React, {Component} from 'react';
import PropTypes from "prop-types";
import API from "../../Util/API";
import {getParam} from "../../Util/WindowUtils";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Chip from '@material-ui/core/Chip';
import {withStyles} from "@material-ui/core/styles";


class SportsSelector extends Component {


    constructor(props) {
        super(props);
        this.state = {q: '', options: [], selected: []};
    }

    componentDidMount() {
        const that = this;
        API.Get('/autocomplete/terms/sports').then(res => {
            var gid = getParam('gid', false, false);
            var initial = [];
            if (gid) {
                initial = gid.split(',');
            }
            that.setState({options: res.data, selected: initial});
        }).catch(err => {
            console.log(err);
            that.setState({options: []});
        });
    }

    isSelected = (tid) => this.state.selected.findIndex(t => t.target_id === tid);

    handleChange(e) {
        const val = e.target.value;

        const obj = [...this.state.selected];
        var isSelected = this.state.selected.find( (v, s) => v === val);
        if (!isSelected) obj.push(val);
        else {
            obj.filter( (v, s)  => {
                if (v === val) { obj.splice(s, 1); }
                return v;
            });
        }
        this.setState({selected:obj});
        if (this.props.onChange) {
            this.props.onChange(val, obj);
        }
    }

    renderChips(selected) {
        const that = this;
        return (
            <div style={{whiteSpace:'pre-wrap'}}>
                {selected.map(i => {
                    var value = that.state.options.find( (obj, s) => obj.target_id.toString() === i.toString());
                    if (!value) return null;
                    return (
                        <Chip key={i} label={value.target_label} className={that.props.classes.chip} />
                    );
                })}
            </div>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <FormControl className={classes.formControl} >
                <InputLabel htmlFor="SportSelector">Filter by Sport</InputLabel>
                <Select
                    multiple={this.props.multiple}
                    value={this.state.selected}
                    onChange={e => this.handleChange(e)}
                    renderValue={(selected) => this.renderChips(selected)}
                    inputProps={{
                        name: 'SportSelector',
                        id: 'SportSelector',
                    }}
                >
                    {this.state.options.map(value => (
                        <MenuItem value={value.target_id} key={value.target_id}>
                            <Checkbox checked={this.state.selected.find(i => i === value.target_id)} />
                            {value.target_label}
                            <span style={{flexGrow:1}}>&nbsp;</span>
                            {value.url && <img src={value.url} alt='thumbnail' style={{maxHeight:50, maxWidth:75, marginRight:4}} />}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}

SportsSelector.defaultProps = {
    selected: [],
    options: 'all',
    multiple: false,
};

SportsSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    selected: PropTypes.array.isRequired,
    options: PropTypes.any.isRequired
};


const styles = theme => ({
    formControl: {
        width:'100%',
    },
    chip: {
        margin: 2,
    }
});

export default withStyles(styles)(SportsSelector);
