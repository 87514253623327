import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {pStyles} from "../../Util/ThemeUtils";
import API from "../../Util/API";
import OrderUser from "../../Components/RewardOrders/OrderUser";
import Grid from "@material-ui/core/Grid";
import {withSnackbar} from "notistack";

class VariationOrders extends React.Component {

    constructor(p) {
        super(p);
        this.state = {orders:{data:false, metadata:false}}
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cacheBust > prevProps.cacheBust) {
            this.reload();
        }
    }

    reload() {
        API.Get(`/group/${this.props.curGroup}/rewards/${this.props.cartItem.getProductId()}/variations/${this.props.cartItem.id()}/orders`)
            .then((res) => {
                if (typeof res.data === 'object' && res.data.error) {
                    this.props.enqueueSnackbar(res.data.error, {variant:'error'});
                    if (typeof this.props.onDelete === 'function') {
                        this.props.onDelete();
                    }
                } else {
                    this.setState({orders: res.data});
                }
            });
    }

    render() {
        if (!this.state.orders.data) return null;
        if (!this.state.orders.data.length) return null;

        return <Grid style={{marginBottom:12}} container={true} xs={12} spacing={5}>{this.state.orders.data.map((orderuser) => <Grid
            key={'orderuser' + orderuser['order_id']}
            item xs={12}>
            <OrderUser
               size={120} variant={'circular'} onDelete={this.reload}
               dispatch={this.props.dispatch}
               curGroup={this.props.curGroup} cartItem={this.props.cartItem} me={this.props.me} orderuser={orderuser} />
        </Grid>)}</Grid>

    }
}

VariationOrders.propTypes = {
    me: PropTypes.object,
    cacheBust : PropTypes.number,
    classes: PropTypes.object.isRequired,
    cartItem: PropTypes.object.isRequired,
    curGroup:PropTypes.number.isRequired,
    onDelete: PropTypes.func
};

export default withStyles(pStyles)(withSnackbar(VariationOrders));
