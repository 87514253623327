import React, {Component} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {withStyles, withTheme} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import Grammer from "../../Util/Grammer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NavigateNext from "@material-ui/icons/NavigateNext";
import SystemUpdate from "@material-ui/icons/SystemUpdate";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Mic from "@material-ui/icons/Mic";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import {loadForm} from "../../redux/formsReducer";
import {tileStyles} from "../TrackTile/TrackTile";
import {loadUpgradePlaylistPrompt} from "../../redux/helpReducer";
import {getIdbySegment} from "../../redux/authActions";
import {changePerPage, nextPage} from "../../redux/listDataReducer";
import {getParam} from "../../Util/WindowUtils";
import Drupal2Json from "../../Util/Drupal2Json";
import API from "../../Util/API";
import {storeTrackList} from "../../redux/playerReducer";
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import GenreSelector from "../GenreSelector";
import {ReactComponent as PlayYtIcon} from "../../images/youtube.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EnabledFeature from "../../Util/EnabledFeature";


class PlaylistControls extends Component {

 constructor(props) {
  super(props);
  this.url = document.location.search;
  this.state = {
   sortVote: {target_id:getParam('vote_type', this.url, ''), target_label:''},
   sortUser: {target_id:getParam('voter', this.url, 0), target_label:''},
   genres: getParam('genres[]', this.url, []),
   perpage: getParam('perpage', this.url, 3),
  };
  if (typeof this.state.genres === 'string') this.state.genres = [this.state.genres];
  if (this.state.genres.length > 0) { // restructure for consistency
   let genres = [];
   for (let g = 0; g < this.state.genres.length; g++) {
    genres.push({target_id:this.state.genres[g]});
   }
   this.state.genres = genres;
  }
  if (this.state.sortVote.target_id !== '') { // get label since only id is passed in url
   this.state.sortVote =  this.props.playlist.field_vote_type.find(e => e.target_id === this.state.sortVote.target_id);
  }
  if (this.state.sortUser.target_id !== 0) {
   this.state.sortUser.target_id = parseInt(this.state.sortUser.target_id);
   this.state.sortUser.target_label =  (this.state.sortUser.target_id === this.props.playlist.uid[0].target_id) ?
    'By Curator' : 'TAM Member'; // we can't provide TAM Member's name from a URL parameter
  } else {
   this.state.sortUser.target_label = 'Community Ratings - avg';
  }
 }

 componentDidMount() {
  const state = {...this.state};
  if (this.props.ptlist.metadata) {
   state.sortVote = this.props.playlist.field_vote_type.find(e => e.target_id === this.props.ptlist.metadata.vote_type);
   state.perpage = this.props.ptlist.metadata.perpage ? this.props.ptlist.metadata.perpage : 3;
  }

  if (state.sortUser.target_id < 1) {
   if (this.props.ptlist.metadata.sort === 'aga') {
    state.sortUser = {target_id: -1, target_label: 'Community Ratings'};
   } else {
    state.sortUser = {target_id: 0, target_label: 'Community Ratings'};
   }
  }
  this.setState(state);
 }


 refresh(random) {
  const pjson = new Drupal2Json(this.props.playlist);
  this.url = `/group/${pjson.get('gid', 'target_id')}/playlists/${pjson.get('id', 'value')}/tracks?perpage=${this.state.perpage}`;
  if (this.state.sortVote.target_id !== '') {
   this.url += '&vote_type=' + this.state.sortVote.target_id;
   if (this.state.sortUser.target_id > 0) {
    this.url += '&voter=' + this.state.sortUser.target_id;
   }
  }
  if (this.state.genres.length > 0) {
   for (let g = 0; g < this.state.genres.length; g++) {
    this.url += '&genres[]=' + this.state.genres[g].target_id;
   }
  }
  if (random === true) {
   this.url += '&sort=rand';
  } else {
   if (this.state.sortUser.target_id === -1) {
    this.url += '&sort=aga';
   } else {
    this.url += '&sort=avg';
   }
   if (this.props.ptlist.metadata.seed) {
    this.url += '&seed=' + this.props.ptlist.metadata.seed;
   }
  }
  console.log(this.url);
  API.Get(this.url).then((res) => {
   this.props.dispatch(storeTrackList(pjson.get('gid', 'target_id'), pjson.get('id', 'value'), res.data, false));
  });
 }

 handleSort(e, key) {
  let val = e.target.value;
  const purchased = this.props.ptlist.metadata.p_roles && typeof this.props.ptlist.metadata.p_roles['purchased'] === 'number';
  if (purchased === true) {
   if (key === 'sortUser') val = parseInt(val);
   let obj = {[key]:{target_id:val, target_label:e.currentTarget.getAttribute('aria-label')}};
   if (key === 'sortUser' && val > 0 && this.state.sortVote.target_id === '') {
    obj.sortVote = this.props.playlist.field_vote_type[0];
   }
   console.log(key, obj);
   this.setState(obj, e => {
    this.refresh();
   });
  } else {
   this.props.dispatch(loadUpgradePlaylistPrompt(this.props.playlist.id[0].value, 'sort', 'dialog'));
   const tdata = getIdbySegment(this.url);
   tdata.target_id = 'sort';
   window.logUse.logEvent('feature-request', tdata);
  }
 }

 handleGenreChange(selected) {
  console.log("genres selected", selected);
  this.handleUpgradeFeature({target:{value:selected}}, 'genres');
 }

 handleUpgradeFeature(e, feature) {
  console.log("UPDATE TO: " + feature, e);
  let val = e.target.value;
  if (!feature || feature === '' || feature === 'random') return false;
  const purchased = this.props.ptlist.metadata.p_roles && typeof this.props.ptlist.metadata.p_roles['purchased'] === 'number';
  if (purchased === true) {
   if (feature === 'perpage') {
    this.setState({perpage:val},
     e => this.props.dispatch(changePerPage(this.props.ptlist.metadata, val, true)));
   } else if (feature === 'nextpage') {
    this.props.dispatch(nextPage(this.props.ptlist.metadata, false));
   } else if (feature === 'genres') {
    this.setState({genres:val}, e => this.refresh());
   } else if (feature === 'export') {
    let tdata = getIdbySegment(this.url);
    tdata.target_id = feature;
    window.logUse.logEvent('feature-request', tdata);
    window.alert("Feature in development");
   }
  } else {
   this.props.dispatch(loadUpgradePlaylistPrompt(this.props.playlist.id[0].value, feature, 'dialog'));
   let tdata = getIdbySegment(this.url);
   tdata.target_id = feature;
   window.logUse.logEvent('feature-request', tdata);
  }
 }

 render() {
  const {classes} = this.props;
  const pjson = new Drupal2Json(this.props.playlist);

  return (
   <div aria-label={`Playlist Controls ${pjson.get('id', 'value')}`}>

    <Grid container alignContent={'flex-end'} alignItems={'flex-end'} justifyContent={'space-between'} >

     <Grid item>
      <FormControl fontSize='small'>
       <label className={classes.filterLabel} htmlFor='perpager'>SHOWING</label>
       <Select
        id='perpager'
        fontSize='small'
        aria-label={`Tracks Per Page ${pjson.get('id', 'value')}`}
        value={this.state.perpage}
        classes={{root:classes.denseSelect}}
        onChange={(e) => this.handleUpgradeFeature(e, 'perpage')}
       >
        {[3, 15, 30, 60, 90, 120].map((perpage, i) =>
         (perpage + this.props.ptlist.metadata.end_index < this.props.ptlist.metadata.total_items || this.state.perpage === perpage) ?
          <MenuItem key={`perpage-${this.props.playlist.id[0].value}-${perpage}`}
           value={perpage}>1 - {perpage} {(i ===0) ? ' of ' + this.props.ptlist.metadata.total_items : ''}</MenuItem>
          :
          null
        )}
       </Select>
      </FormControl>
     </Grid>

     <Grid item >
      <FormControl fontSize='small'>
       <label className={classes.filterLabel} htmlFor='playorder'>SORTING</label>
       <Select id='playorder'
        aria-label={`Play Order ${pjson.get('id', 'value')}`}
        classes={{root:classes.denseSelect}}
        multiple={true} value={[this.state.sortUser.target_label, this.state.sortVote.target_label]}
        placeholder='Play Order'
        renderValue={selected => {
         if (this.state.sortUser.target_label === '' && this.state.sortVote.target_label === '') return null;
         return (this.state.sortUser.target_label === '') ?
          this.state.sortVote.target_label :
          this.state.sortUser.target_label;
        }}
       >
        <div style={{padding:'4px 10px'}}>
         <Typography variant='caption'>Order by rating categories:</Typography>
         <RadioGroup name="vote_type"
          value={this.state.sortVote.target_id}
          aria-label={`Play Order ${pjson.get('id', 'value')} by Vote Type ${this.state.sortVote.target_id}`}
          onChange={e => this.handleSort(e, 'sortVote')} >
          {this.props.playlist.field_vote_type.map((o, i) => (
           <FormControlLabel key={o.target_id} value={o.target_id}
            control={<Radio inputProps={{ 'aria-label': o.target_label }}/>}
            label={o.target_label}
           />
          ))}
         </RadioGroup>


         <div style={{paddingLeft:15}}>
          <Typography variant='caption'>according to</Typography>

          <RadioGroup  name="sortUser"
           value={this.state.sortUser.target_id}
            aria-label={`Play Order ${pjson.get('id', 'value')} According to ${this.state.sortUser.target_id}`}
           onChange={e => this.handleSort(e, 'sortUser')}>

           <FormControlLabel
            value={0}
            control={<Radio inputProps={{ 'aria-label': 'Community ratings' }} />}
            label={'Community ratings'}
           />

           { pjson.isGroupAdmin(this.props.me) &&
            <FormControlLabel value={parseInt(this.props.me.profile.uid[0].value)}
             control={<Radio inputProps={{ 'aria-label': 'My ratings' }} />}
             label={'My ratings'} />
            }

           <FormControlLabel value={parseInt(this.props.playlist.uid[0].target_id)}
            control={<Radio inputProps={{ 'aria-label': 'By Curator' }} />}
            label={'Curator'}
           />

          </RadioGroup>
         </div>
        </div>
       </Select>
      </FormControl>
     </Grid>

     <Grid item>
      <FormControl fontSize='small'>
       <label className={classes.filterLabel} htmlFor='playorder'>NEXT PAGE</label>
       <IconButton size={'small'}
                   aria-label={`Next Page ${pjson.get('id', 'value')}`}
                   onClick={(e) => this.handleUpgradeFeature(e, 'nextpage')}><NavigateNext/></IconButton>
      </FormControl>
     </Grid>
    </Grid>

    <div style={{marginTop:20}}>
     <label style={{marginBottom:12, display:'block'}} className={classes.filterLabel}>FILTER BY GENRES</label>
     <GenreSelector selected={this.state.genres} options={this.props.playlist.field_genres}
      onChange={e => this.handleGenreChange(e, 'genres')}
     />
    </div>

    <Grid container alignContent={'center'} alignItems={'center'} spacing={1}>
     <Grid item xs={6}>
      <FormControl fullWidth>
       <InputLabel className={classes.filterLabel} htmlFor='exporter'>Export {Grammer.g('Playlists', 's')}</InputLabel>
       <Select id='exporter' value=''
        classes={{root:classes.denseSelect}}
        aria-label={`Playlist Export ${pjson.get('id', 'value')}`}
        onChange={e => this.handleUpgradeFeature(e, 'export')}>
        <MenuItem value='' disabled >Export Playlist</MenuItem>
        <MenuItem value='export-youtube'>
         <ListItemIcon size={'small'} aria-label="youtube"><SvgIcon component={PlayYtIcon} color='secondary' /></ListItemIcon>
                                    To Youtube
        </MenuItem>
        <MenuItem value='export-spotify'>
         <ListItemIcon size={'small'} aria-label="spotify"><img alt='spotify' src='/assets/spotify_icon.png' height={22} /></ListItemIcon>
                                    To Spotify
        </MenuItem>
        <MenuItem value='export-offline' >
         <ListItemIcon size={'small'} aria-label="offline access"><SystemUpdate /></ListItemIcon>
                                    Download for offline
        </MenuItem>
       </Select>
      </FormControl>
     </Grid>
     <EnabledFeature feature={'tracks:create'} value={true} bypass={pjson.isGroupAdmin(this.props.me) === true}>
     <Grid item xs={6}>
      <FormControl fullWidth>
       <InputLabel className={classes.filterLabel} htmlFor='addatrack'>Add / Import {Grammer.g('Tracks', 'p')}</InputLabel>
       <Select id='addatrack' value=''
        classes={{root:classes.denseSelect}}
        aria-label={`Add a Track to ${pjson.get('id', 'value')}`}
        onChange={(e) => this.props.dispatch(loadForm("/forms/group/" + this.props.playlist.gid[0].target_id + '/playlists/' + this.props.playlist.id[0].value + '/tracks/add?tab=' + e.target.value, 'dialog'))}>
        <MenuItem value='' disabled >Add / Import {Grammer.g('Tracks', 'p')}</MenuItem>
        <MenuItem value='youtube'>
         <ListItemIcon size={'small'} aria-label="youtube"><SvgIcon component={PlayYtIcon} color='secondary' /></ListItemIcon>
                                    Add from Youtube
        </MenuItem>
        <MenuItem value='tamp3'>
         <ListItemIcon size={'small'} aria-label="TAMp3"><PlayIcon color='primary' /></ListItemIcon>
                                    Add from TAMp4
        </MenuItem>
        <MenuItem value='record'>
         <ListItemIcon size={'small'} aria-label="record"><Mic color='primary' /></ListItemIcon>
                                    Record Media
        </MenuItem>
        <MenuItem value='upload'>
         <ListItemIcon size={'small'} aria-label="upload"><CloudUpload color='primary' /></ListItemIcon>
                                    Upload Media
        </MenuItem>
       </Select>
      </FormControl>
     </Grid>
     </EnabledFeature>
    </Grid>
   </div>
  );
 }
}



PlaylistControls.propTypes = {
 playlists: PropTypes.object.isRequired,
 ptlist: PropTypes.object.isRequired,
};


export default withStyles(tileStyles)(withTheme(PlaylistControls));
