import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {testPlay} from '../../redux/playerReducer';
import moment from 'moment';
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import {ReactComponent as PlayYtIcon} from "../../images/youtube.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
 card: {
  width:'100%',
  maxWidth:960,
  justifyContent:'center',
  marginBottom:15
 },
 content : {
  padding:theme.spacing(1),
  textAlign:'center'
 },
 actions: {
  padding:theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems:'center',
  alignContent:'center',
  justifyContent: 'space-between'
 },
 badge: {
  fontSize: '70%',
  padding: '0px 3px'
 }
});

class YTVideo extends React.Component {

 formatNum(num,digits) {
  let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let floor = Math.floor(Math.abs(num).toString().length / 3);
  let value = (num / Math.pow(1000, floor));
  return value.toFixed(value > 1?digits:2) + units[floor - 2];
  // return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
 }

 populateTrack() {
  const { vid } = this.props;
  const track = {target_id:vid.id};
  this.props.addMediaItem(track);
 }

 render() {
  const { classes, vid } = this.props;

  return (
   <Card className={classes.card} >

    <CardHeader
     title={vid.snippet.title}
     subheader={`${this.formatNum(vid.statistics.viewCount, 3)} views • ${moment(vid.snippet.publishedAt).format('MMM Do \'YY')}`}
    />

    <img src={vid.snippet.thumbnails.medium.url} width={vid.snippet.thumbnails.medium.width} height={vid.snippet.thumbnails.medium.height} alt={vid.snippet.title} />

    <CardActions className={classes.actions} >
     <Button startIcon={<SvgIcon component={PlayYtIcon} />} onClick={e => this.props.dispatch(testPlay({target_id:vid.id}, 'youtube'))} >Play</Button>
     <Button endIcon={<PlaylistAdd aria-label='Add to Playlist' />} onClick={e => this.populateTrack()} >Add</Button>
    </CardActions>

   </Card>
  );
 }
}

YTVideo.propTypes = {
 classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(YTVideo);
