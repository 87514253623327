// import featuresSchema from "../apps/tam/features-schema.json";

import NOUNS from "../apps/tam/nouns.json";

// export const VERBS = ['listen', 'play', 'publish', 'unpublish']

class Grammer {
  g(noun, plurality) {
    const st = window.store.getState().auth.gGrammer;
    const key = noun.toLowerCase();
    if (st[key] && st[key][plurality] !== key) {
      let word = st[key][plurality];
      if (noun.charAt(0).toUpperCase() === noun.charAt(0)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    }
    return noun;
  }

  tac(sym) {
    if (window.store.getState().auth.gGrammer[sym]) {
      return window.store.getState().auth.gGrammer[sym].s;
    }
    return sym;
  }

  replaceAll(str) {
    const st = window.store.getState().auth.gGrammer;
    for(let key in NOUNS) {
      if (st[key]) {
        if (NOUNS[key].p !== st[key].p.toLowerCase()) {
          str = this.replaceWords(str, key, 'p');
        }
        if (NOUNS[key].s !== st[key].s.toLowerCase()) {
          str = this.replaceWords(str, key, 's');
        }
      }
    }
    return str;
  }

  replaceWords(str, key, plurality) {
    let toFind = NOUNS[key][plurality]
    let re = new RegExp("\\b" + toFind + "\\b", "i"); // split by Words.
    let index = str.search(re);
    let count = 0
    while (index > -1) {
      let end = index + toFind.length;
      let val = str.substr(index, toFind.length);  // as capitalized in str
      if (val.charAt(0).toUpperCase() === val.charAt(0)) {
        val = this.g(key.charAt(0).toUpperCase() + key.slice(1), plurality); // preserves capitalization
      } else {
        val = this.g(key, plurality); // preserves capitalization
      }
      str = str.slice(0, index) + val + str.substring(end)
      if (count > 5) {
        console.warn('fail safe!!', str, key, plurality)
        index = -1;
      } else if (toFind === val.toLowerCase()) {
        index = -1;
      } else {
        index = str.search(re)
      }
      count++;
    }
    return str
  }

}
export default new Grammer(); // singleton
