import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {cardSave} from "../redux/walletReducer";
import Address from "./Address";

class CardInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {errors: {}};
        if (props.paymethod) {
            this.state.paymethod = {
                "cardtoken_id": props.paymethod.id,
                "cname": "",
                "cardnumber": "",
                "cvc": "",
                "cc-exp": props.paymethod.expiration
            }
        } else {
            this.state.paymethod = {
                "cname": "",
                "cardnumber": "",
                "cc-exp": "",
                "cvc": ""
            }
        }

        this.onFieldText = this.onFieldText.bind(this);
    }

    validateCard() {
        let errors = {};
        const address = this.formToObject('billingAddress');
        if (address.errors) {
            errors = address.errors;
        }
        const paymethod = this.formToObject('payMethodForm');
        if (paymethod.errors) {
            Object.assign(errors, paymethod.errors);
        }
        if (Object.keys(errors).length > 0) {
            this.setState({errors:errors});
            return false;
        }

        return Object.assign({currencies: {'USD': 0, 'TAC': 0}}, {address:address}, {paymethod:paymethod});
    }

    cardSave() {
        const payload = this.validateCard();
        if (payload !== false) {
            // this.props.onChange(payload); // let redux add it to form if request succeeds
            this.props.dispatch(cardSave(Object.assign({currencies:{USD:0}}, {paymethod:Object.assign(payload.paymethod, payload.address)})));
        }
    }

    useOnce() {
        const payload = this.validateCard();
        if (payload !== false) {
            this.props.onChange(payload);
        }
    }

    formToObject(formId) {
        let form = document.forms[formId], obj = {}, errors = {}, hasErrors = false;
        for (let i = 0; i < form.length; i++) {
            if (typeof form[i] !== 'object' || typeof form[i].name !== 'string') continue;
            if (form[i].checkValidity() === false) {
                errors[form[i].name] = form[i].validationMessage;
                hasErrors = true;
            } else {
                errors[form[i].name] = undefined;
                obj[form[i].name] = form[i].value;
            }
        }
        if (hasErrors === true) return {errors:errors}
        return obj;
    }

    onFieldText(e) {
        const paymethod = {...this.state.paymethod};
        const errors = {...this.state.errors};
        paymethod[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({paymethod: paymethod, errors:errors});
    }

    render() {
        const {classes} = this.props;

        return (
            <Paper style={{margin: '15px 0', padding: '2%'}}>
                <Typography variant='h4' className={classes.sectionHead}>Charge Card</Typography>
                <form name="payMethodForm" style={{marginBottom: 10}}>
                    <TextField
                        style={{margin: '8px 5px 8px 0'}}
                        name='ccname'
                        label={'Name'}
                        value={this.state.paymethod.ccname}
                        onChange={this.onFieldText}
                        required={true}
                        error={typeof this.state.errors['ccname'] === 'string'} type='text'
                        helperText={this.state.errors['ccname'] || null}
                        inputProps={{"autoComplete": "cc-name"}}
                    />

                    <TextField
                        style={{margin: '8px 5px 8px 0'}}
                        name='cardnumber'
                        label={'Card Number'}
                        value={this.state.paymethod.cardnumber}
                        onChange={this.onFieldText}
                        required={true}
                        error={typeof this.state.errors['cardnumber'] === 'string'} type='number'
                        helperText={this.state.errors['cardnumber'] || null}
                        inputProps={{"autoComplete": "cc-number", maxLength:16, minLength:14, min:10000000000000, max: 10000000000000000}}
                    />

                    <TextField
                        style={{margin: '8px 5px 8px 0'}}
                        name='cc-exp'
                        label={'Expiration'}
                        value={this.state.paymethod['cc-exp']}
                        onChange={this.onFieldText}
                        required={true}
                        error={typeof this.state.errors['cc-exp'] === 'string'} type='text'
                        helperText={this.state.errors['cc-exp'] || null}
                        inputProps={{"autoComplete": "cc-exp", "placeholder": "MM/YYYY"}}
                    />

                    <TextField
                        style={{margin: '8px 5px 8px 0'}}
                        name='cvc'
                        label={'CCV'}
                        value={this.state.paymethod.cvc}
                        onChange={this.onFieldText}
                        required={true}
                        error={typeof this.state.errors['cvc'] === 'string'} type='number'
                        helperText={this.state.errors['cvc'] || null}
                        inputProps={{"autoComplete": "cc-csc", maxLength: 3, minLength: 3, min:0, max: 999}}
                    />
                </form>

                <Address dispatch={this.props.dispatch} formKey={'billingAddress'} defaultValue={this.props.paymethod} errors={this.state.errors} />

                <Button variant={'contained'} size={'small'} color="primary" onClick={() => this.cardSave()}>Save
                    Card</Button>
                <Button size={'small'} color="primary" onClick={() => this.useOnce()} style={{marginRight: 10}}>Use
                    Once</Button>

            </Paper>

        );
    }
}

const styles = theme => ({
    sectionHead: {
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 4,
        opacity: 0.4,
        textTransform: 'uppercase',
        fontSize: 13
    }
});

export default withStyles(styles)(CardInfo);
