import RatingSlider from './RatingSlider';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {updateTrackRating} from '../../redux/entityDataReducer';

const mapStateToProps = (state, ownProps) => {
 const newProps = {...ownProps};
 newProps.isLoggedIn = (state.auth.me && state.auth.me.profile) ? state.auth.me.profile.uid[0].value : false;
 newProps.verified_email = (state.auth.me && state.auth.me.profile && typeof state.auth.me.profile.roles.verified_email === 'number') ? true : false;
 newProps.vote_types = newProps.tjson.json.field_vote_type;
 return newProps;
};

const mapDispatchToProps = dispatch => {
 return {
  updateTrackRating: (obj, track) => {
   dispatch(updateTrackRating(obj, track));
  }
 };
};

RatingSlider.propTypes = {
 tjson: PropTypes.object.isRequired,
 ratingComplete : PropTypes.func,
 onTypeChange : PropTypes.func,
 defaultValue : PropTypes.number.isRequired,
 isLoggedIn : PropTypes.any // id or false
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(RatingSlider);
