import React, {Component} from 'react';
import AutoCompleteEntity from '../AutoCompleteEntity';

const defaultConfig = {
 "field_name": "field_product",
 "label": "Product",
 "description": "Search products",
 "type": "entity_reference",
 "bundle": "commerce_product",
 "default_value": [],
 "settings": {
  "target_type": "commerce_product",
  "handler_settings": {
   "target_bundles": ["commerce_product"]
  }
 },
 "#id": "edit-field-product-wrapper",
 "#type": "container",
 "cardinality": 0
};

class ProductAutoComplete extends Component {
 render() {

  var {classes, ...inpProps} = this.props;

  inpProps.id = 'tam-product-input';
  var url = '/autocomplete/products/:query';

  var required = ['field_name', 'type', 'settings', 'bundle', '#id', '#type'];
  for(var r in required) {
   if (typeof inpProps.field[required[r]] === 'undefined') inpProps.field[required[r]] = defaultConfig[required[r]];
  }

  if (this.props.label) inpProps.label = this.props.label;

  return (
   <AutoCompleteEntity
    {...inpProps}
    apiurl={url}
    entry={[]}
    source='commerce_product'
   />
  );
 }
}

export default ProductAutoComplete;
