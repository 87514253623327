import React, {Component} from 'react';
import {withRouter} from 'react-router';
import TransactionGrid from '../../Components/TransactionGrid';
import BetsGrid from '../../Components/BetsGrid';
import {listData} from '../../redux/listDataReducer';
import ProgressLoading from '../../Components/ProgressLoading';
import Typography from "@material-ui/core/Typography";
import PaginationBlock from "../../Components/PaginationBlock";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import {getParam, setQueryParam} from "../../Util/WindowUtils";
import Grammer from "../../Util/Grammer";
import {Link} from "react-router-dom";
import {checkWalletBalance} from "../../redux/walletReducer";

class Transactions extends Component {

    constructor(p) {
        super(p);

        this.state = {
            methods:getParam('methods', this.props.location.search, ''),
            status:getParam('status', this.props.location.search, ''),
            currencies:getParam('currencies', this.props.location.search, ''),
            types:getParam('types:', this.props.location.search, '')
        }
    }

    componentDidMount() {
        if (!this.props.me || !this.props.me.profile) {
            return this.props.history.push('/login');
        } else if (this.props.balance.forceCheck === true) {
            this.props.dispatch(checkWalletBalance(this.props.curGroup));
        } else {
            this.refresh();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname + this.props.location.search !== prevProps.location.pathname + prevProps.location.search) {
            this.refresh();
        } else if (this.props.balance.forceCheck !== prevProps.balance.forceCheck) {
            this.refresh();
        }
    }

    refresh() {
        let apiurl = '/wallet/activity' + this.props.location.search
        if (this.props.location.pathname.indexOf('bets') > 0) {
            apiurl = this.props.location.pathname + this.props.location.search;
        }
        if (this.props.curGroup > 0) {
            apiurl = setQueryParam(apiurl, {'gid':this.props.curGroup});
        }
        return this.props.dispatch(listData(apiurl, 'page'));
    }

    changeFilter(filter, e) {
        let val = e.target.value;
        let base = this.props.location.pathname + this.props.location.search;
        base = setQueryParam(base, {[filter]:val});
        base = setQueryParam(base, {'start_index':0});
        this.setState({[filter]:val});
        return this.props.history.push(base);
    }

    renderBalanceSummary() {
        /* const rules = {
            'tac' : ['earned', 'spent'],
            'usd' : ['deposited', 'spent', 'withdrawn']
        }
         */

        return <Paper elevation={2} style={{padding:'2%'}}  >

            <Typography component={'h5'} variant={'subtitle1'}>{Grammer.tac('TAC')} Earned</Typography>
            {this.props.balance.tac.earned.map(e => {
                return <Grid container justifyContent={'space-between'}>
                    <Typography component={'h5'} variant={'body2'}>{e.status}</Typography>
                    <Typography component={'h5'} variant={'subtitle2'}><sup>{Grammer.tac('₮')}</sup>{e.total.toFixed(2)}</Typography>
                </Grid>
            })}
            <Divider />

            {this.props.balance.tac.spent.length === 0 ?
                <Grid container justifyContent={'space-between'}>
                    <Typography component={'h5'} variant={'subtitle1'}>{Grammer.tac('TAC')} Spent</Typography>
                    <Typography component={'h5'} variant={'subtitle2'}><sup>{Grammer.tac('₮')}</sup>0.00</Typography>
                </Grid>
                :
                this.props.balance.tac.spent.map(e => {
                return <React.Fragment>
                    <Typography component={'h5'} variant={'subtitle1'}>{Grammer.tac('TAC')} Spent</Typography>
                    <Grid container justifyContent={'space-between'}>
                    <Typography component={'h5'} variant={'body2'}>{e.status}</Typography>
                    <Typography component={'h5'} variant={'subtitle2'}><sup>{Grammer.tac('₮')}</sup>{e.total.toFixed(2)}</Typography>
                </Grid>
                </React.Fragment>
            })}

            <Grid container justifyContent={'space-between'} style={{borderTop:'double'}}>
                <Typography component={'h5'} variant={'subtitle1'}>{Grammer.tac('TAC')} Net Balance</Typography>
                <Typography component={'h5'} variant={'subtitle2'} color={'primary'}><sup>$</sup>{this.props.balance.tac.net.toFixed(2)}</Typography>
            </Grid>

            <Divider style={{margin:'15px 0'}} />

            <Grid container justifyContent={'space-between'}>
                <Typography component={'h5'} variant={'subtitle1'}>USD Deposited</Typography>
                <Typography component={'h5'} variant={'subtitle2'}><sup>$</sup>{this.props.balance.usd.deposited.toFixed(2)}</Typography>
            </Grid>
            <Grid container justifyContent={'space-between'}>
                <Typography component={'h5'} variant={'subtitle1'}>USD Spent</Typography>
                <Typography component={'h5'} variant={'subtitle2'}><sup>$</sup>{this.props.balance.usd.spent.toFixed(2)}</Typography>
            </Grid>
            <Grid container justifyContent={'space-between'} >
                <Typography component={'h5'} variant={'subtitle1'}>USD Withdrawn</Typography>
                <Typography component={'h5'} variant={'subtitle2'}><sup>$</sup>{this.props.balance.usd.withdrawn.toFixed(2)}</Typography>
            </Grid>
            <Grid container justifyContent={'space-between'} style={{borderTop:'double'}}>
                <Typography component={'h5'} variant={'subtitle1'}>USD Net Balance</Typography>
                <Typography component={'h5'} variant={'subtitle2'} color={'primary'}><sup>$</sup>{this.props.balance.usd.net.toFixed(2)}</Typography>
            </Grid>
        </Paper>
    }

    render() {
        if (!this.props.me.profile) return <div key='Transactions-error' style={{width: '100%', textAlign: 'center', margin: '20px auto'}}><Typography variant='h2'>Please Login</Typography></div>;
        if (!this.props.balance.tac || this.props.lists.apiData === false || this.props.lists.loading === true || this.props.lists.apiData.metadata.url.indexOf(this.props.location.pathname) === -1) return <ProgressLoading  key='Transactions-loading '/>;
        if (this.props.lists.error) return <div key='Transactions-error' style={{width: '100%', textAlign: 'center', margin: '20px auto'}}><Typography variant='h2'>{this.props.lists.error}</Typography></div>;

        let filters = ['methods', 'types', 'status'];

        return <div style={{width:'100%', padding:'2%'}}>
            <Typography component={Link} to={this.props.curGroup > 0 ? `/group/${this.props.curGroup}/wallet` : '/wallet'} variant={'h6'}>View My Wallet</Typography>

            {this.renderBalanceSummary()}

            <div>
            {this.props.lists.apiData.metadata.total_items > this.props.lists.apiData.data.length &&
            <Grid item><PaginationBlock meta={Object.assign({}, this.props.lists.apiData.metadata, this.state)} dispatch={this.props.dispatch} /></Grid>
            }
            <Grid container alignContent={'center'} justifyContent={'space-around'} wrap={'wrap'} spacing={1}>

                {filters.map(fil => <Grid item xs={3}><TextField
                    select
                    variant='filled'
                    fullWidth={true}
                    margin={'dense'}
                    style={{minWidth:95, textTransform:'capitalize'}}
                    size={'small'}
                    label={fil}
                    value={this.state[fil]}
                    onChange={(e) => this.changeFilter(fil, e)} >
                    <MenuItem value={''}>All</MenuItem>
                    {Object.entries(this.props.lists.apiData.metadata[fil]).map(arr => <MenuItem key={fil +'-' + arr[0]} value={arr[0]}>{arr[1]}</MenuItem>)}
                </TextField></Grid>)}

                <Grid item  xs={3}>
                    <TextField
                        select
                        variant='filled'
                        fullWidth={true}
                        margin={'dense'}
                        style={{minWidth:90}}
                        size={'small'}
                        label={'Currency'}
                        value={this.state.currencies}
                        onChange={(e) => this.changeFilter('currencies', e)} >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'USD'}>USD</MenuItem>
                        <MenuItem value={'TAC'}>{Grammer.tac('TAC')}</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            </div>

            {(this.props.location.pathname.indexOf('bets') > 0) ?
                <BetsGrid apiData={this.props.lists.apiData} me={this.props.me} dispatch={this.props.dispatch}/>
                :
                <TransactionGrid apiData={this.props.lists.apiData} me={this.props.me} dispatch={this.props.dispatch}/>}
        </div>

    }
}

export default withRouter(Transactions);
