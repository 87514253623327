import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import PropTypes from 'prop-types';
import {darken, lighten, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import MediaItem from "../../Components/MediaItem";
import {Link, withRouter} from "react-router-dom";
import UserAvatar from "../../Components/UserAvatar";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import FootageActions from "./FootageActions";
import ClipsLoader from "./ClipsLoader";
import {getParam} from "../../Util/WindowUtils";


class FootageBlock extends Component {

    constructor(p) {
        super(p);
        this.state = {expanded: this.props.expanded}
    }

    startClip(json) {

    }

    render() {
        const {classes} = this.props;
        const json = new Drupal2Json(this.props.footage);

        const authorLink = `/users/${json.get('uid', 'target_id')}/group/${json.get('field_group', 'target_id')}`
        const matchLink = `/group/${json.get('field_group', 'target_id')}/rewards/${json.get('field_product', 'target_id')}`
        const footageLink = `${matchLink}/footage/${json.get('nid', 'value')}`;
        let timestamp = getParam('start_time', document.location.search);
        if  (timestamp && timestamp.split(':').length === 3) {
            timestamp = '?start_time=' + timestamp;
        } else {
            timestamp = null;
        }


        const pathParts = json.get('title').split('/').reverse();
        const title = pathParts.shift();

        let mediaItem = this.props.footage.field_footage[0]
        for(let i=1; i < this.props.footage.field_footage.length; i++) {
            if (this.props.footage.field_footage[i].target_bundle === 'youtube') {
                mediaItem = this.props.footage.field_footage[i];
                break;
            }
        }

        return (<Grid className={classes.container} item>
                <CardHeader title={<React.Fragment>
                                    <Link to={footageLink}>{title}</Link>
                                    {` - ${pathParts.join('  -  ')}`}
                                </React.Fragment>}
                            subheader={<React.Fragment>
                                <Link to={matchLink}>{json.get('field_product', 'target_label')}</Link>
                                &nbsp; - &nbsp;
                                {json.get('field_group', 'target_label')}
                            </React.Fragment>}
                            avatar={<img src={json.get('field_sport', 'url')}/>}
                            action={<FootageActions entityLink={footageLink} fjson={json} />}
                />
                <MediaItem media={mediaItem}
                           poster={json.get('field_cover', 'url')}
                           playbackRate={5}
                           linkTo={footageLink + timestamp} />
                <CardHeader title={<Link to={authorLink}>{json.get('uid', 'target_label')}</Link>}
                            subheader={'Videographer'}
                            avatar={<Link to={authorLink}><UserAvatar data={this.props.footage.uid[0]}/></Link>}
                />
                <CardActions style={{width:'100%'}}>
                {this.props.footage.stats.clips === 0 ?
                    <Button style={{textAlign:'center'}} onClick={() => this.startClip(json) }>Create a clip</Button>
                    :
                    <Button onClick={() => this.setState({expanded: !this.state.expanded})} color={'primary'} variant={'contained'}>
                        {this.state.expanded === true ?
                            `Viewing Highlight Clips (${this.props.footage.stats.clips})`
                            :
                            `View Highlight Clips (${this.props.footage.stats.clips})`
                        }
                    </Button>
                }
                </CardActions>

                {this.state.expanded &&
                    <ClipsLoader gid={this.props.footage.field_group[0].target_id}
                                 rid={this.props.footage.field_product[0].target_id}
                                 apiurl={`/group/${this.props.footage.field_group[0].target_id}/footage/${this.props.footage.nid[0].value}/clips`}/>
                }
            </Grid>
        );
    }
}

FootageBlock.propTypes = {
    classes: PropTypes.object.isRequired,
    footage: PropTypes.object.isRequired,
    expanded: PropTypes.bool
};

const styles = theme => ({
    container: {
        width:'100%',
        background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(10,119,213,0) 50%, ${theme.palette.background.paper} 100%)`,
        marginBottom:25
    },
    clipsList: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `${theme.palette.type === 'dark' ? lighten(theme.palette.background.paper, .2) : darken(theme.palette.background.paper, .2)} -1px 1px 6px 1px`
    }
});

export default withStyles(styles, {withTheme: true})(withRouter(FootageBlock));
