import React from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import SanitizedHTML from 'react-sanitized-html';
import TamTrackSearch from '../TamTrackSearch';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {testPlay} from '../../redux/playerReducer';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Config from '../../Config';
import AddToCartButton from "../../core/checkout/AddToCartButton";
import Commerce2Json from "../../Util/Commerce2Json";

const styles = theme => ({
 card: {
  width:'100%',
  maxWidth:960,
  justifyContent:'center',
  marginBottom:15
 },
 content : {
  padding:theme.spacing(1),
  textAlign:'center'
 },
 actions: {
  padding:theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems:'center',
  alignContent:'center',
  justifyContent: 'space-between'
 },
 badge: {
  fontSize: '70%',
  padding: '0px 3px'
 }
});

class ProductDetails extends React.Component {

 componentDidMount() {
  // get other existing Orders to participants
 }

 render() {
  const { classes } = this.props;
  const item = {...this.props.data};
  const json = new Drupal2Json(item);
  const productId = json.get('product_id', 'value');
  const bundle = json.get('type', 'target_bundle');

  return (
   <Card className={['pBlock', classes.card].join(' ')} >

    <CardHeader title={json.get('title', 'value')} />

    <CardContent className={classes.content}>
     { json.get('body', 'value') &&
       <SanitizedHTML html={json.get('body', 'value')}
        allowedAttributes={Config.allowedAttributes}
        allowedTags={Config.allowedTags.concat('a')}
       />
     }

     {bundle === 'download' && json.findPropByVal('field_partner', '4956', 'target_id') && <TamTrackSearch field={{'#id':'download-'+productId}}  /> }
    </CardContent>

    <Table className={classes.table}>
     <TableHead>
      <TableRow className='memberRow'>
       <TableCell align="right">Title</TableCell>
       <TableCell align="right">Price</TableCell>
       <TableCell align="right">Offer</TableCell>
       <TableCell align="right">Actions</TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {item.variations.map((er, i) => {
        const cartItem = new Commerce2Json(item, er.target_id);
       return (<TableRow key={'variant-'+productId+'-'+er.target_id}>
        <TableCell align="right">{er.target_label}</TableCell>
        <TableCell align="right">{'$' + parseFloat(er.price[0]['number'], 2)}</TableCell>
        <TableCell align="right">
         {(er.field_license_txt) ? <SanitizedHTML html={er.field_license_txt[0].value}
          allowedAttributes={{ 'a': ['href', 'target'], 'img' :['src'] }}
          allowedTags={['a', 'img', 'b', 'p']}
         /> : ''}

        </TableCell>
        <TableCell align="right">
         <AddToCartButton dispatch={this.props.dispatch} cartItem={cartItem} index={i} />
         {(er.field_media) ? <Button onClick={(e) => this.props.dispatch(testPlay(er.field_media[0]))} >Play Sample</Button> : ''}
        </TableCell>
       </TableRow>);
      }
      )}
     </TableBody>
    </Table>

   </Card>
  );
 }
}

ProductDetails.propTypes = {
 classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDetails);
