import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import featuresSchema from '../../apps/tam/features-schema.json';
import {pStyles} from "../../Util/ThemeUtils";
import {connect} from "react-redux";
import {setFeatures, updateMyGroup} from "../../redux/authReducer";
import {toggleHiddenField} from "../../redux/formsReducer";
import Grammer from "../../Util/Grammer";

class FeatureItem extends Component {

    constructor(p) {
        super(p);
        this.handleChange = this.handleChange.bind(this);
        this.curSection = null;
    }

    handleChange(key, val) {
        const features = {...this.props.gFeatures};
        if (val === false) {
            delete features[key];
        } else {
            features[key] = val;
        }
        this.applyFeatures(features, key, val);
    }

    handleSelectChange(e, opt) {
        const selectedValue = e.target;
        const features = {...this.props.gFeatures};
        let index = featuresSchema[selectedValue.name].options.findIndex(o => o.value === selectedValue.value)
        if (index === 0) {
            delete features[selectedValue.name];
            Object.entries(featuresSchema).forEach(arr => {
                if (features[arr[0]] && arr[1].dependencies && arr[1].dependencies.indexOf(selectedValue.name) > -1) {
                    delete features[arr[0]];
                }
            });
        } else {
            if (selectedValue.value === false) {
                delete features[selectedValue.name];
            } else {
                features[selectedValue.name] = selectedValue.value;
            }
        }
        this.applyFeatures(features, selectedValue.name, selectedValue.value);
    }

    applyFeatures(features, key, val) {
        this.props.dispatch(setFeatures(features));
        if (featuresSchema[key].form_overwrite) {
            let field = featuresSchema[key].form_overwrite.split('.');
            if (field[1] === 'target_id' && typeof val !== 'number') {
                // show the field, but don't prepopulate with a boolean or whatever
            } else {
                this.props.dispatch(updateMyGroup({[field[0]]:[{[field[1]]:val}]}))
            }

        }
        let parts = key.split(':')
        this.props.dispatch(toggleHiddenField(parts[1], parts[0]));
    }

    renderOptions(feat, key) {
        const opts = feat.options;
        if (opts.length === 1) return this.renderSwitchOption(feat, key);

        const toPass = {value: this.props.gFeatures[key]};
        let maxPrice = 0, minPrice = Infinity;
        const isDisabled = this.isEnabled(feat);

        let opt = feat.options[0];
        opts.forEach((opt) => {
            maxPrice = Math.max(maxPrice, opt.price);
            minPrice = Math.min(minPrice, opt.price);
        })
        if (this.props.gFeatures[key]) {
            if (feat.multiple) {
                opt = feat.options.find(o => this.props.gFeatures[key].indexOf(o.value) > -1);
                if (!toPass.value) toPass.value = [];
                else if (typeof toPass.value !== 'object') toPass.value = [toPass.value];
                toPass.SelectProps = {
                    multiple: feat.multiple,
                    renderValue: (selected) => typeof selected === 'object' ? selected.join(', ') : selected,
                };
            } else {
                opt = feat.options.find(o => o.value === this.props.gFeatures[key]);
            }
            if (!opt) {
                console.warn("misconfigured feature: " + key, feat)
                opt = {};
            }
        }

        return (
            <Grid container justifyContent={'space-between'} key={key + "-troption"}  className={'altBlock'} style={{padding:'1% 1% 1% 2%'}} >
                <Grid item xs={12} style={{textAlign: 'right', color:this.props.theme.palette.primary.main}}>
                    {minPrice === maxPrice ? (minPrice) : (
                        <span><sup>$</sup>{minPrice} - <sup>$</sup> {maxPrice.toFixed(2)}</span>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!this.props.gFeatures[key] ? <React.Fragment>
                        <FormControlLabel
                            labelPlacement={window.innerWidth > 500 ? 'end' : 'top'}
                            control={
                                <Switch
                                    checked={false}
                                    disabled={isDisabled}
                                    onChange={(_, val) => this.handleChange(key, feat.options[1].value)}
                                    name={key}
                                    color="primary"
                                />
                            }
                            label={Grammer.replaceAll(feat.label)}
                        />
                        {opt.desc || feat.desc ? <FormHelperText style={{marginBottom:10}}>{opt.desc || feat.desc}</FormHelperText> : null}
                        </React.Fragment>
                        :
                        <TextField
                            {...toPass}
                            name={key}
                            fullWidth={true}
                            disabled={isDisabled}
                            onChange={(e) => this.handleSelectChange(e, opts)}
                            select
                            label={Grammer.replaceAll(feat.label)}
                            helperText={opt.desc || feat.desc}
                        >
                            {opts.map((opt) => <MenuItem
                                        key={key + "-val-" + opt.value}
                                        value={opt.value}
                                        style={{display: "flex", justifyContent: "space-between", width: '100%'}}
                                    >
                                        <span>{Grammer.replaceAll(opt.label)}</span>
                                        <small style={{marginLeft: 5, textAlign: 'right'}}>
                                            <sup>$</sup>
                                            {opt.price}
                                        </small>
                                    </MenuItem>
                            )}
                        </TextField>
                    }
                </Grid>
            </Grid>
        );
    }

    isEnabled(feat) {
        if (!feat.dependencies) return false;
        for (let f in feat.dependencies) {
            if (this.props.gFeatures[feat.dependencies[f]]) {
                return false;
            }
        }
        return true;
    }

    renderSwitchOption(feat, key) {
        const opt = feat.options[0];
        const isOn = this.props.gFeatures[key] === opt.value || feat.dataType === typeof this.props.gFeatures[key];
        const isDisabled = this.isEnabled(feat);

        const label = opt.label ? Grammer.replaceAll(opt.label) : ((feat.label) ? Grammer.replaceAll(feat.label) : null)
        const desc = opt.desc ? Grammer.replaceAll(opt.desc) : ((feat.desc) ? Grammer.replaceAll(feat.desc) : null)

        return (
            <Grid container justifyContent={'space-between'} key={key + "-trswitch"} className={'altBlock'} style={{padding:'1% 1% 1% 2%'}}>
                <Grid xs={12} item style={{textAlign: 'right', color:this.props.theme.palette.primary.main}}>
                    <sup>$</sup>
                    {opt.price.toFixed(2)}
                </Grid>
                <Grid xs={12} item style={{flexGrow:1}}>
                    <FormControlLabel
                        labelPlacement={window.innerWidth > 500 ? 'end' : 'top'}
                        control={
                            <Switch
                                checked={isOn}
                                value={opt.value}
                                disabled={isDisabled}
                                onChange={(el, val) => this.handleChange(key, val === true ? opt.value : val)}
                                name={key}
                                color="primary"
                            />
                        }
                        label={label}
                    />
                    {opt.desc || feat.desc ? <FormHelperText style={{marginBottom:10}}>{desc}</FormHelperText> : null}
                    {feat.dataType && isOn && (
                        <TextField
                            name={key + '-value'}
                            type={feat.dataType}
                            fullWidth={true}
                            helperText={opt.desc || "Set your default value"}
                            value={this.props.gFeatures[key]}
                            onChange={(e) => {
                                if (!Number.isNaN(e.target.value)) {
                                    this.handleChange(key, Math.max(0, parseFloat( e.target.value)))
                                }

                            }}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }

    render() {
        return this.renderOptions(this.props.feature, this.props.feature_id);
    }
}

const mapStateToProps = (state) => {
    return {
        gFeatures: state.auth.gFeatures,
        gGrammer: state.auth.gGrammer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pStyles, {withTheme:true})(FeatureItem));
