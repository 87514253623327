import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import DraftOrderContract from "./DraftOrderContract";
import {addAndCheckout, openWallet} from "../../redux/walletReducer";
import Badge from "@material-ui/core/Badge";
import {withSnackbar} from "notistack";
import PromptSignIn from "../prompts/PromptSignIn";
import {connect} from "react-redux";
import AddedPickupMatchToCart from "../prompts/AddedPickupMatchToCart";

class PurchaseWithContractAction extends Component {
    constructor(props) {
        super(props);
        this.state = {showingContract: false, showingCartPrompt: false};
        this.onContinue = this.onContinue.bind(this);
        this.onCheckout = this.onCheckout.bind(this);
        this.onAgree = this.onAgree.bind(this);
    }

    showContract() {
        if (!this.props.me.profile) {
            this.props.enqueueSnackbar('signin', {
                variant: 'warning',
                content: <PromptSignIn onClose={() => this.props.closeSnackbar('signin-to-order')}/>,
                key: 'signin-to-order',
                anchorOrigin: {
                    vertical: 'top', horizontal: 'right'
                }
            });
        } else {
            this.setState({showingContract: true});
        }

    }

    onContinue() {
        this.setState({showingCartPrompt: false, showingContract: false});
    }

    onCheckout() {
        this.setState({showingCartPrompt: false, showingContract: false});
        this.props.dispatch(openWallet(true));
    }

    onAgree() {
        this.props.dispatch(addAndCheckout(this.props.cartItem.toJson()));
        this.setState({showingCartPrompt: true, showingContract: false});
        if (typeof this.props.reload === 'function') {
            this.props.reload();
        }
    }

    render() {
        const {cartItem, classes} = this.props;
        const toPass = {variant: this.props.variant || "outlined", color: "default"};
        const {variation} = this.props.cartItem; // Drupal2Json
        if (variation.get('field_media', 'target_bundle') === 'image') {
            toPass.startIcon = <Avatar
                variant={'circular'}
                src={variation.get('field_media', 'url')}/>;
        }

        const Wrapper = this.props.component ? this.props.component : Button;
        toPass.onClick = () => this.showContract();

        let label = this.props.prefix ?
            <span>
                                <span className={'caption'}>{this.props.prefix}</span>
                                <span>{cartItem.getVariationTitle()}</span>
                        </span>
            :
            cartItem.getVariationTitle();

        if (variation.get('field_stock') && typeof variation.get("order_count")) {
            const inLine = `${variation.get("order_count") || 0}/${variation.get("field_stock")}`;
            if (this.props.showOrderCountAs === 'badge') {
                label = <Badge
                    style={{width: '100%', textAlign: 'center'}}
                    title={'in / available'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: this.props.index % 2 ? 'left' : 'right',
                    }}
                    badgeContent={inLine}
                    classes={{badge: classes.badgeDark}}>
                    <Wrapper fullWidth={true}
                             aria-label="Confirm Draft Order"
                             {...toPass}>
                        {label}
                    </Wrapper>
                </Badge>
            } else if (this.props.showOrderCountAs === 'avatar') {
                label = <Wrapper fullWidth={true}
                                 aria-label="Confirm Draft Order"
                                 {...toPass}>
                    <span style={{flexGrow: 1}}>{label}</span>
                    <Avatar title={'in / available'}
                            className={classes.orderCount} color={'secondary'}>{inLine}</Avatar>
                </Wrapper>
            }
        }


        return (
            <React.Fragment>
                {this.state.showingContract === true && <DraftOrderContract cartItem={cartItem}
                                                                            onAgree={this.onAgree}
                                                                            dispatch={this.props.dispatch}
                                                                            onClose={this.onContinue}/>}
                {this.state.showingCartPrompt === true && <AddedPickupMatchToCart me={this.props.me}
                                                                                  classes={this.props.classes}
                                                                                  cartItem={this.props.cartItem}
                                                                                  dispatch={this.props.dispatch}
                                                                                  onContinue={this.onContinue}
                                                                                  onCheckout={this.onCheckout}/>}


                {label}
            </React.Fragment>
        );
    }
}


const styles = (theme) => ({
    twoLineButton: {
        lineHeight: 1,
        '& > span': {
            textAlign: 'left',
            lineHeight: 1
        },
        '& .caption': {
            fontSize: 11,
            display: 'block',
            margin: 0,
            lineHeight: 1
        }
    },
    wideButton: {
        justifyContent: 'space-between',
        textAlign:'center'
    },
    badgeDark: {
        backgroundColor: theme.palette.primary.dark,
        fontSize: '70%',
        padding: '0px 3px'
    },
    orderCount: {
        width: 25, height: 25, fontSize: 12, marginRight: 10, fontWeight: 900
    }
});

PurchaseWithContractAction.propTypes = {
    dispatch: PropTypes.func.isRequired,
    cartItem: PropTypes.object.isRequired,
    showOrderCountAs: PropTypes.string,
    reload: PropTypes.func
};

const mapStateToProps = (state) => {
    var newState = {};
    newState.me = state.auth.me;
    return newState;
};

export default withStyles(styles, {withTheme: true})(withSnackbar(connect(mapStateToProps)(PurchaseWithContractAction)));
