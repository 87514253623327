import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {pStyles} from "../../Util/ThemeUtils";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {updateMyGroup} from "../../redux/authReducer";

class GroupBranding extends Component {

    applyStep() {
        this.props.dispatch(updateMyGroup(this.props.formData))
    }

    render() {
        return (<form method='POST' name={this.props.formKey}
                      className={'taForm page ' + this.props.classes.taForm} key={this.props.formKey}>
            <Button fullWidth={true}
                    style={{margin: '15px auto'}}
                    variant={'contained'} color={'primary'}
                    onClick={() => this.applyStep()}>Save Changes</Button>
            <Grid
                container
                spacing={1}
                style={{padding: '2%'}}
                direction="row"
                justifyContent="center"
                alignContent="space-between"
                alignItems="stretch"
            >
                {this.props.fields}
            </Grid>
        </form>)

    }
}


GroupBranding.propTypes = {
    fields: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired
};

export default withStyles(pStyles)(GroupBranding);
