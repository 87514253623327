import React from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import SanitizedHTML from 'react-sanitized-html';
import TamTrackSearch from '../TamTrackSearch';
import AutoCompleteEntity from '../AutoCompleteEntity';
import ProductAutoComplete from '../ProductAutoComplete';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import {Link} from "react-router-dom";

const styles = theme => ({
    card: {
        width: '100%',
        maxWidth: 960,
        justifyContent: 'center',
        marginBottom: 15
    },
    content: {
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    actions: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between'
    },
    chip: {
        textDecoration: 'none',
        '& a': {
            textDecoration: 'none'
        }
    },
    chipRoot: {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
    },
    chipLabel: {
        border: '1px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]),
        borderWidth: '1px 1px 2px 2px',
        borderRadius: '0 8px 8px 0',
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        marginLeft: theme.spacing(1) * -1,
        paddingLeft: theme.spacing(2),
    },
    chipAvatar: {
        color: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        fontWeight: 900,
        padding: theme.spacing(2),
        fontSize: '90%',
        width: 40,
        height: 40,
    },
});

const field_recipient = {
    "field_name": "field_recipient",
    "label": "Search Users or enter their email",
    "description": "Select an existing user or add anyone's email",
    "type": "entity_reference",
    "bundle": "user",
    "default_value": [],
    "settings": {
        "target_type": "user"
    },
    "#type": "container",
    "#required": true,
    "#weight": 1,
    "cardinality": 1
};

/*
const sendOptions = {
    'deposit' : {l:'Cash'},
    'download' : {l:'Downloads'},
    'event_tickets' : {l:'Event Tickets'},
    'music_license' : {l:'Music Subscription'},
    'default' : {l:'Merchandise'},
}
 */

class ProductGift extends React.Component {

    constructor(p) {
        super(p);
        this.state = {giftType:'deposit'}
    }

    renderAc() {
        let autocomplete = '';
        if (!this.props.me.profile) {
            return <Link to={'/login'}>Login to share</Link>;
        }
        const productId = this.props.data.product_id[0].value;

        if (this.state.giftType === 'music') {
            autocomplete = <TamTrackSearch field={{'#id': 'sendasong-' + productId}}/>;
        } else {
            autocomplete = <ProductAutoComplete field={{'#id': 'giftaconcert-' + productId}} label='Search Event Tickets'/>;
        }
        return <div style={{marginTop: 8}}>{autocomplete}</div>;
    }

    render() {
        const {classes} = this.props;
        const item = {...this.props.data};
        const json = new Drupal2Json(item);
        // const productId = json.get('product_id', 'value');
        const price = parseFloat(this.props.data.variations[0].price[0].number, 2);

        return (
            <Card className={['pBlock', classes.card].join(' ')}>

                <CardHeader
                    title={json.get('title', 'value')}
                />

                <CardContent className={classes.content}>
                    {
                        json.get('body', 'value') ?
                            <SanitizedHTML html={json.get('body', 'value')}
                                           allowedAttributes={{'a': ['href', 'target'], 'img': ['src']}}
                                           allowedTags={['a', 'img', 'b', 'p']}
                            />
                            : ''
                    }

                    {!this.props.me.profile ? '' :
                        <AutoCompleteEntity field={field_recipient} entry={[]} apiurl={'/autocomplete/user/:query'}
                                            source={'user'}/>
                    }

                    {this.renderAc()}

                </CardContent>

                <CardActions className={classes.actions}>

                    <Chip
                        avatar={<Avatar>{'$' + price}</Avatar>}
                        label={<em>plus gift price</em>}
                        classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                            avatar: classes.chipAvatar
                        }}
                    />

                    <Button disabled color="primary">Send Gift</Button>
                </CardActions>

            </Card>
        );
    }
}

ProductGift.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductGift);
