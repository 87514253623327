import React, {Component} from 'react';
import TrackTile from '../../Components/TrackTile';
import TrackListItem from '../../Components/TrackListItem';
import Drupal2Json from '../../Util/Drupal2Json';
import ProgressLoading from '../../Components/ProgressLoading';
import Grid from '@material-ui/core/Grid';
import GridIcon from '@material-ui/icons/ViewModule';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as PlaceChips} from '../../images/PlaceChips.svg';
import {tileStyles} from '../../Components/TrackTile/TrackTile';
import {withStyles} from '@material-ui/core/styles';
import {push} from 'connected-react-router';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grammer from "../../Util/Grammer";
import PropTypes from "prop-types";
import {ReactComponent as RefreshIcon} from '../../images/360-24px.svg';
import SortByAlpha from '@material-ui/icons/SortByAlpha';
import CommentAddIcon from '@material-ui/icons/RateReview';
import {storeTrackList} from "../../redux/playerReducer";
import API from "../../Util/API";
import {loadForm} from "../../redux/formsReducer";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import GridListTile from "@material-ui/core/GridListTile";
import IconButton from "@material-ui/core/IconButton";
import {getParam} from '../../Util/WindowUtils';
import PlaylistControls from "../../Components/PlaylistControls";
import Badge from '@material-ui/core/Badge';
import GrowControls from "../../Components/GrowControls";
import {hasEnabled, isEnabled} from "../../Util/EnabledFeature";
import HighlightBlock from "../../core/clips/HighlightBlock";

class TrackList extends Component {

 constructor(props) {
  super(props);
  this.url = document.location.search;
  let layout = isEnabled('tracks:layout', 'list') ? 'list' : 'grid';
  this.state = {
   layout: getParam('tab', this.url, layout),
   expanded: false,
  };
 }

 componentDidMount() {
  this.refresh();
 }

 refresh() {
  const sortVote = getParam('vote_type', this.url, ''),
   sortUser = getParam('voter', this.url, ''),
   genres = getParam('genres[]', this.url, []),
   // fields = getParam('fields', this.url, ''),
   perpage = (this.props.blockType === 'pid') ? getParam('perpage', this.url, this.props.perpage) : this.props.perpage;

  const pjson = new Drupal2Json(this.props.playlist);
  const songtype = pjson.get('field_category', 'target_id') === 868 ? 'tracks' : 'tracks';
  this.url = `/group/${pjson.get('gid', 'target_id')}/playlists/${pjson.get('id', 'value')}/${songtype}?perpage=${perpage}`;
  if (sortVote !== '') {
   this.url += '&vote_type=' + sortVote;
   if (sortUser !== '') {
    this.url += '&voter=' + sortUser;
   }
  }
  if (genres.length > 0) {
   for(let g=0; g < genres.length; g++) {
    this.url += '&genres[]=' + genres[g];
   }
  }

  if (isEnabled('playlists:field_judge', 'streamgains-yt')) {
   this.url += '&fields=stats'
  }

  this.url += '&sort=' + (isEnabled('playlists:cta', 'upgrade') ? 'rand' : 'avg');
  if (this.props.ptlist.data && this.props.ptlist.metadata && this.props.ptlist.metadata.seed) {
   // this.url += '&seed=' + this.props.ptlist.metadata.seed;
  }

  API.Get(this.url).then((res) => {
   this.props.dispatch(storeTrackList(pjson.get('gid', 'target_id'), pjson.get('id', 'value'), res.data, false));
  });
 }

 componentDidUpdate(prevProps) {
  if (prevProps.ptlist.loading === true && !prevProps.ptlist.data && this.props.ptlist.loading === false) {
   this.props.onLoaded(); // PlaylistGC autoscrolls to the list
  } else if (prevProps.id && this.props.playlist.id[0].value !== prevProps.id[0].value) {
   this.refresh();
  }
 }

 toggleMenu(e) {
  this.setState({expanded: !this.state.expanded});
 }

 render() {
  const { classes } = this.props;
  let list = [];

  if (!this.props.ptlist.data || this.props.ptlist.loading === true) {
   list = <ProgressLoading key='tracklist-loading'/>;
  } else if (this.props.ptlist.error) {
   list = <div key='tracklist-error' ><Typography variant='h2'>{this.props.ptlist.error}</Typography></div>;
  } else {
   if (this.props.ptlist.data.length === 0) {
    if (this.props.ptlist.metadata.url.indexOf('/bets') > 1) {
     list = <Typography variant='h3' key='tracklist-nobets' style={{textAlign: 'center'}}>You have no Tracks
                        to bet on. Pick a Challenge and Add a Track</Typography>;
    } else {
     list = (this.props.showEmpty === true) ?
      (<Typography variant='h4' key='tracklist-noresults' style={{margin: '10px 5px'}}>There are no
                            public {Grammer.g('tracks', 'p')} in this {Grammer.g('playlists', 's')} available yet. Add
                            yours.</Typography>) : '';
    }
   } else {
    let containerWidth = 'grid'; // dashboard
    if (this.props.blockType === 'pid' && window.innerWidth > 600) {
     containerWidth = 'list';
    }

    let promptBtns = [];
    if (this.state.layout === 'grid') {
     if(this.props.ptlist.data.length < this.props.perpage && isEnabled('tracks:create', true)) {
      promptBtns.push(
          <GridListTile key={'add-btn'}
                        onClick={(e) => this.props.dispatch(loadForm("/forms/group/" + this.props.playlist.gid[0].target_id + '/playlists/' + this.props.playlist.id[0].value + '/tracks/add', 'dialog'))}
                        classes={{root:classes.gridTile}}>
           <GridListTileBar
               title={`Add your ${Grammer.g('Tracks', 's')}`}
               subtitle={<IconButton><PlaylistAdd color={'primary'} /></IconButton>}
               classes={{
                root: classes.titleBar,
                titleWrap: classes.titleWrap,
                title: classes.title,
                subtitle: classes.subtitle
               }}
           />
          </GridListTile>);
     }
     if(this.props.ptlist.data.length < this.props.perpage && isEnabled('playlists:cta', 'upgrade')) {
      promptBtns.push(
          <GridListTile key={'sponsor-btn'} onClick={(e) => this.props.dispatch(push((this.props.me.profile) ? '/wallet' :'/login'))}
                        classes={{root:classes.gridTile}}>
           <GridListTileBar
               title={`Sponsor this ${Grammer.g('Playlists', 's')}`}
               subtitle={<IconButton><SvgIcon color={'primary'} component={PlaceChips} viewBox='0 0 24 24'/></IconButton>}
               classes={{
                root: classes.titleBar,
                titleWrap: classes.titleWrap,
                title: classes.title,
                subtitle: classes.subtitle
               }}
           />
          </GridListTile>
      );
     }
    }

    if (this.props.ptlist.data.length < 3 && this.state.layout === 'grid') {
     containerWidth = (this.props.ptlist.data.length === 1) ? 100 : 50; // TODO consider added slots below
    }

    for (let f in this.props.ptlist.data) {
     let item = {...this.props.ptlist.data[f]};
     if (item.type[0]['target_id'] === 'groups-group_node-tracks') {
      if (this.state.layout === 'grid') {
       list.push(<TrackTile profile={this.props.me.profile} key={f} track={item} containerWidth={containerWidth}
        dispatch={this.props.dispatch}/> );
      } else {
       list.push(<TrackListItem profile={this.props.me.profile} key={f} track={item} playlist={this.props.playlist} dispatch={this.props.dispatch}/>);
      }
     } else if ( item.type[0]['target_id'] === 'groups-group_node-clips') {
      list.push(<HighlightBlock me={this.props.me} key={f} clip={item} playlist={this.props.playlist}
                           gid={item.gid[0].target_id}
                           rid={item.node.field_product[0].target_id}
                           dispatch={this.props.dispatch}/>);
     }
    }
    if (promptBtns.length > 0) {
     list.push(...promptBtns);
    }
   }
  }

  let tabs = {};
  let controlsEnabled = true;
  if (hasEnabled('tracks:controls', 'never')) {
   controlsEnabled = false;
  } else {
   controlsEnabled = hasEnabled('tracks:controls', this.props.blockType === 'pid' ? 'pid' : 'list');
  }
  if (controlsEnabled) {
   tabs = {Layout:true, 'Genres': isEnabled('playlists:field_buy_in', 'upgrades') ? 'Genres' : 'Sorting' };
   if (this.props.ptlist.metadata && this.props.ptlist.metadata.end_index - this.props.ptlist.metadata.start_index < this.props.ptlist.metadata.total_items) {
    tabs.Shuffle = true;
   }
  }

  return (
   <React.Fragment>
       {controlsEnabled === true ?
           <React.Fragment>
               <Paper className={classes.paperBg}>
                   <Tabs
                       variant="fullWidth" centered
                       value={this.state.layout === 'grid' ? 0 : 1}
                       indicatorColor="secondary" textColor="secondary">
                        {tabs.Layout === true && <Tab icon={<GridIcon/>} label={this.props.showTabLabels ? Grammer.g('Tracks', 'p') : ''}
                             onClick={e => this.setState({layout: 'grid'})} className={classes.tab}
                             aria-label={`Playlist Track Layout ${this.props.playlist.id[0].value}`}/>
                        }
                        {tabs.Layout === true && <Tab icon={<CommentAddIcon fontSize={'small'}/>}
                                label={this.props.showTabLabels ? "Comment" : ''}
                                onClick={e => this.setState({layout: 'list'})} className={classes.tab}
                                aria-label={`Playlist Comments ${this.props.playlist.id[0].value}`} />
                        }
                        {tabs.Shuffle === true &&
                        <Tab icon={<SvgIcon component={RefreshIcon}/>} label={this.props.showTabLabels ? "Shuffle" : ''}
                             onClick={e => this.refresh()} className={classes.tab}
                             aria-label={`Playlist Shuffler ${this.props.playlist.id[0].value}`}/>
                        }
                       <Tab icon={this.props.ptlist.metadata ?
                           <Badge max={999} badgeContent={this.props.ptlist.metadata.total_items} color='secondary'
                                  classes={{badge: classes.countBadge}}>
                               <SortByAlpha/></Badge> : <SortByAlpha/>
                       } label={this.props.showTabLabels ? tabs.Genres : ''}
                            onClick={e => this.toggleMenu(e)} className={classes.tab} aria-label={`Playlist Controls Menu ${this.props.playlist.id[0].value}`} />
                   </Tabs>
               </Paper>
               <GrowControls
                   anchorEl={this.state.anchorEl}
                   open={this.state.expanded}
                   transformOrigin={{horizontal: 'center', vertical: 'bottom'}}>
                   <PlaylistControls playlist={this.props.playlist} ptlist={this.props.ptlist}
                                     dispatch={this.props.dispatch} me={this.props.me} />
               </GrowControls>
           </React.Fragment>  : ''
       }

    <Grid
     container
     direction={(this.state.layout === 'grid') ? 'row' : 'column'}
     justifyContent="center"
     alignContent="space-between"
     alignItems="center"
     spacing={0} >
     {list}
    </Grid>
   </React.Fragment>
  );
 }
}

TrackList.defaultProps = {
 perpage: 15,
 showTabLabels: true,
 showEmpty: false
};

TrackList.propTypes = {
 playlist : PropTypes.object.isRequired,
 perpage: PropTypes.number.isRequired,
 onload: PropTypes.func,
 showTabLabels: PropTypes.bool
};

export default withStyles(tileStyles)(TrackList);
