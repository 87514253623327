import {connect} from 'react-redux';
import {pausePlayer, playNext, playTrack, setActivePlaylist} from '../../redux/playerReducer';
import {withSnackbar} from 'notistack';
import PlayerBar from './PlayerBar';
import {loadForm} from "../../redux/formsReducer";

const mapStateToProps = (state) => {
 const newState = {...state.player};
 newState.me = {...state.auth.me};
 newState.location = state.router.location;
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  loadForm: (url, ctx) => {
   dispatch(loadForm(url, ctx));
  },
  playTrack: (track, media) => {
   dispatch(playTrack(track, media));
  },
  setActivePlaylist: (pid, gid, autoplay) => {
   dispatch(setActivePlaylist(pid, gid, autoplay));
  },
  playNext: () => {
   dispatch(playNext());
  },
  pausePlayer: () => {
   dispatch(pausePlayer());
  },
  dispatch
 };
};


export default connect(
 mapStateToProps,
 mapDispatchToProps
)(withSnackbar(PlayerBar));
