import Commerce2Json from "./Commerce2Json";
import {changeCurrency} from "../redux/walletReducer";

export default class MyCart {

    constructor (cartItems) {
        this.total = 0.00;
        this.grouped = {}; // counts by variant id
        this.items = [];
        for(let i in cartItems) {
            let item = new Commerce2Json(cartItems[i].product, cartItems[i].variation.variation_id[0].value, cartItems[i].count);
            this.items.push(item)
        }
        this.recalcTotal(this.items);
    }

    getItems() {
        return this.items;
    }

    recalcTotal(items) {
        let total = 0.00;
        for(let i in items) {
            total += changeCurrency(items[i].getFullPrice(), items[i].get('price', 'currency_code'));
        }
        this.total = total;
    }

    getOrder() {
        return this.items.map(c => {
            let base = {"vid": c.id(), "quantity": c.count}
            if (c.product.json.track) {
                base.tid = c.product.json.track.id[0].value;
            }
            if (c.get("field_wager_value")) {
                base.prediction = c.get("field_wager_value");
            }
            return base;
        })
    }

    getTotal() {
        return parseFloat((this.total).toFixed(2));
    }

    hasShippable() {
        return this.items.findIndex(cartItem => cartItem.get('type', 'target_id') === 'default') >  -1;
    }

    addToCart(cartItem) {
        this.items.push(cartItem);
        this.recalcTotal(this.items);
        // calc other stuff
    }

    removeToCart(cartItem) {
        let i = this.items.find(o => o.id === cartItem.id)
        if (i) {
            this.items.splice(i,1);
        }
        this.recalcTotal(this.items);
    }

}
