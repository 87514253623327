import {connect} from 'react-redux';
import TrackList from './TrackList';

const mapStateToProps = (state, ownProps) => {
 const newProps = {...ownProps};
 newProps.me = state.auth.me;
 let pid = newProps.playlist.id[0].value;

 newProps.ptlist = {loading:true,data:false,error:false};
 if (state.player.tracklist[pid]) {
  newProps.ptlist = state.player.tracklist[pid];
 }

 newProps.lastrating = false;
 if (state.entity.lastrating) {
  newProps.lastrating = parseInt(state.entity.lastrating.substr(0, state.entity.lastrating.indexOf('_')));
  // IF the lastrating was this same playlist!
  let lastVoteType = state.entity.lastrating.substr(newProps.lastrating.toString().length + 1);
  for (var v in newProps.playlist.vote_types) {
   if (newProps.playlist.vote_types[v].target_id === lastVoteType) {
    newProps.voteType = lastVoteType;
    break;
   }
  }
 }

 return newProps;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(TrackList);
