import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TrackActions from '../TrackActions';
import ImageIcon from '@material-ui/icons/Camera';
import {playTrack} from '../../redux/playerReducer';
import CommentsList from "../CommentsList";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import EnabledFeature, {isEnabled} from "../../Util/EnabledFeature";
import TrackStatsGraph from "../TrackStatsGraph";

class TrackListItem extends Component {

    render() {
        const {classes} = this.props;
        const json = new Drupal2Json(this.props.track);
        const node = new Drupal2Json(this.props.track.node);

        var ctas = {
            'rate': true,
            'comments': false,
            'youtube': true,
            'mp3': false,
            'info': false,
            'edit': false,
            'delete': false,
            'bet': true,
            'share': false
        };
        if (this.props.profile === false) {
            ctas = {
                'rate': false,
                'youtube': true,
                'mp3': false,
                'info': true,
                'edit': false,
                'delete': false,
                'bet': false,
                'share': true
            };
        } else {
            if (this.props.profile && typeof this.props.profile.roles['access_mp3'] === 'number') {
                ctas.mp3 = true;
            }
        }

        let statsComponent = false;
        if (isEnabled('playlists:field_judge', 'streamgains-yt')) {
            ctas.bet = true;
            statsComponent = <div style={{marginBottom:40, width:'100%'}}><TrackStatsGraph stats={JSON.parse(node.get('field_stats'))} /></div>
        } else if (isEnabled('playlists:cta', 'bet')) {
            ctas.bet = true;
        }

        let imgUrl = node.get('field_cover', 'url');
        if (node.get('field_yt_id', 'value') && (!imgUrl || imgUrl.indexOf('/video_thumbnails/') > -1)) {
            imgUrl = `https://img.youtube.com/vi/${node.get('field_yt_id', 'value')}/default.jpg`;
        }

        return (<React.Fragment>

            <Card className={classes.card} elevation={0}>
                {imgUrl ? <CardMedia
                        onClick={(e) => this.props.dispatch(playTrack(this.props.track))}
                        className={classes.media}
                        image={imgUrl}
                        title="Track Cover"
                    /> :
                    <div className={classes.media}>
                        <ImageIcon color={'secondary'} fontSize='large' style={{alignSelf: 'center'}}/>
                    </div>}
                <CardContent className={classes.details}>
                    <CardHeader className={classes.header}
                                title={node.get('title')}
                                subheader={node.get('field_artists')}

                    />

                    <TrackActions key={'tactions-' + json.get('id')} track={this.props.track} ctas={ctas}
                                  profile={this.props.profile}
                                  dispatch={this.props.dispatch} />

                  <EnabledFeature feature={'tracks:field_comments'} value={true}>
                      <CommentsList json={json}/>
                  </EnabledFeature>


                    {isEnabled('playlists:cta', 'upgrade') && this.props.profile && this.props.playlist && this.props.profile.uid[0].value === this.props.playlist.uid[0].target_id && this.props.track.node.field_genres.length > 0 ?
                        <CardActions>
                            <AvatarGroup max={9}>
                                {this.props.track.node.field_genres.map(o => (
                                    <Avatar key={o.target_id} classes={{root: classes.genreIcon}} variant={'rounded'}>
                                        {o.url ? <img
                                            style={{width: '100%'}}
                                            src={o.url}
                                            alt={o.target_label}/> : o.target_label}
                                    </Avatar>
                                ))}
                            </AvatarGroup>
                        </CardActions> : ''}
                </CardContent>
            </Card>
                {statsComponent}
            </React.Fragment>
        );
    }
}

TrackListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    track: PropTypes.object.isRequired,
};


const styles = theme => ({
    card: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0,
        position: 'relative'
    },
    header: {
        padding: 0,
        '& .MuiCardHeader-title': {
            display: 'inline',
            fontSize: '1.1rem'
        },
        '& .MuiCardHeader-subheader ': {
            display: 'inline',
            marginLeft: 5
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: '4px!important',
    },
    media: {
        display: 'flex',
        justifyContent: 'center',
        height: 90,
        width: 90,
        cursor: 'pointer',
        alignSelf: 'flex-start',
        background: `radial-gradient(circle at 50% 50%, ${theme.palette.background.paper}, ${theme.palette.primary.main})`,
        backgroundSize: 'cover',
        minWidth: 90,
        minHeight: 90,
        [theme.breakpoints.up('md')]: {
            height: 145,
            width: 145,
            minWidth: 145,
            minHeight: 145
        },
        '& svg': {
            fill: theme.palette.primary.main,
        }
    },
    genreIcon: {
        backgroundColor: theme.palette.background.paper + "!important",
        borderColor: theme.palette.background.paper + "!important"
    },
    statsLine : {
        margin:'0 4px 3px 4px', fontSize:'90%',
        paddingTop:2,
        textAlign:'right',
        borderTop: `.5px ${theme.palette.divider} solid`
    }
});

export default withStyles(styles, {withTheme: true})(TrackListItem);
