import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {withRouter} from 'react-router';
import PaginationBlock from '../../Components/PaginationBlock';
import OverlayLoader from '../../Components/OverlayLoader';
import ItemGrid from '../../Components/ItemGrid';
import StoreHeader from '../../Components/StoreHeader';
import ProgressLoading from '../../Components/ProgressLoading';
import Typography from "@material-ui/core/Typography";
import Drupal2Json from "../../Util/Drupal2Json";
import Grammer from "../../Util/Grammer";
import PmvpFilters from "../../Components/PmvpMatch/PmvpFilters";

class StoreView extends Component {

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname || this.props.location.search !== prevProps.location.search) {
            this.refresh();
        } else if (!prevProps.lists.apiData || !this.props.lists.apiData) {
            // first page
        } else if (prevProps.lists.apiData.metadata.request_time !== this.props.lists.apiData.metadata.request_time) {
            var elIndex = Math.max(this.props.lists.apiData.metadata.end_index - this.props.lists.apiData.metadata.perpage, 0);
            if (elIndex === 0) {
                // do nothing
            } else if (elIndex > 0) {
                var el = document.querySelector('.pBlock:nth-child(' + elIndex + ')');
                if (el) {
                    el.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                }
            } else {
                console.log("WHAT HAPPENED?", prevProps, this.props);
            }
        }
    }

    refresh() {
        return this.props.refreshList(this.props.location.pathname + this.props.location.search);
    }

    render() {
        if (this.props.lists.error) return <div style={{width: '100%', textAlign: 'center', margin: '20px auto'}}>
            <Typography variant='h2'>{this.props.lists.error}</Typography></div>;
        if (!this.props.lists.apiData) return <ProgressLoading/>;
        if (this.props.lists.apiData.metadata.url.indexOf(this.props.location.pathname) !== 0) return <ProgressLoading/>;

        const pagiBlock = <PaginationBlock meta={this.props.lists.apiData.metadata}/>

        let game_type = 0;
        let type = 'default';
        const gid = this.props.match.params && typeof this.props.match.params.gid !== 'undefined' ? this.props.match.params['gid'] : null;
        var gstore = false;
        if (this.props.me.groups && gid > 0 && this.props.me.groups[gid]) {
            if (this.props.me.groups[gid].field_store && this.props.me.groups[gid].field_store.length > 0) {
                gstore = new Drupal2Json(this.props.me.groups[gid].field_store[0].entity);
            }
            if (this.props.lists.apiData.data.length > 0) {
                type = this.props.lists.apiData.data[0].type[0].target_id
            } else if (this.props.me.groups[gid].field_game_type && this.props.me.groups[gid].field_game_type.length > 0) {
                game_type = this.props.me.groups[gid].field_game_type[0].target_id;
            }

        }
        // if (store === false) return 'This group has no store configured';

        // TODO: get store
        // define buy CTA by group / product type

        return (
            <div style={{position: 'relative', width: '100%', paddingTop: 10}}>
                {(this.props.lists.error === true) ?
                    <Grid item xs={12}><Typography variant='h2'>{this.props.lists.error}</Typography></Grid> : null}


                {(gstore === false) ? '' : <StoreHeader gstore={gstore}/>}
                {(type === 'pickup_match' || game_type === 869) &&
                    <PmvpFilters gid={gid} metadata={this.props.lists.apiData.metadata} me={this.props.me}
                                 dispatch={this.props.dispatch}/>}

                {(this.props.lists.apiData.data.length === 0) ?
                    <Typography style={{margin:'20px auto', textAlign:'center'}} variant='h6'>There are no {Grammer.g('Rewards', 'p')} in
                        this {Grammer.g('Group', 's')} yet</Typography>
                    :
                    <ItemGrid lData={this.props.lists.apiData} me={this.props.me} dispatch={this.props.dispatch}
                              curGroup={gid}/>
                }

                {pagiBlock}

                {(this.props.lists.loading === true) ? <OverlayLoader/> : null}

            </div>
        );
    }
}

export default withRouter(StoreView);
