const manifest = require('./apps/' + process.env.REACT_APP_TACLIENT_DIR + '/manifest.json');

const Config = {
 api: {
  base: document.location.hostname.indexOf("qa.") === 0 ? process.env.REACT_APP_STAGE_URL : process.env.REACT_APP_API_URL, // set in .env
  cdn:process.env.REACT_APP_CDN_URL,
  gid: process.env.REACT_APP_GID,
  client_host: 'https://trackauthoritymusic.com',
  client_id: process.env.REACT_APP_OAUTH2_ID,
  client_secret: process.env.REACT_APP_OAUTH2_SECRET,
  tokName: 'd8Auth_' + process.env.REACT_APP_TACLIENT_DIR
 },
 google: {
  key: process.env.REACT_APP_GOOGLE_ID,
  client:'364436864658-jdftfkif2diq5gl00n2l1tfka7rbbota.apps.googleusercontent.com',
  secret:'R0Aks08GBUav8OeimOg6p3RR'
 },
 allowedTags: ['blockquote', 'p', 'ul', 'li', 'ol', 'dl', 'dd', 'dt', // https://www.npmjs.com/package/sanitize-html
  'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
  'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'h2', 'h3', 'h4', 'h5', 'h6',
  'sup', 'sub', 'center', 'button'],
 allowedAttributes: { 'a': ['href'], '*' : ['id', 'style', 'data-toggle', 'data-target', 'aria-label', 'role', 'class'], 'img':['src', 'height', 'width'] },
 ...manifest
};

if (process.env.NODE_ENV !== 'production') {
 console.log(process.env, Config);
}


export default Config;
