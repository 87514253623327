import FaqView from './FaqView';
import {connect} from 'react-redux';
import {helpList} from '../../redux/helpReducer';
import {_showNotice} from '../../redux/formsReducer';
import {getParam} from '../../Util/WindowUtils';

const mapStateToProps = (state) => {
 var newState = {help:state.help};
 newState.topicId = parseInt(getParam('topicId', state.router.location.search, 804));
 newState.faqId = parseInt(getParam('faqId', state.router.location.search, -1));
 newState.ctaId = parseInt(getParam('ctaId', state.router.location.search, -1));
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  helpList: (url) => {
   dispatch(helpList(url));
  },
  showNotice : (msg, variant) => {
   dispatch(_showNotice(msg, variant));
  }
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqView);
