import {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

// we should probably replace these with connected implementation?
export const isEnabled = (key, value) => {
  return window.store.getState().auth.gFeatures[key] === value;
};

export const hasEnabled = (key, value) => {
  return window.store.getState().auth.gFeatures[key] && window.store.getState().auth.gFeatures[key].indexOf(value) > -1;
};

class EnabledFeature extends Component {

  isEnabled() {
    return this.props.gFeatures[this.props.feature] === this.props.value;
  };

  hasEnabled() {
    return this.props.gFeatures[this.props.feature] && this.props.gFeatures[this.props.feature].indexOf(this.props.value) > -1;
  };

  greaterOrEqual() {
    return this.props.gFeatures[this.props.feature] && this.props.gFeatures[this.props.feature] >= this.props.value;
  };

  render() {
    if (this.props.bypass === true) return this.props.children;
    let method = 'isEnabled';
    if (['greaterOrEqual', 'hasEnabled', 'isEnabled'].indexOf(this.props.condition) > -1) {
      method = this.props.condition;
    }
    if (this[method]() === true) {
      return this.props.children;
    }
    return this.props.placeholder || null;
  }
}

EnabledFeature.defaultProps = {
  condition:'isEnabled'
}

EnabledFeature.propTypes = {
  feature: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  condition: PropTypes.string.isRequired,
  placeholder:PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    gFeatures:state.auth.gFeatures
  };
};

export default connect(mapStateToProps,null)(EnabledFeature);
