import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import RatingSliderPlaylist from '../RatingSliderPlaylist';
import RateIcon from '@material-ui/icons/Stars';
import PropTypes from 'prop-types';
import {getParam} from '../../Util/WindowUtils';
import RatingTally from "./RatingTally";

class RatingStarPlaylist extends Component {

 constructor(props) {
  super(props);
  this.state = {showSlider:(this.props.showSlider === true || getParam('showVotes', document.location.search) === 'playlists'), defaultValue:0};

  // CALC default
  this.ratingComplete = this.ratingComplete.bind(this);
 }

 ratingComplete(newDefault) {
  let obj = {showSlider:false};
  if (newDefault) {
   obj.defaultValue = newDefault;
  }
  this.setState(obj);
 }

 render() {
  if (this.state.showSlider === true) {
   return <RatingSliderPlaylist
    {...this.props}
    ratingComplete={this.ratingComplete}
    defaultValue={this.state.defaultValue}  />;
  }

  if (this.props.displayAs === 'slider') {
     return <RatingTally showZero={true} countType={'avg'} displayAs={this.props.displayAs}
                         vote_types={this.props.pjson.json.field_vote_type}
                         gid={this.props.pjson.get('gid', 'target_id')}
                         handleClick={(e) => this.setState({showSlider:true})}
               />
  }

  return (
   <IconButton aria-label={`Rate Playlist ${this.props.pjson.get('id', 'value')}`}
               onClick={(e) => this.setState({showSlider:true})}
               fontSize={'large'}
               style={{paddingRight:'17px'}}>
    <Badge badgeContent={ this.state.defaultValue > 0 ? <span>{this.state.defaultValue}<sup>%</sup></span> : 'vote' }
           color={this.props.color} classes={{badge:this.props.classes.badge}}>
     <RateIcon fontSize={'large'} />
    </Badge>


   </IconButton>
  );
 }
}

const styles = () => ({
 badge : {
  fontSize:10,
  height:13,
  padding:'1px 3px 0 3px;',
  top: 11,
  right: -3
 }
});

RatingStarPlaylist.defaultProps = {
 color:'primary',
 metadata : {sort:'avg'},
 displayAs:'star'
};

RatingStarPlaylist.propTypes = {
 color:PropTypes.string,
 pjson : PropTypes.object.isRequired,
 displayAs : PropTypes.string,
 metadata : PropTypes.object
};

export default withStyles(styles)(RatingStarPlaylist);
