import React, {Component} from 'react';
import API from "../../Util/API";
import {withRouter} from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FileCopy from "@material-ui/icons/FileCopy";
import Tag from "@material-ui/icons/Label";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import SanitizedHTML from "react-sanitized-html";
import IconButton from "@material-ui/core/IconButton";
import {Close, Edit} from "@material-ui/icons";
import UserTagForm from "../users/UserTagForm";
import {loadForm} from "../../redux/formsReducer";

class ClipAction extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false, alltags: this.props.clip.json.field_user_tags || []};
        this.handleUserTag = this.handleUserTag.bind(this)
        this.addToPlaylist = this.addToPlaylist.bind(this)
        this.cloneClip = this.cloneClip.bind(this)
    }


    handleUserTag(index, user_tag) {
        console.log("ADD TAG TO CLIP ", user_tag);
        if (this.state.savingChanges === true) return false;
        this.setState({savingChanges: true});

        const tags = [...this.state.alltags];
        tags[index] = user_tag;

        let apiurl = `/forms/${this.props.entityLink}/edit`
        API.Put(apiurl, {field_user_tags:tags}).then((res) => {
            if (res.data.success) {
                this.props.enqueueSnackbar(res.data.success, {variant: 'warning'});
                window.logUse.logEvent('clip_edit', this.tdata);
            } else {
                this.props.enqueueSnackbar(res.data.error || 'Unknown error', {variant: 'error'});
            }
            this.setState({promptSave: false, savingChanges: false});
        }).catch((err) => {
            window.logUse.logEvent('clip_edit_failed', this.tdata);
            var msg = API.getErrorMsg(err);
            this.setState({savingChanges: false});
            this.props.enqueueSnackbar(msg, {variant: 'error'});
        });
    }

    promptDelete(apiurl) {
        if (window.confirm('Are you sure you want to delete this clip?')) {
            API.Delete(apiurl)
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        this.props.enqueueSnackbar(msg, {variant: 'error'});
                    } else {
                        this.props.enqueueSnackbar(res.data.success, {variant: 'info'});
                        this.props.history.go(0)
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.enqueueSnackbar(msg, {variant: 'error'});
                    return err;
                });
        }
    }

    notice(variant) {
        return {
            variant: variant, persist: true, action: key => (
                <IconButton variant="outline-light"
                            onClick={() => {
                                this.props.closeSnackbar(key);
                            }}
                >
                    <Close/>
                </IconButton>
            )
        }
    }


    addToPlaylist() {
        this.setState({loading: true});
        API.Put(`/forms${this.props.entityLink}/replay`).then((res) => {
            this.props.enqueueSnackbar(<SanitizedHTML html={res.data.success} />, this.notice('success'));
            this.setState({loading: true});
        }).catch((err) => {
            const msg = API.getErrorMsg(err);
            this.props.enqueueSnackbar(<SanitizedHTML html={msg} />, this.notice('error'));
            this.setState({loading: false});
        });
    }

    cloneClip(link) {
        API.Put(`/forms${this.props.entityLink}/clone`).then((res) => {
            // this.props.dispatch(populateTrack(res.data));
            console.log(res.data);
            // this.reload()
        }).catch((err) => {
            alert(API.getErrorMsg(err));
            this.setState({loading: false});
        });
    }

    render() {
        const {classes} = this.props;
        const {entityLink, clip} = this.props;
        const inPage = [];

        if (clip.get('field_playlist_gc', 'target_id') > 0) {
            //
        } else {
            inPage.push(<Grid item key={`addtoplaylist-${entityLink}`}><Button
                aria-label={`Add to Replay ${clip.get('id', 'value')}`}
                variant={'outlined'}
                startIcon={<PlaylistAdd color={this.props.color}/>}
                onClick={this.addToPlaylist}>
                Add to Replay
            </Button></Grid>);
        }


        inPage.push(<Grid item key='clone clip'><Button variant={'outlined'}
                                                        aria-label={`Clone Clip ${clip.get('id', 'value')}`}
                                                        startIcon={<FileCopy color={this.props.color}/>}
                                                        onClick={this.cloneClip}
                                                        className={classes.actionLine}>
            Clone
        </Button></Grid>);

        if (clip.can_delete(this.props.me) === true) {
            inPage.push(<Grid item key='delete clip'><Button variant={'outlined'}
                                                             startIcon={<DeleteIcon color={this.props.color}/>}
                                                             onClick={() => this.promptDelete(`/forms${this.props.entityLink}/delete`)}
                                                             className={classes.actionLine}>
                Delete
            </Button></Grid>);
        }

        if (clip.can_edit(this.props.me) === true) {
            inPage.push(<Grid item key='edit clip'><Button variant={'outlined'}
                                                             startIcon={<Edit color={this.props.color}/>}
                                                             onClick={() => this.props.dispatch(loadForm(`/forms${this.props.entityLink}/edit`, 'dialog'))}
                                                             className={classes.actionLine}>
                Edit
            </Button></Grid>);
        }


        return (
            <Grid container style={{gap:8}} justifyContent={inPage.length === 1 ? 'space-between' : 'space-around'} alignItems='center'>
                {inPage}

                {this.state.alltags.map((tag, i) => <Grid key={`tagform${entityLink}-${i}`} item xs={12} style={{margin:'25px auto'}}>
                    <UserTagForm clip={clip}
                                 user_tag={tag}
                                 entityLink={entityLink}
                                 onSubmit={(payload) => this.handleUserTag(i, payload)} />
                </Grid>)}

                {this.state.alltags.length === 0 && this.state.expanded === false ?
                    <Button variant={'outlined'}
                            startIcon={<Tag color={this.props.color}/>}
                            onClick={() => this.setState({expanded:!this.state.expanded})}
                            className={classes.actionLine}>
                        Add Tags
                    </Button>
                    :
                    <Grid item xs={12}>
                        <UserTagForm clip={clip}
                                     user_tag={null}
                                     entityLink={entityLink}
                                     onSubmit={(payload) => this.handleUserTag(this.state.alltags.length, payload)}/>
                    </Grid>
                }
            </Grid>
        );

    }
}

ClipAction.defaultProps = {
    color: 'primary',
    ctas: {
        'rate': false,
        'info': false,
        'edit': false,
        'tag': true,
        'delete': false,
        'upgrade': true,
        'share': false
    }
};

ClipAction.propTypes = {
    color: PropTypes.string,
    ctas: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    me: PropTypes.any
};

const styles = theme => ({
    actionLine: {
        display: 'flex',
        alignItems: 'center'
    },
    actionItem: {
        marginBottom: 15
    }
});


const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    newState.forms = state.forms;
    if (!props.gFeatures) {
        newState.gFeatures = state.auth.gFeatures;
    }
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(withRouter(withSnackbar(ClipAction))));
