import React from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import SanitizedHTML from 'react-sanitized-html';
import CharitySelector from '../../Components/CharitySelector';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {sendTransfer} from '../../redux/walletReducer';
import Config from '../../Config';

const styles = theme => ({
 card: {
  width:'100%',
  maxWidth:960,
  justifyContent:'center',
  marginBottom:15
 },
 header : {
  margin:0,
  paddingTop: theme.spacing(2),
  fontWeight:'normal',
  textAlign:'center',
  color: theme.palette.text.secondary,
  width:'100%',
  '& a' : {
   textDecoration:'none'
  }
 },
 media: {
  height: 0,
  paddingTop: '56.25%', // 16:9
 },
 content : {
  padding:theme.spacing(1),
  textAlign:'center'
 },
 placeholder : {
  color: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
 },
 actions: {
  padding:theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems:'center',
  alignContent:'center',
  justifyContent: 'space-between'
 },
 inlineFields: { // some classes are sent to CharitySelector
  display: 'flex',
  width:'100%'
 }
});

class ProductCashTransfer extends React.Component {

 contribute(e) {
  var formId = e.currentTarget.form.name;
  var amount = parseInt(document.forms[formId].amount.value, 0);

  const obj = {
   'amount': amount,
   'currency' : document.forms[formId].currency.value,
   'paymethod' : -1,  // TODO: stored method
   'recipient' : document.forms[formId].recipient.value, // group owner
  };

  var percent = document.forms[formId].charity_percent.value;
  var charity = document.forms[formId].charity.value;

  if (percent > 0) {
   obj.charity = charity;
   obj.charity_percent = percent;
  }

  return this.props.dispatch(sendTransfer(obj));

 }

 render() {
  const { classes } = this.props;
  const item = {...this.props.data};
  const json = new Drupal2Json(item);
  const variation = new Drupal2Json(item.variations[0]);

  // TODO: display wallet.error

  return (
   <Card className={['pBlock', classes.card].join(' ')} >

    <CardHeader
     title={json.get('title', 'value')}
    />

    {json.get('body', 'value') ?
     <CardContent className={classes.content}>
      <SanitizedHTML html={json.get('body', 'value')}
       allowedAttributes={Config.allowedAttributes}
       allowedTags={Config.allowedTags.concat('a')}
      />
     </CardContent>
     : ''
    }
    <form name='contributeToFund' >
     <input name='recipient' value={variation.get('field_recipient', 'target_id')} type="hidden" />
     <input name='currency' value={variation.get('price', 'currency_code')} type="hidden" />
     <div className={classes.actions} >
      <Input
       id="adornment-amount"
       name="amount"
       defaultValue={parseFloat(variation.get('price', 'number')) > 0 ? parseFloat(variation.get('price', 'number'), 2) : ''}
       type='number'
       startAdornment={<InputAdornment position="start">$</InputAdornment>}
      />
      <Button onClick={
       e =>
        this.contribute(e)
      } color="primary">Contribute</Button>
     </div>

     { json.get('type', 'target_bundle') === 'funds_transfer' ?
      <CharitySelector {...this.props} /> : ''
     }
    </form>

   </Card>
  );
 }
}

ProductCashTransfer.propTypes = {
 classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCashTransfer);
