import React, {Component} from 'react';
import AutoCompleteEntity from '../../Components/AutoCompleteEntity';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router';
import {getParam} from '../../Util/WindowUtils';
import API from "../../Util/API";
import FormInstructions from "../../Components/FormInstructions";
import {withStyles} from "@material-ui/core";

const pField = {
 "field_name": "entity_id",
 "label": "Playlist Title",
 "description": "Branch an existing titles or create a new one",
 'placeholder':'Search titles or write your own',
 "type": "entity_reference",
 "bundle": "groups-group_node-playlists",
 "cardinality": 1,
 "default_value": [],
 "settings": {
  "target_type": "node",
  "handler": "default",
  "handler_settings": {
   "target_bundles": ["playlists"]
  }
 },
 "#type": "container",
 "#required": false,
 "#weight": 0,
 "widget": [{
  "#required": true
 }]
};

class NewPlaylist extends Component {

 constructor(props) {
  super(props);
  this.state = {q:'', instructions:{caption:false, step1:false}};
  this.handleNew = this.handleNew.bind(this);
  this.handleInputChange = this.handleInputChange.bind(this);
  this.handleSelection = this.handleSelection.bind(this);
 }

 componentDidMount(){
  if (!this.props.me || !this.props.me.profile) {
   return this.props.history.push(`/login`);
  }

  let formurl = this.props.location.pathname.replace('/start', '/add');

  let preload = getParam('title', this.props.location.search, '');
  if (preload.length > 0) {
   this.props.history.push(formurl + '?title='+ preload, 'page');
  } else {
   preload = getParam('nid', this.props.location.search, '');
   if (preload.length > 0) {
    this.props.history.push(formurl + '?nid='+ preload, 'page');
   } else {
    const that = this;
    API.Get('/faqs/instructions/groups-group_node-playlists/' + this.props.match.params.gid).then((res) => {
     that.setState({instructions:res.data});
    }).catch((err) => {
     console.log(API.getErrorMsg(err));
    });
   }
  }
 }

 handleInputChange(val) {
  this.setState({q:val});
 }

 handleNew(e) {
  e.stopPropagation();
  let formurl = this.props.location.pathname.replace('/start', '/add');
  this.props.history.push(formurl + '?title='+ this.state.q, 'page');
  return false;
 }

 handleSelection(value) {
  if (value.target_id > 0) {
   let formurl = this.props.location.pathname.replace('/start', '/add');
   this.props.history.push(formurl + '?nid='+ value.target_id, 'page');
  }
 }

 render() {

  // WARN: AutoCompleteEntity may need unique ID from dialog version
  return (
   <Grid container key={'empty-playlistform'} style={{width:'100%', padding:10}} direction="column" justifyContent="center" alignContent="stretch" alignItems="stretch" >
    { this.state.instructions.caption || this.state.instructions.step1 ? <FormInstructions step={'step1'} instructions={this.state.instructions} /> : ''}
    <div style={{backgroundColor:this.props.theme.palette.action.selected}}>
     <AutoCompleteEntity
      apiurl={'/autocomplete/playlists/:query'}
      onInputChange={this.handleInputChange}
      onSelected={this.handleSelection}
      source='playlists'
      name="title"
      placeholder='Search titles or write your own'
      field={pField} entry={[]} index={0} />
    </div>
     <Button color='primary' id='createOrBranchBtn' variant='contained' disabled={this.state.q.length < 3} onClick={this.handleNew} style={{marginTop:15}}>Create</Button>
   </Grid>);
 }
}

export default withRouter(withStyles(null, {withTheme:true})(NewPlaylist));
