import React, {Component} from 'react';
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import Drupal2Json from '../../Util/Drupal2Json';
import GroupBlock from '../../Components/GroupBlock';
import PlaylistBlock from '../../Components/PlaylistBlock';
import ProductGift from '../../Components/ProductGift';
import ProductCashTransfer from '../../Components/ProductCashTransfer';
import ProductReferral from '../../Components/ProductReferral';
import TrackTile from '../../Components/TrackTile';
import Masonry from "react-masonry-css";
import {withStyles} from "@material-ui/core/styles";
import ProductBase from "../Products/ProductBase";
import EventTicketsBlock from "../ProductEvents/EventTicketsBlock";
import Grid from "@material-ui/core/Grid";
import MemberBlock from "../MemberBlock";
import RatingItem from "../RatingItem/RatingItem";
import PickupMatchBlock from "../ProductEvents/PickupMatchBlock";
import Replay from "../../core/playlists/Replay";
import HighlightBlock from "../../core/clips/HighlightBlock";

const breakpoints = {
    default: 3,
    900: 2,
    600: 1,
};

class ItemGrid extends Component {

    render() {
        if (!this.props.lData) return '';
        if (this.props.lData.data.length === 0) {
            if (document.location.pathname.indexOf('/search') > -1) return null;
            return 'no content yet';
        }

        let list = [], type = null, typesShowing = {};

        for (let f in this.props.lData.data) {
            let item = this.props.lData.data[f];
            let json = new Drupal2Json(item);
            type = json.get('type', 'target_id');
            typesShowing[type] = true;
            let canEdit = json.can_edit(this.props.me) === true;

            let elevation = this.props.theme.palette.type === 'light' ? 4 : 8;

            let key = type + '_' + f + this.props.lData.metadata.request_time; // TODO: use id | target_id : key might duplicate on pagination if appending data rather than replacing

            if (this.props.showLocation === true) {

            }

            if (json.get('type', 'target_type') === 'commerce_product') {
                if (type === 'amazon_associates') {
                    list.push(<Paper elevation={elevation} key={key}><ProductReferral data={item} me={this.props.me}
                                                                                      canEdit={canEdit}
                                                                                      dispatch={this.props.dispatch}
                                                                                      curGroup={this.props.curGroup}/></Paper>);
                } else if (type === 'funds_transfer') {
                    list.push(<Paper elevation={elevation} key={key}><ProductCashTransfer data={item} me={this.props.me}
                                                                                          canEdit={canEdit}
                                                                                          dispatch={this.props.dispatch}
                                                                                          curGroup={this.props.curGroup}/></Paper>);
                } else if (type === 'sponsorship') {
                    list.push(<Paper elevation={elevation} key={key}><ProductGift data={item} me={this.props.me}
                                                                                  canEdit={canEdit}
                                                                                  dispatch={this.props.dispatch}
                                                                                  curGroup={this.props.curGroup}/></Paper>);
                } else if (type === 'event_tickets') {
                    list.push(<Paper elevation={elevation} key={key}><EventTicketsBlock product={item}
                                                                                        me={this.props.me}
                                                                                        canEdit={canEdit}
                                                                                        dispatch={this.props.dispatch}
                                                                                        curGroup={this.props.curGroup}/></Paper>);
                } else if (type === 'pickup_match') {
                    list.push(<Paper elevation={elevation} key={key}><PickupMatchBlock product={item} me={this.props.me}
                                                                                       canEdit={canEdit}
                                                                                       dispatch={this.props.dispatch}
                                                                                       curGroup={this.props.curGroup}/></Paper>);
                } else {
                    list.push(<Paper elevation={elevation} key={key}><ProductBase product={item} me={this.props.me}
                                                                                  canEdit={canEdit}
                                                                                  dispatch={this.props.dispatch}
                                                                                  curGroup={this.props.curGroup}/></Paper>);
                }
            } else if (item.rating > 0) {
                typesShowing['ratings'] = true;
                if (type === 'groups-group_node-tracks' || type === 'groups-group_node-clips') {
                    list.push(<Grid item key={key} style={{margin: '4px auto'}}><RatingItem item={item}/></Grid>);
                } else if (type === 'groups-group_node-playlists') {
                    list.push(<Grid item key={key} style={{margin: '4px auto'}}><RatingItem item={item}/></Grid>);
                }
            } else {
                if (type === 'groups-group_node-playlists') {
                    if (!canEdit) canEdit = json.isGroupAdmin(this.props.me);
                    list.push(<Paper elevation={elevation}
                                     key={key}>{(json.get('field_category', 'target_id') === 868) ?
                        <Replay jreplay={json} me={this.props.me}
                                       canEdit={canEdit}
                                       dispatch={this.props.dispatch} />
                        : <PlaylistBlock data={item} me={this.props.me}
                                         canEdit={canEdit}
                                         dispatch={this.props.dispatch}
                                         curGroup={this.props.curGroup}/>}
                    </Paper>);
                }
                if (type === 'tracks') {
                    list.push(<TrackTile key={key} track={{node: item}} dispatch={this.props.dispatch} containerWidth={'grid'}/>);
                } else if (type === 'groups-group_node-tracks') {
                    list.push(<TrackTile key={key} track={item} dispatch={this.props.dispatch} containerWidth={'grid'}/>);
                } else if (type === 'groups-group_node-clips') {
                    list.push(<HighlightBlock key={key  + item.id[0].value} clip={item} dispatch={this.props.dispatch} me={this.props.me} />);
                } else if (type === 'groups') {
                    //if (!json.get('field_parent_group', 'target_id') || json.get('field_parent_group', 'target_id') == json.get('id')) {
                    list.push(<Paper elevation={elevation} key={key}><GroupBlock data={item} me={this.props.me}
                                                                                 dispatch={this.props.dispatch}/></Paper>);
                    // }
                } else if (type === 'groups-group_membership') {
                    list.push(<Paper elevation={elevation} key={key}><MemberBlock data={item}
                                                                                  me={this.props.me}/></Paper>);
                }
            }
        }

        if (typeof typesShowing['ratings'] === 'undefined' && (type === 'groups-group_node-tracks' || type === 'tracks')) {
            return (<GridList spacing={8} cellHeight={120} style={{width: '100%'}}>{list}</GridList>);
        }

        if (list.length < 3) breakpoints.default = list.length;

        return <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {list}
        </Masonry>
    }
}

export default withStyles(null, {withTheme: true})(ItemGrid);
