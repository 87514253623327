import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

class FieldList extends Component {
 render() {
  const entity = this.props.entity;
  const header = this.props.header;
  const json = new Drupal2Json(entity);

  return (
   <List
    component="nav"
    dense={true}
    subheader={(header) ? <ListSubheader component="div">{header}</ListSubheader> : ''}
   >
    {Object.entries(this.props.map).map(arr => {
     if (json.count(arr[0]) < 1) return '';

     var entries = [];
     for(var delta in entity[arr[0]]) {
      var val = false;
      if (typeof entity[arr[0]][delta]['value'] !== 'undefined') {
       val = json.get(arr[0], 'value', delta);
      } else if (typeof entity[arr[0]][delta]['value'] !== 'undefined') {
       val = json.get(arr[0], 'target_label', delta);
      }
      entries.push(
       <ListItem key={arr[0] + '-' + delta} >
        <ListItemText primary={val} secondary={arr[1]} style={{whiteSpace:'nowrap'}} />
       </ListItem>
      );
     }
     return entries;
    })}
   </List>
  );
 }
}

export default FieldList;
