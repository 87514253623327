import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import API from "../../Util/API";
import FootageBlock from "./FootageBlock";
import Grid from "@material-ui/core/Grid";
import PaginationBlock from "../../Components/PaginationBlock";

class FootageLoader extends Component {

    constructor(p) {
        super(p);
        this.state = {footage: false, allfootage:false}
    }
    componentDidMount() {
        this.refresh()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.fid !== prevProps.match.params.fid) {
            this.refresh();
        }
    }

    refresh() {
        let apiurl = `/group/${this.props.match.params.gid}/rewards/${this.props.match.params.rid}/footage`;
        if (this.props.match.params.fid) {
            apiurl += `/${this.props.match.params.fid}`
            API.Get(apiurl).then((res) => {
                this.setState({footage: res.data, allfootage:false});
            }).catch((err) => {
                alert(API.getErrorMsg(err));
                this.setState({loading: false});
            });
        } else {
            API.Get(apiurl).then((res) => {
                this.setState({allfootage: res.data, footage:false});
            }).catch((err) => {
                alert(API.getErrorMsg(err));
                this.setState({loading: false});
            });
        }
    }


    render() {
        if (this.state.footage) return <FootageBlock footage={this.state.footage} expanded={true} />;

        if (this.state.allfootage) {
            let breakpoints = {xs:12}
            if (this.state.allfootage.data.length > 2) {
                breakpoints.sm = 6;
                breakpoints.md = 4;
            }
            return <div>
                <Grid container justifyContent={'space-around'} spacing={1}>
                    {this.state.allfootage.data.map((footage, i) => <Grid {...breakpoints} item key={`footage${i}`} ><FootageBlock footage={footage} expanded={false}/></Grid>)}
                </Grid>
                {this.state.allfootage.metadata.total_items > this.state.allfootage.data.length
                    &&
                    <PaginationBlock meta={this.state.allfootage.metadata}/>
                }</div>
        }
        return 'loading...'
    }
}

FootageLoader.propTypes = {
    match: PropTypes.object.isRequired,
};

export default withRouter(FootageLoader)
