import React, {Component} from 'react';

import Avatar from '@material-ui/core/Avatar';

import GroupIcon from '@material-ui/icons/Group';
import GroupAdd from '@material-ui/icons/GroupAdd';
import SearchIcon from '@material-ui/icons/Search';
import QueueMusic from '@material-ui/icons/QueueMusic';
import LibraryMusic from '@material-ui/icons/LibraryMusic';
import SpaIcon from '@material-ui/icons/Spa';
import HowToReg from '@material-ui/icons/HowToReg';
import WCIcon from '@material-ui/icons/Wc';
import PersonPin from '@material-ui/icons/PersonPin';
import AlbumIcon from '@material-ui/icons/Album';
import CasinoIcon from '@material-ui/icons/Casino';
import HelpIcon from '@material-ui/icons/LiveHelp';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import GavelIcon from '@material-ui/icons/Gavel';
import Apartment from "@material-ui/icons/Apartment";
import Sports from "@material-ui/icons/Sports";
import StarHalf from "@material-ui/icons/StarHalf";
// import HistoryEdu from "@material-ui/icons/HistoryEdu";
import DateRange from "@material-ui/icons/DateRange";
// import HowToVote from '@material-ui/icons/HowToVote';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ReactComponent as SignInButton} from '../../images/SignInButton.svg';
import {ReactComponent as SignUpButton} from '../../images/SignUpButton.svg';
import {ReactComponent as Campaign} from '../../images/campaign-24px.svg';
import {ReactComponent as Policy} from '../../images/policy-24px.svg';
import {ReactComponent as Mediation} from '../../images/mediation-24px.svg';
import {ReactComponent as FaqDice} from '../../images/FaqDice.svg';
import {ReactComponent as HistoryEdu} from '../../images/history_edu_black_24dp.svg';
import PropTypes from "prop-types";

class TamIcon extends Component {
 render() {
  const { iconName, noavatar, color } = this.props;
  let icon = false;

  if (iconName === 'author') {
   icon = <SvgIcon component={HistoryEdu} style={{height:24, width:24}} color={color} />
  } else if (iconName === 'Campaign') {
   icon = <SvgIcon component={Campaign} style={{height:24, width:24}} color={color} />;
  } else if (iconName === 'DateRange') {
   icon = <DateRange color={color} />;
  } else if (iconName === 'Apartment') {
   icon = <Apartment color={color} />;
  } else if (iconName === 'Gavel') {
   icon = <GavelIcon color={color} />;
  } else if (iconName === 'Policy') {
   icon = <SvgIcon component={Policy} style={{height:24, width:24}} color={color} />;
  } else if (iconName === 'Mediation') {
   icon = <SvgIcon component={Mediation} style={{height:24, width:24}} color={color} />;
  } else if (iconName === 'WalletIcon') {
   icon = <WalletIcon color={color} />;
  } else if (iconName === 'menuMusic' || iconName === 'tracks') {
   icon = <LibraryMusic color={color} />;
  } else if (iconName === 'PersonPin' || iconName === 'members') {
   icon = <PersonPin color={color} />;
  } else if (iconName === 'Search') {
   icon = <SearchIcon color={color} />;
  } else if (iconName === 'Sports') {
   icon = <Sports color={color} />;
  } else if (iconName === 'StarHalf') {
   icon = <StarHalf color={color} />;
  } else if (iconName === 'casinoIcon') {
   icon = <CasinoIcon color={color} />;
  } else if (iconName === 'playlistAdd') {
   icon = <PlaylistAdd color={color} />;
  } else if (iconName === 'groupAdd') {
   icon = <GroupAdd color={color} />;
  } else if (iconName === 'helpIcon') {
   icon = <HelpIcon color={color} />;
  } else if (iconName === 'FaqDice') {
   icon = <SvgIcon component={FaqDice} style={{height:30, width:30}} color={color}  />;
  } else if (iconName === 'licenseMusic') {
   icon = <QueueMusic color={color} />;
  } else if (iconName === 'signIn') {
   icon = <SvgIcon component={SignInButton} style={{height:24, width:24}} color={color} />;
  } else if (iconName === 'signUp') {
   icon = <SvgIcon component={SignUpButton} style={{height:24, width:24}} color={color} />;
  } else if (iconName === 'miniGoldCD') {
   icon = <img alt="cd music icon" src='/assets/cdMusic.png' color={color} style={{height:24, width:24}} />;
  } else if (iconName === 'menuReward' || iconName === 'rewards') {
   icon = <SpaIcon color={color} />;
  } else if (iconName === 'presentBoxMini') {
   icon = <img alt='present icon' src='/assets/presentBoxMini.png'  />;
  } else if (iconName === 'weddingProfile') {
   icon = <WCIcon color={color} />;
   //icon = <img alt='present icon' src='/assets/wedding-rings.png' />;
  } else if (iconName === 'albumIcon' || iconName === 'playlists') {
   icon = <AlbumIcon color={color} />;
  } else if (iconName === 'guestList') {
   icon = <HowToReg color={color} />;
  } else if (iconName === 'menuGroup' || iconName === '%gmenuicon') {
   icon = <GroupIcon color={color} />;
  } else if (!iconName) {
   return '';
  } else if (iconName.indexOf('http') === 0) {
   icon = <img alt='icon' src={iconName} style={{maxWidth:'100%', maxHeight:'100%'}} />;
  } else {
   icon = iconName.substr(0,3);
  }
  if (noavatar) return icon;
  return (
   <Avatar color={color}>
    {icon}
   </Avatar>
  );
 }
}

PropTypes.propTypes = {
 iconName: PropTypes.string.isRequired,
 noavatar: PropTypes.bool,
 color: PropTypes.string.isRequired,

}

export default TamIcon;
