import React, {Component} from 'react';
import {connect} from "react-redux";
import {listData} from '../../redux/listDataReducer';
import {listEmails} from '../../redux/emailReducer';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import API from "../../Util/API";
import {storeTrackList} from "../../redux/playerReducer";
import EnabledFeature from "../../Util/EnabledFeature";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import {Link} from "react-router-dom";
import Drupal2Json from "../../Util/Drupal2Json";
import {getIdbySegment} from "../../redux/authActions";
import {withRouter} from "react-router";
import Grammer from "../../Util/Grammer";

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: '5px auto 5px auto',
        padding: '0 5px',
        fontSize: 14,
        width: '100%',
        flexGrow: 1,
        color: theme.palette.grey[500],
    },
    title: {
        borderLeft: '0.1em solid ' + theme.palette.grey[400],
        paddingLeft: theme.spacing(1),
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'

    },
    pagiPart: {
        marginLeft: theme.spacing(3),
    },
    selector: {
        color: theme.palette.grey[500],
        minWidth:70,
        fontSize: 14
    },
    fabBtn: {
        margin: 10,
        textAlign: 'right'
    }
});

class PaginatonBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {perpage: this.props.meta.perpage, curPage: 1};
        if (document.location.search) {
            this.state.curPage =  Math.ceil((this.props.meta.start_index - 1) / this.state.perpage) + 1;
        }
    }

    buildPageUrl(start) {
        let url = this.props.meta.url + '?start_index=' + start + '&perpage=' + this.state.perpage;
        let extras = ['cat', 'seed', 'sort', 'status', 'methods', 'currencies', 'types'];
        extras.forEach(e => {
            if (this.props.meta[e]) {
                url += `&${e}=${this.props.meta[e]}`;
            }
        })
        return url;
    }

    changePagi(param, value) {
        let newMeta = {...this.props.meta};
        newMeta[param] = value;
        if (param === 'start_index') {
            newMeta['end_index'] = value + newMeta['perpage'];
        } else if (param === 'perpage') {
            if (newMeta['start_index'] + newMeta['perpage'] > newMeta['total_items']) {
                newMeta['start_index'] = Math.max(0, newMeta['total_items'] - newMeta['perpage']);
            }
            // newMeta['start_index'] = newMeta['end_index'];
            newMeta['end_index'] = newMeta['start_index'] + newMeta['perpage'];
        }

        if (newMeta['start_index'] > newMeta['total_items']) newMeta['start_index'] = newMeta['total_items'] - 1;
        if (newMeta['end_index'] > newMeta['total_items']) newMeta['end_index'] = newMeta['total_items'];

        //this.props.onChange(newMeta);
        let url = this.buildPageUrl(newMeta.start_index);

        if (this.props.playlist) {
            API.Get(url).then((res) => {
                this.props.dispatch(storeTrackList(this.props.playlist.gid[0].target_id, this.props.playlist.id[0].value, res.data, true));
            });
        } else if (newMeta.type === 'emails') {
            this.props.dispatch(listEmails(url));
        } else {
            this.props.dispatch(listData(url));
        }
    }

    handlePerPageChange(event) {
        this.setState({'perpage': event.target.value}, () => {
            this.changePagi('perpage', event.target.value);
        });

    }

    render() {


        const createLink = `/forms${this.props.meta.url}/${this.props.meta.type === 'playlists' ? 'start' : 'add'}`;

        if (this.props.meta.total_items === 0 && this.props.meta.type !== 'activity' && this.props.hideAddButton !== true) {
            return <EnabledFeature feature={this.props.meta.type + ':create'} value={true} bypass={this.props.isGroupAdmin}>
                    <footer style={{marginTop: 50}}>
                        <Fab component={Link} className={this.props.classes.fabBtn} color={'primary'} size={'large'}
                             to={createLink}><AddIcon  fontSize={'large'} /></Fab>
                        {'Add ' + Grammer.replaceAll(this.props.meta.page_title)}
                    </footer>
                </EnabledFeature>
        }

        return (
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap' style={{padding: '2%'}}>
                {this.props.meta.type !== 'activity'  && this.props.hideAddButton !== true &&
                    <EnabledFeature feature={this.props.meta.type + ':create'} value={true} bypass={this.props.isGroupAdmin}>
                        <Grid item>
                            <Fab color={'primary'} size={'small'} component={Link} to={createLink} >
                                <AddIcon />
                            </Fab>
                            <span style={{textTransform: 'capitalize', marginLeft:10}}>{Grammer.replaceAll(this.props.meta.page_title)}</span>
                        </Grid>
                    </EnabledFeature>
                }

                {
                    (this.props.meta.end_index - this.props.meta.start_index >= this.props.meta.total_items) ?
                        <Grid item>
                            {(this.props.meta.start_index + 1) + '-' + (this.props.meta.end_index > this.props.meta.total_items ? this.props.meta.total_items : this.props.meta.end_index)} / {this.props.meta.total_items}
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item>
                                <Pagination
                                    onChange={(e, pg) => this.setState({curPage:pg})}
                                    page={this.state.curPage}
                                    count={Math.ceil(this.props.meta.total_items / this.state.perpage)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            component={Link}
                                            to={this.buildPageUrl(this.state.perpage * (item.page - 1))}
                                            {...item}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    variant='standard'
                                    label={'Per page'}
                                    value={this.state.perpage}
                                    className={this.props.classes.selector}
                                    onChange={(e) => this.handlePerPageChange(e)}
                                >
                                    {[2, 3, 10, 30, 50, 100].map(perpage => (
                                            (perpage < this.props.meta.total_items + perpage || this.state.perpage === perpage) ?
                                                <MenuItem key={'perpage-' + perpage}
                                                          value={perpage}>{perpage}</MenuItem> : null
                                        )
                                    )}
                                </TextField>
                            </Grid>
                        </React.Fragment>
                }
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    const newState = {isGroupAdmin:false};
    if (state.auth.me) {
        let gid = getIdbySegment(document.location.pathname).gid;
        if (gid && typeof state.auth.me.groups[gid] !== 'undefined') {
            let gjson =  new Drupal2Json(state.auth.me.groups[gid]);
            newState.isGroupAdmin = gjson.isGroupAdmin(state.auth.me);
        }
    }
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(PaginatonBlock)));
