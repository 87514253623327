import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

class MyGeoLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {location: null, error: null, triggered:props.triggered};
        this.onFailed = this.onFailed.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    componentDidMount() {
        this.reload()
    }

    reload() {
        navigator.geolocation.getCurrentPosition(this.onSuccess, this.onFailed);
    }

/*
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.triggered > prevProps.triggered) {
            this.reload();
        }
    }
*/

    onSuccess(position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        this.setState({location:position, error:null, timestamp:new Date().getTime()})
        this.props.onLocated({triggered:new Date().getTime(), lat:position.coords.latitude, lng:position.coords.longitude});
    }

    onFailed(error) {
        let str = "Unknown";
        switch (error.code) {
            case error.PERMISSION_DENIED:
                str = error.message || "User denied the request for Geolocation."
                break;
            case error.POSITION_UNAVAILABLE:
                str = error.message || "Location information is unavailable."
                break;
            case error.TIMEOUT:
                str = error.message || "The request to get user location timed out."
                break;
            case error.UNKNOWN_ERROR:
                str = error.message || "An unknown error occurred."
                break;
        }
        console.log(str);
        this.setState({error: str, timestamp:new Date().getTime()})
        this.props.onError(str);
    };

    render() {
        return this.props.children
    }
}

MyGeoLocation.defaultProps = {
    onError: (err) => console.warn(err)
}

MyGeoLocation.propTypes = {
    onLocated: PropTypes.func.isRequired,
    onError : PropTypes.func,
    children: PropTypes.element.isRequired,
    triggered: PropTypes.number
};


export default withRouter(MyGeoLocation);
