import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import RatingSlider from '../RatingSlider';
import RateIcon from '@material-ui/icons/Stars';
import PropTypes from 'prop-types';
import {getIdbySegment} from "../../redux/authActions";
import RatingSliderHighlight from "../RatingSlider/RatingSliderHighlight";

class RatingStar extends Component {

 constructor(props) {
  super(props);
  this.state = {showSlider:(this.props.showSlider === true), defaultValue:0, voteType:props.voteType};
  this.onTypeChange = this.onTypeChange.bind(this);
  this.ratingComplete = this.ratingComplete.bind(this);
 }

 componentDidMount() {
  let val = this.getTypeValue(this.props.voteType);
  this.setState({defaultValue:val, voteType:this.props.voteType});
 }

 componentDidUpdate(prevProps) {
  if (prevProps.voteType !== this.props.voteType || prevProps.metadata.sort !== this.props.metadata.sort) {
   let val = this.getTypeValue(this.props.voteType);
   if (val !== this.state.defaultValue) {
    this.setState({defaultValue:val});
   }
  }
 }

 getTypeValue(selected) { // depends on redux update
  const types = this.props.tjson.json.field_vote_type;
  for(let r=0; r < types.length; r++) {
   if (types[r].target_id === selected) {
    return (typeof types[r][this.props.metadata.sort] === 'number') ? types[r][this.props.metadata.sort] : types[r].avg;
   }
  }
  return -1; // debug these
 }

 getTypeLabel(selected) { // depends on redux update
  const types = this.props.tjson.json.field_vote_type;
  for(let r=0; r < types.length; r++) {
   if (types[r].target_id === selected) {
    return types[r].target_label || selected;
   }
  }
  return 'vote';
 }

 getTypeLabels() { // depends on redux update
  if (this.props.tjson.json.field_vote_type.length === 0) return 'vote';
  if (this.props.tjson.json.field_vote_type.length === 1) return this.props.tjson.json.field_vote_type[0].target_label;
  let str = 'vote: ' + this.props.tjson.json.field_vote_type.map(v => v.target_label).join(', ');
  if (str.length > 30) str = str.substring(0, 30) + '...';
  return str;
 }

 onTypeChange(vtype) {
  return this.getTypeValue(vtype);
  // TODO: prompt to refresh ptlist?
 }

 ratingComplete(obj) {
  this.setState({showSlider:false, defaultValue:this.getTypeValue(this.props.voteType)});
 }

 render() {
  if (this.state.showSlider === true) {
   if (this.props.tjson.get('type', 'target_id') === 'clip' || this.props.tjson.get('type', 'target_id') === 'groups-group_node-clips') {
    return <RatingSliderHighlight
        {...this.props}
        ratingComplete={this.ratingComplete}
        onTypeChange={this.onTypeChange}
        voteType={this.props.voteType}
        defaultValue={this.state.defaultValue}  />;
   } else {
    return <RatingSlider
        {...this.props}
        ratingComplete={this.ratingComplete}
        onTypeChange={this.onTypeChange}
        voteType={this.props.voteType}
        defaultValue={this.state.defaultValue}  />;
   }

  }

  let label = this.getTypeLabels(this.props.voteType);

  return (
   <IconButton aria-label={`Rate Track ${this.props.tjson.get('id', 'value')}`} onClick={(e) => this.setState({showSlider:true})} fontSize={getIdbySegment(document.location.pathname).tid > 0 ? 'large' : 'small'} style={{paddingRight:'17px', position:'relative'}}>
    <Badge badgeContent={this.state.defaultValue > 0 ? <span>{this.state.defaultValue}<sup>%</sup></span> : label }
           color={this.props.color}
           classes={{badge:this.props.classes.badge}}>
     <RateIcon fontSize={getIdbySegment(document.location.pathname).tid > 0 ? 'large' : 'medium'} />
    </Badge>
   </IconButton>
  );
 }
}

const styles = theme => ({
 badge : {
  fontSize:10,
  height:13,
  right: 'auto',
  display:'block',
  width:'auto',
  padding:'1px 3px 0 3px;',
  borderRadius: 14,
  alignSelf: 'center',
  marginLeft: -6,
  position:'relative',
  transform:'none'
 }
});

RatingStar.defaultProps = {
 color:'primary',
 metadata : {sort:'avg'}
};

RatingStar.propTypes = {
 color:PropTypes.string,
 tjson : PropTypes.object.isRequired,
 metadata : PropTypes.object,
 voteType : PropTypes.string.isRequired,
};

export default withStyles(styles)(RatingStar);
