import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import {playTrack} from '../../redux/playerReducer';
import Drupal2Json from '../../Util/Drupal2Json';
import PropTypes from 'prop-types';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import TrackActions from '../TrackActions';
import Typography from "@material-ui/core/Typography";
import ImageIcon from "@material-ui/icons/Camera";

const PreloadComponent = props => {
 const {classes} = props;
 const node = new Drupal2Json(props.track.node);

 const handleTileClick = (e) => {
  if (e.target && e.target.nodeName && e.target.className) {
   const cl = e.target.getAttribute('class') || '';
   if (e.target.nodeName.toUpperCase() === 'IMG' || cl.indexOf('MuiGridListTileBar') > -1 || cl.indexOf('fallbackImg') > -1 || cl.indexOf('MuiTypography-root') > -1) {
    props.dispatch(playTrack(props.track));
    return false;
   }
  }
 };

 let imgUrl = node.get('field_cover', 'url');
 if (node.get('field_yt_id', 'value') && (!imgUrl || imgUrl.indexOf('/video_thumbnails/') > -1) ) {
  imgUrl = `https://img.youtube.com/vi/${node.get('field_yt_id', 'value')}/default.jpg`;
 }

 let gridClass = '';
 if (props.containerWidth ===  'player') {
  gridClass = classes.playerTile;
 } else if (props.containerWidth ===  'list') {
  gridClass = classes.listTile;
 } else if (typeof props.containerWidth === 'number') {
  gridClass = classes['gridTile' + props.containerWidth];
 } else {
  gridClass = classes.gridTile;
 }

 return (
  <GridListTile
   className={'trackTile'}
   onClick={handleTileClick}
   classes={{root: gridClass}} >
   {imgUrl ?
    <img src={imgUrl} className={classes.carouselImg} alt='track cover'/>
    :
    <div className={classes.fallbackImg}>
     <ImageIcon color={'secondary'} fontSize='large' style={{alignSelf: 'center'}}/>
    </div>
   }

   <GridListTileBar
    title={
     <>
      <Typography className={classes.titleText} variant={'h5'}> {node.get('title')}</Typography>
      <Typography className={classes.artistText} variant={'h6'}>{node.get('field_artists')}</Typography>
     </>
    }
    subtitle={<TrackActions key={'tactions-'+props.track.id[0].value} profile={props.profile} track={props.track} dispatch={props.dispatch} />}

    classes={{
     root: classes.titleBar,
     titleWrap: classes.titleWrap,
     title: classes.title,
     subtitle: classes.subtitle
    }}
   />
  </GridListTile>
 );
};

export const tileStyles = theme => ({
 gridTile: {
  margin: 0,
  width: '33%',
  maxWidth:600,
  height:200,
  listStyle:'none'
 },
 gridTile50: {
  margin: 0,
  width: '50%',
  maxWidth:600,
  height:200,
  listStyle:'none'
 },
 gridTile100: {
  margin: 0,
  width: '100%',
  maxWidth:600,
  height:200,
  listStyle:'none'
 },
 playerTile: {
  margin: 0,
  width: '22%',
  height:150,
  listStyle:'none'
 },
 listTile: {
  margin: 0,
  width: '20%',
  height:200,
  listStyle:'none'
 },
 curTrack : {
  border:  theme.palette.text.primary,
 },
 titleBar: {
  flexWrap: 'wrap',
  height:'100%',
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  color: theme.palette.text.primary,
 },
 titleWrap: {
  textShadow: '1px 1px 2px black',
  padding:8,
  marginLeft:0,
  display:'flex',
  height:'100%',
  flexDirection:'column',
  justifyContent:'space-between'
 },
 titleText : {
  fontSize:13,
  fontWeight:800,
 },
 artistText : {
  fontSize:11,
  fontWeight:500,
 },
 title : {
  alignSelf:'flex-start',
  whiteSpace:'normal',
  marginBottom:10
 },
 subtitle : {
  display:'flex',
  width:'100%',
 },
 tab : {
  minWidth: 'auto',
 },
 popOver : {
  padding:theme.spacing(1),
  background: alpha(theme.palette.background.paper, .9),
  minWidth:300
 },
 filterFormControl : {
  marginTop:10,
 },
 countBadge: {
  fontSize: '70%',
  padding:0,
  top: 7,
  right: -4
 },
 fallbackImg: {
  display: 'flex',
  justifyContent: 'center',
  height:'100%',
  cursor: 'pointer',
  alignSelf: 'flex-start',
  background: `radial-gradient(circle at 50% 50%, ${theme.palette.background.paper}, ${theme.palette.primary.main})`,
  '& svg': {
   fill: theme.palette.primary.main,
  }
 },
 filterLabel : {
  fontSize:10,
  fontWeight:900,
  color: theme.palette.text.hint,
  textTransform:'uppercase'
 },
 genreAvatar : {
  objectFit:'contain'
 },
 denseSelect : {
  padding:'6px 2px'
 }
});

const StyleWithThemeProps = (props) => {
 return withStyles(tileStyles, {withTheme: true})(PreloadComponent);
};

// export default withStyles(styles)(TrackTile);

class TrackTile extends React.PureComponent {

 constructor(props, context) {
  super(props);
  this.StyledPreloadFull = false;
 }

 UNSAFE_componentWillMount() { // componentWillMount deprecated :/
  this.StyledPreloadFull = StyleWithThemeProps(this.props);
 }

 render() {
  const {StyledPreloadFull, props} = this;

  return (
   <StyledPreloadFull {...props}/>
  );
 }
}

TrackTile.defaultProps = {
 ctas: {'youtube': true, 'mp3': false, 'rate': false, 'bet': false, 'info': false, 'edit': false, 'delete': false, 'copy':false}
};

TrackTile.propTypes = {
 track: PropTypes.object.isRequired,
 containerWidth : PropTypes.any.isRequired,
};

export default TrackTile;
