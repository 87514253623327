import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import {ReactComponent as PlaceChips} from '../../images/PlaceChips.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import RateIcon from '@material-ui/icons/Stars';

class FAQplaylists extends Component {
 constructor(props) {
  super(props);
  this.state = { expanded: this.props.expanded };
 }

  handleChange = panel => (event, expanded) => {
   if (panel === this.state.expanded) {
    this.setState({expanded:false}); // close
   } else {
    this.setState({expanded:panel});
   }
  };


  render() {
   const {classes, gid } = this.props;
   if (gid !== 9 && gid !== 6) return '';

   return (
    <div className={classes.header}>
     {(gid === 9) ?
      <div>
       <Typography variant='h5' style={{marginBottom:0, marginTop:15, textAlign:'center'}}>Crowdsourced Communal Playlists</Typography>
       <Typography variant='h6' style={{marginTop:0, marginBottom:15, textAlign:'center', fontStyle:'italic'}}>Rewarding Musical Tastes</Typography>
       <Accordion expanded={this.state.expanded === 'howtoplay'} onChange={this.handleChange('howtoplay')} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
         <Typography variant='h5'>How to Play</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <div>
          <ul className={classes.list}>
           <li className={classes.listItem}>Click Play  <PlayIcon color='primary'/>  to listen to any song from the playlist themes below</li>
           <li className={classes.listItem}>Click Add a Track  <PlaylistAdd color='primary' />  to contribute a song that matches the theme</li>
           <li className={classes.listItem}>Click Rate  <RateIcon color='primary' />  to earn &#x20AE;AC and help refine the playlists</li>
           <li className={classes.listItem}>Click Upgrade  <SvgIcon color='primary' component={PlaceChips} viewBox='0 0 24 24'/> to unlock play preferences and earn a chance to win part of the pool</li>
          </ul>

          <Typography variant='h6'>Check each playlists for specific rules and rates.</Typography>
         </div>
        </AccordionDetails>
       </Accordion>
      </div>
      : (gid === 6) ?
       <div>
        <Typography variant='h2' className={classes.headerTitle}>Beat Bazaar is still is Beta.</Typography>
        <Accordion expanded={this.state.expanded === 'howtoplay'} onChange={this.handleChange('howtoplay')} >
         <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant='h5'>How to Play</Typography>
         </AccordionSummary>
         <AccordionDetails>
          <ul className={classes.list}>
           <li className={classes.listItem}><b>Complete your profile</b> as a Producer or Vocalist</li>
           <li className={classes.listItem}><b>Create your own listening playlist</b> to share your all or part of your catalogue</li>
           <li className={classes.listItem}><b>Commission a style</b> or composition by creating a playlist with your own artist direction</li>
           <li className={classes.listItem}><b>Upload your music</b> to match the theme or genre of any open challenges.</li>
           <li className={classes.listItem}>Check Playlists for exact schedule, rules and awards</li>
          </ul>
         </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'legalrules'} onChange={this.handleChange('legalrules')} >
         <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        Legal Rules
         </AccordionSummary>
         <AccordionDetails>
          <ul className={classes.list}>
           <li className={classes.listItem}>You must have legal rights to license any music you post in this group.</li>
           <li className={classes.listItem}>You grant <em>TAM</em> rights to play your uploaded music to others using this service. (TAM owns none of your work and cannot publish it elsewhere)</li>
          </ul>
         </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'devroad'} onChange={this.handleChange('devroad')} >
         <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                          Development Roadmap
         </AccordionSummary>
         <AccordionDetails>
          <ul className={classes.list}>
           <li className={classes.listItem}>Automate copyright ownership verification</li>
           <li className={classes.listItem}>Offer template licensing options and facilitate licensing and file transfers</li>
           <li className={classes.listItem}>Messaging tools between Producers and Vocalists</li>
          </ul>
         </AccordionDetails>
        </Accordion>
       </div>
       : ''
     }
    </div>
   );
  }
}

const styles = theme => ({
 header: {
  width:'100%',
  padding:0,
  marginTop:0,
  marginBottom:10,
  justifyContent:'center'
 },
 list : {
  width:'100%',
  marginTop:0,
  marginBottom:10,
  listStyle:'decimal',
 },
 listItem :{
  marginBottom:4,
  '& svg' : {
   marginBottom:-9,
   marginLeft:2,
   marginRight:2
  }
 },
 headerTitle : {
  textAlign:'center',
  fontWeight:'normal',
  margin:'0 0 10px 0',
 },
 subHeading : {
  marginTop:10,
  marginBottom:4,
  fontWeight:'normal',
  textAlign:'center',
  textDecoration:'underline'
 }
});


export default withStyles(styles)(FAQplaylists);
