import React from 'react';
import SanitizedHTML from "react-sanitized-html";
import Config from "../../Config";
import PropTypes from "prop-types";

class FormattedText extends React.Component {
    render() {

        if (this.props.format === 'plaintext') return this.props.value;
        let toprint = '';
        if (this.props.displayField === 'summary') {
            toprint = this.props.summary;
        } else if (this.props.displayField === 'value') {
            toprint = this.props.value;
        } else { // auto
            toprint = (this.props.summary && this.props.summary !== "") ? this.props.summary : this.props.value;
        }
        if (!toprint || toprint === '') return null;
        if (toprint.indexOf('\\n') > -1) {
            toprint = toprint.replace(/\n/g,'<br />')
        }

        let tags = Config.allowedTags, atts = Config.allowedAttributes;
        if (this.props.format === 'full_html') {
           tags = Config.allowedTags.concat('a')
        }

        return (
            <SanitizedHTML allowedTags={tags} allowedAttributes={atts} html={toprint} />
        );
    }
}

FormattedText.defaultProps = {
    format:'plaintext',
    displayField:'auto'
}

FormattedText.propTypes = {
    format: PropTypes.string,
    value: PropTypes.string.isRequired,
    summary: PropTypes.string,
    displayField:PropTypes.string
};

export default FormattedText
