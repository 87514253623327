import {useEffect} from 'react';

export function getParam(name, url, d) {
 if (!d && typeof d !== 'number') d = '';
 if (!url) url = document.location.search;
 if (url.indexOf('?') > -1) url = url.slice(url.indexOf('?') + 1);
 let parts = url.split('&');
 let vals =  [];
 parts.forEach(e => {
  let param = e.split("=");
  if (param[0] === name) vals.push(param[1]);
 });
 if (vals.length === 0) return d;
 if (vals.length === 1) return vals[0];
 return vals;
}

export function getStartupParam(name, url, d) { // keeps these params persistent: https://pickupmvp.com?appOS=ios&paddingTop=44.0&paddingBottom=34.0
 if (localStorage.getItem(process.env.REACT_APP_TACLIENT_DIR + name)) {
  return localStorage.getItem(process.env.REACT_APP_TACLIENT_DIR + name)
 }
 const val = getParam(name, url, d);
 if (val) {
  localStorage.setItem(process.env.REACT_APP_TACLIENT_DIR + name, val);
 }
 return val;
}

export function setQueryParam(path, params){
 // if (path.indexOf("http") !== 0) path = document.location.protocol + document.location.hostname + path;
 const Url = new URL(path, document.location.protocol + document.location.hostname);
 const urlParams = new URLSearchParams(Url.search);
 for (const key in params) {
  if (params[key] !== undefined) {
   urlParams.set(key, params[key]);
  }
 }
 Url.search = urlParams.toString(); // adds ? or leaves empty
 return Url.pathname + Url.search + Url.hash;
 // return Url.toString();
}

export function useParamListener(paramName, callback) {
 useEffect(() => {
  const handleQueryParamChange = () => {
   const searchParams = new URLSearchParams(window.location.search);
   const searchValue = searchParams.get(paramName);
   callback(searchValue);
  };

  // Initial call to set up the initial value
  handleQueryParamChange();

  // Listen for popstate events (back/forward navigation) and call the callback
  const popstateListener = () => handleQueryParamChange();
  window.addEventListener('popstate', popstateListener);

  return () => {
   window.removeEventListener('popstate', popstateListener);
  };
 }, [callback]);
}
