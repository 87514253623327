import React, {Component} from 'react';
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const MAP = {
    "Rating": false,
    "Won": "Wins",
    "Lost": "Losses",
    "Recommendation": false,
    "GameOrganized": "Matches organized",
    "Played": "Matches Played",
    "MVP": "MVPs",
    "NoShows": "No Shows",
    "UserLevel": false,
    "UserLevelName": "User Level",
    "Id": false,
    "FullName": false,
    "PlayerPosition": "Preferred Position",
    "PreferredSport": "Preferred Sport",
    "InterestedSport": false,
    "Nationality": false,
    "Dob": false,
    "PlayerHeight": "Height",
    "PlayerWeight": "Weight",
    "Latitude": false,
    "Longtitude": false
}


class Sport12Stats extends Component {

    render() {
        if (!this.props.data) return null;

        let parts = [];
        for(let key in MAP) {
            if (MAP[key] === false || typeof this.props.data[key] === 'undefined' || this.props.data[key] === "NULL" || this.props.data[key] === "N/A" || this.props.data[key] === "") continue;
            if (this.props.showKeys && typeof this.props.showKeys[key] === 'undefined') continue;
            parts.push(<ListItem key={`${this.props.data.Id}-stat-${key}`}>
                <ListItemText primary={MAP[key]} secondary={this.props.data[key]} />
            </ListItem>)
        }
        return <List component="ul" dense={true} style={{display:'flex', flexWrap:'wrap'}} >{parts}</List>
    }
}

Sport12Stats.propTypes = {
    data : PropTypes.object.isRequired,
    showKeys: PropTypes.object
};

export default Sport12Stats;
