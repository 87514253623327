import React, {Component} from 'react';
import Mp3Icon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {addMediaItem, submitPublicMedia} from "../../redux/formsReducer";
import API from "../../Util/API";
import {withSnackbar} from 'notistack';
import {connect} from "react-redux";
import ProgressLoading from "../ProgressLoading";
import Config from "../../Config";

class MediaPublicUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {loading:false, filedata: false, mediatype: false, mediaTitle: '' };

        this.fileEl = React.createRef();
        this.startBrowse = this.startBrowse.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onRateChange = this.onRateChange.bind(this);
    }

    startBrowse(e) {
        this.fileEl.current.click();
    }

    componentWillUnmount() {
        if (this.state.filedata) {
            URL.revokeObjectURL(this.state.filedata);
        }
    }

    handleChange(event) {
        if (event.target.files.length === 0) return false; // cancelled change
        var file = event.target.files[0];
        var name = file.name.substring(0, file.name.lastIndexOf('.')); // remove extension
        name = name.replace(/[^a-zA-Z\d\s:\u00C0-\u00FF]/g, ' '); // remove all nonalphanumeric except space, color and latin equivalents
        name = name.replace('  ', ' '); // remove all nonalphanumeric except space, color and latin equivalents
        const data64 = URL.createObjectURL(file);
        this.setState({filedata: data64, mediaTitle: name, mediatype:file.type},
            () => this.submitForm());
    }

    onTitleChange(e) {
        this.setState({mediaTitle: e.target.value});
    }

    onRateChange(e) {
        this.setState({ppp: parseFloat(e.target.value).toFixed(3)});
    }

    submitForm() {
        this.setState({loading:true});
        if (!this.fileEl || !this.fileEl.current) {
            alert('please provide a file');
            return false;
        }
        submitPublicMedia(this.fileEl.current, this.fileEl.name, this.props.forms.apiurl)
        .then((res) => {
            this.setState({loading:true});
            const err = API.checkError(res.data);
            console.log(res, err);
            if (err.length > 0) {
                this.props.enqueueSnackbar(err, {variant:'error'});
            } else {
                // TODO: display and pass back target_id
                // this.props.handleSuccess(res.data);
                this.props.enqueueSnackbar('Upload Successful', {variant:'info'});
                this.props.dispatch(addMediaItem(res.data, this.props.field));
            }
            this.setState({loading:false});
        }).catch((err) => {
            var msg = API.getErrorMsg(err);
            console.error(msg)
        });

    }

    makeAbsolute(url) { // because current rest endpoint doesn't handle normalization properly
        if (url.indexOf('http') === 0) return url;
        return Config.api.base + url;
    }

    render() {
        const {classes, entry, index} = this.props; // field,
        let progress = this.state.loading === true ?
            <ProgressLoading position='absolute'/>
            : (this.state.filedata ?
                <div className={classes.filedata} style={{backgroundImage: 'url(' + this.state.filedata + ')'}}>&nbsp;</div>
                :
                (entry[index] && entry[index].url ?
                    <div className={classes.filedata} style={{backgroundImage: 'url(' + this.makeAbsolute(entry[index].url) + ')'}}>&nbsp;</div>
                    : null));


        return (
            <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>

                <Button color='primary'
                        disabled={this.state.loading}
                        className={classes.uploadBtn}
                        startIcon={<Mp3Icon/>} onClick={this.startBrowse}
                        variant='contained'>Browse (jpg, jpeg, gif, png, svg)</Button>
                <input
                    className={classes.fileInput}
                    accept="image/*"
                    onChange={this.handleChange}
                    ref={this.fileEl}
                    name='file'
                    type="file"
                />

                {progress}

            </div>
        );
    }
}


const styles = theme => ({
    filedata: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
        width: '100%', height: 200,
    },
    fileInput: {
        display: 'none'
    },
    mediaEl: {
        width: '100%',
        minHeight: 20,
        margin: '8px 0'
    },
    uploadBtn: {
        width: '100%',
        textTransform: 'none',
        margin: '8px 0'
    },
    videoEl: {
        maxHeight: '90vh',
        maxWidth: '100%',
        minHeight: 250,
        margin: '8px 0'
    },
    inlineBulletList: {
        flexDirection: 'row-reverse',
        textAlign: 'center', justifyContent: 'center', width: '100%', marginTop: 20,
        letterSpacing: 1,
        fontWeight: 600,
        lineHeight: '26px',
        '& a': {
            textDecoration: 'none'
        }
    }
});

const mapStateToProps = (state) => {
    return {forms :state.forms};
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSnackbar(withStyles(styles)(MediaPublicUpload)));
