import React, {Component} from 'react';
import AutoCompleteEntity from '../../Components/AutoCompleteEntity';
import API from "../../Util/API";
import Typography from "@material-ui/core/Typography";
import {getIdbySegment} from "../../redux/authActions";
import PropTypes from 'prop-types';
import FileCopy from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import Grammer from '../../Util/Grammer';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

const groupField = {
 "field_name": "gid",
 "label": "My Groups",
 "type": "entity_reference",
 "bundle": "groups",
 "cardinality": 1,
 "default_value": [],
 "settings": {
  "target_type": "group",
  "handler": "default",
  "handler_settings": {
   "target_bundles": ["groups"]
  }
 },
 "#type": "container",
 "#required": false,
 "#weight": 0,
 "widget": [{
  "#required": true
 }]
};

const playlistField = {
 "field_name": "pid",
 "label": "My Playlists",
 "type": "entity_reference",
 "bundle": "playlists",
 "cardinality": 1,
 "default_value": [],
 "settings": {
  "target_type": "group_content",
  "handler": "default",
  "handler_settings": {
   "target_bundles": ["playlists"]
  }
 },
 "#type": "container",
 "#required": false,
 "#weight": 0,
 "widget": [{
  "#required": true
 }]
};

class Copier extends Component {

 constructor(props) {
  super(props);
  this.state = {showForm: false, results:false, loading:false};
  this.handlePlaylistSelection = this.handlePlaylistSelection.bind(this);
  this.handleGroupSelection = this.handleGroupSelection.bind(this);
 }

 componentDidMount() {
  this.tdata = getIdbySegment(document.location.pathname);
  this.tdata.page = 'copier';
  window.logUse.logEvent('view', this.tdata);
 }

 submitCopy(path) {
  const that = this;
  that.tdata.value = path;
  this.setState({loading:true});
  API.Get(path).then(res => {
   if (res.data.error) {
    window.alert(res.data.error);
    window.logUse.logEvent('copy-failed', that.tdata);
   } else {
    that.setState({results:res.data});
    that.tdata.value = path;
    window.logUse.logEvent('copy', that.tdata);
   }
   this.setState({loading:false});
  }).catch(e => {
   that.setState({'success':[], 'dups':['error copying']});
   window.logUse.logEvent('copy-failed', that.tdata);
   this.setState({loading:false});
  });
 }

 handleGroupSelection(value) {
  if (this.state.loading === false && value.target_id > 0 && window.confirm('Are you sure you want to copy ' + this.props.source.get('label') + ' to ' + value.target_label)) {
   this.submitCopy('/copier/playlist/' + this.props.source.get('id') + '/' + value.target_id);
  }
 }

 handlePlaylistSelection(value) {
  if (this.state.loading === false && value.target_id > 0 && window.confirm('Are you sure you want to copy ' + this.props.source.get('label') + ' to ' + value.target_label)) {
   const path = this.props.source.get('type', 'target_id') === 'groups-group_node-tracks' ? '/copier/track/' : '/copier/tracks/';
   this.submitCopy(path + this.props.source.get('id') + '/' + value.target_id);
  }
 }

 renderResults(key, label) {
  if (this.state.results[key].length === 0) return '';
  return <>
   <Typography style={{marginTop: 20}} variant={'h6'}>{label}</Typography>
   <ul>{this.state.results[key].map((title, index) => <li key={index}>{title}</li>)}</ul>
  </>;
 }

 render() {
  if (this.state.showForm === false) {
   return (<IconButton onClick={e => this.setState({showForm: true})}>
    <FileCopy color={this.props.color} />
   </IconButton>);
  }

  let type = this.props.source.get('type', 'target_id');

  let content = null;
  if (this.state.loading === true) {
   content = <div style={{textAlign:'center', minWidth:300}}><CircularProgress color='secondary' /></div>;
  } else if (this.state.results) {
   content = <div>
    <Button style={{marginTop: 20}} onClick={e => this.setState({results:false})} variant={'contained'} color={'primary'} >Reset Form</Button>
    {this.renderResults('success', 'Successfully copied!')}
    {this.renderResults('dups', 'Duplicates ignored')}
   </div>;
  } else {
   content = (type === 'groups-group_node-tracks') ?
    <>
     <Typography variant={'h6'}>Copy to another {Grammer.g('playlists', 's')}</Typography>
     <AutoCompleteEntity
      apiurl={'/autocomplete/open_playlists/:query/-1'}
      onSelected={this.handlePlaylistSelection}
      source='playlists'
      name='dest'
      placeholder={'Search my ' + Grammer.g('playlists', 'p')}
      field={playlistField} entry={[]} index={0} />
    </>
    :
    <>
     <Typography variant={'h6'}>Copy entire {Grammer.g('playlists', 's')} to another group</Typography>
     <AutoCompleteEntity
      apiurl={'/autocomplete/my_groups/:query'}
      onSelected={this.handleGroupSelection}
      name='group'
      placeholder={'Search my ' + Grammer.g('groups', 'p')}
      field={groupField} entry={[]} index={0} />


     <Typography style={{marginTop:20}} variant={'h6'}>Copy all {Grammer.g('tracks', 'p')} from this {Grammer.g('playlists', 's')} to another active {Grammer.g('playlists', 's')}</Typography>
     <AutoCompleteEntity
      apiurl={'/autocomplete/my_playlists/:query/' + this.props.source.get('field_category', 'target_id')}
      onSelected={this.handlePlaylistSelection}
      source='playlists'
      name='dest'
      placeholder={'Search my ' + Grammer.g('playlists', 'p')}
      field={playlistField} entry={[]} index={0}/>

    </>;

  }

  return <Dialog maxWidth='sm' fullWidth={false} open={this.state.showForm === true}>
   <DialogTitle>
                Copy <em>{this.props.source.get('label')}</em>
    <IconButton style={{float:'right', marginTop:-10}} onClick={e => this.setState({showForm:false})}  ><CloseIcon  color="secondary"/></IconButton>
   </DialogTitle>
   <DialogContent style={{paddingBottom:20}}>
    {content}
   </DialogContent>
  </Dialog>;


 }
}


Copier.defaultProps = {
 color:'inherit'
};

Copier.propTypes = {
 source: PropTypes.object.isRequired,
 color:PropTypes.string.isRequired
};

export default Copier;
