import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Chip from "@material-ui/core/Chip";
import {Close} from "@material-ui/icons";
import {getStartupParam} from "../../Util/WindowUtils";


class PromptSignIn extends Component {
    render() {

        const styles = {padding:'20px 0'};
        styles.marginTop = parseInt(getStartupParam('paddingTop', null, 0)); // WARN: make sure snackbar's anchorOrigin has vertical: 'top'

        return (
            <Chip size={'medium'}
                  style={styles}
                  clickable={false}
                  deleteIcon={<Close onClick={() => this.props.onClose()}  />}
                  onDelete={() => this.props.onClose()}
                  color={'primary'} label={<h3>Please  <Link to={'/signin'} >Sign In</Link> or <Link to={'/signup'} >Sign Up</Link></h3>} />
        );
    }
}

export default PromptSignIn;
