import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MediaItem from "../../Components/MediaItem";
import {Link} from "react-router-dom";
import ClipEfx from "./ClipEfx";
import ClipActions from "./ClipActions";
import CardActions from "@material-ui/core/CardActions";
import EditableTitle from "./EditableTitle";

class ClipBlock extends Component {

    render() {
        const {classes} = this.props;
        const json = new Drupal2Json(this.props.clip);
        const {rid} = this.props;
        const gid = json.get('gid', 'target_id');

        const footageLink = `/group/${gid}/rewards/${rid}/footage/${json.get('entity_id', 'target_id')}`
        const entityLink = `${footageLink}/clips/${json.get('id', 'value')}`;

        let cliptime = json.findPropByVal('field_videfx', 'CLIP', 'efx_type');
        if (!cliptime) {
            console.log("CLIP MISSING clip efx: " + json.get('id'), this.props.clip.field_videfx)
            cliptime = {start_time :0}
        }

        let media = json.findPropByVal('field_clip', 'youtube', 'target_bundle');
        if (!media) media = this.props.clip.field_clip[0];

        return (<Card className={classes.card}>
                <CardHeader title={json.can_edit(this.props.me) ? <EditableTitle title={json.getLabel()} entityLink={entityLink} /> : json.getLabel()} />
                <MediaItem media={media} entityLink={entityLink} poster={json.get('field_cover', 'url')} />
                <CardActions>
                    <ClipActions entityLink={entityLink} clip={json} />
                </CardActions>
                <CardContent>
                    <ClipEfx cjson={json} />
                    <small style={{display:'block', textAlign:'right'}}><Link to={`${footageLink}?start_time=${cliptime.start_time}`}>View full footage</Link></small>
                </CardContent>
         </Card>
        );
    }
}

ClipBlock.propTypes = {
    classes: PropTypes.object.isRequired,
    clip: PropTypes.object.isRequired,
    gid: PropTypes.number.isRequired,
    rid: PropTypes.number.isRequired,
    me: PropTypes.object
};

const styles = theme => ({
    card: {
        width: '100%',
        maxWidth:800,
        background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, rgba(10,119,213,0) 50%, ${theme.palette.background.paper} 100%)`,
        marginBottom:25
    }
});

export default withStyles(styles, {withTheme: true})(ClipBlock);
