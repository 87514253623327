import React, {Component} from 'react';
import Drupal2Json from '../../Util/Drupal2Json';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/styles";
import {headFootStyles} from "../../Util/ThemeUtils";

class UserAvatar extends Component {

 render() {

  const item = this.props.data;
  const ujson = new Drupal2Json(item);
  const topass = {variant : this.props.variant};
  if (this.props.classOverrides) {
    topass.classes = this.props.classOverrides;
  }
  if (this.props.size !== 40) {
   topass.style = {height:this.props.size, width: this.props.size};
  }

  let icon = '';
  if (typeof item.url === 'string') {
   return <Avatar {...topass} aria-label="Member avatar" src={item.url} />;
  } else if (typeof item.uid !== 'undefined' || typeof item.id !== 'undefined') { // is a full entity with fields
   icon = ujson.getImgPath({'field_headshot':'url', 'uid':'url', 'user_picture':'url', 'field_fb_id':'value'}); // 'uid':'url',
   if (icon) {
    return <Avatar {...topass} aria-label="Member avatar" src={icon}  />;
   }
   icon = ujson.getAvatarPath({'field_avatar':'value', 'entity_id':'avatar'}, this.props.theme.palette.type);
   if (icon) {
    if (!topass.classes) topass.classes = {}
    topass.classes.root = this.props.classes.softAvatar;
    return <Avatar {...topass} aria-label="Member avatar" src={icon}  />;
   }
  } else if (typeof item.target_fbid !== 'undefined') {
   return <Avatar {...topass} aria-label="Member avatar" src={'https://graph.facebook.com/' + item.target_fbid + '/picture?width=70&height=70&redirect=true'} />;
  } else if (typeof item.avatar !== 'undefined') {
   icon = ujson.getConfig('api').client_host+'/avatars/'+(this.props.theme.palette.type === 'light' ? 'black' : 'white')+'/' + item.avatar + '.png';
   if (!topass.classes) topass.classes = {}
   topass.classes.root = this.props.classes.softAvatar;
   return <Avatar {...topass} aria-label="Member avatar" src={icon} />;
  }
  if (item.target_label) {
   icon = item.target_label.substr(0, 3);
  } else {
   icon = ujson.getImgPath({'field_name':'value', 'target_label':'value', 'label':'value', 'title':'value'});
   if (icon) icon = icon.substr(0, 3);
   else icon = 'ERR';
  }
  return <Avatar {...topass} aria-label="Member Letters" style={{fontSize:'130%',textDecoration:'none'}} >{icon}</Avatar>;
 }
}

UserAvatar.defaultProps = {
 variant: 'rounded',
 size:40
};

UserAvatar.propTypes = {
 data: PropTypes.object.isRequired,
 size:PropTypes.number.isRequired,
 classOverrides:PropTypes.object,
 variant:PropTypes.string
};

export default withStyles(headFootStyles, {withTheme:true})(UserAvatar);
