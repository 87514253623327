import PlayButton from './PlayButton';
import {connect} from 'react-redux';
import {pausePlayer, playTrack, testPlay} from '../../redux/playerReducer';

const mapStateToProps = (state) => {
 return {
  curTrack : state.player.curTrack,
  status : state.player.status,
  media : state.player.media
 };
};

const mapDispatchToProps = dispatch => {
 return {
  testPlay: (obj, source) => {
   dispatch(testPlay(obj, source));
  },
  playTrack: (obj, source) => {
   dispatch(playTrack(obj, source));
  },
  pausePlayer : () => {
   dispatch(pausePlayer());
  }
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(PlayButton);
