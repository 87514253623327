import {connect} from 'react-redux';

import Transactions from './Transactions';

const mapStateToProps = (state) => {
 var newState = {
  me:state.auth.me,
  curGroup:state.auth.curGroup,
  balance:state.wallet.balance
 };
 newState.lists = state.lists;
 return newState;
};

const mapDispatchToProps = dispatch => {
 return {
  dispatch
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(Transactions);
