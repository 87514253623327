import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import API from "../../Util/API";
import {Link, withRouter} from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";
import {withSnackbar} from "notistack";
import {Edit} from "@material-ui/icons";
import {connect} from "react-redux";

class FootageActions extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false};
    }

    promptDelete(apiurl) {
        if (window.confirm('Are you sure you want to delete this footage?')) {
            API.Delete(apiurl)
                .then((res) => {
                    var msg = API.checkError(res.data);
                    if (msg.length > 0) {
                        this.props.enqueueSnackbar(msg, {variant: 'error'});
                    } else {
                        this.props.enqueueSnackbar(res.data.success, {variant: 'info'});
                        this.props.history.go(0)
                    }
                })
                .catch((err) => {
                    var msg = API.getErrorMsg(err);
                    this.props.enqueueSnackbar(msg, {variant: 'error'});
                    return err;
                });
        }
    }

    render() {
        const {fjson} = this.props;
        const inPage = [];
        if (fjson.can_delete(this.props.me) === true) {
            inPage.push(<IconButton
                size={20}
                key={`delete-${this.props.entityLink}`}
                onClick={() => this.promptDelete(`/forms${this.props.entityLink}/delete`)} >
                <DeleteIcon fontSize={'small'}/>
            </IconButton>);
        }

        if (fjson.can_edit(this.props.me)) {
            inPage.push(<IconButton key={`edit-${this.props.entityLink}`} component={Link}
                                    to={`/forms${this.props.entityLink}/edit`} >
                    <Edit color={this.props.color}/>
                </IconButton>
            );
        }

        return <div>
            {inPage}
        </div>

    }
}


const mapStateToProps = (state, props) => {
    var newState = {me: state.auth.me};
    return newState;
};

const mapDispatchToProps = dispatch => {
    return {dispatch};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withSnackbar(FootageActions)));
